import React, { useEffect, useState } from "react";
import { backgroundModal, boxModalV3 } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, Select, Typography, MenuItem } from "@mui/material";
import { notifyInfo } from "../NotifyToast";
import { useDispatch } from "react-redux";
import { client_audio } from "../../store/audioSlice";
export default function MoadlExaminationRoomQueue({ onClose, roomList, onSubmit }) {

    const dispatch = useDispatch()

    const [roomSelected, setRoomSelected] = useState({
        room_name_1: '',
        room_name_2: '',
        room_name_3: ''
    })

    const [selectedRoomOne, setSelectedRoomOne] = useState(null);
    const [selectedRoomTwo, setSelectedRoomTwo] = useState(null)

    const [selectedAudio, setSelectdAudio] = useState({
        name: 'ปิดเสียง',
        value: 'OFF_AUDIO',
    })

    useEffect(() => {
        console.log(roomList)
    }, [roomList])


    useEffect(() => {
        console.log(roomSelected)
    }, [roomSelected])

    useEffect(() => {
        console.log(selectedAudio)
        if (selectedAudio) {
            dispatch(client_audio(selectedAudio))
        }
    }, [selectedAudio])

    useEffect(() => {
        if (selectedRoomOne) {
            setRoomSelected(prevForm => ({
                ...prevForm,
                room_name_1: selectedRoomOne,
            }));
        }

        console.log(selectedRoomOne)
    }, [selectedRoomOne]);


    useEffect(() => {
        if (selectedRoomTwo) {
            setRoomSelected(prevForm => ({
                ...prevForm,
                room_name_2: selectedRoomTwo,
            }));
        }

        console.log(selectedRoomTwo)
    }, [selectedRoomTwo]);

    const HandleChangeSelectRoom = (name, value) => {
        console.log('log selecgt', name, value)
        if (name === 'room_name_1') {
            setSelectedRoomOne(value)
            setSelectedRoomTwo(null)
            return
        }

        if (name === 'room_name_2') {
            setSelectedRoomTwo(value)
        }

    }

    const handleDisabled = (room) => {
        const disable = roomList.some((item => item['key'] == room))
        console.log(disable)
        if (disable) {
            return true
        } else {
            return false
        }

    }

    // const handleChangAudio = (value) => {

    // }

    return (
        <>
            <Box sx={backgroundModal}>
                <Box sx={boxModalV3}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={() => { onClose() }}><ClearIcon />
                    </Box>
                    <Box>
                        <Typography sx={{
                            fontSize: 22,
                            fontWeight: 'bold',
                            '@media (max-width: 800px)': {
                                fontSize: 16
                            }
                        }}>เลือกห้อง</Typography>
                        <div style={{ borderBottom: '1px solid #E1E3E9' }}></div>
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: 20,
                        margin: '25px 0'
                    }}>
                        <FormControl>
                            <Typography fontWeight={'bold'}>เลือกห้องที่ 1</Typography>
                            <Select
                                size="small"
                                name='room_name_1'
                                renderValue={(selected) => selectedRoomOne ? selected : ''}
                                value={selectedRoomOne || ''}
                                error={selectedRoomOne ? false : true}
                                // disabled={handleDisabled('1') ? false : true}
                                onChange={(event) => {
                                    const {
                                        target: { name, value },
                                    } = event;

                                    HandleChangeSelectRoom(name, value)
                                }}
                            >
                                <MenuItem
                                    value={{
                                        name: 'ปิดห้อง',
                                        value: ''
                                    }}
                                >
                                    ปิดห้อง
                                </MenuItem>
                                {/* <MenuItem
                                    value={{
                                        name: 'เปิดห้อง',
                                        value: '1'
                                    }}
                                >
                                    เปิดห้อง
                                </MenuItem> */}
                                {roomList.map(i => (
                                    <MenuItem
                                        key={i['key']}
                                        value={i['key']}
                                    >{'เปิดห้อง' + ' ' + i['key']}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography fontWeight={'bold'}>เลือกห้องที่ 2</Typography>
                            <Select
                                size="small"
                                name='room_name_2'
                                renderValue={(selected) => selectedRoomTwo ? selected : ''}
                                value={selectedRoomTwo || ''}
                                error={selectedRoomTwo ? false : true}
                                disabled={handleDisabled('2') ? false : true}
                                onChange={(event) => {
                                    const {
                                        target: { name, value },
                                    } = event;

                                    HandleChangeSelectRoom(name, value)
                                }}
                            >
                                <MenuItem
                                    value={{
                                        name: 'ปิดห้อง',
                                        value: ''
                                    }}
                                >
                                    ปิดห้อง
                                </MenuItem>
                                {/* <MenuItem
                                    value={{
                                        name: 'เปิดห้อง',
                                        value: '2'
                                    }}
                                >
                                    เปิดห้อง
                                </MenuItem> */}
                                {roomList.filter((item) => selectedRoomOne !== item.key).map(i => (
                                    <MenuItem
                                        key={i['key']}
                                        value={i['key']}
                                    >{'เปิดห้อง' + ' ' + i['key']}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography fontWeight={'bold'}>การเล่นเสียง</Typography>
                            <Select
                                size="small"
                                name='AUDIO_PLAY'
                                renderValue={(selected) => selectedAudio ? selected.name : ''}
                                value={selectedAudio || ''}
                                onChange={(event) => {
                                    const {
                                        target: { name, value },
                                    } = event;

                                    setSelectdAudio(value)
                                }}
                            >
                                <MenuItem
                                    value={{
                                        name: 'เปิดเสียง',
                                        value: 'ON_AUDIO'
                                    }}
                                >
                                    เปิดเสียง
                                </MenuItem>
                                <MenuItem
                                    value={{
                                        name: 'ปิดเสียง',
                                        value: 'OFF_AUDIO'
                                    }}
                                >
                                    ปิดเสียง
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'grid', justifyContent: 'center' }}>
                        <Button variant="contained" onClick={() => {
                            // if (!selectedRoomOne) {
                            //     notifyInfo('กรุณาเลือกสถานะห้องที่ 1')
                            //     return
                            // }

                            // if(!selectedRoomTwo) {
                            //     notifyInfo('กรุณาเลือกสถานะห้องที่ 2')
                            //     return
                            // }

                            onSubmit(roomSelected) // state room all
                        }}>เปิดแสดง</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}