// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/IBMPlexSansThai-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Kanit' !important;
} */

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

* {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans Thai Looped' !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
  height: 0.65rem;
  width: 0.65rem;
}
::-webkit-scrollbar-thumb{
  background-color: #006838;
  border-radius: 0.25rem;
}
.bg__rama__dashboard {
  background-color: #FBFBFB;
  min-height: 100vh;
  height: auto;
}


#map {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes fade { 
  from { opacity: 0.2; } 
}

.blinking {
  animation: fade 0.3s infinite alternate;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;;AAEH;EACE,wCAAwC;EACxC,4CAAsD;AACxD;;AAEA;EACE,SAAS;EACT,UAAU;EACV,mDAAmD;EACnD,sBAAsB;EACtB,wCAAwC;AAC1C;AACA;EACE,eAAe;EACf,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;AACd;;;AAGA;EACE,SAAS;EACT,OAAO;EACP,kBAAkB;EAClB,QAAQ;EACR,MAAM;AACR;;AAEA;EACE,OAAO,YAAY,EAAE;AACvB;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":["/* @font-face {\n  font-family: 'Kanit';\n  src: url('./assets/fonts/Kanit-Regular.ttf');\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  font-family: 'Kanit' !important;\n} */\n\n@font-face {\n  font-family: 'IBM Plex Sans Thai Looped';\n  src: url('./assets/fonts/IBMPlexSansThai-Regular.ttf');\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  font-family: 'IBM Plex Sans Thai Looped' !important;\n  box-sizing: border-box;\n  -webkit-tap-highlight-color: transparent;\n}\n::-webkit-scrollbar {\n  height: 0.65rem;\n  width: 0.65rem;\n}\n::-webkit-scrollbar-thumb{\n  background-color: #006838;\n  border-radius: 0.25rem;\n}\n.bg__rama__dashboard {\n  background-color: #FBFBFB;\n  min-height: 100vh;\n  height: auto;\n}\n\n\n#map {\n  bottom: 0;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n}\n\n@keyframes fade { \n  from { opacity: 0.2; } \n}\n\n.blinking {\n  animation: fade 0.3s infinite alternate;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
