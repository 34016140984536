export const contentProfile = {
    display:'flex',
    justifyContent:'center'
}

export const boxProfile = {
    backgroundColor: '#fff',
    borderRadius:'10px',
    padding: {xl:'2rem',lg:'2rem',md:'2rem',sm:'2rem',xs:'1rem'},
    width: {xl:'70%',lg:'70%',md:'70%',sm:'100%',xs:'100%'}
} 

export const buttomPrimary = {
    backgroundColor: '#006838',
    border: '1px solid #006838',
    padding: '0.5rem 1rem',
    fontSize:'calc(15px + 0.10vw)',
    width: 'auto',
    borderRadius: '8px',
    color: '#fff',
    boxShadow:'none',
    '&:hover': {
      boxShadow:'none',
    },
}

export const buttomSecondary = {
    backgroundColor: '#fff',
    border: '1px solid #006838',
    padding: '0.5rem 1rem',
    fontSize:'calc(15px + 0.10vw)',
    width: 'auto',
    borderRadius: '8px',
    color: '#006838',
    boxShadow:'none',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#006838',
      boxShadow:'none',
    },
  }

export const buttonActive = {
    backgroundColor: '#E1F4EB',
    border: '1px solid #E1F4EB',
    borderRadius: '6px',
    color: '#006838',
    padding: '0.35rem 1rem',
    '&:hover': {
        backgroundColor: '#E1F4EB',
        color: '#006838',
    }
}

export const buttonActive2 = {
    width: '100px',
    backgroundColor: '#E1F4EB',
    border: '1px solid #E1F4EB',
    borderRadius: '6px',
    color: '#006838',
    padding: '0.35rem 1rem',
    '&:hover': {
        backgroundColor: '#E1F4EB',
        color: '#006838',
    }
}

export const buttonInActive = {
    backgroundColor: '#FAECEB',
    border: '1px solid #FAECEB',
    borderRadius: '6px',
    color: '#D13C32',
    padding: '0.35rem 1rem',
    '&:hover': {
        backgroundColor: '#FAECEB',
        color: '#D13C32',
    }
}