import { Box,Typography,TextField,Button } from '@mui/material'
import React from 'react'
import { boxModal } from '../../components/ModalController/ModalAccessCss'
import { useNavigate } from 'react-router-dom'
import { contentBox } from './ForgetPasswordCss'
import { useState } from 'react'
import * as api from '../../services/public_api' 
import LoaderAnimation from '../../components/LoaderAnimation';
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import { notifyError, notifyInfo, notifySuccess , notifyWarning } from '../../components/NotifyToast';


function ForgetPassword() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email,setEmail] = useState('')
    const [modalSuccess,setModalSuccess] = useState(false)

    const handleSubmitForm = async() => {
            if(!email){
                return notifyWarning('กรุณากรอก email')
            }
            // console.log(email)
            setLoading(true)
            let res = await api.resetPassword(email)
            setLoading(false)
            if(res){
                // console.log("res ====> ",res)
                if(res['data']['code'] === 200){
                    // navigate('/newPassword')
                    setModalSuccess(true)
                }else{
                    notifyInfo(res['data']['message'])
                }
            }else{
                notifyError('ไม่ตอบสนอง')
            }
            
            
    }

  return (
    <Box sx={contentBox}>
        <LoaderAnimation loading={loading} />
        {
            modalSuccess ?
                <ModalSuccess 
                    title={'ยืนยืนอีเมลสำเร็จ'}
                    detail={'กรุณาตรวจสอบ link การเปลี่ยนรหัสผ่านที่อีเมลของท่าน'}
                    closeModal={() => setModalSuccess(false)}
                    goPage={''}
                /> : ''
        }
        <Box sx={boxModal}>
            <Typography component="h5" variant="h5" sx={{fontWeight:'bold',marginBottom:'0.5rem',textAlign:'center'}}>ลืมรหัสผ่าน</Typography>
            <Typography component="p" variant="p" color={'#006838'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>อีเมล</Typography>
            <TextField fullWidth placeholder="กรุณาระบุอีเมลที่ลงทะเบียน" variant="outlined" 
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // error={!email ? true : false}
            />
            <Box sx={{display:'flex',justifyContent:'center'}}>
                <Button onClick={() => handleSubmitForm()}
                    variant="contained"
                    sx={{padding:'0.5rem 2rem',marginTop:'2rem'}}
                    >
                    {'ตกลง'}
                </Button>
            </Box>
        </Box>
    </Box>
  )
}

export default ForgetPassword