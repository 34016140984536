import React, { useState,useEffect } from 'react'
import { Box,Typography,TextField,Button } from '@mui/material'
import { boxModal } from '../../components/ModalController/ModalAccessCss'
import { useLocation, useNavigate } from 'react-router-dom'
import { contentBox,boxPassword,boxIcon } from './ForgetPasswordCss'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import * as api from '../../services/public_api'
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifyInfo, notifySuccess , notifyWarning } from '../../components/NotifyToast';

function NewPassword() {
    const navigate = useNavigate()
    const location = useLocation()
    const [token,setToken] = useState('')
    const [modalSuccess,setModalSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [messageModal,setMessageModal] = useState({
        title:'',
        detail:''
    })

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const code = searchParams.get('code')
        setToken(code)

    }, [location.search]);

    const [updatePassword,setUpdatePassword] = useState({
        password: '',
        confirm_password:''
    }) 

    const [showPassword,setShowPassword] = useState({
        password: false,
        confirm_password: false,
    })

    const handleOnChange = (e) => {
        const {name,value} = e.target
        setUpdatePassword({...updatePassword,[name]:value})
    }

    const handlePassword = (type_password) => {
        switch(type_password){
                case 'password' : setShowPassword({...showPassword,password: !showPassword['password']}) 
            break
                case 'confirm_password' : setShowPassword({...showPassword,confirm_password: !showPassword['confirm_password']}) 
            break
        }
    }

    const handleSubmitForm = async() => {
        if(!updatePassword['password']){
            return notifyWarning('กรุณากรอก รหัสผ่านใหม่')
        }
        if(!updatePassword['confirm_password']){
            return notifyWarning('กรุณากรอก ยืนยันรหัสผ่านใหม่')
        }
        if(updatePassword['password'] !== updatePassword['confirm_password']){
            return notifyWarning('รหัสผ่านไม่ตรงกัน')
        }

        setLoading(true)
        let res = await api.updatePassword(updatePassword['password'],token)
        setLoading(false)
        if(res){
            // console.log("res update password ====> ",res)
            if(res['data']['code'] === 200){
                setModalSuccess(true)
                setMessageModal({
                    title:'เปลี่ยนรหัสผ่านสำเร็จ',
                    detail:'ท่านได้ทำการเปลี่ยนรหัสใหม่สำเร็จ'
                })
            }else{
                console.log(res['data']['message'])
                await notifyInfo('เกิดข้อผิดพลาด กรุณาทำการกรอก email ของท่านใหม่อีกครั้ง')
                navigate('/forgetPassword')
            }           
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }
  return (
    <Box sx={contentBox}>
        <LoaderAnimation loading={loading} />
         {
            modalSuccess ?
                <ModalSuccess 
                    title={messageModal['title']}
                    detail={messageModal['detail']}
                    closeModal={() => setModalSuccess(false)}
                    goPage={'/'}
                /> : ''
        }
        <Box sx={boxModal}>
            <Typography component="h5" variant="h5" sx={{fontWeight:'bold',marginBottom:'0.5rem',textAlign:'center'}}>ตั้งค่ารหัสผ่านใหม่</Typography>
            <Box sx={{marginBottom:'1rem'}}>
                <Typography component="p" variant="p" color={'#006838'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>รหัสผ่าน</Typography>
                <Box sx={boxPassword}>
                    <TextField fullWidth placeholder="กรุณาระบุรหัสผ่านใหม่" variant="outlined" type={showPassword['password'] ? 'text' : 'password'} 
                        name='password'
                        value={updatePassword['password']}
                        onChange={handleOnChange}
                    />
                    <Box sx={boxIcon} onClick={() => handlePassword('password')}>
                        {
                            showPassword['password'] ?  (<VisibilityOffOutlinedIcon/>) : (<RemoveRedEyeOutlinedIcon/>) 
                        } 
                    </Box>
                </Box>
            </Box>
            <Box sx={{marginBottom:'1rem'}}>
                <Typography component="p" variant="p" color={'#006838'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ยืนยันรหัสผ่าน</Typography>
                <Box sx={boxPassword}>
                    <TextField fullWidth placeholder="กรุณายืนยันรหัสผ่านอีกครั้ง" variant="outlined" type={showPassword['confirm_password'] ? 'text' : 'password'} 
                        name='confirm_password'
                        value={updatePassword['confirm_password']}
                        onChange={handleOnChange}
                    />
                    <Box sx={boxIcon} onClick={() => handlePassword('confirm_password')}>
                        {
                            showPassword['confirm_password'] ?  (<VisibilityOffOutlinedIcon/>) : (<RemoveRedEyeOutlinedIcon/>) 
                        } 
                    </Box>
                </Box>
            </Box>
            <Box sx={{display:'flex',justifyContent:'center'}}>
                <Button onClick={() => handleSubmitForm()}
                    variant="contained"
                    sx={{padding:'0.5rem 2rem',marginTop:'2rem'}}
                    >
                    {'ตกลง'}
                </Button>
            </Box>
        </Box>
    </Box>
  )
}

export default NewPassword