import React, { useEffect, useState } from "react";
import { backgroundModal, boxItem, boxModalV3 } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, Autocomplete } from "@mui/material";
import * as api from '../../services/ApiConsultDoctor'
import LoaderAnimation from "../LoaderAnimation";
import { notifySuccess, notifyInfo, notifyError, notifyWarning } from '../../components/NotifyToast'
import DeleteIcon from '@mui/icons-material/Delete';

export default function ModalRecordKeeping({ onClose, DocNumber, item, getdata }) {

    const [loading, setLoading] = useState(false)

    // const [recordKeeping, setRecordKeeping] = useState('')

    const [dataDrug, setDataDrug] = useState([])

    const [group, setGroup] = useState('')
    const [drugGroup, setDragGroup] = useState([])

    const [drug, setDrug] = useState('')
    const [drugList, setDragList] = useState([])

    const [drugAddList, setDrugAddList] = useState([
        // {
        //     drung_name : '',
        //     drung_detail : ''
        // }
    ])

    const [diag, setDiag] = useState('')
    const [diagList, setDiagList] = useState([])

    const [diagAddList, setDiagAddList] = useState([])

    useEffect(() => {
        console.log(item)
        // console.log(DocNumber)
        if (item) {
            // if (item['Diag'].length === 1) { //ตอนบันทึกไม่ได้แยก Diag 
            //     setRecordKeeping(item['Diag'][0]['Detail'])
            // }

            if (item['Order'].length > 0) {
                let modifyList = item['Order']
                modifyList.map((item) => (
                    item['drug_type'] = '-',
                    item['drug_name'] = item['Detail'],
                    item['drug_detail'] = item['Remark']
                ))
                console.log(modifyList)
                setDrugAddList(modifyList)
            }

            if (item['Diag'].length > 0) {
                let list = item['Diag']
                list.map((item) => (
                    item['Diag'] = item['Detail'],
                    item['Remark'] = item['Remark']
                ))
                console.log(list)
                setDiagAddList(list)
            }
        }
    }, [item])

    useEffect(() => {
        drugGroupAll()
        findIcd10()
    }, [])

    useEffect(() => {
        drugListAll()
        console.log(group)
    }, [group])

    const findIcd10 = async () => {
        setLoading(true)
        let res = await api.findIcd10()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        option.push({
                            // key: res['data']['data'][i]['ICD_10'],
                            label: res['data']['data'][i]['ICD_10'] + ' ' + res['data']['data'][i]['ICD_Name_Eng']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.label, m])).values()]
                setDiagList(unique)
            } else {
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const drugGroupAll = async () => {
        setLoading(true)
        let res = await api.drug()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataDrug(res['data']['data'])
                filterDrug(res['data']['data'])
            } else {
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const filterDrug = (data) => {
        const unique = [...new Set(data.map(item => item.DrugGroupName))];
        // console.log(unique)
        setDragGroup(unique)
    }

    const List = []
    const drugListAll = () => {
        for (let i = 0; i < dataDrug.length; i++) {
            if (dataDrug[i]['DrugGroupName'] === group) {
                List.push(dataDrug[i])
            }
        }
        setDragList(List)
    }

    const clickSave = async () => {
        let res = await api.saveMedicalRecords(diagAddList, drugAddList, DocNumber)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess(res['data']['message'])
                getdata()
                onClose()
            } else {
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleAddSelectedDrug = (typeDrug, NameDrug) => {
        if (!NameDrug) {
            notifyInfo('กรุณาเลือกรายการยา')
            return
        }
        //clear value state 
        setGroup(null)
        setDrug(null)

        const newKey = {
            drug_type: typeDrug,
            drug_name: NameDrug,
            drug_detail: ''
        }
        const insertDrugAddState = [...drugAddList]
        insertDrugAddState.push(newKey)
        setDrugAddList(insertDrugAddState)
    }

    const handleRemoveSelectedDrug = (itemDrug) => {
        const removeDrugtate = drugAddList.filter((itemRemove) => itemRemove !== itemDrug);
        setDrugAddList(removeDrugtate);
    }

    const handleChangeDrugAdd = async (e, indexDrug) => {
        const updatedDrugAddState = [...drugAddList];

        updatedDrugAddState[indexDrug] = {
            ...updatedDrugAddState[indexDrug],
            [e.target.name]: e.target.value,
        };

        setDrugAddList(updatedDrugAddState);
    }

    const handleAddSelectedDiag = async (valueDiag) => {
        if (!valueDiag) {
            notifyInfo('กรุณาเลือกรายการวินิจฉัย')
            return
        }
        setDiag(null)

        const newKey = {
            Diag: valueDiag,
            Remark: ''
        }
        const state = [...diagAddList]
        state.push(newKey)
        setDiagAddList(state)
    }

    const handleChangeDiagAdd = async (e, index) => {
        const updatedState = [...diagAddList];

        updatedState[index] = {
            ...updatedState[index],
            [e.target.name]: e.target.value,
        };

        setDiagAddList(updatedState);
    }

    const handleRemoveSelectedDiag = (item) => {
        const removeState = diagAddList.filter((itemRemove) => itemRemove !== item);
        setDiagAddList(removeState);
    }


    const handleSubmit = () => {
        // if (!recordKeeping) {
        //     notifyInfo('กรุณาระบุราบละเอียดบันทึกการรักษา')
        //     return
        // }

        if (diagAddList.length <= 0) {
            notifyInfo('กรุณาระบุข้อมูลบันทึกการวินิจฉัย')
            return
        }
        for (let i of diagAddList) {
            if (!i['Diag']) {
                notifyInfo('กรุณาเลิอกรายการวินิจฉัย')
                return
            }
            if (!i['Remark']) {
                notifyInfo('กรุณาระบุรายละเอียดการวินิจฉัย')
                return
            }
        }

        if (drugAddList.length <= 0) {
            notifyInfo('กรุณาระบุข้อมูลบันทึกการจ่ายยา')
            return
        }
        for (let valid of drugAddList) {
            if (!valid['drug_type']) {
                notifyInfo('กรุณาเลือกหมวดยาให้ครบถ้วน')
                return
            }
            if (!valid['drug_name']) {
                notifyInfo('กรุณาเลิอกรายการยาให้ครบถ้วน')
                return
            }
            if (!valid['drug_detail']) {
                notifyInfo('กรุณาระบุรายละเอียดยาให้ครบถ้วน')
                return
            }
        }

        clickSave()
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={backgroundModal}>
                <Box sx={boxModalV3}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={() => { onClose() }}><ClearIcon /></Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', fontWeight: 'bold' }}>
                            <Typography sx={{
                                fontWeight: 'bold',
                            }}>บันทึกการรักษา</Typography>
                        </Box>
                        <Box sx={{ borderBottom: '1px solid #E1E3E9' }}></Box>

                        <Box sx={{
                            margin: '3% 0',
                            display: 'grid',
                            gridGap: 15,
                        }}>
                            <Typography sx={{ fontWeight: 'bold' }}>ผลการวินิจฉัย</Typography>
                            <FormControl size="small" fullWidth>
                                <Autocomplete
                                    fullWidth
                                    size='small'
                                    options={diagList}
                                    value={diag}
                                    onChange={(e, value) => {
                                        console.log(value)
                                        setDiag(value?.['label'])
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            placeholder="เลือกรายการวินิจฉัย"
                                        />
                                    }
                                />
                                {/* <InputLabel>เลือกรายการวินิจฉัย</InputLabel> */}
                                {/* <Select
                                    fullWidth
                                    size="small"
                                    label='เลือกรายการวินิจฉัย'
                                    value={diag}
                                    onChange={(e) => {
                                        const {
                                            target: { value }
                                        } = e;
                                        console.log(value)
                                        setDiag(value)
                                    }}
                                >
                                    <MenuItem value="">ทั้งหมด</MenuItem>
                                    {diagList.map((item) => (
                                        <MenuItem
                                            key={item['key']}
                                            value={item['key']}
                                        >
                                            {item['label']}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" sx={{ width: 'auto' }}
                                onClick={() => {
                                    handleAddSelectedDiag(diag)
                                }}>เพิ่มข้อมูลการวินิจฉัย</Button>
                        </Box>
                        <Box sx={{
                            border: '1px solid #E1E3E9',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            overflow: 'auto',
                            maxHeight: '200px',
                            mt: '20px'
                        }}>
                            {diagAddList.length > 0 ? diagAddList.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ position: 'relative' }}>
                                        <Box sx={{ position: 'absolute', top: 8, right: 0 }}>
                                            <div></div>
                                            <IconButton onClick={() => {
                                                handleRemoveSelectedDiag(item)
                                            }}>
                                                <DeleteIcon color="primary" />
                                                <Typography fontWeight={'bold'}>ลบ</Typography>
                                            </IconButton>
                                        </Box>
                                    </div>
                                    <Grid container spacing={3} >
                                        <Grid item xs={12} sm={6} md={3} xl={3}>
                                            <Typography color={'#747676'} sx={{ fontWeight: 'bold' }} >ผลการวินิจฉัย</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={9} xl={9}>
                                            <Typography color={'#747676'} fontWeight={'bold'}>
                                                {item['Diag']}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3} xl={3}>
                                            <Typography color={'#747676'} sx={{ fontWeight: 'bold' }}>รายละเอียด</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={9} xl={9}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                name="Remark"
                                                placeholder='รายละเอียด'
                                                value={item['Remark']}
                                                onChange={(e) => {
                                                    handleChangeDiagAdd(e, index)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div style={{ width: '100%', borderBottom: '1px solid #E1E3E9' }}></div>
                                </React.Fragment>
                            ))
                                :
                                <Typography sx={{ textAlign: 'center' }}>กรุณาเลือกข้อมูลการวินิจฉัย</Typography>}
                        </Box>

                        <Box sx={{
                            margin: '3% 0',
                            display: 'grid',
                            gridGap: 15,
                        }}>
                            {/* <Typography sx={{ fontWeight: 'bold' }}> บันทึกการรักษา </Typography>
                            <TextField
                                rows={3}
                                placeholder="รายละเอียดบันทึกการรักษา"
                                multiline
                                fullWidth
                                value={recordKeeping}
                                onChange={(e) => {
                                    const {
                                        target: { value }
                                    } = e;
                                    setRecordKeeping(value)
                                }}
                            >
                            </TextField> */}

                            <Typography sx={{ fontWeight: 'bold' }}>บันทึกการจ่ายยา</Typography>
                            <FormControl size="small" fullWidth>
                                <InputLabel>เลือกหมวดยา</InputLabel>
                                <Select
                                    fullWidth
                                    size="small"
                                    label='เลือกหมวดยา'
                                    value={group}
                                    onChange={(e) => {
                                        const {
                                            target: { value }
                                        } = e;
                                        setGroup(value)
                                        setDrug(null)
                                    }}
                                >
                                    <MenuItem value="">ทั้งหมด</MenuItem>
                                    {drugGroup.map((item) => (
                                        <MenuItem
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl size="small" fullWidth>
                                <InputLabel>เลือกรายการยา</InputLabel>
                                <Select
                                    size="small"
                                    label='เลือกรายการยา'
                                    value={drug}
                                    onChange={(e) => {
                                        const {
                                            target: { value }
                                        } = e;
                                        setDrug(value)
                                    }}
                                >
                                    <MenuItem value="">ทั้งหมด</MenuItem>
                                    {drugList.map((item) => (
                                        <MenuItem
                                            key={item['DrugName']}
                                            value={item['DrugName']}
                                        >
                                            {item['DrugName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" sx={{ width: 'auto' }}
                                onClick={() => {
                                    handleAddSelectedDrug(group, drug)
                                }}>เพิ่มข้อมูลการจ่ายยา</Button>
                        </Box>
                        <Box sx={{
                            border: '1px solid #E1E3E9',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            overflow: 'auto',
                            maxHeight: '200px',
                            mt: '20px'
                        }}>
                            {drugAddList.length > 0 ? drugAddList.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div style={{ position: 'relative' }}>
                                            <Box sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 0,
                                            }}>
                                                <div></div>
                                                {
                                                    item['drug_type'] === "-" ? '' :
                                                        <IconButton onClick={() => {
                                                            handleRemoveSelectedDrug(item)
                                                        }}>
                                                            <DeleteIcon color="primary" />
                                                            <Typography fontWeight={'bold'}>ลบ</Typography>
                                                        </IconButton>
                                                }
                                            </Box>
                                        </div>

                                        <Grid container spacing={3} >
                                            {item['drug_type'] === "-" ? "" :
                                                <>
                                                    <Grid item xs={12} sm={6} md={3} xl={3}>
                                                        <Typography color={'#747676'} sx={{ fontWeight: 'bold' }} >หมวดยา</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={9} xl={9}>
                                                        <Typography color={'#747676'} fontWeight={'bold'}>
                                                            {item['drug_type']}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }

                                            <Grid item xs={12} sm={6} md={3} xl={3}>
                                                <Typography color={'#747676'} sx={{ fontWeight: 'bold' }}>รายการยา</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={9} xl={9}>
                                                <Typography color={'#747676'} fontWeight={'bold'}>
                                                    {item['drug_name']}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3} xl={3}>
                                                <Typography color={'#747676'} sx={{ fontWeight: 'bold' }}>รายละเอียดยา</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={9} xl={9}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    name='drug_detail'
                                                    placeholder='รายละเอียด'
                                                    value={item['drug_detail']}
                                                    onChange={(e) => {
                                                        handleChangeDrugAdd(e, index)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div style={{ width: '100%', borderBottom: '1px solid #E1E3E9' }}></div>
                                    </React.Fragment>
                                )
                            })
                                :
                                <Typography sx={{ textAlign: 'center' }}>กรุณาเลือกข้อมูลการจ่ายยา</Typography>}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: 20, marginTop: '1rem' }}>
                            <Button variant="outlined" onClick={() => { onClose() }}>ยกเลิก</Button>
                            <Button variant="contained" onClick={() => { handleSubmit() }}>บันทึก</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}