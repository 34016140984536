import { Box, Typography, Radio, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { contentModal, boxCondition } from './Css'
import { useLocation, useNavigate } from 'react-router-dom'
import * as api from '../../services/refer/ApiPublicRefer'
import { notifyError, notifyInfo } from '../../components/NotifyToast'
import LoaderAnimation from '../../components/LoaderAnimation'

function Consent() {
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(location.state.name)
    const [pid, setPid] = useState(location.state.pid)

    console.log(location.state)

    const submitForm = async () => {
        setLoading(true)
        let res = await api.confirmConcent(pid)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                if (location.state.type === 'refer') {
                    navigate('/addRefer', { state: { type: 'add', button: '1' } })
                } else {
                    navigate('/addResive', { state: { type: 'add', button: '1' } })
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <Box sx={contentModal}>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#fff',
                padding: '1rem',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '10px',
            }}>
                <Typography variant='h6' color='primary' sx={{ textAlign: 'center', fontWeight: 600, fontSize: 'calc(15px + 0.5vw)' }}>
                    การยินยอมให้เปิดเผยข้อมูลด้านสุขภาพ <br />
                    ของบุคคลทางอิเล็กทรอนิกส์
                </Typography>
                <Box sx={{
                    wordBreak: 'break-word',
                    border: 1,
                    borderColor: '#E1E3E9',
                    borderRadius: '10px',
                    maxWidth: '500px',
                    padding: '20px',
                    margin: '0 20px',
                    '@media (max-width: 800px)': {
                    }
                }}>
                    <Box>
                        <div>
                            1.ข้าพเจ้า นาย / นาง / นางสาว {name + ' '}
                            เลขประจำตัวประชาชน {pid + ' '}
                            ในฐานะ ประชาชนทั่วไป/ ผู้ป่วย
                        </div>
                        <div>
                            2. ข้าพเจ้า ได้รับการอธิบายจากสถานพยาบาล ให้ทราบถึงวัตถุประสงค์ในโครงการฯ
                            ข้อจำกัดด้านเทคโนโลยีและอิเล็กทรอนิกส์รวมถึงมาตรการปกป้องที่เหมาะสมเพื่อ
                            คุ้มครองสิทธิของเจ้าของข้อมูลส่วนบุคคล
                        </div>
                        <div>
                            3. ข้าพเจ้า ยินยอมเข้าสู่การให้ข้อมูลภายใต้โครงการฯ โดยยินยอมเปิดเผยข้อมูล/ส่งข้อมูล/
                            รับข้อมูล ทางอิเล็กทรอนิกส์ (สำเนาข้อมูล) เพื่อการวินิจฉัยโรคทางการแพทย์ การให้บริการ
                            ด้านสุขภาพหรือด้านสังคม การรักษาทางการแพทย์ การจัดการด้านสุขภาพ การป้องกันด้าน
                            สุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร
                            เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของข้าพเจ้าแก่ผู้ประกอบ
                            วิชาชีพทางการแพทย์และสาธารณสุข
                        </div>
                        <div>
                            4. หากหน่วยงานและ รพ. ดังกล่าวนำข้อมูลสุขภาพส่วนบุคคลของข้าพเจ้าไปใช้เพื่อ
                            วัตถุประสงค์อื่นของโครงการฯ ต้องได้รับคำยินยอมจากข้าพเจ้า เว้นแต่การเปิดเผย
                            ตามที่กฎหมายบัญญัติหรือมีคำสั่งศาลให้เปิดเผย
                        </div>
                        <div>
                            5. ข้าพเจ้า สามารถจะถอนความยินยอมเมื่อใดก็ได้ การถอนความยินยอมย่อมไม่ส่งผล
                            กระทบต่อการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคล
                            ได้ให้ความยินยอมไปแล้ว โดยการยกเลิกความยินยอมต้องมีหลักฐานเป็นลายลักษณ์อักษร
                        </div>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Radio />
                    <Typography variant="p" color="secondary" sx={{ fontSize: '14px' }}>
                        ข้าพเจ้ายินยอมให้บุคลากรทางการแพทย์เข้าถึงข้อมูลทางการแพทย์ได้
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => submitForm()}
                        variant="contained"
                        sx={{ width: '50%', marginTop: '1rem' }}
                    >
                        ยืนยัน
                    </Button>
                </Box>

            </Box>
        </Box>
    )
}

export default Consent