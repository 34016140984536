import React from 'react'
import './PageHeader.css'
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import icon_backward_arrow_left from '../assets/icons/backward-arrow-left.svg'

function PageHeader({
  title_name,
  hide_date,
  back_path,
  state
}) {

  const navigate = useNavigate()

  const handleClickNivagate = (path) => {
    navigate(path,{ state })
  }

  

  return (
    <div className='title__component__pageheader'>
      <div className='flex__content__top'>
        {/* {
          back_path && <IconButton size='lg' onClick={() => {}}>
            <img src={icon_backward_arrow_left} alt='' />
          </IconButton>
        } */}

        {
          back_path ?
            <IconButton size='lg' onClick={() => { handleClickNivagate(back_path) }}>
              <img src={icon_backward_arrow_left} alt='' />
            </IconButton>
            : null
        }
        <div>{title_name}</div>
      </div>

      {hide_date ?
        null
        :
        <div className='label__date__name'>
          ประจำวันที่ 1 มกราคม 2566
        </div>
      }
    </div>
  )
}

export default PageHeader