import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    menu: null
}

const menuSlice = createSlice({
    name:'menu_active',
    initialState,
    reducers:{
        menuActive(state,action){
            state.menu = action.payload
        }
    }
})

export const { menuActive } = menuSlice.actions
export default menuSlice.reducer