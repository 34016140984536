import {
  Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Stack,
  Pagination, TextField, InputAdornment, IconButton, FormControl,InputLabel,
  Select, MenuItem, Button, Autocomplete
} from '@mui/material'
import React, { useState } from 'react'
import { contentBox, menuTable, buttonActive, buttonInActive, boxPagination,  menuTable2, buttonNewUser } from './mainCss'
import SearchIcon from '@mui/icons-material/Search';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalAccessUser from '../../components/ModalController/ModalAccessUser';
import { useEffect } from 'react';

import * as ApiManage from '../../services/Manage/ApiManageUser'
import * as ApiPublic from '../../services/public_api'
import * as apiOutPatient from '../../services/OutPatient/ApiAddAppointment'
import LoaderAnimation from '../../components/LoaderAnimation';
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { notifyError, notifyInfo, notifySuccess } from '../../components/NotifyToast';
import { useNavigate } from 'react-router-dom';

function UserPermission() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [userList, setUserList] = useState([])
  const [userSelectEdited, setUserListEdited] = useState(null)

  const [openModal, setOpenModal] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [messageModal, setMessageModal] = useState({
    title: '',
    detail: ''
  })

  const [numPage, setNumPage] = useState(5)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [showList, setShowList] = useState([])

  const [search, setSearch] = useState('')
  const [hospital,setHospital] = useState([])
  const [idHospital,setIdHospital] = useState('all')
  const [nameHospital,setNameHospital] = useState('แสดงข้อมูลทั้งหมด')

  useEffect(() => {
    if(localStorage.getItem('hospital_code') === 'N/A'){
      getHospital()
    }
  }, [])

  useEffect(() => {
    getManageUser()
  }, [idHospital,numPage])

  useEffect(() => {
    setPage(1)
  }, [numPage])

  useEffect(() => {
    let start = page * numPage - numPage
    setShowList(searchData().slice(start, start + numPage))
    // console.log(searchData())
    setMaxPage((Math.ceil(searchData().length / numPage)))

  }, [page, userList, search])



  const handlePageChange = (event, page) => {
    setPage(page)
  }

  async function getManageUser() {
    setLoading(true)
    let res = await ApiManage.getManageUser(idHospital)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        let data = res['data']['data']
        // console.log(data)
        let newData = data.filter((item) => item['UID'] !== localStorage.getItem('uid'))
        // console.log("====> ",newData)
        setUserList(newData)
        setMaxPage((Math.ceil(newData.length / numPage)))
      } else {
        setUserList([])
        // notifyInfo((res['data']['message']))
        console.log((res['data']['message']))
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  async function toggleManageUser(UID) {
    setLoading(true)
    let res = await ApiManage.toggleManageUser(UID)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        getManageUser()
        notifySuccess(res['data']['message'])
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }

  }

  async function changePermissionManageUser(PermissionObject) {
    setLoading(true)
    let res = await ApiManage.changePermissionManageUser(PermissionObject)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        // getManageUser()
        setOpenModalSuccess(true)
        setMessageModal({
          title: 'จัดการแก้ไขสิทธิ์สำเร็จ',
          detail: 'ท่านได้แก้ไขสิทธิ์เรียบร้อยแล้ว'
        })
        getManageUser()
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }

  }

  async function deleteMangeUser(UID) {
    setLoading(true)
    let res = await ApiManage.deleteMangeUser(UID)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        // getManageUser()
        setOpenModalSuccess(true)
        setMessageModal({
          title: 'จัดการลบผู้ใช้งานสำเร็จ',
          detail: 'ท่านได้ลบผู้ใช้งานเรียบร้อยแล้ว'
        })
        getManageUser()
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }

  }

  const handleOpenModal = () => {
    setOpenModal(!openModal)
  }
  const th_user = [
    {
      id: 'th_title01',
      th_name: '#',
    },
    {
      id: 'th_title02',
      th_name: 'ผู้ใช้งาน',
    },
    {
      id: 'th_title03',
      th_name: 'อีเมล',
    },
    // {
    //   id: 'th_title04',
    //   th_name: 'เบอร์โทรศัพท์',
    // },
    {
      id: 'th_title05',
      th_name: 'ประเภทผู้ใช้งาน',
    },
    {
      id: 'th_title06',
      th_name: 'สิทธิ์การใช้งาน',
    },
    {
      id: 'th_title07',
      th_name: 'สถานะ',
    },
    {
      id: 'th_title08',
      th_name: 'จัดการ',
    }
  ]

  const handleEditUser = () => {
    setOpenModal(true)
  }

  const handleOpenSuccessModal = () => {
    setOpenModalSuccess(!openModalSuccess)
  }

  const searchData = () => {
    return userList.filter((item) =>
      (item['Name'] ?? '').toLowerCase().includes(search.toLowerCase()) ||
      (item['Email'] ?? '').toLowerCase().includes(search.toLowerCase()) ||
      // (item['PhoneNumber'] ?? '').toLowerCase().includes(search.toLowerCase()) || 
      (item['GroupName'] ?? '').toLowerCase().includes(search.toLowerCase()) ||
      (item['Position'] ?? '').toLowerCase().includes(search.toLowerCase())
    )
  }

  // const getHospital = async() => {
  //   setLoading(true)
  //   let res = await ApiPublic.findHospital()
  //   setLoading(false)
  //   if (res) {
  //     if (res['data']['code'] === 200) {
  //       // console.log(res)
  //       setHospital(res['data']['data'])
  //     } else {
  //       notifyInfo((res['data']['message']))
  //     }
  //   } else {
  //     notifyError('ไม่ตอบสนอง')
  //   }
  // }

  const getHospital = async() => {
    setLoading(true)
    let res = await apiOutPatient.findHospital()
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        // console.log(res)
        // setHospital(res['data']['data'])
        let option = [{key:'all',label:'แสดงข้อมูลทั้งหมด'}]
        for (let i = 0; i < res['data']['data'].length; i++) {
            if (res['data']['data'][i]) {
                option.push({
                key: res['data']['data'][i]['HospitalCode'],
                label: res['data']['data'][i]['HospitalName']
                })
            }
            const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
            // console.log(unique)
            setHospital(unique)
        }
      } else {
        notifyInfo((res['data']['message']))
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  // console.log(idHospital)

  if (openModal) {
    document.body.style.overflow = "hidden";
  } else {
      document.body.style.overflow = "auto";
  }

  return (
    <>
      <LoaderAnimation loading={loading} />
      <Box sx={contentBox}>
        {
          openModal ? (
            <ModalAccessUser
              data={userSelectEdited}
              closeModal={() => handleOpenModal()} onSubmit={(data) => {
                changePermissionManageUser(data)
              }} />
          ) : ''
        }

        {
          openModalSuccess ? (
            <ModalSuccess
              title={messageModal['title']}
              detail={messageModal['detail']}
              closeModal={() => handleOpenSuccessModal()}
              goPage={'closeModal'}
            />
          ) : ''
        }

        <Box sx={menuTable2}>
          {
            localStorage.getItem('hospital_code') === 'N/A' ? 
            <Autocomplete
                size='small'
                disablePortal
                options={hospital}
                value={nameHospital}
                onChange={(e, value) => {
                    // console.log(value)
                    setIdHospital(value?.['key'])
                    setNameHospital(value?.['label'])
                }}
                sx={{width:'250px'}}
                renderInput={(params) => <TextField {...params} placeholder="เลือกโรงพยาบาล" />}
            /> : <Box></Box>
          }
          <TextField sx={{ marginRight: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '0' } }}
            variant='outlined'
            placeholder="ค้นหา"
            value={search}
            size='small'
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
        <TableContainer sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <Table sx={{ minWidth: '1000px' }}>
            <TableHead>
              <TableRow>
                {th_user.map((th, index) => (
                  <TableCell key={th['id']} align={index != 1 && index != 2 ? 'center' : 'left'} sx={index === 0 ? { width: '10%', fontWeight: '600' } : { fontWeight: '600' }}>{th['th_name']}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                showList.length === 0 ? 
                <>
                   <TableRow>
                        <TableCell
                            colSpan={12}
                            align='center'
                        >
                            <Typography
                                component={"p"}
                                sx={{ margin: '1rem 0' }}
                            >
                                ไม่พบข้อมูล 
                            </Typography>
                        </TableCell>
                    </TableRow>
                </> : 
                <>
                  {showList.map((data, index) => 
                      (
                        <TableRow key={data['UID']}>
                          <TableCell align="center">{numPage * (page - 1) + 1 + index}</TableCell>
                          <TableCell align='left'>{data['Name']}</TableCell>
                          <TableCell align='left'>{data['Email']}</TableCell>
                          <TableCell align='center'>{data['GroupName']}</TableCell>
                          <TableCell align='center'>{data['Position']}</TableCell>
                          <TableCell align='center'>
                            <Button onClick={() => {
                              toggleManageUser(data['UID'],data['DocStatus'])
                            }} sx={data['DocStatus'] === 'W' ? buttonActive : data['DocStatus'] === 'C' ? buttonInActive : buttonNewUser }>{
                            data['DocStatus'] === 'W' ? "Active" :
                            data['DocStatus'] === 'C' ? "Cancelled" : "New user"
                            }</Button>
                          </TableCell>
                          <TableCell >
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gapGap: '10px'
                            }}>
                              <IconButton onClick={() => {
                                setUserListEdited(data)
                                handleEditUser()
                              }}>
                                <ModeIcon sx={{ color: '#006838', '&:hover': { cursor: 'pointer' } }} />
                              </IconButton>
                              <IconButton onClick={() => {
                                deleteMangeUser(data['UID'])
                              }}>
                                <DeleteIcon sx={{ color: '#006838', '&:hover': { cursor: 'pointer' } }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                  )
                }                
                </>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={boxPagination}>
          <Box sx={{ width: '80px' }}>
            <FormControl fullWidth>
              <Select
                value={numPage}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setNumPage(value)
                }}
                size='small'
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack spacing={2}>
            <Pagination
              count={maxPage}
              page={page}
              onChange={handlePageChange}
              shape="rounded" color="primary"
            />
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default UserPermission