import React, { useState } from 'react'
import { TextField, Typography, Box, Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { backgroundModal, boxModal, buttomPrimary, buttomSecondary } from './ModalAccessCss'
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '../../components/NotifyToast';
import { Group } from '@mui/icons-material';

function ModalAccess(props) {
    const { closeModal, typeAccess, titleModal, data, onFinishApi,deleteMenu } = props
    // console.log("data ====> ", data)

    const [MenuName, setMenuName] = useState('')
    const [menuId,setMenuId] = useState('')
    const [groupRole,setGroupRole] = useState({
        GroupCode:'',
        GroupName:'',
        GroupID:''
    })


    const submitForm = async () => {
        switch (typeAccess) {
            case 'add':
                if (!groupRole['GroupName']) {
                    notifyWarning('กรุณาระบุระดับผู้ใช้งานภาษาไทย')
                    return
                }
                if (!groupRole['GroupCode']) {
                    notifyWarning('กรุณาระดับผู้ใช้งานภาษาอังกฤษ')
                    return
                }

                await onFinishApi(groupRole['GroupName'],groupRole['GroupCode'])
                break
            case 'delete':
                if (!groupRole['GroupID']) {
                    notifyWarning('กรุณาระบุระดับผู้ใช้งาน')
                    return
                }
                await deleteMenu(groupRole['GroupID'])
                break
            default: break
        }
    }

    // console.log(groupRole)

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal} >
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={closeModal}><ClearIcon /></Box>
                <Typography component="p" variant="p" color='primary' sx={{ fontSize: '18px', fontWeight: 'bold' }}>{titleModal}</Typography>
                <hr style={{ margin: '1.5rem 0', width: '100%' }} />
                {
                    typeAccess === 'add' ? (
                        <>
                            <Typography component="p" variant="p" color='primary' sx={{ fontSize: '18px', marginBottom: '0.5rem', fontWeight: 'bold' }}>เพิ่มระดับผู้ใช้งานภาษาไทย</Typography>
                            <TextField name='GroupName' value={groupRole['GroupName']} fullWidth onChange={(e) => {
                                setGroupRole({...groupRole,['GroupName']: e.target.value})
                            }} placeholder="กรุณาระบุระดับผู้ใช้งานภาษาไทย" variant="outlined" />

                            <Typography component="p" variant="p" color='primary' sx={{ fontSize: '18px', margin: '0.5rem 0', fontWeight: 'bold' }}>เพิ่มระดับผู้ใช้งานภาษาอังกฤษ</Typography>
                            <TextField name='GroupCode' value={groupRole['GroupCode']} fullWidth onChange={(e) => {
                                setGroupRole({...groupRole,['GroupCode']: e.target.value})
                            }} placeholder="กรุณาระบุระดับผู้ใช้งานภาษาอังกฤษ" variant="outlined" />
                        </>
                    ) : (
                        <>
                            <Typography component="p" variant="p" color='primary' sx={{ fontSize: '18px', marginBottom: '0.5rem', fontWeight: 'bold' }}>ระดับผู้ใช้งาน</Typography>
                            <FormControl>
                                <RadioGroup >
                                    {data.map((data) => (
                                        <FormControlLabel 
                                            key={data['GroupID']} 
                                            value={data['GroupID']} 
                                            control={<Radio />} 
                                            onChange={(e) => setGroupRole({...groupRole,['GroupID']: e.target.value})}
                                            label={data['GroupName']} 
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </>
                    )
                }
                <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '1.5rem' }}>
                    <Button onClick={closeModal}
                        fullWidth
                        variant="contained"
                        sx={buttomSecondary}
                    >
                        {'ยกเลิก'}
                    </Button>
                    <Button onClick={() => submitForm()}
                        fullWidth
                        variant="contained"
                        sx={buttomPrimary}
                    >
                        {'บันทึก'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalAccess