import React from 'react'
import { useEffect } from 'react'
import { Card, CardContent } from '@mui/material';
function WebViewPhrDoctor({
    pid_patient
}) {

    useEffect(() => {

    }, [])
    return (
        <Card sx={{ boxShadow : 'none'}}>
            <CardContent sx={{ boxShadow : 'none'}}>
                <iframe
                    src={`https:https://microservice.numeddatacenter.com/microservice-personal-health-record/#/people-health-record-doctor?pid=${pid_patient}&token=${localStorage.getItem('AccessToken')}`}
                    width="100%"
                    height="920px"
                    title="WebViewPhrDoctor"
                />
            </CardContent>
        </Card>
    )
}

export default WebViewPhrDoctor