import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LoaderAnimation from '../components/LoaderAnimation'
import { notifyError, notifyInfo, notifySuccess } from '../components/NotifyToast'
import * as api from '../services/public_api'

import { login, user_data } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';


function LoginThaiID() {

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getParameter()
  }, [])

  const getParameter = () => {
    let refreshToken = new URLSearchParams(window.location.search).get('RefreshToken')
    let accessToken = new URLSearchParams(window.location.search).get('AccessToken')

    if (refreshToken) {
      getUserData(accessToken, refreshToken)
    } else {
      notifyError('ไม่ตอบสนอง กรุณาติดต่อเจ้าหน้าที่')
    }
  }

  const getProfile = async () => {
    setLoading(true)
    let res = await api.profile()
    setLoading(false)
    if (res) {
      // console.log("res ====> ",res)
      if (res['data']['code'] === 200 || res['data']['status']) {
        dispatch(user_data(res['data']['data']))
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  const getUserData = async (token, refresh) => {
    console.log('refreshToken', token)
    console.log('accessToken', refresh)
    
    const expire = jwtDecode(token);
    console.log(expire)

    if (refresh) {
      dispatch(login({"ExpireInTimestamp": expire}))
      
      localStorage.setItem("AccessToken", token)
      localStorage.setItem("RefreshToken", refresh)
    }

    await getProfile()

    navigate('/auth/home')
    return notifySuccess('ล็อกอินสำเร็จ')

    // setLoading(true)
    // let res = await api.getUserData(token)
    // setLoading(false)
    // if (res) {
    //   if (res['data']['code'] === 200) {
    //     console.log(res['data'])

    //     dispatch(LoginReduce(res['data']['data']))
    //     localStorage.setItem("AccessToken", res['data']['data']['AccessToken'])
    //     localStorage.setItem("RefreshToken", res['data']['data']['RefreshToken'])

    //     navigate('/auth/home')
    //   } else {
    //     notifyInfo(res['data']['message'])
    //   }
    // } else {
    //   notifyError('ไม่ตอบสนอง')
    // }
  }

  return (
    <>
      <LoaderAnimation loading={loading} />
    </>
  )
}

export default LoginThaiID