import React,{useEffect} from 'react'
import DashboardAll from './DashboardAll'
import DashboardHospital from './DashboardHospital'
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../../store/menuSlice';

function DashboardRefer() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(menuActive('ภาพรวมการส่งต่อผู้ป่วย'))
  },[])
  return (
    <>
        { 
            // localStorage.getItem('hospital_code') === 'N/A' ? 
            // <DashboardAll/> : localStorage.getItem('group_role') === 'ADMIN' ?
            // <DashboardHospital/> : null
            localStorage.getItem('hospital_code') === 'N/A' || localStorage.getItem('group_role') === 'PROVINCEADMIN' ? 
            <DashboardAll/> : <DashboardHospital/>
        }
    </>
  )
}

export default DashboardRefer