import axios from "axios";
import { environment } from "../../../config.env";

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain
const domain = ENDPOINT_DOMAIN

export function findReferStatisticsAll(hospitalcode) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findReferStatistics?hospitalcode=${hospitalcode}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'r12network-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findReferStatistics(hospitalcode, datestart, dateend) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findReferStatistics?hospitalcode=${hospitalcode}&datestart=${datestart}&dateend=${dateend}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'r12network-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

