import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function getMenu() {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/menu`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function getManagePermissionMenu() {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/permission`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function togglePermissionMenu(GroupRole, MenuCode) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/togglePermission`
        let body = {
            GroupRole: GroupRole,
            MenuCode: MenuCode
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.put(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}
export function CreateAddManageMenu(MenuName) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/menu`
        let body = {
            MenuName: MenuName
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function deleteManageMenu(menu_id){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/menu?MenuID=${menu_id}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })   
}

export function getGroupRole(){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/groupRole`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function addGroupRole(GroupName,GroupCode){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/groupRole`
        let body = {
            GroupName: GroupName,
            GroupCode: GroupCode
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function deleteGroupRole(GroupID){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/groupRole?GroupID=${GroupID}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}