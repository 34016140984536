// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueueSystem_btGreen__eAm90 {
    background-color: #E1F4EB;
    border: 1px solid #006838;
    border-radius: 10px;

    color: #006838;
    text-align: center;
    padding: 10px;
}

.QueueSystem_btGay__gECtV {
    background-color: none;
    border: none;
    border-radius: 10px;

    color: #747676;
    text-align: center;
    padding: 10px;
}

.QueueSystem_history__I\\+KJr {
    background-color: #E1F4EB;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/consult_doctor/queue_system/QueueSystem.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;;IAEnB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;;IAEnB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".btGreen {\n    background-color: #E1F4EB;\n    border: 1px solid #006838;\n    border-radius: 10px;\n\n    color: #006838;\n    text-align: center;\n    padding: 10px;\n}\n\n.btGay {\n    background-color: none;\n    border: none;\n    border-radius: 10px;\n\n    color: #747676;\n    text-align: center;\n    padding: 10px;\n}\n\n.history {\n    background-color: #E1F4EB;\n    width: 100%;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btGreen": `QueueSystem_btGreen__eAm90`,
	"btGay": `QueueSystem_btGay__gECtV`,
	"history": `QueueSystem_history__I+KJr`
};
export default ___CSS_LOADER_EXPORT___;
