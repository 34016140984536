import React, { useEffect, useState } from 'react'
import { Typography, Box, Button, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { backgroundModal, boxModal, buttomPrimary } from './ModalAccessCss'
import { notifyInfo } from '../NotifyToast';

function ModalSentResive({ closeModal, type, onSubmit }) {

    const [data, setData] = useState([])

    const [referGroupCode, setReferGroupCode] = useState(null)
    const [remark, setRemark] = useState('')

    useEffect(() => {
        if (type === 'refer') {
            const dataRefer = [
                {
                    name: '06.1',
                    label: 'เสียชีวิตระหว่างการส่งต่อ'
                },
                {
                    name: '06.3',
                    label: 'ไม่พบผู้ป่วย'
                },
                {
                    name: '06.4',
                    label: 'ยกเลิก โปรดระบุ'
                },
                {
                    name: '07',
                    label: 'อื่นๆ โปรดระบุ'
                }
            ]
            setData(dataRefer)

        } else {
            const dataResive = [
                {
                    name: '02',
                    label: 'ตอบรับการส่งต่อ'
                },
                {
                    name: '06.1',
                    label: 'ปฏิเสธการส่งต่อ'
                },
                // {
                //     name: '06.2',
                //     label: 'เสียชีวิตระหว่างการส่งต่อ'
                // },
                {
                    name: '06.3',
                    label: 'ไม่พบผู้ป่วย'
                },
                {
                    name: '06.4',
                    label: 'ยกเลิก โปรดระบุ'
                },
                {
                    name: '07',
                    label: 'อื่นๆ โปรดระบุ'
                }
            ]
            setData(dataResive)
        }
    }, [])

    const inputRemark = (e) => {
        if (referGroupCode === '06.4' || referGroupCode === '07') {
            setRemark(e.target.value)
        } else {
            setRemark('')
        }
    }

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={closeModal}><ClearIcon /></Box>
                <Typography variant='p' color='primary' sx={{ fontWeight: 600 }}>
                    สถานะการส่งต่อ
                </Typography>
                <hr style={{ margin: '1rem 0', border: '0.5px solid #E3E3E5' }} />
                <FormControl sx={{ display: 'block', margin: '20px 30px' }}>
                    <RadioGroup >
                        {data.map((item, index) => {
                            return item['name'] === '06.4' || item['name'] === '07' ?
                                (
                                    <React.Fragment key={index}>
                                        <FormControlLabel
                                            value={item['name']}
                                            control={<Radio />}
                                            onChange={(e) =>
                                                setReferGroupCode(e.target.value)
                                            }
                                            label={<Typography sx={{ color: '#747676' }}>{item['label']}</Typography>}
                                        />
                                        <TextField
                                            disabled={referGroupCode !== item['name']}
                                            placeholder={item['label']}
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            value={remark.index}
                                            onChange={(e) => inputRemark(e)}
                                        />
                                    </React.Fragment>
                                )
                                :
                                (
                                    <FormControlLabel
                                        key={index}
                                        value={item['name']}
                                        control={<Radio />}
                                        onChange={(e) =>
                                            setReferGroupCode(e.target.value)
                                        }
                                        label={<Typography sx={{ color: '#747676' }}>{item['label']}</Typography>}
                                    />
                                )
                        })}
                    </RadioGroup>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '1.5rem' }}>
                    <Button onClick={closeModal}
                        variant="outlined"
                    >ยกเลิก</Button>
                    <Button
                        onClick={() => {
                            if (!referGroupCode) {
                                notifyInfo('กรุณาเลือกสถานะการส่งต่อ')
                            } else {
                                closeModal()
                                onSubmit(referGroupCode, remark)
                            }
                        }}
                        variant="contained"
                    >ตกลง</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalSentResive