import React, { useState, useEffect } from 'react'
import { tabContained2, tabOutlined2 } from '../Css'
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, Grid, TextField, Typography, InputAdornment, IconButton,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination,
    FormControl, Select, MenuItem, Stack
} from '@mui/material'
import ModalCalendar from '../../../components/ModalController/ModalCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import 'moment/locale/th';
import { content, contentTable, btnSecondary } from '../Css'
import dayjs from 'dayjs';

import * as apiRefer from '../../../services/refer/refer/ApiRefer'
import { notifyError, notifyInfo } from '../../../components/NotifyToast';
import LoaderAnimation from '../../../components/LoaderAnimation'

export default function ReferOther() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [openCalendar, setOpenCalendar] = useState(false)
    const [date, setDate] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))

    const [buttonData, setButtonData] = useState([])
    const [menu, setMenu] = useState('06.1')
    const menuAll = [
        {
            "TypeReferCode": "06.1",
            "TypeReferName": "ปฎิเสธการรับรักษา",
            "DocStatus": "W",
            "TypeReferRemark": "-"
        },
        {
            "TypeReferCode": "06.2",
            "TypeReferName": "เสียชีวิต",
            "DocStatus": "W",
            "TypeReferRemark": "เสียชีวิตระหว่างการส่งต่อ"
        },
        {
            "TypeReferCode": "06.3",
            "TypeReferName": "ไม่พบผู้ป่วย",
            "DocStatus": "W",
            "TypeReferRemark": "ไม่พบผู้ป่วย"
        },
        {
            "TypeReferCode": "06.4",
            "TypeReferName": "ยกเลิกการส่งต่อ",
            "DocStatus": "W",
            "TypeReferRemark": "ยกเลิกการส่งต่อ โปรดระบุ"
        },
        // {
        //     "TypeReferCode": "07",
        //     "TypeReferName": "อื่น ๆ",
        //     "DocStatus": "W",
        //     "TypeReferRemark": "อื่น ๆ โปรดระบุ"
        // }
    ]

    const [dataAll, setDataAll] = useState([])
    const [disabledDay, setDisabledDay] = useState([])

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [namePatient, setNamePatient] = useState('')
    const [pid, setPid] = useState('')

    useEffect(() => {
        setButtonData(menuAll)
    }, [])

    useEffect(() => {
        findReferOutGroup()
    }, [numPage, menu])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(searchData().slice(start, start + numPage))
    }, [page, dataAll, namePatient, pid])

    const searchData = () => {
        return dataAll.filter((item) =>
            (namePatient === '' || (item['Name']).includes(namePatient)) &&
            (pid === '' || item['cid'].includes(pid))
        )
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleButton = (type_tpe) => {
        setMenu(type_tpe)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const findReferOutGroup = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')
        setLoading(true)
        let res = await apiRefer.findReferOutGroup(dateNow, menu, localStorage.getItem('hospital_code_'))
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataAll(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            } else {
                setDataAll([])
                setMaxPage(1)
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickViewMedicalHistory = async (refer_no) => {
        setLoading(true)
        let res = await apiRefer.findReferoutSummary(refer_no)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                navigate('/viewMedicalHistory', { state: { MedicalInfomation: res['data']['data']['MedicalInfomation'], MedicalInfomationType: res['data']['data']['MedicalInfomationType'] } })
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickOpenDoc = (no) => {
        findDocReferOut(no)
    }

    const findDocReferOut = async (referNum) => {
        setLoading(true)
        let res = await apiRefer.findDocReferOut(referNum)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                window.open(res['data']['data']['DocsURL'], '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickOpenAdd = async (pid, refer_no, name) => {
        navigate('/addRefer', { state: { refer_no: refer_no, type: 'edit', button: '1' } })
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {openCalendar &&
                <ModalCalendar
                    onFinished={(paramsState => {
                        if (paramsState === paramsState) {
                            findReferOutGroup()
                        }
                    })}
                    onClose={() => { setOpenCalendar(false) }}
                    value={date ? dayjs(moment(date['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
                    onChange={(e) => setDate(e)}
                    disableDay={disabledDay}
                />
            }
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gridGap: '5px',
                margin: '20px',
                '@media (max-width: 1024px)': {
                    gridTemplateColumns: '1fr 1fr',
                }
            }}>
                {buttonData.map((item, index) => (
                    <Box key={index}
                        sx={item['TypeReferCode'] === menu ? tabContained2 : tabOutlined2}
                        onClick={() => handleButton(item['TypeReferCode'])}
                    >
                        {item['TypeReferName']}
                    </Box>
                ))}
            </Box>

            <Box sx={{ ...content, margin: '1rem 0' }}>
                <Typography component='p' variant='p' color='primary'>Filters</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                        <Typography variant='p'>
                            ค้นหาโดยชื่อ-นามสกุล
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            placeholder={'กรุณาระบุชื่อ-นามสกุลของผู้ป่วย'}
                            value={namePatient}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setNamePatient(value)
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Typography variant='p'>
                            ค้นหาโดยเลขประจำตัวประชาชน
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            placeholder={'กรุณาระบุเลขประจำตัวประชาชนของผู้ป่วย'}
                            value={pid}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                const maxLength = 13;
                                const numericRegex = /^[0-9]*$/;
                                if (value.length <= maxLength && numericRegex.test(value)) {
                                    setPid(value)
                                }
                            }}
                        ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Typography variant='p'>
                            ค้นหาโดยวันที่
                        </Typography>
                        <TextField
                            size='small'
                            fullWidth
                            value={convertDate(date['$d'])}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => {
                                            setOpenCalendar(true)
                                        }}>
                                            <CalendarTodayIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                        <Box sx={{ height: '100%', display: 'flex', alignItems: 'end' }}>
                            <Typography component='p' variant='p'>
                                จำนวนทั้งหมด {dataAll?.length}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={contentTable}>
                {dataAll?.length > 0 ?
                    <TableContainer>
                        <Table sx={{ minWidth: 1700 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' variant='p' color='primary'>#</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>รหัสการส่งต่อ</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>เลขบัตรประจำตัวประชาชน</TableCell>
                                    <TableCell variant='p' color='primary'>รายชื่อ</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>วันที่ส่งต่อ</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>โรงพยาบาลที่ส่งไป</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>ประเภทการส่งต่อ</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>สถานะ</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>ประวัติการแพทย์</TableCell>
                                    <TableCell align='center' variant='p' color='primary'>เอกสารส่งตัว</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showList.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{item['ReferNumber']}</TableCell>
                                        <TableCell align='center'>{item['cid']}</TableCell>
                                        <TableCell sx={{
                                            color: '#006838',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                            onClick={() => { clickOpenAdd(item['cid'], item['ReferNumber'], item['Name']) }}
                                        >{item['Name']}</TableCell>
                                        <TableCell align='center'>{convertDate(item['ReferDate'])}</TableCell>
                                        <TableCell align='center'>{item['ToHospitalName']}</TableCell>
                                        <TableCell align='center'>{item['ReferTypeName']}</TableCell>
                                        <TableCell align='center'>{item['ReferGroupTypeName']}</TableCell>
                                        <TableCell align='center'>
                                            <Button
                                                sx={btnSecondary}
                                                variant="contained"
                                                onClick={() => { clickViewMedicalHistory(item['ReferNumber']) }}
                                            >ดูประวัติการแพทย์</Button>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Button onClick={() => { clickOpenDoc(item['ReferNumber']) }} variant="contained">การส่งตัว</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Box style={{ fontSize: 20, textAlign: 'center', margin: '10%' }}>ไม่พบข้อมูล</Box>
                }

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>
            </Box>
        </>
    )
}