import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from '../../services/public_api'
import { notifyError } from '../../components/NotifyToast';

export default function ReceiptRequest() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [optionPname, setOptionPname] = useState([])

    const [detail, setDetail] = useState({
        package: '',
        price: '',
        date: '',
        time: '',
    })

    useEffect(() => {
        setDetail(location.state.data)

        findTitleName()
    }, [])

    const findTitleName = async () => {
        setLoading(true)
        let res = await api.findTitleName()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOptionPname(res['data']['data'])
            } else {
                setOptionPname([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetail({ ...detail, [name]: value })
    }

    return (
        <Box sx={{ padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' }, }}>
            <Box
                sx={{
                    display: 'flex',
                    gridGap: 10,
                    cursor: 'pointer',
                    alignItems: 'center'
                }}
                onClick={() => { navigate('/dataUpgradehistory') }}
            >
                <ArrowBackIosIcon sx={{ fontSize: 16 }} />
                <div>ย้อนกลับ</div>
            </Box>

            <Box sx={{
                textAlign: 'center',
                color: '#006838',
                fontSize: '22px'
            }}
            >ขอใบเสร็จรับเงิน</Box>

            <Box sx={{
                margin: 'auto',
                width: '70%',
                '@media (max-width: 1024px)': {
                    width: '80%',
                    marginTop: 2,
                    padding: 1
                },
                '@media (max-width: 700px)': {
                    width: '100%',
                    marginTop: 1,
                    padding: 1
                }
            }}>
                <Box sx={{
                    display: 'grid',
                    gridGap: '15px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 2,
                    marginTop: 2,
                    padding: 3,
                    '@media (max-width: 1023px)': {
                        marginTop: 2,
                        padding: 2
                    },
                    '@media (max-width: 800px)': {
                        marginTop: 1,
                        padding: 1
                    }
                }}>
                    <Typography>รายละเอียด</Typography>
                    <Box sx={{ borderTop: '1px solid #E1E3E9' }}></Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '15px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }}>
                        <FormControl>
                            <Typography>ชื่อแพคเกจ</Typography>
                            <TextField
                                size='small'
                                fullWidth
                                value={detail['package']}
                                name={'package'}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>ราคา</Typography>
                            <TextField
                                size='small'
                                fullWidth
                                value={detail['price']}
                                name={'price'}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>วันเวลาที่ชำระ</Typography>
                            <TextField
                                size='small'
                                fullWidth
                                value={detail['date'] + ' ' + detail['time']}
                                name={'date'}
                                // onChange={handleChange}
                            />
                        </FormControl>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridGap: '15px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 2,
                    marginTop: 2,
                    padding: 3,
                    '@media (max-width: 1023px)': {
                        marginTop: 2,
                        padding: 2
                    },
                    '@media (max-width: 800px)': {
                        marginTop: 1,
                        padding: 1
                    }
                }}>
                    <Typography>ตรวจสอบที่อยู่สำหรับจัดส่งเอกสาร</Typography>
                    <Box sx={{ borderTop: '1px solid #E1E3E9' }}></Box>
                    <FormControl>
                        <Typography>เลขประจำตัวผู้เสียภาษี</Typography>
                        <TextField
                            size='small'
                            fullWidth
                        />
                    </FormControl>
                    <FormControl>
                        <Typography>หน่วยงาน</Typography>
                        <TextField
                            size='small'
                            fullWidth
                            placeholder='ระบุชื่อหน่วยงาน...'
                        />
                    </FormControl>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '15px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }}>
                        <FormControl>
                            <Typography>คำนำหน้า</Typography>
                            <Select
                                size='small'
                                fullWidth
                            >
                                <MenuItem value=" ">เลือกคำนำหน้า</MenuItem>
                                {optionPname.map((i, idx) => (
                                    <MenuItem
                                        key={idx}
                                        value={i['TitleName']}
                                    >{i['TitleName']}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography>ชื่อ</Typography>
                            <TextField
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>นามสกุล</Typography>
                            <TextField
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                    <FormControl>
                        <Typography>ที่อยู่</Typography>
                        <TextField
                            size='small'
                            fullWidth
                        />
                    </FormControl>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '15px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }}>
                        <FormControl>
                            <Typography>รหัสไปรษณีย์</Typography>
                            <TextField
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>จังหวัด</Typography>
                            <Select
                                size='small'
                                fullWidth
                            >
                                <MenuItem value=''></MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography>อำเภอ</Typography>
                            <Select
                                size='small'
                                fullWidth
                            >
                                <MenuItem value=''></MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridGap: '15px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr'
                        }
                    }}>
                        <FormControl>
                            <Typography>ตำบล</Typography>
                            <Select
                                size='small'
                                fullWidth
                            >
                                <MenuItem value=''></MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <Typography>เบอร์โทรศัพท์</Typography>
                            <TextField
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <Typography>อีเมล</Typography>
                            <TextField
                                size='small'
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ margin: 'auto', marginTop: '20px' }}>
                        <Button variant='contained' sx={{ width: '200px' }}>ออกใบเสร็จ</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
