// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExaminationRoomQueue_Img__ucs0D {
    width: 250px;
}

@media screen and (max-width: 800px) {
    .ExaminationRoomQueue_Img__ucs0D {
        width: 100px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/consult_doctor/queue_system/ExaminationRoomQueue.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".Img {\n    width: 250px;\n}\n\n@media screen and (max-width: 800px) {\n    .Img {\n        width: 100px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Img": `ExaminationRoomQueue_Img__ucs0D`
};
export default ___CSS_LOADER_EXPORT___;
