export const environment = {
    // production
    // production: true,
    // production_domain: 'https://api-r12network.numeddatacenter.com/dashboard-api',


    
    // Uat
    production: false,
    development_domain: 'https://api-r12network-uat.numeddatacenter.com/dashboard-api',
    development_domain_microservice: 'https://microservice.numeddatacenter.com/api/microservice/dky/',
    development_telequeue: 'https://api-r12network-uat.numeddatacenter.com',
    development_socket_payment: 'https://api-r12network-uat.numeddatacenter.com'

}

