import React, { useState, useEffect } from 'react'
import { Box, TextField, Typography, InputAdornment, IconButton, Select, MenuItem } from '@mui/material'
import ModalCalendar from '../../../components/ModalController/ModalCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import 'moment/locale/th';
import dayjs from 'dayjs';
import * as api from '../../../services/refer/dashboard/ApiDashboard'
import * as apiRefer from '../../../services/refer/ApiPublicRefer'
import { notifyError, notifyInfo } from '../../../components/NotifyToast';
import LoaderAnimation from '../../../components/LoaderAnimation'

import ReferInactive from '../../../assets/icons/refer-inactive.svg'
import ReferActive from '../../../assets/icons/refer-active.svg'
import ReferReject from '../../../assets/icons/refer-reject.svg'
import ReferWait from '../../../assets/icons/refer-wait.svg'
import ER from '../../../assets/icons/refer-emergency.svg'
import OPD from '../../../assets/icons/refer-opd.svg'
import OR from '../../../assets/icons/refer-or.svg'
import IPD from '../../../assets/icons/refer-ipd.svg'
import LR from '../../../assets/icons/refer-lr.svg'

function DashboardAll() {

  const [loading, setLoading] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [typeDate, setTypeDate] = useState('')

  const [hospital, setHospital] = useState('')
  const [optionHospital, setOptionHospital] = useState([])

  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [statistics, setStatistics] = useState(null)

  useEffect(() => {
    findHospital()
  }, [])

  useEffect(() => {
    findReferStatistics()
  }, [hospital])

  const handleOpenCalendar = (typeDate) => {
    setTypeDate(typeDate)
    setOpenCalendar(true)
  }

  const convertDateSearch = (dataDate) => {
    const date = new Date(dataDate)
    const optionDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const thaiLocale = 'th-TH';
    const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

    const newDate = `${formattedDate}`
    return newDate
  }

  const findReferStatistics = async () => {
    let dateNow = moment(new Date()).format('YYYY-MM-DD')

    let start = moment(dateStart['$d']).format('YYYY-MM-DD')
    let end = moment(dateEnd['$d']).format('YYYY-MM-DD')

    setLoading(true)
    let res = await api.findReferStatistics(hospital, dateStart ? start : dateNow, dateEnd ? end : dateNow)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        console.log(res['data']['data'])
        setStatistics(res['data']['data'])
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  const findHospital = async () => {
    setLoading(true)
    let res = await apiRefer.findHospital()
    setLoading(false)
    if (res) {
      if (res['data']['status'] === true) {
        let option = []
        for (let i = 0; i < res['data']['data'].length; i++) {
          if (res['data']['data'][i]) {
            option.push({
              key: res['data']['data'][i]['hoscode'],
              label: res['data']['data'][i]['hosname']
            })
          }
        }
        const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
        // console.log(unique)
        setOptionHospital(unique)
      } else {
        notifyInfo(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }


  return (
    <Box>
      <LoaderAnimation loading={loading} />
      {openCalendar &&
        <ModalCalendar
          onClose={() => { setOpenCalendar(false) }}
          value={
            typeDate === 'start' ?
              dateStart ? dayjs(moment(dateStart['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
              :
              dateEnd ? dayjs(moment(dateEnd['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
          }
          onChange={
            typeDate === 'start' ?
              (e) => setDateStart(e) : (e) => setDateEnd(e)
          }
          disableDay={''}
          onFinished={(paramsState => {
            if (paramsState === paramsState) {
              findReferStatistics()
            }
          })}
        />
      }

      <Box sx={{
        display: 'grid',
        gridGap: '20px'
      }}>
        <Box sx={{
          border: '1px solid #E1E3E9',
          borderRadius: '10px',
          padding: '20px',
          '@media (max-width: 800px)': {
            padding: '10px'
          }
        }}>
          <Typography component='p' variant='p' color='primary' sx={{ marginBottom: '1rem' }}>Filters</Typography>
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '20px',
            '@media (max-width: 800px)': {
              gridTemplateColumns: '1fr',
              gridGap: '10px',
            }
          }}>
            <Box>
              <Typography variant='p'>ค้นหาโรงพยาบาล</Typography>
              <Select
                size='small'
                fullWidth
                value={hospital || ' '}
                onChange={(e) => (
                  setHospital(e.target.value)
                )}
              >
                <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                {optionHospital.map((i, idx) => (
                  <MenuItem key={idx} value={i['key']}>{i['label']}</MenuItem>
                ))}
              </Select>
            </Box>

            <Box>
              <Typography variant='p'>ค้นหาโดยวันที่เริ่มต้น</Typography>
              <TextField size='small'
                value={dateStart ? convertDateSearch(dateStart['$d']) : convertDateSearch(dayjs(moment(new Date()).format('YYYY-MM-DD')))}
                placeholder='เลือกวันที่'
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() =>
                        handleOpenCalendar('start')
                      }>
                        <CalendarTodayIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box>
              <Typography variant='p'>ค้นหาโดยวันที่สิ้นสุด</Typography>
              <TextField size='small'
                value={dateEnd ? convertDateSearch(dateEnd['$d']) : convertDateSearch(dayjs(moment(new Date()).format('YYYY-MM-DD')))}
                placeholder='เลือกวันที่'
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() =>
                        handleOpenCalendar('end')
                      }>
                        <CalendarTodayIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

          </Box>
        </Box>

        {statistics &&
          <>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '20px',
              '@media (max-width: 1024px)': {
                gridTemplateColumns: '1fr 1fr',
              },
              '@media (max-width: 800px)': {
                gridTemplateColumns: '1fr',
              }
            }}>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#006838',
                color: '#FFFFFF',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferActive} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['summary']?.['referout'] + statistics['summary']?.['referback']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ข้อมูลการรับ Refer</Box>
                </Box>
              </Box>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#006838',
                color: '#FFFFFF',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferActive} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['summary']['referout']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ข้อมูลการส่งต่อ</Box>
                </Box>
              </Box>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#006838',
                color: '#FFFFFF',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferActive} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['summary']['referback']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ข้อมูลการส่งกลับ</Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '20px',
              '@media (max-width: 1024px)': {
                gridTemplateColumns: '1fr 1fr',
              },
              '@media (max-width: 800px)': {
                gridTemplateColumns: '1fr',
              }
            }}>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#FFFFFF',
                color: '#006838',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferInactive} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['referStatus']['statusAccept']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>รับเข้ารักษา</Box>
                </Box>
              </Box>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#FFFFFF',
                color: '#006838',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferReject} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['referStatus']['statusReject']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ปฏิเสธการรับผู้ป่วย</Box>
                </Box>
              </Box>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px',
                background: '#FFFFFF',
                color: '#006838',
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                padding: '20px'
              }}>
                <img src={ReferWait} style={{ width: '50px', height: '50px' }} />
                <Box>
                  <Box sx={{ fontSize: '25px' }}>{statistics['referStatus']['statusPending']}</Box>
                  <Box sx={{ fontSize: '15px', fontWeight: 600 }}>รอการตอบกลับ</Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              gridGap: '20px',
              '@media (max-width: 1024px)': {
                gridTemplateColumns: '1fr 1fr 1fr',
              },
              '@media (max-width: 800px)': {
                gridTemplateColumns: '1fr 1fr',
              }
            }}>
              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px'
              }}>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  padding: '20px'
                }}>
                  <img src={ER} alt='' style={{ width: '50px', height: '50px' }} />
                  <Box>
                    <Typography component='p' variant='p' sx={{ fontSize: '25px' }}>{statistics['referStation']['er']['total']}</Typography>
                    <Typography component='p' variant='p' sx={{ fontSize: '15px', fontWeight: 600 }}>ER</Typography>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2px'
                }}>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 0 10px', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary' >Refer out</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['er']['out']}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 10px 0', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary'>Refer back</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['er']['back']}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px'
              }}>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  padding: '20px'
                }}>
                  <img src={OPD} alt='' style={{ width: '50px', height: '50px' }} />
                  <Box>
                    <Typography component='p' variant='p' sx={{ fontSize: '25px' }}>{statistics['referStation']['opd']['total']}</Typography>
                    <Typography component='p' variant='p' sx={{ fontSize: '15px', fontWeight: 600 }}>OPD</Typography>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2px'
                }}>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 0 10px', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary' >Refer out</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['opd']['out']}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 10px 0', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary'>Refer back</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['opd']['back']}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px'
              }}>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  padding: '20px'
                }}>
                  <img src={IPD} alt='' style={{ width: '50px', height: '50px' }} />
                  <Box>
                    <Typography component='p' variant='p' sx={{ fontSize: '25px' }}>{statistics['referStation']['ipd']['total']}</Typography>
                    <Typography component='p' variant='p' sx={{ fontSize: '15px', fontWeight: 600 }}>IPD</Typography>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2px'
                }}>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 0 10px', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary' >Refer out</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['ipd']['out']}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 10px 0', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary'>Refer back</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['ipd']['back']}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px'
              }}>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  padding: '20px'
                }}>
                  <img src={OR} alt='' style={{ width: '50px', height: '50px' }} />
                  <Box>
                    <Typography component='p' variant='p' sx={{ fontSize: '25px' }}>{statistics['referStation']['or']['total']}</Typography>
                    <Typography component='p' variant='p' sx={{ fontSize: '15px', fontWeight: 600 }}>OR</Typography>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2px'
                }}>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 0 10px', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary' >Refer out</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['or']['out']}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 10px 0', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary'>Refer back</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['or']['back']}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box sx={{
                border: '1px solid #E1E3E9',
                borderRadius: '10px'
              }}>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  padding: '20px'
                }}>
                  <img src={LR} alt='' style={{ width: '50px', height: '50px' }} />
                  <Box>
                    <Typography component='p' variant='p' sx={{ fontSize: '25px' }}>{statistics['referStation']['lr']['total']}</Typography>
                    <Typography component='p' variant='p' sx={{ fontSize: '15px', fontWeight: 600 }}>LR</Typography>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: '2px'
                }}>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 0 10px', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary' >Refer out</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['lr']['out']}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'center', backgroundColor: '#E1F4EB', borderRadius: '0 0 10px 0', display: 'grid', gridGap: '10px', padding: '10px' }}>
                    <Typography component='p' variant='p' color='primary'>Refer back</Typography>
                    <Typography variant='p' color='primary'>{statistics['referStation']['lr']['back']}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        }

      </Box>

    </Box >
  )
}

export default DashboardAll