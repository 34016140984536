import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function getDrugGroup(idHospital) {
    return new Promise((resolve) => {
        let url = ''
        if(!idHospital){
            url = `${domain}/api/v1/manage/drugGroup?HospitalCode=${localStorage.getItem('hospital_code')}`
        }else{
            url = `${domain}/api/v1/manage/drugGroup?HospitalCode=${idHospital}`
        }
        
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function addDrugGroup(drugGroupName) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/drugGroup`
        let body = {
            HospitalCode: localStorage.getItem('hospital_code'),
            DrugGroupName: drugGroupName
        }

        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        // console.log(body)

        axios.post(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findDrungGroup(id_drugGroup){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/drugGroup?HospitalCode=${localStorage.getItem('hospital_code')}&ID=${id_drugGroup}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function deleteDrugGroup(id_drugGroup){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/drugGroup?ID=${id_drugGroup}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function editDrugGroup(id_drugGroup,drug_group_name) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/drugGroup?ID=${id_drugGroup}`
        let body = {
            DrugGroupName: drug_group_name,
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.put(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}
