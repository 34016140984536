import React, { useEffect, useState } from 'react'
import { backgroundModal3, contentAddRefer, backgroundModal, boxModalV3 } from './ModalAccessCss'
import {
    Box, Grid, Typography, TextField, FormControl, Select, MenuItem,
    FormControlLabel, Checkbox, Button, IconButton
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';
import { notifyError, notifyInfo } from '../NotifyToast';
import _ from 'lodash'
import * as apiRefer from '../../services/refer/ApiPublicRefer'
import LoaderAnimation from '../LoaderAnimation';


export default function ModalAddReferOPD({ closeModal, pid, onSubmit }) {

    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataAll, setDataAll] = useState([])
    const [dataInformation, setDataInformation] = useState([])

    const [dataOPD, setDataOPD] = useState([])

    useEffect(() => {
        if (date === '' || date === ' ') {
            findInformation(pid)
        } else {
            findfilterByDate()
        }

    }, [hospital, date])

    const findInformation = async (PID) => {
        setLoading(true)
        let res = await apiRefer.findInformationOPD(PID, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                // เรียงข้อมูลแยกตามวันที่
                let raw = res['data']
                let date_list = raw.data.visit_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] }));
                date_list = date_list.concat(raw.data.labs_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = date_list.concat(raw.data.diagnosis_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = date_list.concat(raw.data.order_information_list.map(item => ({ hos_id: item.hos_id, visit_date: item.visit_date, visit_information_list: [], labs_information_list: [], diagnosis_information_list: [], order_information_list: [] })));
                date_list = _.orderBy(_.uniqBy(date_list, 'visit_date'), ['visit_date'], ['desc'])

                date_list.forEach((item_) => {
                    item_.visit_information_list = raw.data.visit_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.labs_information_list = raw.data.labs_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.diagnosis_information_list = raw.data.diagnosis_information_list.filter(item => item.visit_date === item_.visit_date)
                    item_.order_information_list = raw.data.order_information_list.filter(item => item.visit_date === item_.visit_date)
                })
                setDataInformation(date_list)
                setDataAll(date_list)

                //option date
                const List = []
                for (let i = 0; i < date_list.length; i++) {
                    if (date_list[i]) {
                        List.push(date_list[i]['visit_date'])
                    }
                }
                setOptionDate(List)

                if (hospital === '' || hospital === ' ') {
                    filterHospitalOption(res['data']['data'])
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const filterHospitalOption = (data) => {
        let option = []
        for (let i = 0; i < data['personal_information_list'].length; i++) {
            if (data['personal_information_list'][i]) {
                option.push({
                    key: data['personal_information_list'][i]['hos_id'],
                    value: data['personal_information_list'][i]['hos_name']
                })
            }
        }
        const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
        setOptionHospital(unique)
        // console.log(unique)
    }

    const findfilterByDate = () => {
        let data = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['visit_date'] === date) {
                data.push(dataAll[i])
            }
        }
        setDataInformation(data)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const nameHospital = (id) => {
        for (let i = 0; i < optionHospital.length; i++) {
            if (optionHospital[i]['key'] === id) {
                return optionHospital[i]['value']
            }
        }
    }

    const selectingData = (item, index, type, event) => {
        // console.log(item)
        let checked = event.target.checked;

        if (checked) {
            let findItemIndex = dataOPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['visit_date']);

            if (findItemIndex > -1) {
                let data = dataOPD[findItemIndex];
                if (type == 'visit') {
                    data['visit_information_list'] = item['visit_information_list'];
                }

                if (type == 'order') {
                    data['order_information_list'] = item['order_information_list'];
                }

                if (type == 'lab') {
                    data['labs_information_list'] = item['labs_information_list'];
                }

                if (type == 'diagnosis') {
                    data['diagnosis_information_list'] = item['diagnosis_information_list'];
                }
                dataOPD[findItemIndex] = data;

            } else {
                let data = {
                    date: item['visit_date'],
                    hos_id: item['hos_id'],
                }

                if (type == 'visit') {
                    data['visit_information_list'] = item['visit_information_list'];
                }

                if (type == 'order') {
                    data['order_information_list'] = item['order_information_list'];
                }

                if (type == 'lab') {
                    data['labs_information_list'] = item['labs_information_list'];
                }

                if (type == 'diagnosis') {
                    data['diagnosis_information_list'] = item['diagnosis_information_list'];
                }
                dataOPD.push(data)
            }
        } else {
            let findItemIndex = dataOPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['visit_date']);

            if (findItemIndex > -1) {
                let data = dataOPD[findItemIndex];

                if (type == 'visit') {
                    delete data['visit_information_list'];
                }

                if (type == 'order') {
                    delete data['order_information_list'];
                }

                if (type == 'lab') {
                    delete data['labs_information_list'];
                }

                if (type == 'diagnosis') {
                    delete data['diagnosis_information_list'];
                }

                dataOPD[findItemIndex] = data;

                if (!dataOPD['visit_information_list'] && !dataOPD['order_information_list'] && !dataOPD['labs_information_list'] && !dataOPD['diagnosis_information_list']) {
                    dataOPD.splice(findItemIndex, 1);
                }

            }
        }
        setDataOPD([...dataOPD]);
    }

    return (
        <Box sx={backgroundModal3}>
            <LoaderAnimation loading={loading} />
            <Box sx={contentAddRefer}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <ClearIcon onClick={closeModal} />
                </Box>
                <Box>
                    <Typography variant='p' color='primary'>เลือกข้อมูล</Typography>
                    <hr style={{ margin: '1rem 0' }} />
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: '20px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr',
                            gridGap: '5px'
                        }
                    }}>
                        <Box>
                            <Typography component='p' variant='p' color='primary'>โรงพยาบาล</Typography>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={hospital || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setHospital(value)
                                        setDate('')
                                    }}
                                >
                                    <MenuItem value=" ">ทั้งหมด</MenuItem>
                                    {optionHospital.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['key']}
                                        >
                                            {item['value']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography component='p' variant='p' color='primary'>วันที่</Typography>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={date || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setDate(value)
                                    }}
                                >
                                    <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                                    {optionDate.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item}
                                        >
                                            {convertDate(item)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{
                        height: '450px',
                        overflow: 'scroll',
                        fontSize: 18,
                        '@media (max-width: 800px)': {
                            fontSize: 12
                        }
                    }}>
                        {dataInformation.length > 0 ?
                            <>
                                {dataInformation.map((item, index) => {

                                    let findOpdIndex = dataOPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['visit_date']);

                                    let checkedVisit = false;
                                    let checkedLab = false;
                                    let checkedOrder = false;
                                    let checkedDiagnosis = false;

                                    if (findOpdIndex > -1) {
                                        let opd = dataOPD[findOpdIndex];
                                        checkedVisit = opd['visit_information_list'] ? true : false;
                                        checkedLab = opd['labs_information_list'] ? true : false;
                                        checkedOrder = opd['order_information_list'] ? true : false;
                                        checkedDiagnosis = opd['diagnosis_information_list'] ? true : false;
                                    }

                                    return (
                                        <Box key={index} sx={{ color: '#707070' }}>
                                            <Box sx={{
                                                marginTop: '1rem',
                                                color: '#006838',
                                                fontSize: 20,
                                                fontWeight: 700,
                                                '@media (max-width: 800px)': {
                                                    fontSize: 16
                                                }
                                            }}>{convertDate(item['visit_date'])}</Box>
                                            <Box sx={{
                                                color: '#006838',
                                                fontSize: 16,
                                                '@media (max-width: 800px)': {
                                                    fontSize: 14
                                                }
                                            }}>{nameHospital(item['hos_id'])}</Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'visit', e) }} />}
                                                        checked={checkedVisit}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการแพทย์</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['visit_information_list'].length > 0 ?
                                                        <>
                                                            {item['visit_information_list'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                                    <Box sx={{
                                                                        display: 'grid',
                                                                        gridTemplateColumns: '1fr 1fr 1fr',
                                                                        gridGap: '10px',
                                                                        padding: '10px',
                                                                        '@media (max-width: 800px)': {
                                                                            gridTemplateColumns: '1fr'
                                                                        }
                                                                    }}>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>CC</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['cc'] === '' ? '-' : i['cc']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Ht</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['ht'] === '' ? '-' : i['ht']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Pi</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['pi'] === '' ? '-' : i['pi']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Bt</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['bt'] === '' ? '-' : i['bt']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Pe</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['pe'] === '' ? '-' : i['pe']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Rr</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['rr'] === '' ? '-' : i['rr']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Bp</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['bp'] === '' ? '-' : i['bp']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Bmi</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['bmi'] === '' ? '-' : i['bmi']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Pr</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['pr'] === '' ? '-' : i['pr']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>sat O2</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['sat_o2'] === '' ? '-' : i['sat_o2']}</div>
                                                                        </Box>
                                                                        <Box>
                                                                            <div style={{ fontWeight: 700, color: '#006838' }}>Bw</div>
                                                                            <div style={{ margin: '0 10%' }}>{i['bw'] === '' ? '-' : i['bw']}</div>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>

                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'lab', e) }} />}
                                                        checked={checkedLab}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ผลแลป</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['labs_information_list'].length > 0 ?
                                                        <>
                                                            {item['labs_information_list'].map((i, idx) => (
                                                                <Box key={idx} sx={{ padding: '10px' }}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                                    {i['lab_list'].map((item, index) => (
                                                                        <Box key={index} >
                                                                            <Box sx={{ fontWeight: 700, color: '#006838', fontSize: 16 }}>{item['ordername']}</Box>
                                                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                                                {item['detail'].map((i, idx) => (
                                                                                    <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                                        <CircleIcon style={{ width: '5px' }} /> {i}
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'diagnosis', e) }} />}
                                                        checked={checkedDiagnosis}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >การวินิจฉัย</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['diagnosis_information_list'].length > 0 ?
                                                        <>
                                                            {item['diagnosis_information_list'].map((i, idx) => (
                                                                <Box key={idx} sx={{ padding: '10px' }}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                                    {i['diagnosis'].map((item, index) => (
                                                                        <Box key={index} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                            <CircleIcon style={{ width: '5px' }} /> {item['diag']}
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'order', e) }} />}
                                                        checked={checkedOrder}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}

                                                        >การจ่ายยา</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['order_information_list'].length > 0 ?
                                                        <Box sx={{ padding: '10px' }}>
                                                            {item['order_information_list'].map((item, index) => (
                                                                <Box key={index}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                    {item['order_list'].length > 0 ?
                                                                        <Box>
                                                                            {item['order_list'].map((i, idx) => (
                                                                                <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                                    <CircleIcon style={{ width: '5px' }} /> {i['med']}
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                        :
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                                    }
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                        </Box>
                                    )
                                })}
                            </>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center', margin: 15 }}>ไม่พบข้อมูล</Box>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem' }}>
                        <Button variant="outlined" onClick={closeModal}>ยกเลิก</Button>
                        <Button variant="contained"
                            onClick={() => {
                                closeModal()
                                onSubmit(dataOPD)
                            }}
                        >ยืนยัน</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export function ModalAddReferIPD({ closeModal, pid, onSubmit }) {

    const [loading, setLoading] = useState(false)

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])
    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataAll, setDataAll] = useState([])
    const [dataInformation, setDataInformation] = useState([])

    const [dataIPD, setDataIPD] = useState([])

    useEffect(() => {
        if (date === '' || date === ' ') {
            findInformation(pid)
        } else {
            findfilterByDate()
        }

    }, [hospital, date])

    const findInformation = async (PID) => {
        setLoading(true)
        let res = await apiRefer.findInformationIPD(PID, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                // เรียงข้อมูลแยกตามวันที่
                let raw = res['data']
                let date_list = raw.data.diagnosis_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] }));
                date_list = date_list.concat(raw.data.labs_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.order_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.proced_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.date_admit, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = date_list.concat(raw.data.visit_information_ipd_list.map(item => ({ hos_id: item.hos_id, date_admit: item.admit_date, diagnosis_information_ipd_list: [], labs_information_ipd_list: [], order_information_ipd_list: [], proced_information_ipd_list: [], visit_information_ipd_list: [] })));
                date_list = _.orderBy(_.uniqBy(date_list, 'date_admit'), ['date_admit'], ['desc'])

                date_list.forEach((item_) => {
                    item_.diagnosis_information_ipd_list = raw.data.diagnosis_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.labs_information_ipd_list = raw.data.labs_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.order_information_ipd_list = raw.data.order_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.proced_information_ipd_list = raw.data.proced_information_ipd_list.filter(item => item.date_admit === item_.date_admit)
                    item_.visit_information_ipd_list = raw.data.visit_information_ipd_list.filter(item => item.admit_date === item_.date_admit)
                })
                setDataInformation(date_list)
                setDataAll(date_list)
                console.log(date_list)

                //option date
                const List = []
                for (let i = 0; i < date_list.length; i++) {
                    if (date_list[i]) {
                        List.push(date_list[i]['date_admit'])
                    }
                }
                setOptionDate(List)

                if (hospital === '' || hospital === ' ') {
                    filterHospitalOption(res['data']['data'])
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const filterHospitalOption = (data) => {
        let option = []
        for (let i = 0; i < data['personal_information_list'].length; i++) {
            if (data['personal_information_list'][i]) {
                option.push({
                    key: data['personal_information_list'][i]['hos_id'],
                    value: data['personal_information_list'][i]['hos_name']
                })
            }
        }
        const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
        setOptionHospital(unique)
        // console.log(unique)
    }

    const findfilterByDate = () => {
        let data = []
        for (let i = 0; i < dataAll.length; i++) {
            if (dataAll[i]['date_admit'] === date) {
                data.push(dataAll[i])
            }
        }
        setDataInformation(data)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const nameHospital = (id) => {
        for (let i = 0; i < optionHospital.length; i++) {
            if (optionHospital[i]['key'] === id) {
                return optionHospital[i]['value']
            }
        }
    }

    const selectingData = (item, index, type, event) => {
        // console.log(item)
        let checked = event.target.checked;

        if (checked) {
            let findItemIndex = dataIPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['date_admit']);

            if (findItemIndex > -1) {
                let data = dataIPD[findItemIndex];
                if (type == 'visit') {
                    data['visit_information_ipd_list'] = item['visit_information_ipd_list'];
                }

                if (type == 'diagnosis') {
                    data['diagnosis_information_ipd_list'] = item['diagnosis_information_ipd_list'];
                }

                if (type == 'lab') {
                    data['labs_information_ipd_list'] = item['labs_information_ipd_list'];
                }

                if (type == 'order') {
                    data['order_information_ipd_list'] = item['order_information_ipd_list'];
                }

                if (type == 'proced') {
                    data['proced_information_ipd_list'] = item['proced_information_ipd_list'];
                }

                dataIPD[findItemIndex] = data;

            } else {
                let data = {
                    date: item['date_admit'],
                    hos_id: item['hos_id'],
                }

                if (type == 'visit') {
                    data['visit_information_ipd_list'] = item['visit_information_ipd_list'];
                }

                if (type == 'diagnosis') {
                    data['diagnosis_information_ipd_list'] = item['diagnosis_information_ipd_list'];
                }

                if (type == 'lab') {
                    data['labs_information_ipd_list'] = item['labs_information_ipd_list'];
                }

                if (type == 'order') {
                    data['order_information_ipd_list'] = item['order_information_ipd_list'];
                }

                if (type == 'proced') {
                    data['proced_information_ipd_list'] = item['proced_information_ipd_list'];
                }

                dataIPD.push(data)
            }
        } else {
            let findItemIndex = dataIPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['date_admit']);

            if (findItemIndex > -1) {
                let data = dataIPD[findItemIndex];

                if (type == 'visit') {
                    delete data['visit_information_ipd_list'];
                }

                if (type == 'diagnosis') {
                    delete data['diagnosis_information_ipd_list'];
                }

                if (type == 'lab') {
                    delete data['labs_information_ipd_list'];
                }

                if (type == 'order') {
                    delete data['order_information_ipd_list'];
                }

                if (type == 'proced') {
                    delete data['proced_information_ipd_list'];
                }

                dataIPD[findItemIndex] = data;

                if (!dataIPD['visit_information_ipd_list'] && !dataIPD['diagnosis_information_ipd_list'] && !dataIPD['labs_information_ipd_list'] && !dataIPD['order_information_ipd_list'] && !dataIPD['proced_information_ipd_list']) {
                    dataIPD.splice(findItemIndex, 1);
                }

            }
        }
        setDataIPD([...dataIPD]);
    }


    return (
        <Box sx={backgroundModal3}>
            <LoaderAnimation loading={loading} />
            <Box sx={contentAddRefer}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <ClearIcon onClick={closeModal} />
                </Box>
                <Box>
                    <Typography variant='p' color='primary'>เลือกข้อมูล</Typography>
                    <hr style={{ margin: '1rem 0' }} />
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: '20px',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: '1fr',
                            gridGap: '5px'
                        }
                    }}>
                        <Box>
                            <Typography component='p' variant='p' color='primary'>โรงพยาบาล</Typography>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={hospital || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setHospital(value)
                                        setDate('')
                                    }}
                                >
                                    <MenuItem value=" ">ทั้งหมด</MenuItem>
                                    {optionHospital.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['key']}
                                        >
                                            {item['value']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography component='p' variant='p' color='primary'>วันที่</Typography>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={date || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setDate(value)
                                    }}
                                >
                                    <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                                    {optionDate.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item}
                                        >
                                            {convertDate(item)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{
                        height: '450px',
                        overflow: 'scroll',
                        fontSize: 18,
                        '@media (max-width: 800px)': {
                            fontSize: 12
                        }
                    }}>
                        {dataInformation.length > 0 ?
                            <>
                                {dataInformation.map((item, index) => {
                                    let findOpdIndex = dataIPD.findIndex(e => e['hos_id'] === item['hos_id'] && e['date'] == item['date_admit']);

                                    let checkedVisitSl = false;
                                    let checkedVisit = false;
                                    let checkedLab = false;
                                    let checkedOrder = false;
                                    let checkedDiagnosis = false;
                                    let checkedProced = false;

                                    if (findOpdIndex > -1) {
                                        let ipd = dataIPD[findOpdIndex];
                                        checkedVisitSl = ipd['visit_information_ipd_list'] ? true : false;
                                        checkedVisit = ipd['visit_information_ipd_list'] ? true : false;
                                        checkedLab = ipd['labs_information_ipd_list'] ? true : false;
                                        checkedOrder = ipd['order_information_ipd_list'] ? true : false;
                                        checkedDiagnosis = ipd['diagnosis_information_ipd_list'] ? true : false;
                                        checkedProced = ipd['proced_information_ipd_list'] ? true : false;
                                    }

                                    return (
                                        <Box key={index} sx={{ color: '#707070' }}>
                                            <Box sx={{
                                                marginTop: '1rem',
                                                color: '#006838',
                                                fontSize: 20,
                                                fontWeight: 700,
                                                '@media (max-width: 800px)': {
                                                    fontSize: 16
                                                }
                                            }}>{convertDate(item['date_admit'])}</Box>
                                            <Box sx={{
                                                color: '#006838',
                                                fontSize: 16,
                                                '@media (max-width: 800px)': {
                                                    fontSize: 14
                                                }
                                            }}>{nameHospital(item['hos_id'])}</Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'visit', e) }} />}
                                                        checked={checkedVisitSl}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการนอน รพ.</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['visit_information_ipd_list'].length > 0 ?
                                                        <>
                                                            {item['visit_information_ipd_list'].map((i, idx) => (
                                                                <Box>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                                    <Box sx={{ margin: '20px' }}>
                                                                        <Box sx={{
                                                                            display: 'grid',
                                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                                            gridGap: '50px 10px',
                                                                            '@media (max-width: 800px)': {
                                                                                gridGap: '20px',
                                                                                gridTemplateColumns: '1fr',
                                                                            }
                                                                        }} key={idx}>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่แอดมิด</div>
                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['admit_date'])}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่แอดมิด</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['admit_time']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>แผนกที่แอดมิด</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['admit_clinic']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ดที่แอดมิด</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['admit_ward']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ออกจาก รพ.</div>
                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['dch_date'])}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่ออกจาก รพ.</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['dch_time']}</div>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'visit', e) }} />}
                                                        checked={checkedVisit}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการเข้ารับการรักษา</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['visit_information_ipd_list'].length > 0 ?
                                                        <>
                                                            {item['visit_information_ipd_list'].map((i, idx) => (
                                                                <Box sx={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                    gridGap: '20px',
                                                                    '@media (max-width: 800px)': {
                                                                        gridGap: '10px',
                                                                        gridTemplateColumns: '1fr',
                                                                    }
                                                                }} key={idx}>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bmi</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['bmi'] ? i['bmi'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bp</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['bp'] ? i['bp'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bt</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['bt'] ? i['bt'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bw</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['bw'] ? i['bw'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>cc</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['cc'] ? i['cc'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>code</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['code'] ? i['code'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>ht</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['ht'] ? i['ht'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pe</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['pe'] ? i['pe'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pi</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['pi'] ? i['pi'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pr</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['pr'] ? i['pr'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>rr</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['rr'] ? i['rr'] : '-'}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>sat_o2</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['sat_o2'] ? i['sat_o2'] : '-'}</div>
                                                                    </Box>
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'diagnosis', e) }} />}
                                                        checked={checkedDiagnosis}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการวินิจฉัย</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['diagnosis_information_ipd_list'].length > 0 ?
                                                        <>
                                                            {item['diagnosis_information_ipd_list'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    {i['diagnosis'].map((item, index) => (
                                                                        <Box key={index}>
                                                                            <Box sx={{
                                                                                display: 'grid',
                                                                                gridTemplateColumns: '1fr 1fr',
                                                                                '@media (max-width: 800px)': {
                                                                                    gridTemplateColumns: '1fr',
                                                                                    gridGap: '10px'
                                                                                }
                                                                            }}>
                                                                                <Box>
                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วันที่วินิจฉัย</div>
                                                                                    <div style={{ marginLeft: '20px' }}>{convertDate(item['diagnosis_date'])}</div>
                                                                                </Box>
                                                                                <Box>
                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>ผลการวินิจฉัย</div>
                                                                                    <div style={{ marginLeft: '20px' }}>{item['diag']}</div>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'lab', e) }} />}
                                                        checked={checkedLab}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการตรวจแลป</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['labs_information_ipd_list'].length > 0 ?
                                                        <>
                                                            {item['labs_information_ipd_list'].map((i, idx) => (
                                                                <Box key={idx} sx={{ padding: '10px' }}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                                    {i['lab_list'].map((item, index) => (
                                                                        <Box key={index} >
                                                                            <Box sx={{ fontWeight: 700, color: '#006838', fontSize: 16 }}>{item['ordername']}</Box>
                                                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                                                {item['detail'].map((i, idx) => (
                                                                                    <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                                        <CircleIcon style={{ width: '5px' }} /> {i}
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'order', e) }} />}
                                                        checked={checkedOrder}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >การจ่ายยา</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['order_information_ipd_list'].length > 0 ?
                                                        <Box sx={{ padding: '10px' }}>
                                                            {item['order_information_ipd_list'].map((item, index) => (
                                                                <Box key={index}>
                                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                    {item['order_list'].length > 0 ?
                                                                        <Box>
                                                                            {item['order_list'].map((i, idx) => (
                                                                                <Box key={idx}>
                                                                                    <Box sx={{ margin: '20px' }}>
                                                                                        <Box sx={{
                                                                                            display: 'grid',
                                                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                                                            gridGap: '20px',
                                                                                            '@media (max-width: 800px)': {
                                                                                                gridTemplateColumns: '1fr',
                                                                                                gridGap: '10px'
                                                                                            }
                                                                                        }} key={index}>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ได้รับยา</div>
                                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['verify_date'])}</div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>รายการยา</div>
                                                                                                <div style={{ marginLeft: '15px' }}>{i['drug']}</div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วิธีการใช้ยา</div>
                                                                                                <div style={{ marginLeft: '15px' }}>{i['drugUse']}</div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>ปริมาณยาที่ได้รับ</div>
                                                                                                <div style={{ marginLeft: '15px' }}>{i['drugAmount']}</div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>med</div>
                                                                                                <div style={{ marginLeft: '15px' }}>{i['med']}</div>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                        :
                                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                                    }
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            </Box>

                                            <Box sx={{
                                                border: 1,
                                                borderColor: '#006838',
                                                borderRadius: '10px',
                                                padding: '10px 20px',
                                                margin: '10px 20px',
                                                '@media (max-width: 800px)': {
                                                    margin: '5px 0',
                                                    padding: '0 5px'
                                                }
                                            }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<Checkbox onClick={(e) => { selectingData(item, index, 'proced', e) }} />}
                                                        checked={checkedProced}
                                                        label={<Typography
                                                            sx={{
                                                                color: '#272727',
                                                                fontSize: 18,
                                                                '@media (max-width: 800px)': {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                        >ประวัติการทำหัตถการ</Typography>}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                                    {item['proced_information_ipd_list'].length > 0 ?
                                                        <Box sx={{ padding: '10px' }}>
                                                            {item['proced_information_ipd_list'].map((item, index) => (
                                                                <Box key={index}>
                                                                    {item['proced'].map((i, idx) => (
                                                                        <Box key={idx}>
                                                                            <Box sx={{ margin: '20px' }}>
                                                                                <Box sx={{
                                                                                    display: 'grid',
                                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                                    gridGap: '20px',
                                                                                    '@media (max-width: 800px)': {
                                                                                        gridTemplateColumns: '1fr',
                                                                                        gridGap: '10px'
                                                                                    }
                                                                                }}>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ทำหัตถการ</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{convertDate(i['proced_date'])}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ด</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{i['proced_ward']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>รายการ</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{i['proced']}</div>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                            </Box>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }

                                                </Box>
                                            </Box>

                                        </Box>
                                    )
                                })}
                            </>
                            :
                            <Box sx={{ display: 'flex', justifyContent: 'center', margin: 15 }}>ไม่พบข้อมูล</Box>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem' }}>
                        <Button variant="outlined" onClick={closeModal}>ยกเลิก</Button>
                        <Button variant="contained"
                            onClick={() => {
                                closeModal()
                                onSubmit(dataIPD)
                            }}
                        >ยืนยัน</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export function ModalOpenData({ closeModal, data }) {

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }


    console.log(data)

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModalV3}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <IconButton onClick={() => { closeModal() }}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    maxHeight: '450px',
                    overflow: 'scroll'
                }}>
                    <Box sx={{ color: '#707070' }}>
                        <Box sx={{
                            color: '#006838',
                            fontSize: 20,
                            fontWeight: 700,
                            '@media (max-width: 800px)': {
                                fontSize: 16
                            }
                        }}>{convertDate(data['date'])}</Box>
                        {/* <Box sx={{
                            color: '#006838',
                            fontSize: 16,
                            '@media (max-width: 800px)': {
                                fontSize: 14
                            }
                        }}>{nameHospital(data['hos_id'])}</Box> */}

                        {data['visit_information_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Typography sx={{
                                    color: '#272727',
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 12
                                    }
                                }}>ประวัติการแพทย์</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['visit_information_list'].map((i, idx) => (
                                        <Box key={idx}>
                                            <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                            <Box sx={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                gridGap: '10px',
                                                padding: '10px',
                                                '@media (max-width: 800px)': {
                                                    gridTemplateColumns: '1fr'
                                                }
                                            }}>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>CC</div>
                                                    <div style={{ margin: '0 10%' }}>{i['cc'] === '' ? '-' : i['cc']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Ht</div>
                                                    <div style={{ margin: '0 10%' }}>{i['ht'] === '' ? '-' : i['ht']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Pi</div>
                                                    <div style={{ margin: '0 10%' }}>{i['pi'] === '' ? '-' : i['pi']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Bt</div>
                                                    <div style={{ margin: '0 10%' }}>{i['bt'] === '' ? '-' : i['bt']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Pe</div>
                                                    <div style={{ margin: '0 10%' }}>{i['pe'] === '' ? '-' : i['pe']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Rr</div>
                                                    <div style={{ margin: '0 10%' }}>{i['rr'] === '' ? '-' : i['rr']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Bp</div>
                                                    <div style={{ margin: '0 10%' }}>{i['bp'] === '' ? '-' : i['bp']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Bmi</div>
                                                    <div style={{ margin: '0 10%' }}>{i['bmi'] === '' ? '-' : i['bmi']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Pr</div>
                                                    <div style={{ margin: '0 10%' }}>{i['pr'] === '' ? '-' : i['pr']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>sat O2</div>
                                                    <div style={{ margin: '0 10%' }}>{i['sat_o2'] === '' ? '-' : i['sat_o2']}</div>
                                                </Box>
                                                <Box>
                                                    <div style={{ fontWeight: 700, color: '#006838' }}>Bw</div>
                                                    <div style={{ margin: '0 10%' }}>{i['bw'] === '' ? '-' : i['bw']}</div>
                                                </Box>
                                            </Box>
                                        </Box>

                                    ))}
                                </Box>
                            </Box>
                            : null
                        }

                        {data['labs_information_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Typography sx={{
                                    color: '#272727',
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 12
                                    }
                                }}>ผลแลป</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['labs_information_list'].map((i, idx) => (
                                        <Box key={idx} sx={{ padding: '10px' }}>
                                            <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                            {i['lab_list'].map((item, index) => (
                                                <Box key={index} >
                                                    <Box sx={{ fontWeight: 700, color: '#006838', fontSize: 16 }}>{item['ordername']}</Box>
                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                        {item['detail'].map((i, idx) => (
                                                            <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                <CircleIcon style={{ width: '5px' }} /> {i}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}

                                </Box>
                            </Box>
                            : null
                        }

                        {data['diagnosis_information_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Typography sx={{
                                    color: '#272727',
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 12
                                    }
                                }}>การวินิจฉัย</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['diagnosis_information_list'].map((i, idx) => (
                                        <Box key={idx} sx={{ padding: '10px' }}>
                                            <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                            {i['diagnosis'].map((item, index) => (
                                                <Box key={index} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                    <CircleIcon style={{ width: '5px' }} /> {item['diag']}
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            : null
                        }

                        {data['order_information_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Typography sx={{
                                    color: '#272727',
                                    fontSize: 18,
                                    '@media (max-width: 800px)': {
                                        fontSize: 12
                                    }
                                }}>การจ่ายยา</Typography>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['order_information_list'].map((item, index) => (
                                        <Box key={index}>
                                            <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                            {item['order_list'].length > 0 ?
                                                <Box>
                                                    {item['order_list'].map((i, idx) => (
                                                        <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                            <CircleIcon style={{ width: '5px' }} /> {i['med']}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                            }
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            : null
                        }

                    </Box>
                </Box>

            </Box>
        </Box >
    )
}

export function ModalOpenDataIPD({ closeModal, data }) {

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }


    console.log(data)

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModalV3}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <IconButton onClick={() => { closeModal() }}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box sx={{
                    maxHeight: '450px',
                    overflow: 'scroll'
                }}>
                    <Box sx={{ color: '#707070' }}>
                        <Box sx={{
                            color: '#006838',
                            fontSize: 20,
                            fontWeight: 700,
                            '@media (max-width: 800px)': {
                                fontSize: 16
                            }
                        }}>{convertDate(data['date'])}</Box>
                        {/* <Box sx={{
                            color: '#006838',
                            fontSize: 16,
                            '@media (max-width: 800px)': {
                                fontSize: 14
                            }
                        }}>{nameHospital(data['hos_id'])}</Box> */}

                        {data['visit_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >ประวัติการนอน รพ.</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['visit_information_ipd_list'].length > 0 ?
                                        <>
                                            {data['visit_information_ipd_list'].map((i, idx) => (
                                                <Box>
                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                    <Box sx={{ margin: '20px' }}>
                                                        <Box sx={{
                                                            display: 'grid',
                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                            gridGap: '50px 10px',
                                                            '@media (max-width: 800px)': {
                                                                gridGap: '20px',
                                                                gridTemplateColumns: '1fr',
                                                            }
                                                        }} key={idx}>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่แอดมิด</div>
                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['admit_date'])}</div>
                                                            </Box>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่แอดมิด</div>
                                                                <div style={{ marginLeft: '15px' }}>{i['admit_time']}</div>
                                                            </Box>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>แผนกที่แอดมิด</div>
                                                                <div style={{ marginLeft: '15px' }}>{i['admit_clinic']}</div>
                                                            </Box>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ดที่แอดมิด</div>
                                                                <div style={{ marginLeft: '15px' }}>{i['admit_ward']}</div>
                                                            </Box>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ออกจาก รพ.</div>
                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['dch_date'])}</div>
                                                            </Box>
                                                            <Box>
                                                                <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่ออกจาก รพ.</div>
                                                                <div style={{ marginLeft: '15px' }}>{i['dch_time']}</div>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }
                                </Box>
                            </Box>
                            : null
                        }

                        {data['visit_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >ประวัติการเข้ารับการรักษา</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['visit_information_ipd_list'].length > 0 ?
                                        <>
                                            {data['visit_information_ipd_list'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                    gridGap: '20px',
                                                    '@media (max-width: 800px)': {
                                                        gridGap: '10px',
                                                        gridTemplateColumns: '1fr',
                                                    }
                                                }} key={idx}>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bmi</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['bmi'] ? i['bmi'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bp</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['bp'] ? i['bp'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bt</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['bt'] ? i['bt'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bw</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['bw'] ? i['bw'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>cc</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['cc'] ? i['cc'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>code</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['code'] ? i['code'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>ht</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['ht'] ? i['ht'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pe</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['pe'] ? i['pe'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pi</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['pi'] ? i['pi'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pr</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['pr'] ? i['pr'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>rr</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['rr'] ? i['rr'] : '-'}</div>
                                                    </Box>
                                                    <Box>
                                                        <div style={{ color: '#006838', fontWeight: 700 }}>sat_o2</div>
                                                        <div style={{ marginLeft: '15px' }}>{i['sat_o2'] ? i['sat_o2'] : '-'}</div>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }
                                </Box>
                            </Box>
                            : null
                        }

                        {data['diagnosis_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >ประวัติการวินิจฉัย</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['diagnosis_information_ipd_list'].length > 0 ?
                                        <>
                                            {data['diagnosis_information_ipd_list'].map((i, idx) => (
                                                <Box key={idx}>
                                                    {i['diagnosis'].map((item, index) => (
                                                        <Box key={index}>
                                                            <Box sx={{
                                                                display: 'grid',
                                                                gridTemplateColumns: '1fr 1fr',
                                                                '@media (max-width: 800px)': {
                                                                    gridTemplateColumns: '1fr',
                                                                    gridGap: '10px'
                                                                }
                                                            }}>
                                                                <Box>
                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วันที่วินิจฉัย</div>
                                                                    <div style={{ marginLeft: '20px' }}>{convertDate(item['diagnosis_date'])}</div>
                                                                </Box>
                                                                <Box>
                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>ผลการวินิจฉัย</div>
                                                                    <div style={{ marginLeft: '20px' }}>{item['diag']}</div>
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ))}
                                        </>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }
                                </Box>
                            </Box>
                            : null
                        }

                        {data['labs_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >ประวัติการตรวจแลป</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['labs_information_ipd_list'].length > 0 ?
                                        <>
                                            {data['labs_information_ipd_list'].map((i, idx) => (
                                                <Box key={idx} sx={{ padding: '10px' }}>
                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{i['hos_name']}</Box>
                                                    {i['lab_list'].map((item, index) => (
                                                        <Box key={index} >
                                                            <Box sx={{ fontWeight: 700, color: '#006838', fontSize: 16 }}>{item['ordername']}</Box>
                                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                                {item['detail'].map((i, idx) => (
                                                                    <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                        <CircleIcon style={{ width: '5px' }} /> {i}
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ))}
                                        </>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }
                                </Box>
                            </Box>
                            : null
                        }

                        {data['order_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >การจ่ายยา</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['order_information_ipd_list'].length > 0 ?
                                        <Box sx={{ padding: '10px' }}>
                                            {data['order_information_ipd_list'].map((item, index) => (
                                                <Box key={index}>
                                                    <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                    {item['order_list'].length > 0 ?
                                                        <Box>
                                                            {item['order_list'].map((i, idx) => (
                                                                <Box key={idx}>
                                                                    <Box sx={{ margin: '20px' }}>
                                                                        <Box sx={{
                                                                            display: 'grid',
                                                                            gridTemplateColumns: '1fr 1fr 1fr',
                                                                            gridGap: '20px',
                                                                            '@media (max-width: 800px)': {
                                                                                gridTemplateColumns: '1fr',
                                                                                gridGap: '10px'
                                                                            }
                                                                        }} key={index}>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ได้รับยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{convertDate(i['verify_date'])}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>รายการยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drug']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วิธีการใช้ยา</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drugUse']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>ปริมาณยาที่ได้รับ</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['drugAmount']}</div>
                                                                            </Box>
                                                                            <Box>
                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>med</div>
                                                                                <div style={{ marginLeft: '15px' }}>{i['med']}</div>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                        :
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                                    }
                                                </Box>
                                            ))}
                                        </Box>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }
                                </Box>
                            </Box>
                            : null
                        }

                        {data['proced_information_ipd_list'] ?
                            <Box sx={{
                                border: 1,
                                borderColor: '#006838',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                margin: '10px 20px',
                                '@media (max-width: 800px)': {
                                    margin: '5px 0',
                                    padding: '0 5px'
                                }
                            }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#272727',
                                            fontSize: 18,
                                            '@media (max-width: 800px)': {
                                                fontSize: 12
                                            }
                                        }}
                                    >ประวัติการทำหัตถการ</Typography>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                                    {data['proced_information_ipd_list'].length > 0 ?
                                        <Box sx={{ padding: '10px' }}>
                                            {data['proced_information_ipd_list'].map((item, index) => (
                                                <Box key={index}>
                                                    {item['proced'].map((i, idx) => (
                                                        <Box key={idx}>
                                                            <Box sx={{ margin: '20px' }}>
                                                                <Box sx={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                    gridGap: '20px',
                                                                    '@media (max-width: 800px)': {
                                                                        gridTemplateColumns: '1fr',
                                                                        gridGap: '10px'
                                                                    }
                                                                }}>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ทำหัตถการ</div>
                                                                        <div style={{ marginLeft: '15px' }}>{convertDate(i['proced_date'])}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ด</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['proced_ward']}</div>
                                                                    </Box>
                                                                    <Box>
                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>รายการ</div>
                                                                        <div style={{ marginLeft: '15px' }}>{i['proced']}</div>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ))}
                                        </Box>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '5px 0 20px 0' }}>ไม่พบข้อมูล</Box>
                                    }

                                </Box>
                            </Box>
                            : null
                        }


                    </Box>
                </Box>

            </Box>
        </Box >
    )
}