
import bgImg from '../../assets/img/bg_login$register.png'

export const tabActive = {
    backgroundColor: '#E1F4EB',
    border: '1px solid #006838',
    '&:hover': {
        backgroundColor: '#E1F4EB',
        color: '#006838',
    }
}

export const btnSecondary = {
    boxShadow: 'none',
    backgroundColor: '#E1F4EB',
    border: '1px solid #E1F4EB',
    color: '#006838',
    '&:hover': {
        backgroundColor: '#E1F4EB',
        color: '#006838',
    }
}

export const content = {
    backgroundColor: '#fff',
    padding: '1rem',
    border: '0.5px solid #E1E3E9',
    borderRadius: '0.5rem'
}
export const contentTable = {
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    border: '1px solid #E1E3E9',
}
export const boxSearch = {
    display: 'flex',
    justifyContent: 'center',
    gridGap: '2rem',
    borderRadius: '0.5rem',
    border: '1px solid #E1E3E9',
    width: { xl: '60%', lg: '60%', md: '70%', sm: '80%', xs: '100%' },
    padding: '1.5rem 1rem',
    flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
    alignItems: 'center',
    cursor: 'pointer'
}

export const contentModal = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
}

export const boxScan = {
    backgroundColor: '#fff',
    width: { xl: '70%', lg: '70%', md: '70%', sm: '80%', xs: '90%' },
    padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '1rem', xs: '1rem' },
    borderRadius: '10px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
}

export const boxCondition = {
    backgroundColor: '#fff',
    padding: '1rem',
    textAlign: 'center',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    borderRadius: '10px',
}

export const tabContained = {
    backgroundColor: '#006838',
    border: '1px solid #006838',
    borderRadius: '10px',
    height: '40px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const tabContained2 = {
    backgroundColor: '#E1F4EB',
    border: '1px solid #006838',
    borderRadius: '10px',
    height: '40px',
    color: '#006838',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const tabOutlined = {
    backgroundColor: '#FFFFFF',
    border: '1px solid #006838',
    borderRadius: '10px',
    height: '40px',
    color: '#006838',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const tabOutlined2 = {
    backgroundColor: '#FFFFFF',
    border: '1px solid #747676',
    borderRadius: '10px',
    height: '40px',
    color: '#747676',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const ButtonContained = {
    backgroundColor: '#006838',
    border: '1px solid #006838',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const ButtonOutlined = {
    backgroundColor: '#FFFFFF',
    border: '1px solid #006838',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    color: '#006838',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 700
}

export const cardHeader = {
    backgroundColor:'#fff',
    border:'0.5px solid #E1E3E9',
    borderRadius:'10px',
    padding:'1rem',
    display:'flex',
    gridGap:'1rem',
    alignItems:'center',
}

export const cardRefer = {
    backgroundColor:'#fff',
    padding:'1rem',
    display:'flex',
    gridGap:'2rem',
    alignItems:'center',
    border:'0.5px solid #E1E3E9',
    borderRadius:'10px',
}

export const activeCard = {
    backgroundColor:'#006838',
    color:'#fff'
  }
export const inActiveCard = {
    backgroundColor:'#fff',
    color:'#006838'
}
export const cardReferType = {
    backgroundColor:'#fff',
    border:'0.5px solid #E1E3E9',
    borderRadius:'10px',
}
export const cardReferTypeTop = {
    padding:'1rem 1rem 0 1rem',
    display:'flex',
    gridGap:'2rem',
    alignItems:'center',
}