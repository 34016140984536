import React, { useContext } from 'react'
import { Typography, Box, IconButton } from "@mui/material"
import { Menu, MenuItem, Sidebar, useProSidebar, SubMenu } from "react-pro-sidebar"
import { Link, useNavigate } from "react-router-dom"
import {
    RECORD_PATIENT_TITLE,
    OUT_PITENT_TITLE,
    CONSULT_DOCTOR_TITLE,
    DRUG_DELIVERY_TITLE,
    MANAGE_USER_TITLE,
    SOS_TITLE,
    CONSULT_HOSPITAL_TITLE,

    RECORD_PATIENT_PATH,
    OUT_PITENT_PATH,
    CONSULT_PATH,
    DRUG_DELIVERY_PATH,
    MANAGE_USER_PATH,
    SOS_PATH,
    CONSULT_HOSPITAL_PATH

} from "../config/constants"
import { useState, useEffect } from "react"

import * as apiManage from '../services/public_api'
import { MainContext } from '../App';
import { notifyError } from '../components/NotifyToast';

import { useDispatch, useSelector } from 'react-redux';
import { menuActive as ManageMenu } from '../store/menuSlice'

import { IoMdHome } from "react-icons/io";
import { IoIosPaper } from "react-icons/io";
import { FaCalendarPlus } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { BsCapsulePill } from "react-icons/bs";
import { BsPersonFillGear } from "react-icons/bs";
import { MdOutlineSettingsPhone } from "react-icons/md";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { BsPersonFillUp } from "react-icons/bs";

const SideNav = () => {
    const {
        menuState,
        handleOpenPopup
    } = useContext(MainContext)

    const { collapsed, toggleSidebar, collapseSidebar, broken } = useProSidebar()
    const navigate = useNavigate()

    const [activeMenuItem, setActiveMenuItem] = useState('')

    const menuActive = useSelector((state) => state.menu_active)
    const dispatch = useDispatch()

    const handleMenuClick = (menu, path) => {
        if (menu === 'ประวัติผู้ป่วย') {
            handleOpenPopup({
                actvie: true
            })
        }
        setActiveMenuItem(menu)
        toggleSidebar()
        navigate(path)

        dispatch(ManageMenu(menu))
    }

    const [menu, setMenu] = useState([])
    const menuSidebar = [
        {
            menu: RECORD_PATIENT_TITLE,
            icon_active: <IoIosPaper size={20} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <IoIosPaper size={20} sx={{ color: '#747676' }} />,
            path: RECORD_PATIENT_PATH,
            subMenu: false
        },
        {
            menu: OUT_PITENT_TITLE,
            icon_active: <FaCalendarPlus size={20} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <FaCalendarPlus size={20} sx={{ color: '#747676' }} />,
            path: OUT_PITENT_PATH,
            subMenu: false
        },
        {
            menu: CONSULT_DOCTOR_TITLE,
            icon_active: <FaUserDoctor size={20} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <FaUserDoctor size={20} sx={{ color: '#747676' }} />,
            path: CONSULT_PATH,
            subMenu: false
        },
        {
            menu: DRUG_DELIVERY_TITLE,
            icon_active: <BsCapsulePill size={20} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <BsCapsulePill size={20} sx={{ color: '#747676' }} />,
            path: DRUG_DELIVERY_PATH,
            subMenu: false
        },
        {
            menu: 'ส่งต่อผู้ป่วย',
            icon_active: <BsPersonFillUp size={25} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <BsPersonFillUp size={25} sx={{ color: '#747676' }} />,
            path: '',
            subMenu: true,
            subList: [
                {
                    menu: 'ส่งต่อผู้ป่วย',
                    path: '/refer'
                },
                {
                    menu: 'รับผู้ป่วย',
                    path: '/resive'
                },
                {
                    menu: 'ภาพรวมการส่งต่อผู้ป่วย',
                    path: '/dashboardRefer'
                },
            ]
        },
        {
            menu: SOS_TITLE,
            icon_active: <MdOutlineSettingsPhone size={25} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <MdOutlineSettingsPhone size={25} sx={{ color: '#747676' }} />,
            path: SOS_PATH,
            subMenu: false
        },
        {
            menu: 'ปรึกษา',
            icon_active: <HiChatBubbleLeftRight size={20} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <HiChatBubbleLeftRight size={20} sx={{ color: '#747676' }} />,
            path: CONSULT_HOSPITAL_PATH,
            subMenu: false
        },
        {
            menu: MANAGE_USER_TITLE,
            icon_active: <BsPersonFillGear size={25} sx={{ color: '#FFFFFF' }} />,
            icon_inactive: <BsPersonFillGear size={25} sx={{ color: '#747676' }} />,
            path: MANAGE_USER_PATH,
            subMenu: false
        },
        
    ]

    useEffect(() => {
        getMenu()

    }, [menuState])

    const getMenu = async () => {
        let res = await apiManage.profile()
        if (res) {
            let menu_list = res['data']['data']?.['MenuList']
            const newMenu = menuSidebar.filter(item => {
                return menu_list?.find((menuItem) => menuItem['MenuName'] === item['menu'])
            })
            setMenu(newMenu)
        } else {
            setMenu(menuSidebar)
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <Sidebar
            style={{
                minHeight: "100vh",
                top: 'auto'
            }}
            breakPoint="md"
            backgroundColor={'#fff'}
        >
            {/* <br /> */}
            <Box sx={{ height: broken ? '90vh' : '91vh', overflow: 'auto' }}>
                <br />
                <Menu
                    menuItemStyles={{
                        button: ({ active }) => {
                            return {
                                backgroundColor: active ? '#006838' : 'transparent',
                                color: active ? '#ffffff' : '#747676',
                                fontWeight: active ? 'bold' : 'normal',
                                borderRadius: '0.5rem',
                                margin: '0.15rem',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#006838',
                                    color: '#fff',
                                },
                            }
                        }
                    }}
                >
                    <MenuItem
                        active={menuActive.menu === 'หน้าแรก'}
                        icon={<IoMdHome size={25} sx={{ color: menuActive.menu === 'หน้าแรก' ? '#fff' : '#747676' }} />}
                        onClick={() => handleMenuClick('หน้าแรก', '/auth/home')}
                    >
                        <p>หน้าแรก</p>
                    </MenuItem>

                    {
                        menu.map((item, index) => (
                            <React.Fragment key={index}>
                                {item['subMenu'] === true ?
                                    <SubMenu
                                        onClick={() => dispatch(ManageMenu('ส่งต่อผู้ป่วย'))}
                                        active={
                                            menuActive.menu === 'ส่งต่อผู้ป่วย' ||
                                            menuActive.menu === 'รับผู้ป่วย' ||
                                            menuActive.menu === 'ภาพรวมการส่งต่อผู้ป่วย'
                                        }
                                        defaultOpen={false} label={"ส่งต่อผู้ป่วย"}
                                        // icon={<img src={menuActive.menu === 'ส่งต่อผู้ป่วย' ? icon_menu_refer_active : icon_menu_refer_inactive} alt='' />}
                                        icon={menuActive.menu === item['menu'] ? item['icon_active'] : item['icon_inactive']}
                                    >
                                        <Menu
                                            menuItemStyles={{
                                                button: ({ active }) => {
                                                    return {
                                                        backgroundColor: active ? '#747676' : 'transparent',
                                                        color: active ? '#ffffff' : '#747676',
                                                        fontWeight: active ? 'bold' : 'normal',
                                                        borderRadius: '0.5rem',
                                                        margin: '0.15rem',
                                                        paddingLeft: '4rem',
                                                        transition: 'background-color 0.3s ease',
                                                        '&:hover': {
                                                            backgroundColor: '#747676',
                                                            color: '#fff',
                                                        },
                                                    }
                                                }
                                            }}
                                        >
                                            {item['subList'].map((i, idx) => (
                                                <MenuItem key={idx}
                                                    onClick={() => handleMenuClick(i['menu'], i['path'])}
                                                    active={menuActive.menu === i['menu']}
                                                >
                                                    <p>{i['menu']}</p>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </SubMenu>
                                    :
                                    <MenuItem
                                        active={menuActive.menu === item['menu']}
                                        // icon={<img src={menuActive.menu === item['menu'] ? item['icon_active'] : item['icon_inactive']} alt="" />}
                                        icon={menuActive.menu === item['menu'] ? item['icon_active'] : item['icon_inactive']}
                                        onClick={() => handleMenuClick(item['menu'], item['path'])}
                                    >
                                        <p>{item['menu']}</p>
                                    </MenuItem>
                                }
                            </React.Fragment>
                        ))
                    }

                </Menu >
            </Box>


        </Sidebar >
    )
}

export default SideNav