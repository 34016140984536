import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import logo from '../../assets/img/logo.svg'
import logoUp from '../../assets/img/upgradeVersion.svg'
import correct from '../../assets/icons/correct.svg'
import ModalPaymentUpload from '../../components/ModalController/ModalPaymentUpload'
import CloseIcon from '@mui/icons-material/Close';

export default function UpgradeSuccess() {

    const [openModal, setOpenModal] = useState(false)
    const inputFileRef = useRef(null)
    const [attachFile, setAttachFile] = useState([])

    const deleteItemAttach = (item) => {
        const newAttachFile = attachFile.filter(i => i !== item)
        setAttachFile(newAttachFile)
    }

    const inputFileChange = async (event) => {
        console.log(event.target.files[0])
        setAttachFile(event.target.files[0])

        let fileObj = event.target.files && event.target.files[0]



    }

    return (
        <>
            {openModal ?
                <ModalPaymentUpload
                    onClose={() => setOpenModal(false)}
                />
                : null
            }

            <Box sx={{
                height: '100vh',
                display: 'grid',
                alignContent: 'center'
            }}>
                <Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        justifyItems: 'center',
                        padding: '30px 70px',
                        '@media (max-width: 1024px)': {
                            padding: '20px'
                        }
                    }}>
                        <Box sx={{ display: 'grid', gridGap: '30px', justifyItems: 'center' }}>
                            <img style={{ width: '50%' }} src={logo} />
                            <img style={{ width: '100%' }} src={logoUp} />
                        </Box>
                        <Box>
                            <Box sx={{
                                display: 'grid',
                                justifyItems: 'center',
                                alignItems: 'center',
                                border: '1px solid #E1E3E9',
                                borderRadius: '20px',
                                background: '#FFFFFF',
                                padding: '20px 70px',
                                gridGap: '30px'
                            }}>
                                <img src={correct} />
                                <Box sx={{ display: 'grid', textAlign: 'center' }}>
                                    <Typography sx={{ fontSize: 30, fontWeight: 700 }}>ชำระค่าบริการเสร็จสิ้น</Typography>
                                    <Typography sx={{ fontSize: 16, fontWeight: 700 }}>กรุณาแนบสลิปหลักฐานการชำระเงิน</Typography>
                                </Box>
                                <Box sx={{
                                    height: '200px',
                                    display: 'grid',
                                    alignContent: 'center'
                                }}>
                                    <Box>
                                        <Typography
                                            sx={{ color: '#707070', cursor: 'pointer', textDecoration: 'underline' }}
                                            onClick={() => {
                                                inputFileRef.current.click()
                                            }}
                                        >แนบไฟล์หรือลากไฟล์เพื่อวางที่นี่</Typography>
                                        <Box>
                                            <input
                                                type={'file'}
                                                accept='image/png,image/jpeg,application/pdf'
                                                ref={inputFileRef}
                                                onChange={(e) => {
                                                    inputFileChange(e)
                                                }}
                                                style={{ display: 'none' }}>
                                            </input>
                                            {attachFile ?
                                                <Box sx={{ textAlign: 'center' }}>
                                                    {attachFile.name}
                                                </Box>
                                                : null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Button
                                    variant='contained'
                                    sx={{ width: '120px' }}
                                    onClick={() => setOpenModal(true)}
                                >บันทึก</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
