import React, { useEffect, useState } from 'react'
import { Box, FormControl, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export default function DataUpgradeHistory() {

    const navigate = useNavigate()
    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [dataList, setDataList] = useState([
        {
            date: '19/04/2567',
            time: '11:23:09',
            package: '3 เดือน',
            price: '1400'
        },
        {
            date: '19/04/2567',
            time: '16:23:00',
            package: '3 เดือน',
            price: '1400'
        }
    ])

    useEffect(() => {
        findList()
    }, [numPage])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(dataList.slice(start, start + numPage))
    }, [page, dataList])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const findList = () => {

    }

    return (
        <Box sx={{ padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '2rem 1rem' }, }}>
            <Box
                sx={{
                    display: 'flex',
                    gridGap: 10,
                    cursor: 'pointer',
                    alignItems: 'center'
                }}
                onClick={() => { navigate('/profile') }}
            >
                <ArrowBackIosIcon sx={{ fontSize: 16 }} />
                <div>ย้อนกลับ</div>
            </Box>

            <Box sx={{
                textAlign: 'center',
                color: '#006838',
                fontSize: '22px'
            }}
            >ประวัติการอัปเกรด Health ATM</Box>

            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1
                }
            }}>
                <TableContainer>
                    <Table sx={{ marginTop: 3, minWidth: '1000px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>#</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>วันที่ชำระ</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>เวลา</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>จำนวนแพคเกจ</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ราคา</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ออกใบเสร็จ</TableCell>
                            </TableRow>
                        </TableHead>
                        {showList.length === 0 ?
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        align='center'
                                        sx={{ fontSize: 18 }}
                                    >ไม่พบข้อมูล</TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <>
                                {showList.map((i, idx) => (
                                    <TableBody key={idx}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{i['date']}</TableCell>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{i['time']}</TableCell>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{i['package']}</TableCell>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{i['price']}</TableCell>
                                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                                <FileDownloadOutlinedIcon
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => { navigate('/receiptRequest', { state: { data: i } }) }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </>
                        }
                    </Table>
                </TableContainer>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}
