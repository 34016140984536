import { Box, Container, FormControl, Grid, InputLabel, Select, MenuItem, TextField, Typography, Button, IconButton,Autocomplete } from '@mui/material'
import React, { useEffect } from 'react'
import { InputTextNormal, InputTextSearch } from '../../components/InputText'
import { useState } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { InputAdornment } from '@mui/material';
import ModalCalendar from '../../components/ModalController/ModalCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import * as apiConsult from '../../services/ApiConsultDoctor'
import * as apiOutPatient from '../../services/OutPatient/ApiAddAppointment'
import * as apiPublic from '../../services/public_api'
import LoaderAnimation from '../../components/LoaderAnimation';
import moment from 'moment'
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '../../components/NotifyToast';
import { BsPersonSquare } from "react-icons/bs";


function AddAppointment() {
    const [loading, setLoading] = useState(false)
    const [searchPID, setSearhPID] = useState('')
    const [openCalendar, setOpenCalendar] = useState(false)
    const [SuccessModal, setSucessModal] = useState(false)

    const [department, setDepartment] = useState([])
    const [titleName, setTitleName] = useState([])

    const [search, setSearch] = useState(false)
    const [findData, setFindData] = useState([])

    const [form, setForm] = useState({
        title_name: '',
        fname: '',
        lname: '',
        pid: '',
        name: '',
        department_code: '',
        appoint_date: '',
        appoint_time: ''
    })

    const [isData, setIsData] = useState(false)
    const [dataDay, setDataDay] = useState([
        {
            day: 0,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 1,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 2,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 3,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 4,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 5,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 6,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        }

    ])

    const [disabledDay, setDisabledDay] = useState([0, 1, 2, 3, 4, 5, 6,])
    const [open, setOpen] = useState({
        open_hours: '',
        open_minutes: '',
    })
    const [close, setClose] = useState({
        close_hours: '',
        close_minutes: '',
    })
    const [idHospital,setIdHospital] = useState('')

    useEffect(() => {
        findTitleName()
    }, [])

    useEffect(() => {
        findDepartment()
    }, [idHospital])

    useEffect(() => {
        setTime()
    }, [form])

    const findTitleName = async () => {
        setLoading(true)
        let res = await apiPublic.findTitleName()
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setTitleName(res['data']['data'])
            } else {
                return notifyInfo(res['data']['message'])
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async () => {
        setLoading(true)
        let res = await apiOutPatient.findDepartment(idHospital)
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setDepartment(res['data']['data'])
            } else {
                setDepartment([])
                console.log(res['data']['message'], ' แผนก')
                // return notifyInfo(res['data']['message'])
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    const findOneDepartment = async (value) => {
        // console.log(value)
        setLoading(true)
        let res = await apiOutPatient.findOneDepartment(value,idHospital)
        setLoading(false)
        if (res) {
            // console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                let data = res['data']['data'][0]['DepartmentDetail']
                const updateData = [
                    {
                        day: 0,
                        status_open: data['Sunday']['Open'],
                        time_open: data['Sunday']['TimeOpen'],
                        time_close: data['Sunday']['TimeClose']
                    },
                    {
                        day: 1,
                        status_open: data['Monday']['Open'],
                        time_open: data['Monday']['TimeOpen'],
                        time_close: data['Monday']['TimeClose']
                    },
                    {
                        day: 2,
                        status_open: data['Tuesday']['Open'],
                        time_open: data['Tuesday']['TimeOpen'],
                        time_close: data['Tuesday']['TimeClose']
                    },
                    {
                        day: 3,
                        status_open: data['Wednesday']['Open'],
                        time_open: data['Wednesday']['TimeOpen'],
                        time_close: data['Wednesday']['TimeClose']
                    },
                    {
                        day: 4,
                        status_open: data['Thursday']['Open'],
                        time_open: data['Thursday']['TimeOpen'],
                        time_close: data['Thursday']['TimeClose']
                    },
                    {
                        day: 5,
                        status_open: data['Friday']['Open'],
                        time_open: data['Friday']['TimeOpen'],
                        time_close: data['Friday']['TimeClose']
                    },
                    {
                        day: 6,
                        status_open: data['Saturday']['Open'],
                        time_open: data['Saturday']['TimeOpen'],
                        time_close: data['Saturday']['TimeClose']
                    }
                ]
                setDataDay(updateData)
                const result = updateData.filter((item) => (item['status_open'] !== true)).map(item => item.day)
                setDisabledDay(result)
            } else {
                setDisabledDay([0, 1, 2, 3, 4, 5, 6])
                // console.log(res['data']['message'], " แผนก")
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    const findInformationSearch = async () => {
        setLoading(true)
        let res = await apiConsult.findInformationSearch(searchPID)
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setFindData(res['data']['data'])
                const updateData = {
                    pid: res['data']['data'][0]['pid'],
                    title_name: res['data']['data'][0]['pname'],
                    fname: res['data']['data'][0]['fname'],
                    lname: res['data']['data'][0]['lname'],
                }
                setForm({ ...form, ...updateData })
                return
            } else {
                setFindData([])
                const resetForm = {
                    pid: '',
                    title_name: '',
                    fname: '',
                    lname: '',
                }
                setForm({ ...form, ...resetForm })
                return
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        if (name === 'pid') {
            const numericRegex = /^[0-9]*$/;
            if (numericRegex.test(value)) {
                setForm({ ...form, [name]: value })
            }
            return
        }
        setForm({ ...form, [name]: value })
        // findOneDepartment()
    }

    const handleSearch = (e) => {
        const { value } = e.target
        const numericRegex = /^[0-9]*$/;
        if (numericRegex.test(value)) {
            setSearhPID(value)
        }
        setSearch(false)
        setIsData(false)
    }

    const searchData = () => {
        setSearch(true)
        // console.log("search data pid ====> ",searchPID)
        findInformationSearch()
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const setTime = () => {
        // console.log(form['appoint_date']['$W'])

        const findDay = dataDay.filter((item) => item['day'] === form['appoint_date']['$W'])
        // console.log(findDay)
        if (findDay.length > 0) {
            const [hoursStr_open, minutesStr_open] = findDay[0]['time_open'].split(':')
            const hours_open = parseInt(hoursStr_open, 10)
            const minutes_open = parseInt(minutesStr_open, 10)

            open['open_hours'] = hours_open
            open['open_minutes'] = minutes_open

            // setOpen({ ...open, ['open_hours']: hours_open, ['open_minutes']: minutes_open })

            const [hoursStr_close, minutesStr_close] = findDay[0]['time_close'].split(':')
            const hours_close = parseInt(hoursStr_close, 10)
            const minutes_close = parseInt(minutesStr_close, 10)

            close['close_hours'] = hours_close
            close['close_minutes'] = minutes_close

            setClose({ ...close, ['close_hours']: hours_close, ['close_minutes']: minutes_close })
            // console.log(open , close)
        }
    }

    const resetForm = () => {
        setSearch(false)
        setSearhPID('')
        setForm({
            title_name: '',
            fname: '',
            lname: '',
            pid: '',
            name: '',
            department_code: '',
            appoint_date: '',
            appoint_time: ''
        })
    }

    const submitForm = async () => {
        const full_name = `${form['title_name']} ${form['fname']} ${form['lname']}`
        form['name'] = full_name


        if (!form['pid']) {
            return notifyWarning("กรุณากรอก เลขบัตรประจำตัวประชาชน")
        }
        if (form['pid'].length < 13) {
            return notifyWarning("กรุณากรอก เลขบัตรประจำตัวประชาชน")
        }
        if (!form['title_name']) {
            return notifyWarning("กรุณากรอก คำนำหน้า")
        }
        if (!form['fname']) {
            return notifyWarning("กรุณากรอก ชื่อ")
        }
        if (!form['lname']) {
            return notifyWarning("กรุณากรอก นามสกุล")
        }
        if (!form['department_code']) {
            return notifyWarning("กรุณากรอก แผนก")
        }
        if (!form['appoint_date']) {
            return notifyWarning("กรุณากรอก วันที่ต้องการนัดหมาย")
        }
        if (!form['appoint_time']) {
            return notifyWarning("กรุณากรอก เวลาต้องการนัดหมาย")
        }

        form['appoint_date'] = moment(form['appoint_date']['$d']).format('YYYY-MM-DD')
        form['appoint_time'] = moment(form['appoint_time']['$d']).format('HH:mm')

        setLoading(true)
        let res = await apiOutPatient.addAppointment(form,idHospital)
        setLoading(false)
        if (res) {
            // console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                setSucessModal(true)
                setSearch(false)
                setSearhPID('')
                setForm({
                    title_name: '',
                    fname: '',
                    lname: '',
                    pid: '',
                    name: '',
                    department_code: '',
                    appoint_date: '',
                    appoint_time: ''
                })
            } else {
                return notifyInfo(res['data']['message'])
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {openCalendar && <ModalCalendar
                onClose={() => { setOpenCalendar(!openCalendar) }}
                value={form['appoint_date'] ? dayjs(moment(form['appoint_date']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
                onChange={(e) => {
                    setForm({ ...form, ['appoint_date']: e })
                }}
                disableDay={disabledDay}
                onFinished={(paramsState => {
                    if (paramsState === paramsState) {
                        setTime()
                    }
                })}
            />}
            {SuccessModal && <ModalSuccess
                title='นัดหมายสำเร็จ'
                detail='ท่านได้เพิ่มการนัดหมายเรียบร้อยแล้ว'
                closeModal={() => { setSucessModal(false) }}
                goPage={'closeModal'}
            />}
            <Box sx={{
                border: 1,
                my: '0.3rem',
                borderRadius: 2,
                borderColor: '#E1E3E9',
                padding: '20px',
                mt: '50px',
                bgcolor: '#FFF'
            }}>
                <Typography sx={{
                    fontWeight: 'bold'
                }}>เพิ่มการนัดหมาย</Typography>

                <Box sx={{
                    mx: '25%',
                    '@media (max-width: 950px)': {
                        mx: '10%',
                    },
                }}>
                    <InputTextSearch
                        maxLength={13}
                        placeholder={"เลขบัตรประจำตัวประชาชน"}
                        // validate={searchPID ? false : true}
                        value={searchPID}
                        onChange={handleSearch}
                        submitForm={() => searchData()}
                    />

                    {search ?
                        <Box sx={{
                            border: '1px solid #E1E3E9',
                            borderRadius: 2,
                            padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '1rem', xs: '1rem' },
                        }}>
                            {findData.length === 0 ?
                                <Grid sx={{ textAlign: 'center' }}> ไม่พบข้อมูล</Grid>
                                :
                                <Grid container sx={{ cursor: 'pointer', alignItems: 'center' }} spacing={2} onClick={() => setIsData(true)}>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Box textAlign="center">
                                            <BsPersonSquare size={60} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <div className='label__sub__text'>
                                            ชื่อ-นามสกุล: {findData[0]['pname']} {findData[0]['fname']} {findData[0]['lname']}
                                        </div>
                                        <div className='label__sub__text'>
                                            เลขประจำตัวประชาชน: {findData[0]['pid']}
                                        </div>
                                    </Grid>
                                </Grid>
                            }

                        </Box>

                        : ""
                    }
                </Box>
            </ Box>

            {
                search && isData === true ?
                    <Box
                        sx={{
                            border: 1,
                            my: '0.3rem',
                            borderRadius: 2,
                            borderColor: '#E1E3E9',
                            padding: '20px',
                            display: 'grid',
                            gap: '20px',
                            mt: '50px',
                            bgcolor: '#FFF',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{
                                width: '100%',
                            }}>
                                <Typography sx={{ fontSize: '18px', mb: '0.5rem', fontWeight: 'bold' }}>
                                    โรงพยาบาลที่เข้ารับบริการ
                                </Typography>
                                <InputTextNormal
                                        disabled={false}
                                        placeholder={'โรงพยาบาล'}
                                        value={localStorage.getItem('hospital_name')}
                                        onChange={() => { }}
                                        readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: '18px', mb: '0.5rem', fontWeight: 'bold' }}>
                                    แผนกที่เข้ารับบริการ
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        name='department_code'
                                        value={form['department_code'] || ' '}
                                        onChange={(e) => {
                                            const {
                                                target: { value }
                                            } = e
                                            // console.log(value)
                                            setForm({ ...form, ['department_code']: value })
                                            findOneDepartment(value)
                                        }}
                                    >
                                        <MenuItem value=" ">เลือกแผนก</MenuItem>
                                        {
                                            department.map((item, idex) => (
                                                <MenuItem key={`d_${idex}`} value={item['DepartmentCode']}>{item['DepartmentName']}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: '18px', mb: '0.5rem', fontWeight: 'bold' }}>
                                    เลือกวันที่ต้องการนัดหมาย
                                </Typography>

                                <TextField
                                    value={form['appoint_date'] ? convertDate(form['appoint_date']) : 'เลือกวันที่ต้องการ'}
                                    fullWidth
                                    // disabled={false}
                                    // error={form['appoint_date'] ? false : true }

                                    placeholder={'เลือกวันที่ต้องการนัดหมาย'}
                                    InputProps={{
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => {
                                                    setOpenCalendar(true)
                                                }}>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    เลือกเวลาต้องการนัดหมาย
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer
                                        components={[
                                            'MobileTimePicker',
                                        ]}
                                    >
                                        <MobileTimePicker
                                            // locale={thLocale}
                                            ampm={false}
                                            sx={{ width: '100%' }}
                                            value={
                                                form['appoint_time'] ?
                                                    dayjs(moment(form['appoint_time']['$d']).format('YYYY-MM-DDTHH:mm'))
                                                    :
                                                    dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm')).set('hour', 0).set('minute', 0)
                                            }
                                            onChange={
                                                (e) => setForm({ ...form, ['appoint_time']: e })
                                            }

                                            minTime={
                                                open ?
                                                    dayjs().set('hour', open['open_hours']).set('minute', open['open_minutes']) : null
                                            }
                                            maxTime={
                                                close ?
                                                    dayjs().set('hour', close['close_hours']).set('minute', close['close_minutes']) : null
                                            }



                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid justifyContent={'center'} container gap={'20px'}>
                            <Button variant='outlined'
                                onClick={() => resetForm()}
                            >
                                ยกเลิก
                            </Button>
                            <Button variant='contained'
                                // onClick={() => { setSucessModal(true) }}
                                onClick={() => submitForm()}
                            >
                                บันทึก
                            </Button>
                        </Grid>
                    </Box > : ''
            }
        </>
    )
}

export default AddAppointment