import React from 'react'
import { backgroundModal, boxModal, buttomPrimary, buttomSecondary } from './ModalAccessCss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Button, IconButton, Typography } from '@mui/material';
import thLocale from 'dayjs/locale/th';
import { InputTextNormal } from '../InputText';
import { notifyInfo } from '../NotifyToast';

function ModalCalendar({
    onFinished,
    onClose,
    value,
    onChange,
    disableDay
}) {

    let data_day = disableDay

    const shouldDisableDate = (date) => {
        let disableDay = data_day
        return disableDay.includes(date['$d'].getDay());
    };

    // console.log(onChange)

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <IconButton onClick={() => { onClose() }}>
                        <ClearIcon />
                    </IconButton>
                </Box>

                {/* <Typography sx={{ fontSize: '18px', my: '0.5rem', fontWeight: 'bold' }}>
                    เลือกวันที่
                </Typography>
                <InputTextNormal disabled={true} placeholder={'ระบุ วัน/เดือน/ปี'} value={'ทดสอบ'} onChange={() => { }} /> */}

                <LocalizationProvider dateAdapter={AdapterDayjs} locale={thLocale}>
                    <DateCalendar
                        value={value}
                        onChange={onChange}
                        shouldDisableDate={shouldDisableDate}
                    />
                </LocalizationProvider>

                <Grid container justifyContent="center" gap={2}>
                    <Grid item>
                        <Button variant="outlined" onClick={() => { onClose() }}>
                            ยกเลิก
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => {
                            onClose()
                            if(!value) {
                                notifyInfo('กรุณาเลือกวันที่ก่อนยืนยัน')
                            } else {
                                onFinished(value)
                            }
                        }}>
                            ยืนยัน
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}

export default ModalCalendar