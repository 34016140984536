import React, { useEffect, useState } from 'react'
import styles from './ModalPayment.module.css'
import CloseIcon from '@mui/icons-material/Close'
import { Box, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { notifyError, notifyWarning } from '../NotifyToast';
import * as api from '../../services/payment/payment_api'
import { useNavigate } from 'react-router-dom';
import LoaderAnimation from '../LoaderAnimation';

export default function ModalUpgradeHealthATM({ onClose, detail }) {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [dataCheck, setDataCheck] = useState(null)
    const [dataCheckPackage, setDataCheckPackage] = useState([])
    const [dataPackage, setDataPackage] = useState([])


    useEffect(() => {
        findPackage()
    }, [])

    const findPackage = async () => {
        setLoading(true)
        let res = await api.findPackage()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res['data']['data'])
                setDataPackage(res['data']['data'])
            } else {
                notifyWarning(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const handlerClickCheckbox = (event, item) => {
        setDataCheck(event.target.value)
        setDataCheckPackage(item)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <div className={styles.overlay}>
                <div className={styles.modalController}>
                    <div className={styles.closeButton}>
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => { onClose() }} />
                    </div>
                    <Box sx={{
                        marginTop: '-10px',
                        padding: '1rem',
                        display: 'grid',
                        gridGap: '15px'
                    }}>
                        <Typography sx={{ fontSize: 24, fontWeight: 700, textAlign: 'center' }}>อัปเกรด Health ATM</Typography>

                        {detail ?
                            <Box>
                                <Box sx={{
                                    display: 'grid',
                                    gridGap: '20px',
                                    gridTemplateColumns: '1fr 1fr'
                                }}>
                                    <Typography sx={{ color: '#747676', textAlign: 'right' }}>สถานะปัจจุบัน</Typography>
                                    <Typography sx={{ fontWeight: 500, color: '#8F7000' }}>{detail.day_remain}</Typography>
                                </Box>
                                <Box sx={{
                                    display: 'grid',
                                    gridGap: '20px',
                                    gridTemplateColumns: '1fr 1fr'
                                }}>
                                    <Typography sx={{ color: '#747676', textAlign: 'right' }}>ใช้งานได้ถึงวันที่</Typography>
                                    <Typography sx={{ fontWeight: 500, color: '#8F7000' }}>{convertDate(detail.expire_day)}</Typography>
                                </Box>
                            </Box>
                            :
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gridGap: '20px'
                            }}>
                                <Typography sx={{ color: '#747676', textAlign: 'right' }}>สถานะปัจจุบัน</Typography>
                                <Typography sx={{ fontWeight: 500 }}>Free</Typography>
                            </Box>
                        }

                        <Typography sx={{ fontSize: 18, fontWeight: 600 }}>เลือกจำนวนต้องการอัปเกรด</Typography>

                        <Box sx={{
                            display: 'grid',
                            gridGap: '5px'
                        }}>
                            {dataPackage.map((i, idx) => (
                                <React.Fragment key={idx}>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', alignItems: 'center', marginLeft: '10%' }}>
                                        <RadioGroup
                                            value={dataCheck}
                                            onChange={(e) => {
                                                handlerClickCheckbox(e, i)
                                            }}
                                        >
                                            <FormControlLabel value={i['package_name']} control={<Radio />} label={i['package_name']} />
                                        </RadioGroup>
                                        <Typography>{i['amount_total']} บาท</Typography>
                                    </Box>
                                    <hr style={{ borderTop: '1rem' }} />
                                </React.Fragment>
                            ))}
                        </Box>

                        <Typography sx={{ fontWeight: 500 }}>เงื่อนไขการชำระค่าบริการ</Typography>

                        <Box sx={{ marginLeft: '20px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 10 }}>
                                <FiberManualRecordIcon sx={{ fontSize: 7, color: '#747676' }} />
                                <Typography sx={{ color: '#747676' }}>สามารถสะสมจำนวนวันเพื่อใช้งานได้อย่างต่อเนื่อง</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 10 }}>
                                <FiberManualRecordIcon sx={{ fontSize: 7, color: '#747676' }} />
                                <Typography sx={{ color: '#747676' }}>ตรวจสอบจำนวนวันคงเหลือได้ใน บัญชีผู้ใช้งาน ของคุณ</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 10 }}>
                                <FiberManualRecordIcon sx={{ fontSize: 7, color: '#747676' }} />
                                <Typography sx={{ color: '#747676' }}>หากชำระค่าบริการแล้วไม่สามารถเปลี่ยนวันที่เหลือเป็นยอดเงินได้</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'grid', justifyContent: 'center' }}>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    if (!dataCheck) {
                                        notifyWarning('กรุณาเลือกจำนวนต้องการอัปเกรด')
                                        return
                                    }
                                    navigate('/upgradeEquipment', { state: { data: dataCheckPackage, detail: detail } })
                                    onClose()
                                }}
                            >ชำระค่าบริการ</Button>
                        </Box>

                    </Box>
                </div>
            </div>
        </>
    )
}
