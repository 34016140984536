import { InputAdornment, Box, FormControl, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Stack, Pagination, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from '../../services/ApiDrugDelivery'

import ModalCheckAddress from "../../components/ModalController/ModalCheckAddress";
import LoaderAnimation from '../../components/LoaderAnimation'
import ModalContactConference from "../../components/ModalController/ModalContactConference";

import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import 'dayjs/locale/th';

import { notifyError, notifyInfo } from "../../components/NotifyToast";

export default function SentDrugDone({ dataSystemDone, numPage1, setNumPage1, maxPage1, page1, setPage1, convertDate, searchData, namePatient, pid }) {

    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const [openModalShare, setOpenModalShare] = useState(false)

    const [dataPid, setDataPid] = useState('')
    const [dataDocNumber, setDataDocNumber] = useState('')
    const [name, setName] = useState('')

    const [showList, setShowList] = useState([])

    const [PID, setPID] = useState('')

    dayjs.extend(buddhistEra);
    dayjs.locale('th');

    useEffect(() => {
        setPage1(1)
    }, [numPage1])

    useEffect(() => {
        let start = page1 * numPage1 - numPage1
        setShowList(searchData.slice(start, start + numPage1))
    }, [page1, dataSystemDone, namePatient, pid])

    const handlePageChange = (event, page1) => {
        setPage1(page1)
    }

    const documentViewer = async (Doc) => {
        setLoading(true)
        let res = await api.documentViewer(Doc)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let url = res['data']['data']['url']
                window.open(url, '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleCheckAddress = (DocNumber, pid, name) => {
        setDataDocNumber(DocNumber)
        setDataPid(pid)
        setName(name)
        setOpenModal(true)
    }

    const documents = (Doc) => {
        documentViewer(Doc)
    }

    const ShareTelepharmacy = (pid) => {
        setPID(pid)
        setOpenModalShare(true)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                fontSize: 18,
                '@media (max-width: 1023px)': {

                },
                '@media (max-width: 800px)': {
                    fontSize: 14
                }
            }}>
                <TableContainer>
                    <Table sx={{ marginTop: 3, minWidth: '1200px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>#</TableCell>
                                <TableCell sx={{ color: '#006838' }}>เลขบัตรประจำตัวประชาชน</TableCell>
                                <TableCell sx={{ color: '#006838' }}>รายชื่อ</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>วันที่สั่งยา</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>Telepharmacy</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>รายการสั่งยา</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ที่อยู่</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>สถานะ</TableCell>
                            </TableRow>
                        </TableHead>
                        {showList.length === 0 ?
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        align='center'
                                        sx={{ fontSize: 18 }}
                                    >ไม่พบข้อมูล</TableCell>
                                </TableRow>
                            </TableBody>
                            : null
                        }

                        {showList.map((i, idx) => (
                            <TableBody key={idx}>
                                <TableRow>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                    <TableCell sx={{ color: '#747676' }}>{i['PID']}</TableCell>
                                    <TableCell sx={{ color: '#747676' }}>{i['Name']}</TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{dayjs(i['DrugDeliveryDate']).format("D MMMM BBBB")}</TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 3, width: '100%' }}
                                            onClick={() => { ShareTelepharmacy(i['PID']) }}
                                        >แชร์ห้อง Telepharmacy</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 3, width: '100%' }}
                                            onClick={() => { documents(i['DocNumber']) }}
                                        >เอกสารใบสั่งยา</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            sx={{ borderRadius: 3, width: '100%' }}
                                            onClick={() => { handleCheckAddress(i['DocNumber'], i['PID'], i['name']) }}
                                        >ตรวจสอบที่อยู่</Button>
                                    </TableCell>
                                    <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                                        {i['TeleState'] === 'CONFIRM_DRUG' ?
                                            <Button
                                                style={{
                                                    background: '#FCF5BC',
                                                    width: '100%',
                                                    color: '#866A09',
                                                    cursor: 'text',
                                                    '&:hover': {
                                                        backgroundColor: '#FCF5BC',
                                                    },
                                                }}
                                            >รอการยืนยัน</Button>
                                            :
                                            <Button
                                                style={{
                                                    background: '#E1F4EB',
                                                    width: '100%',
                                                    color: '#006838',
                                                    cursor: 'text',
                                                    '&:hover': {
                                                        backgroundColor: '#E1F4EB',
                                                    },
                                                }}
                                            >ยืนยันรับยาแล้ว</Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                    </Table>
                </TableContainer>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage1}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage1(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage1}
                            page={page1}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>

            </Box>

            {openModal ?
                <ModalCheckAddress
                    onClose={() => { setOpenModal(false) }}
                    pid={dataPid}
                    DocNumber={dataDocNumber}
                    name={name}
                />
                : null
            }
            {openModalShare ?
                <ModalContactConference
                    onClose={() => { setOpenModalShare(false) }}
                    pid={PID}
                />
                : null
            }
        </>
    )
}