import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    audio: null //ระบบคิว เปิดจอแสดงผล
};

const audioSlice = createSlice({
    name: 'sound_audio',
    initialState,
    reducers: {
        client_audio(state, action) {
            state.audio = action.payload;
        },
    },
});

export const { client_audio } = audioSlice.actions;
export default audioSlice.reducer;