import React, { useEffect, useState } from 'react'
import { InputLabel, Typography, Box, Button, Select, MenuItem, FormControl, Autocomplete } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import { backgroundModal, boxModal, buttomPrimary, buttomSecondary } from './ModalAccessCss'
import ModalSuccess from './ModalSuccess';
import * as api from '../../services/public_api'
import { notifyError, notifyInfo, notifyWarning } from '../../components/NotifyToast';

function ModalAccessUser(props) {
    const { closeModal, titleModal, id_user, data, onSubmit, hospitalList } = props

    const [hospital, setHospital] = useState([])
    const [roleGroup, setRoleGroup] = useState([])
    const [editUser, setEditUser] = useState({
        group_role: '',
        hospital_code: ''
    })

    const [SelectedTypeUser, setSelectedTypeUser] = useState(null)
    const [SelectedHospital, setSelectedHospital] = useState(null)

    useEffect(() => {
        findHospital()
        findRoleGroup()
    }, [])

    useEffect(() => {
        // console.log("===> ",data)
        setEditUser({
            group_role:data['GroupRole'],
            hospital_code: data['HospitalCode']
        })
    }, [data])
    
    useEffect(() => {
        // console.log(editUser)
    }, [editUser])


    const findHospital = async () => {
        let res = await api.findHospital()
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setHospital(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findRoleGroup = async () => {
        let res = await api.findRoleGroup()
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setRoleGroup(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setEditUser({ ...editUser, [name]: value })
    }

    const submitForm = async () => {
        if (!editUser['group_role'] || editUser['group_role'] === ' ') {
            return notifyWarning('กรุณากรอก ประเภทผู้ใช้งาน')
        }
        if (!editUser['hospital_code'] || editUser['hospital_code'] === ' ') {
            return notifyWarning('กรุณากรอก โรงพยาบาล')
        }

        await onSubmit({
            "UID": data['UID'],
            "Permission": {
                "GroupRole": editUser['group_role'],
                "HospitalCode": editUser['hospital_code']
            }
        })

        closeModal()
    }

    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', '&:hover': { cursor: 'pointer' } }} onClick={closeModal}>
                    <Typography component="p" variant="p" color={'#006838'} sx={{ fontSize: '18px', marginBottom: '0.5rem', fontWeight: 'bold' }}>แก้ไขสิทธิ์ {data?.['Name']}</Typography>
                    <ClearIcon />
                </Box>
                <Typography component="p" variant="p" color={'#006838'} sx={{ fontSize: '18px', fontWeight: 'bold' }}>{titleModal}</Typography>
                <hr style={{ margin: '1.5rem 0', width: '100%' }} />
                <Box sx={{ marginBottom: '1rem' }}>
                    <Typography component="p" variant="p" color={'#006838'} sx={{ fontSize: '18px', marginBottom: '0.5rem', fontWeight: 'bold' }}>ประเภทผู้ใช้งาน</Typography>
                    <FormControl fullWidth>
                        <Select
                            name='group_role'
                            value={editUser['group_role'] || ' '}
                            onChange={handleOnChange}
                            fullWidth
                        >
                            <MenuItem value=" ">เลือกประเภทผู้ใช้งาน</MenuItem>
                            {
                                roleGroup.map((item) => (
                                    <MenuItem key={item['GroupCode']} value={item['GroupCode']}>{item['GroupName']}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ marginBottom: '1rem' }}>
                    <Typography component="p" variant="p" color={'#006838'} sx={{ fontSize: '18px', marginBottom: '0.5rem', fontWeight: 'bold' }}>โรงพยาบาล</Typography>
                    <FormControl fullWidth>
                        <Select
                            name='hospital_code'
                            value={editUser['hospital_code'] || ' '}
                            onChange={handleOnChange}
                            fullWidth
                        >
                            <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                            {
                                hospital.map((item) => (
                                    <MenuItem key={item['HospitalCode']} value={item['HospitalCode']}>{item['HospitalName']}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {/* <Autocomplete
                        size='small'
                        disablePortal
                        options={hospital}
                        value={editUser['hospital_code'] || ' '}
                        onChange={(e, value) => {
                            // console.log(value)
                            setIdHospital(value?.['key'])
                            setNameHospital(value?.['label'])
                        }}
                        sx={{width:'250px'}}
                        renderInput={(params) => <TextField {...params} placeholder="เลือกโรงพยาบาล" />}
                    /> */}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '2rem' }}>
                    <Button onClick={closeModal}
                        fullWidth
                        variant="contained"
                        sx={buttomSecondary}
                    >
                        {'ยกเลิก'}
                    </Button>
                    <Button onClick={() => submitForm()}
                        fullWidth
                        variant="contained"
                        sx={buttomPrimary}
                    >
                        {'บันทึก'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalAccessUser