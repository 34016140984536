// System Constants
export const SYSTEM_NAME = 'RAMA UBS'

export const RECORD_PATIENT_TITLE = 'ประวัติผู้ป่วย'
export const OUT_PITENT_TITLE = 'ผู้ป่วยนอก (ทางไกล)'
export const CONSULT_DOCTOR_TITLE = 'ปรึกษาแพทย์/พยาบาล'
export const DRUG_DELIVERY_TITLE = 'การจัดส่งยา'
export const MANAGE_USER_TITLE = 'การจัดการผู้ใช้งาน'
export const SOS_TITLE = 'รับแจ้งเหตุฉุกเฉิน'
export const CONSULT_HOSPITAL_TITLE = 'ปรึกษา'


const BASE_PATH = '/auth/'
export const RECORD_PATIENT_PATH = BASE_PATH + 'record_patient'
export const OUT_PITENT_PATH = BASE_PATH + 'out_patient'
export const CONSULT_PATH = BASE_PATH + 'consult_doctor'
export const DRUG_DELIVERY_PATH = BASE_PATH + 'drug_delivery'
export const MANAGE_USER_PATH = BASE_PATH + 'manage_user'
export const SOS_PATH = BASE_PATH + 'helperInformation'
export const CONSULT_HOSPITAL_PATH = BASE_PATH + 'consultHospital'


