import { Box, Button, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import LoaderAnimation from '../../components/LoaderAnimation'
import * as api from '../../services/payment/payment_api'
import { notifyError, notifyWarning } from '../../components/NotifyToast'

import ReplayIcon from '@mui/icons-material/Replay';
import logo from '../../assets/img/logo.svg'
import logoUp from '../../assets/img/upgradeVersion.svg'
import ModalPaymentFailed from '../../components/ModalController/ModalPaymentFailed'
import ModalPaymentSuccess from '../../components/ModalController/ModalPaymentSuccess'
import warn from '../../assets/icons/warn.svg'

import { io } from 'socket.io-client'
import { environment } from '../../config.env'
const ENDPOINT_SOCKET_PAYMENT = environment.development_socket_payment
const domain_socket = ENDPOINT_SOCKET_PAYMENT


export default function UpgradeEquipment() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [openModalFail, setOpenModalFail] = useState(false)
    const [openModalSuccess, setOpenModalSuccess] = useState(false)

    const [counter, setCounter] = useState(3600)

    const [dataPackage, setDataPackage] = useState({})
    const [docNumber, setDocNumber] = useState(null)
    const [qrCode, setQrCode] = useState(null)

    const _socket = useRef(null);

    useEffect(() => {
        console.log(location.state)
        setDataPackage(location.state.data)
        init();

        return () => {
            if (_socket.current != null) {
                _socket.current.disconnect();
            }
        }
    }, [])

    useEffect(() => {
        CountNumber()
    }, [counter])

    async function init() {
        let doc = await requestPackage(location.state.data);
        console.log(doc)

        if (doc) {
            const socket = io.connect(domain_socket, {
                path: '/realtime',
                // reconnect: true
            });

            socket.on("connect", () => {
                console.log("Connected to the server");

                let uid = localStorage.getItem('cid');
                let platform = "r12network-dashboard";
                let doc_number = doc

                console.log(doc_number, uid)

                socket.emit("join-listen-payment", { payload: { uid: uid, platform: platform, doc_number: doc_number } });
                socket.on("response-listen-payment", ({ payload }) => {
                    console.log("response-listen-payment : ", payload);

                    let { status, code } = payload
                    if (status && code === 200) {
                        //รอการชำระเงิน
                        socket.emit("listen-payment", { payload: { uid: uid, platform: platform, doc_number: doc_number } });
                        console.log('รอการชำระเงิน')
                    } else if (status && code === 201) {
                        //ชำระเงินสำเร็จ
                        console.log('ชำระเงินสำเร็จ')
                        socket.disconnect()
                        setOpenModalSuccess(true)
                    } else {
                        // อื่น ๆ
                        console.log('อื่นๆ')
                        // notifyError('เกิดข้อผิดพลาด โปรดติดต่อแอดมิน')
                    }
                })
            })

            _socket.current = socket;
        }
    }

    const CountNumber = () => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
        if (counter === 0) {
            setOpenModalFail(true)
        }
    }

    const generateQrCode = () => {
        CountNumber()
        requestPackage(location.state.data)
    }

    const requestPackage = async (data) => {
        setLoading(true)
        let res = await api.requestPackage(data['package_code'])
        setLoading(false)
        let _docNumber = null;
        if (res) {
            if (res['data']['code'] === 200) {
                let doc = res['data']['data']['doc_number'];
                setDocNumber(doc)
                setQrCode(res['data']['data']['qrcode'])
                _docNumber = doc;
            } else if (res['data']['code'] === 999) {
                let doc = res['data']['data']['doc_number'];
                setDocNumber(doc)
                setQrCode(res['data']['data']['qrcode'])
                _docNumber = doc;
            } else {
                notifyWarning(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
        return _docNumber;
    }

    const clickCancelPackage = async () => {
        setLoading(true)
        let res = await api.cancelRequestPackage(docNumber)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                navigate('/profile')
            } else {
                notifyWarning(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const addDays = (days) => {
        if (location.state.detail) {
            const date = new Date(location.state.detail.expire_day)
            date.setDate(date.getDate() + days)
            return convertDate(date.toDateString())
        } else {
            const date = new Date()
            date.setDate(date.getDate() + days)
            return convertDate(date.toDateString())
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <>
            <LoaderAnimation loading={loading} />

            {openModalFail ?
                <ModalPaymentFailed
                    onClose={() => setOpenModalFail(false)}
                    onSubmit={() => {
                        setCounter(3600)
                        generateQrCode()
                    }}
                />
                : null
            }
            {openModalSuccess ?
                <ModalPaymentSuccess
                    onClose={() => setOpenModalSuccess(false)}
                />
                : null
            }

            <Box sx={{
                height: '100vh',
                display: 'grid',
                alignContent: 'center'
            }}>
                <Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        justifyItems: 'center',
                        padding: '30px 70px',
                        '@media (max-width: 1024px)': {
                            padding: '20px'
                        }
                    }}>
                        <Box sx={{
                            display: 'grid',
                            gridGap: '15px'
                        }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 25, fontWeight: 700 }}>ชำระค่าบริการ</Typography>
                            {dataPackage ?
                                <>
                                    <Box sx={{ display: 'grid', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '250px' }}>
                                            <Typography sx={{ color: '#747676' }}>{dataPackage['package_name']}</Typography>
                                            <Typography sx={{ textAlign: 'end' }}>{dataPackage['amount_total']} บาท</Typography>
                                        </Box>
                                    </Box>
                                    <hr style={{ borderTop: '1rem', borderLeft: 'none', borderRight: 'none' }} />
                                    <Box sx={{ display: 'grid', justifyContent: 'center' }}>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '250px' }}>
                                            <Typography sx={{ color: '#747676' }}>ใช้งานได้ถึง</Typography>
                                            <Typography sx={{ textAlign: 'end' }}>{addDays(dataPackage['period_day'])}</Typography>
                                        </Box>
                                    </Box>
                                </>
                                : null
                            }


                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ width: '80%' }} src={logoUp} />
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'grid',
                            justifyItems: 'center',
                            alignItems: 'center',
                            gridGap: '10px',
                            border: '1px solid #E1E3E9',
                            borderRadius: '20px',
                            background: '#FFFFFF',
                            padding: '20px 70px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            '@media (max-width: 1024px)': {
                                width: '400px'
                            }
                        }}>
                            <img style={{ width: '150px' }} src={logo} />
                            {qrCode ?
                                <>
                                    <QRCode
                                        value={qrCode}
                                        size={200}
                                    />
                                    <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}>
                                        <Typography sx={{ fontSize: '18px' }}>ยอดรวมราคา</Typography>
                                        <Typography sx={{ fontSize: 25 }}>{dataPackage['amount_total']} บาท</Typography>
                                    </Box>
                                    <Typography>กรุณาสแกน QR Code เพื่อชำระเงิน</Typography>
                                    <Typography sx={{ display: 'flex', alignContent: 'center', gridGap: '10px' }}> <ReplayIcon /> เหลือเวลาอีก {counter} วินาที</Typography>
                                </>
                                :
                                <Box sx={{
                                    width: '280px',
                                    display: 'grid',
                                    justifyItems: 'center',
                                    gridGap: 20
                                }}>
                                    <img src={warn} />
                                    <Box>
                                        <Typography sx={{ color: '#747676' }}>ไม่สามารถสร้าง QR Code ได้</Typography>
                                        <Typography sx={{ color: '#747676' }}>กรุณาเลือกแพคเกจใหม่อีกครั้ง</Typography>
                                    </Box>
                                </Box>
                            }

                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'grid',
                    justifyContent: 'center',
                    gridGap: '10px',
                    marginTop: '30px'
                }}>
                    {qrCode ?
                        <>
                            <Typography>ต้องการเปลี่ยนแพคเกจ ?</Typography>
                            <Button
                                variant='contained'
                                style={{
                                    background: '#B11629',
                                    border: 'none'
                                }}
                                onClick={() => { clickCancelPackage() }}
                            >ยกเลิกแพคเกจนี้</Button>
                        </>
                        :
                        <>
                            <Button
                                style={{
                                    width: 250
                                }}
                                variant='contained'
                                onClick={() => { navigate('/profile') }}
                            >ย้อนกลับ</Button>
                        </>
                    }
                </Box>
            </Box>

        </>
    )
}
