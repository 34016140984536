import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from '../../components/PageHeader'
import styles from './ConsultDoctor.module.css'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import * as api from '../../services/ApiConsultDoctor'
import * as apiDocument from '../../services/ApiDrugDelivery'
import { useLocation } from "react-router-dom";
import LoaderAnimation from "../../components/LoaderAnimation";
import { notifyError, notifyInfo } from "../../components/NotifyToast";
import print from '../../assets/icons/print.svg'

export default function TreatmentHistory() {

    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [dataTreatment, setDataTreatment] = useState([])
    const [menuSelected, setMenuSelected] = useState();

    useEffect(() => {
        // console.log(location.state.PID, location.state.DocNumber)
        findReport()
    }, [])

    const findReport = async () => {
        setLoading(true)
        let res = await api.findReportAll(location.state.PID)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataTreatment(res['data']['data'])
                setMenuSelected(Array(res['data']['data'].length).fill(0))
                console.log(res['data']['data'])
            } else {
                setDataTreatment([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const documentViewer = async (DocNumber) => {
        setLoading(true)
        let res = await apiDocument.documentViewer(DocNumber)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let url = res['data']['data']['url']
                window.open(url, '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const printTreatmentRecord = (DocNumber) => {
        documentViewer(DocNumber)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const dataSetMenu = (index, value) => {
        let tmp = menuSelected;
        if (menuSelected.length != dataTreatment.length) {
            tmp = Array(dataTreatment.length).fill(0);
        }

        tmp[index] = value;
        setMenuSelected([...tmp]);
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <PageHeader title_name={'กลับไปที่ผู้ป่วยนัด'} back_path={'/auth/consult_doctor'} hide_date />

            {dataTreatment.length > 0 ?
                <Box sx={{
                    display: 'grid',
                    gridGap: '15px',
                    marginTop: '20px'
                }}>
                    {dataTreatment.map((item, index) => (
                        <div key={index}>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 3fr',
                                border: 1,
                                borderColor: '#E1E3E9',
                                borderRadius: '10px',
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr 2fr',
                                }
                            }} key={index}>
                                <Box sx={{
                                    backgroundColor: '#E1F4EB',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    display: 'grid',
                                    gridGap: '20px'
                                }}>
                                    <Box sx={{
                                        color: '#006838',
                                        fontWeight: 700,
                                        fontSize: 20,
                                        textAlign: 'center',
                                        '@media (max-width: 1024px)': {
                                            fontSize: 16,
                                        },
                                        '@media (max-width: 800px)': {
                                            fontSize: 12,
                                        }
                                    }}>{convertDate(item['AppointDate'])}</Box>
                                    <Button onClick={() => { dataSetMenu(index, 0) }} variant={menuSelected[index] === 0 ? 'contained' : 'outlined'}>ผลการวินิจฉัย</Button>
                                    <Button onClick={() => { dataSetMenu(index, 1) }} variant={menuSelected[index] === 1 ? 'contained' : 'outlined'}>รายการยา</Button>
                                    <Button sx={{ background: '#FCF5BC', display: 'flex', gridGap: 10 }} onClick={() => { printTreatmentRecord(item['DocNumber']) }}><img src={print} /><div>พิมพ์บันทึกการรักษา</div></Button>
                                </Box>

                                {menuSelected[index] === 0 ?
                                    <Box sx={{ padding: '20px', height: '260px' }}>
                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ผลการวินิจฉัย</Box>
                                        <Box sx={{ color: '#006838', fontWeight: 500, fontSize: 16 }}>{item['HospitalNameFrom']}</Box>
                                        {item['DiagList'].length > 0 ?
                                            <Box sx={{ maxHeight: '190px', overflowY: "auto" }}>
                                                {item['DiagList'].map((i, idx) => (
                                                    <div style={{ margin: '10px 0' }} key={idx}>
                                                        <div className={styles.value} style={{ color: '#747676' }}> <span style={{ fontWeight: 600 }}>ผลการวินิจฉัย : </span> {i['Detail']}</div>
                                                        <div className={styles.value} style={{ color: '#747676' }}> <span style={{ fontWeight: 600 }}>เพิ่มเติม : </span> {i['Remark']}</div>
                                                    </div>
                                                ))}
                                            </Box>
                                            :
                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                        }
                                    </Box>
                                    : null
                                }

                                {menuSelected[index] === 1 ?
                                    <Box sx={{ padding: '20px', height: '260px' }}>
                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>รายการยา</Box>
                                        <Box sx={{ color: '#006838', fontWeight: 500, fontSize: 16 }}>{item['HospitalNameFrom']}</Box>
                                        {item['DiagList'].length > 0 ?
                                            <Box sx={{ maxHeight: '190px', overflowY: "auto" }}>
                                                {item['OrderList'].map((i, idx) => (
                                                    <div style={{ margin: '10px 0' }} key={idx}>
                                                        <div className={styles.value}> <span style={{ fontWeight: 600, color: '#747676' }}>รายการยา : </span> {i['Detail']}</div>
                                                        <div className={styles.value} style={{ color: '#747676' }}><span style={{ fontWeight: 600 }}>เพิ่มเติม : </span> {i['Remark']}</div>
                                                    </div>
                                                ))}
                                            </Box>
                                            :
                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                        }
                                    </Box>
                                    : null
                                }

                            </Box>
                        </div>
                    ))}
                </Box>
                :
                <div style={{ fontSize: 24, textAlign: 'center', margin: '20%' }}>ไม่พบข้อมูล</div>
            }

        </>
    )
}