import React, { useEffect, useState } from "react"
import { Box, Button } from "@mui/material";
import { backgroundModal, boxModal } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";


export default function ModalCalendarAddAppoinment({ onClose, onSubmit, value, setValue, disableDay }) {

    const [date, setDate] = useState('')

    const dateMonth = (date) => {
        var monthNameList = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        return monthNameList[date]
    }

    let data_day = disableDay

    const shouldDisableDate = (date) => {
        let disableDay = data_day
        return disableDay.includes(date['$d'].getDay());
    }

    return (
        <>
            <Box sx={backgroundModal}>
                <Box sx={boxModal}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={() => { onClose() }}><ClearIcon /></Box>
                    <Box sx={{
                        width: '70%',
                        display: 'grid',
                        margin: 'auto',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            fontSize: 18
                        }}>เลือกวันที่</Box>
                        <Box sx={{
                            border: '1px solid #E1E3E9',
                            borderRadius: 3,
                            padding: 2
                        }}>
                            {date ?
                                <div>{date.$D} {dateMonth(date.$M)} {date.$y + 543} </div>
                                :
                                <div>ระบุ วัน/เดือน/ปี</div>
                            }
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                value={value}
                                onChange={(e) => {
                                    setValue(e)
                                    setDate(e)
                                }}
                                shouldDisableDate={shouldDisableDate}
                            />
                        </LocalizationProvider>

                        <Box sx={{
                            display: 'flex',
                            gridGap: 30,
                            justifyContent: 'center',
                        }}>
                            <Button variant="outlined" onClick={() => { onClose() }}>ยกเลิก</Button>
                            <Button variant="contained" onClick={onSubmit}>ยืนยัน</Button>
                        </Box>
                    </Box>


                </Box>
            </Box>
        </>
    )
}