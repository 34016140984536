import axios from "axios";
import { environment } from '../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain_telequeue = environment.development_telequeue
const domain = ENDPOINT_DOMAIN
const domain_microservice = environment.development_domain_microservice

export function getCurrentDateAppointment() {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/getCurrentDateAppointment`
        let body = {}
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function printAppointment(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/printAppointment`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getClinicAndRoom() {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/getClinicAndRoom`
        let body = {}
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getClinicCheckInAppointment(clinicCode, location, available) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/getClinicCheckInAppointment`
        let body = {
            "hospital_code": localStorage.getItem('hospital_code'),
            "clinic_code": clinicCode,
            "location": location,
            "available": available
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getRoomForAssign(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/getRoomForAssign`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function saveAssignRoom(id, roomName) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/saveAssignRoom`
        let body = {
            "_id": id,
            "room_name": roomName
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function saveAssignRoomEmergency(id, roomName, emergency) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/saveAssignRoomEmergency`
        let body = {
            "_id": id,
            "room_name": roomName,
            "is_emergency": emergency
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function changeAssignRoom(id, roomName) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/changeAssignRoom`
        let body = {
            "_id": id,
            "room_name": roomName
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function callHistoryInquiry(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/callHistoryInquiry`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function openVN(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/openVN`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getCurrentDateQueue(clinicCode, location, roomName) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/getCurrentDateQueue`
        let body = {
            "clinic_code": clinicCode,
            "location": location,
            "room_name": roomName
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}


export function getCurrentThreeRoomDateQueue(
    hospital_code,
    clinic_code,
    location,
    room
) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/dashboard/getCurrentThreeRoomDateQueue`
        let body = {
            "hospital_code": hospital_code,
            "clinic_code": clinic_code,
            "location": location,
            "room_name_1": room['room_name_1'],
            "room_name_2": room['room_name_2'],
            "room_name_3": room['room_name_3']
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}


export function callNextQueue(clinicCode, location, roomName) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/callNextQueue`
        let body = {
            "clinic_code": clinicCode,
            "location": location,
            "room_name": roomName
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function setCancelQueue(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/setCancelQueue`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function callDirectQueue(id) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/callDirectQueue`
        let body = {
            "_id": id
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function setRoomPause(isPause, clinicCode, location, roomName) {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/user/setRoomPause`
        let body = {
            "is_pause": isPause,
            "clinic_code": clinicCode,
            "location": location,
            "room_name": roomName
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken')
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getClinicAndRoomDashboard() {
    return new Promise(resolve => {
        let url = `${domain_telequeue}/queue/api/v1/dashboard/getClinicAndRoom`
        let body = {}
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}


export function findTelemedicine(date) { //ผู้ป่วยนัดจากระบบ
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/findTelemedicine?AppointDate=${date}&order=DocNumber&sort=asc&DocStatus=W&HospitalCodeFrom=${localStorage.getItem('hospital_code')}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findTelemedicineHospital(date) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/findTelemedicine?order=DocNumber&sort=desc&DocStatus=W&AppointDate=${date}&HospitalCodeTo=${localStorage.getItem('hospital_code')}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformation(pid, hospitalId) { //ประวัติการแพทย์
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/R12Network-dashboard?group=date`
        let body = {
            "pid": pid,
            "hos_id": hospitalId,
            "collection": 'all'
        }
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}



export function findInformationPersonal(pid, hospitalId) { //ประวัติการแพทย์
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/R12Network-dashboard`
        let body = {
            "pid": pid,
            "hos_id": hospitalId,
            "collection": 'all'
        }
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findReportAll(PID) { //ประวัติการรักษา
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/findReportAll?sort=desc&PID=${PID}&order=CreatedDate`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findIcd10() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/manage/icd10`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function drug() { //รายการยา
    return new Promise(resolve => {
        let url = `${domain}/api/v1/manage/drug?HospitalCode=${localStorage.getItem('hospital_code')}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function saveMedicalRecords(diag, list, DocNumber) { //บันทึกการรักษา
    let newList = []
    list.map((i) => {
        return newList.push({
            //ตอนนี้ไม่รองรับ type ยา
            Order: i['drug_name'],
            Remark: i['drug_detail']
        })
    })
    console.log(diag)

    let newDiag = []
    diag.map((i) => {
        return newDiag.push({
            Diag: i['Diag'],
            Remark: i['Remark']
        })
    })

    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/saveMedicalRecords?DocNumber=${DocNumber}`
        let body = {
            "DiagList": newDiag, //ผลการวิจัย
            "OrderList": newList //การจ่ายยา
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard',
            'Content-Type': 'application/json'
        }
        axios.put(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findBloodTestResult(PID) { //ผลตรวจเลือด
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/point-of-Care/findBloodTestResult?PID=${PID}`
        let header = {
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function getHealthStation(pid, type) {
    return new Promise(resolve => {
        let url = `${domain_microservice}/api/v1/microservice/thrid-party/getHealthStation`
        let body = {
            "pid": pid,
            "type": type
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.xIiwidmlsbGFnZV9jb2RlIjoiIiwiZW1haWwiOiJhZG1pbmRlcGFydDFAZ21haWwuY29tIiwiY2l0aXplbl9pZCI6IjUxMjQ2MDQ1ODYxNzkiLCJ0ZWxfcGhvbmVfbnVtYmVyIjoiMDYzODYwNzkxMyIsInVzZXJfZGVwYXJ0bWVudCI6eyJkZXBhcnRfaWQiOiI5MjAxMDExIiwiZGVwYXJ0X25hbWUiOiLguYDguJfguKjguJrguLLguKXguJnguITguKPguJXguKPguLHguIciLCJkZXBhcnRfbGV2ZWwiOjF9LCJpYXQiOjE2ODkxNzY1MDYsImV4cCI6MTY4OTI2MjkwNn0.FBRcJ69MO4NFvf2OpcGfaM6tmYnrHyw9Wr0nic_uLd4`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationSearch(pid) { //ค้นหานัดหมาย
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/data/findInformation`
        let body = {
            "pid": pid
        }
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findHospital() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/findHospital`
        let header = {
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findDepartment() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/findDepartment`
        let header = {
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function addTelemedicine(body) { // เพิ่มการนัดหมาย
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/addTelemedicine/R12Network-dashboard`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function cancelTelemedicine(DocNumber) { //ลบนัดหมาย
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/telemedicine/cancelTelemedicine?DocNumber=${DocNumber}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findVitalsignTestResult(PID) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/point-of-Care/findVitalsignTestResult?PID=${PID}&order=SavedDate&sort=desc`
        let header = {
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findMeasureResult(PID) {
    return new Promise(resolve => {
        let url = 'https://api-med-devices.numeddatacenter.com/api/v1/tunnel/callback?endpoint=https%3A%2F%2Fmedcloud.radical-enlighten.com%2Fapi%2Frequest'
        let body = {
            "token": "hWdZq0x0o7VjTGkyek9rQXlUMFE3KzJaR2hrUVpZWC9oSmF4OU9Hcmc2UFIwTXdqalhVPQ==",
            "NID": PID
        }
        let header = {
            'Content-Type': 'application/json',
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findHealthStationResult(PID) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/point-of-Care/findHealthStationResult?cid=${PID}&order=created_date&sort=desc`
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Platform': 'r12network-dashboard',
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
            }
        };

        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            });
    })
}

