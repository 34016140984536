import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function getManageUser(
    hospital_code
    ) {
    return new Promise((resolve) => {
        // console.log('api id_hospital ======> ',hospital_code)
        let url = ''
        if(localStorage.getItem('hospital_code') === 'N/A' && hospital_code === 'all'){
            // console.log('api all =====> ',hospital_code)
            url = `${domain}/api/v1/manage/user`
        }else if(localStorage.getItem('hospital_code') === 'N/A' && hospital_code !== 'all'){
            url = `${domain}/api/v1/manage/user?HospitalCode=${hospital_code}`
            // console.log('api hospital =====> ',hospital_code)
        }else{
            url = `${domain}/api/v1/manage/user?HospitalCode=${localStorage.getItem('hospital_code')}`
        }
        
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function toggleManageUser(uid) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/toggleUser`
        let body = {
            UID: uid,
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Content-Type': 'application/json'
        }

        axios.put(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}


export function changePermissionManageUser(Permission) {
    // console.log("===> ",Permission)
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/changePermissionUser`
        // let body = {
        //     UID: Permission['UID'],
        //     Permission: {
        //         GroupRole: Permission['GroupRole'],
        //         HospitalCode: Permission['HospitalCode']
        //     }
        // }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Content-Type': 'application/json'
        }

        axios.put(url, Permission, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}



export function deleteMangeUser(uid) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/manage/user?UID=${uid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}


export function saveSystem(page,limit,date_start,date_end,search,sort,idHospital){
    return new Promise((resolve) => {
        let url = ''
        if(!idHospital){
            url = `${domain}/api/v1/logs/usage?page=${page}&limit=${limit}&order=CreatedDate&sort=${sort}
            &datestart=${date_start}&dateend=${date_end}&search=${search}& HospitalCode=${localStorage.getItem('hospital_code')}`
        }else{
            url = `${domain}/api/v1/logs/usage?page=${page}&limit=${limit}&order=CreatedDate&sort=${sort}
            &datestart=${date_start}&dateend=${date_end}&search=${search}& HospitalCode=${idHospital}`
        }
        
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url,{headers: header}).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })   
}