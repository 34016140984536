import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { backgroundModal, boxModal, buttomPrimary, buttomSecondary } from './ModalAccessCss'
import { Typography, Box, TextField, Button, FormControl, Select, MenuItem, InputLabel, Autocomplete } from '@mui/material'
import { notifySuccess, notifyError, notifyInfo } from '../../components/NotifyToast'
import LoaderAnimation from '../LoaderAnimation';
import * as Api from '../../services/public_api'

function ModalAddHospital({ closeModal, submitForm, selectedValue }) {

    const [loading, setLoading] = useState(false)

    const [province, setProvince] = useState('')
    const [optionProvince, setOptionProvince] = useState([])

    const [hospital, setHospital] = useState('')
    const [subHospitalList, setSubHospitalList] = useState([])
    const [affiliation, setAffiliation] = useState('')

    const [hospitalList, setHospitalList] = useState([])
    const [SelectedHospital, setSelectedHospital] = useState(null)


    useEffect(() => {
        if (SelectedHospital) {
            console.log(SelectedHospital)
            let { HospitalCode, HospitalName } = SelectedHospital
            selectedValue({
                hospital_code: HospitalCode,
                hospital_name: HospitalName
            })

        }
    }, [SelectedHospital])

    const handleChangeSelectHospital = (e) => {
        console.log(e.target.value)

        if (affiliation === "subHospital") {
            const data = {
                HospitalCode: e.target.value['Hospital2Code'],
                HospitalName: e.target.value['Hospital2Name']
            }
            if (e.target.name === 'HospitalCode') {
                setSelectedHospital(data)
            }
        } else {
            if (e.target.name === 'HospitalCode') {
                setSelectedHospital(e.target.value) // object value
            }
        }
    }

    const handleConfirmAddHospital = () => {
        console.log(SelectedHospital)
        if (!SelectedHospital) {
            notifyInfo('กรุณาเลือกชื่อสถานะพยาบาล')
            return
        }

        submitForm()
    }

    const handleChangeAffiliation = async (event) => {
        setAffiliation(event.target.value)

        setLoading(true)
        let res = await Api.findProvince()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]['ProvinceZone'] === "12") {
                        option.push(res['data']['data'][i])
                    }
                }
                setOptionProvince(option)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleChangeProvince = async (event) => {
        setProvince(event.target.value)

        setLoading(true)
        let res = await Api.findHospitalProvince(event.target.value)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        option.push(res['data']['data'][i])
                    }
                }
                setHospitalList(option)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleChangeSelectSubHospital = async (event) => {
        setHospital(event.target.value)
        setSelectedHospital(null)

        let option = []
        for (let i = 0; i < hospitalList.length; i++) {
            if (hospitalList[i]['HospitalCode'] === event.target.value) {
                option.push(hospitalList[i]['Hospital2List'])
            }
        }

        if (option[0] === false) {
            setSubHospitalList(null)
        } else {
            setSubHospitalList(option[0])
        }
    }

    return (
        <Box sx={backgroundModal}>
            <LoaderAnimation loading={loading} />
            <Box sx={boxModal}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={closeModal}><ClearIcon /></Box>
                <Typography
                    component="p"
                    variant="p"
                    color={'#006838'}
                    sx={{ fontSize: '18px', fontWeight: 'bold' }}
                >
                    เพิ่มสถานพยาบาลที่ต้องการ ขอคำปรึกษา
                </Typography>
                <hr style={{ margin: '1.5rem 0', width: '100%' }} />

                <Box sx={{ display: 'grid', gridGap: 10 }}>
                    <Typography
                        component="p"
                        variant="p"
                        color={'#006838'}
                        sx={{ fontSize: '18px', fontWeight: 'bold' }}
                    >เลือกสังกัด</Typography>
                    <FormControl fullWidth>
                        <InputLabel sx={{ color: '#747676' }}>เลือกสังกัด</InputLabel>
                        <Select
                            label='เลือกสังกัด'
                            name='affiliation'
                            value={affiliation || ''}
                            onChange={(e) => {
                                setProvince('')
                                setSelectedHospital(null)
                                handleChangeAffiliation(e)
                            }}
                        >
                            <MenuItem value="hospital">โรงพยาบาล</MenuItem>
                            <MenuItem value="subHospital">โรงพยาบาลส่งเสริมสุขภาพตำบล</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography
                        component="p"
                        variant="p"
                        color={'#006838'}
                        sx={{ fontSize: '18px', fontWeight: 'bold' }}
                    >เลือกจังหวัด</Typography>
                    <FormControl fullWidth>
                        <InputLabel sx={{ color: '#747676' }}>เลือกจังหวัด</InputLabel>
                        <Select
                            label='เลือกจังหวัด'
                            name='province'
                            value={province || ''}
                            onChange={(e) => {
                                setSelectedHospital(null)
                                setHospital('')
                                handleChangeProvince(e)
                            }}
                            disabled={affiliation === '' ? true : null}
                        >
                            {
                                optionProvince.map((item, index) => (
                                    <MenuItem key={index} value={item['ProvinceCode']}>{item['ProvinceName']}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                    {affiliation === '' || affiliation === "hospital" ?
                        <>
                            <Typography
                                component="p"
                                variant="p"
                                color={'#006838'}
                                sx={{ fontSize: '18px', fontWeight: 'bold' }}
                            >เลือกสถานพยาบาล</Typography>
                            <FormControl fullWidth>
                                <InputLabel sx={{ color: '#747676' }}>เลือกโรงพยาบาล</InputLabel>
                                <Select
                                    label='เลือกโรงพยาบาล'
                                    name='HospitalCode'
                                    renderValue={(selected) => SelectedHospital ? selected.HospitalName : ''}
                                    value={SelectedHospital || ''}
                                    onChange={handleChangeSelectHospital}
                                    disabled={province === '' ? true : null}
                                >
                                    {
                                        hospitalList.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item['HospitalName']}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </>
                        :
                        <>
                            <Typography
                                component="p"
                                variant="p"
                                color={'#006838'}
                                sx={{ fontSize: '18px', fontWeight: 'bold' }}
                            >เลือกสถานพยาบาล</Typography>
                            <FormControl fullWidth>
                                <InputLabel sx={{ color: '#747676' }}>เลือกโรงพยาบาล</InputLabel>
                                <Select
                                    label='เลือกโรงพยาบาล'
                                    name='Hospital'
                                    value={hospital || ''}
                                    onChange={(e) => { handleChangeSelectSubHospital(e) }}
                                >
                                    {
                                        hospitalList.map((item, index) => (
                                            <MenuItem key={index} value={item['HospitalCode']}>{item['HospitalName']}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel sx={{ color: '#747676' }}>เลือกโรงพยาบาล</InputLabel>
                                <Select
                                    label='เลือกโรงพยาบาล'
                                    name='HospitalCode'
                                    renderValue={(selected) => SelectedHospital ? selected.HospitalName : ''}
                                    value={SelectedHospital || ''}
                                    onChange={handleChangeSelectHospital}
                                    disabled={hospital === '' ? true : null}
                                >
                                    {
                                        subHospitalList.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item['Hospital2Name']}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '2rem' }}>
                    <Button
                        onClick={closeModal}
                        fullWidth
                        variant="contained"
                        sx={buttomSecondary}
                    >
                        {'ยกเลิก'}
                    </Button>
                    <Button
                        onClick={() => { handleConfirmAddHospital() }}
                        fullWidth
                        variant="contained"
                        sx={buttomPrimary}
                    >
                        {'บันทึก'}
                    </Button>
                </Box>

            </Box>
        </Box>
    )
}

export default ModalAddHospital