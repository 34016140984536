import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from './ConsultDoctor.module.css'
import * as api from '../../services/ApiConsultDoctor'
import LoaderAnimation from '../../components/LoaderAnimation'
import tabBmi from '../../assets/img/tab_bmi.svg'
import tabBloodPrNormal from '../../assets/img/tab_blood_pressure_narmal.svg'
import tabBloodPrSick from '../../assets/img/tab_blood_pressure_sick.svg'
import tabSugarNormal from '../../assets/img/tab_blood_sugar_normal.svg'
import tabSugarNormal8 from '../../assets/img/tab_blood_sugar_normal8.svg'
import tabSugarSick2 from '../../assets/img/tab_blood_sugar_sick2.svg'
import tabSugarSick8 from '../../assets/img/tab_blood_sugar_sick8.svg'

import machSugar from '../../assets/img/machine_blood_sugar.svg'
import machPress from '../../assets/img/machine_blood_pressure.svg'
import machWeight from '../../assets/img/machine_weight.svg'
import machOxygen from '../../assets/img/machine_oxygen.svg'
import _ from 'lodash'

import { notifyError, notifyInfo } from "../../components/NotifyToast";

import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useLocation } from "react-router-dom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function PointOfCare({ pid }) {
    const [value, setValue] = useState(0);

    return (
        <>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                fontSize: 18,
                marginTop: 5,
                marginLeft: 5,
                marginRight: 5,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >ผลตรวจเลือด</div>
                <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >Health Station</div>
                <div onClick={() => { setValue(2) }} className={value === 2 ? styles.btGreen : styles.btGay} >ผลการคัดกรองสุขภาพ(ความดันและน้ำตาล)</div>
            </Box>
            {value === 0 ?
                <>
                    <BloodTestResults
                        PID={pid}
                    />
                </> : null
            }
            {value === 1 ?
                <>
                    <HealthStation
                        PID={pid}
                    />
                </> : null
            }
            {value === 2 ?
                <>
                    <ResultsUsingIoT
                        PID={pid}
                    />
                </> : null
            }
        </>
    )
}

const BloodTestResults = ({ PID }) => {

    const [loading, setLoading] = useState(false)
    const [dataBloodTest, setDataBloodTest] = useState([])

    useEffect(() => {
        findBloodTestResult()
        console.log(PID)
    }, [])

    const findBloodTestResult = async () => {
        setLoading(true)
        let res = await api.findBloodTestResult(PID)
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataBloodTest(res['data']['data'])
            } else {
                setDataBloodTest([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {dataBloodTest.length > 0 ?
                <Box sx={{
                    display: 'flex',
                    gridGap: 20,
                    marginTop: 3,
                    overflow: "hidden",
                    overflowX: "scroll",
                    height: 'auto',
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    {dataBloodTest.map((i, idx) => (
                        <Box sx={{
                            backgroundColor: '#E1F4EB',
                            borderRadius: 2,
                            padding: 2,
                            minWidth: '350px'
                        }} key={idx}>
                            <div className={styles.date}>{convertDate(i['TestDate'])}</div>
                            <Box sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: 2,
                                padding: 2
                            }}>
                                <div className={styles.title}>ผลตรวจเลือด</div>
                                <Box sx={{
                                    padding: 2
                                }}>
                                    {i['TestResults'].map((item, index) => (
                                        <div key={index}>
                                            <div className={styles.key}>{item['TestName']}</div>
                                            {item['TestResults'].map((value, key) => (
                                                <div className={styles.detail} key={key} >
                                                    <div className={styles.value}>{value['ResultName'] + ' : '}</div>
                                                    <div className={styles.value2}>{value['ResultValue'] + ' ' + value['ResultUnit']}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                :
                <div style={{ textAlign: 'center', margin: '20%', fontSize: 28 }}>ไม่พบข้อมูล</div>
            }
        </>
    )
}

const HealthStation = ({ PID }) => {
    const [loading, setLoading] = useState(false)
    const [valueOption, setValueOption] = useState('blood_pressure')
    const [optionSelect, setOptionSelect] = useState([
        {
            key: 'blood_pressure',
            value: 'ค่าความดันโลหิต'
        },
        {
            key: 'blood_sugar',
            value: 'น้ำตาลในเลือด'
        },
        {
            key: 'bmi',
            value: 'BMI'
        },
        {
            key: 'pulse_rate',
            value: 'ชีพจร'
        },
        {
            key: 'oxygen_saturation',
            value: 'ค่าออกซิเจนในเลือด'
        }
    ])
    const [listYear, setListYear] = useState([])
    const [listHealth, setListHealth] = useState([])
    const [listColor, setListColor] = useState([])

    useEffect(() => {
        getHealthStation()
    }, [valueOption])

    const getHealthStation = async () => {
        setLoading(true)
        let res = await api.getHealthStation(PID, valueOption)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let dataYearList = []
                let dataHealth = []
                let dataColor = []

                if (valueOption === 'blood_pressure') {
                    res['data']['blood_pressure_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['diastolic'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'blood_sugar') {
                    res['data']['blood_sugar_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['blood_sugar'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'bmi') {
                    res['data']['bmi_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['bmi'])
                        dataColor.push(i['color'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                    setListColor(dataColor)
                } else if (valueOption === 'oxygen_saturation') {
                    res['data']['oxygen_saturation_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['oxygen_saturation'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                } else if (valueOption === 'pulse_rate') {
                    res['data']['pulse_rate_list'].map((i) => {
                        dataYearList.push(i['date_text'])
                        dataHealth.push(i['pulse_rate'])
                    })
                    setListYear(dataYearList)
                    setListHealth(dataHealth)
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const option = {
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    font: {
                        size: '12%'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: '12%'
                    }
                }
            }
        }
    }

    const data = {
        labels: listYear,
        datasets: [
            {
                label: '',
                data: listHealth,
                backgroundColor: (valueOption === 'oxygen_saturation' || valueOption === 'pulse_rate') ? ['#006838'] : listColor
            }
        ]
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#E1F4EB',
                borderRadius: 3,
                padding: 3,
                marginTop: 3,
                marginLeft: 5,
                marginRight: 5,
                '@media (max-width: 800px)': {
                    padding: 2,
                    marginTop: 2,
                    marginLeft: 2,
                    marginRight: 2
                }
            }}>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: 3,
                    paddingBottom: 6
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        '@media (max-width: 800px)': {
                            justifyContent: 'center',
                        }
                    }}>
                        <Box sx={{
                            margin: 2,
                            width: '40%',
                            '@media (max-width: 800px)': {
                                width: '100%'
                            }
                        }}>
                            <Select
                                fullWidth
                                size="small"
                                value={valueOption}
                                onChange={(event) => {
                                    const {
                                        target: { value },
                                    } = event;
                                    setValueOption(value)
                                }}
                            >
                                {optionSelect.map((i) => (
                                    <MenuItem
                                        key={i['key']}
                                        value={i['key']}
                                    >
                                        {i['value']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        width: '600px',
                        maxHeight: '400px',
                        '@media (max-width: 1030px)': {
                            width: '500px',
                            maxHeight: '300px'
                        },
                        '@media (max-width: 500px)': {
                            width: '300px',
                            maxHeight: '200px'
                        }
                    }}>
                        <Bar
                            options={option}
                            data={data}
                        />
                    </Box>

                    {valueOption === 'blood_pressure' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            gridTemplateColumns: '1fr 1fr',
                            marginTop: 3,
                            '@media (max-width: 1300px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 10
                            }
                        }}>
                            <img className={styles.Img} src={tabBloodPrNormal} />
                            <img className={styles.Img} src={tabBloodPrSick} />
                        </Box>
                        : null
                    }

                    {valueOption === 'bmi' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            marginTop: 3
                        }}>
                            <img className={styles.Img} src={tabBmi} />
                        </Box>
                        : null
                    }

                    {valueOption === 'blood_sugar' ?
                        <Box sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            gridTemplateColumns: '1fr 1fr',
                            marginTop: 3,
                            '@media (max-width: 1300px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 10
                            }
                        }}>
                            <img className={styles.Img} src={tabSugarNormal} />
                            <img className={styles.Img} src={tabSugarNormal8} />
                            <img className={styles.Img} src={tabSugarSick2} />
                            <img className={styles.Img} src={tabSugarSick8} />
                        </Box>
                        : null
                    }

                </Box>
            </Box>
        </>
    )
}

const ResultsUsingIoT = ({ PID }) => {

    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [dataResult, setDataResult] = useState([])
    const [notData, setNotData] = useState('')

    // const [measureResult, setMeasureResult] = useState([])

    useEffect(() => {
        findResult()
    }, [])

    const findResult = async () => {
        setLoading(true)
        let res = await api.findHealthStationResult(PID)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataResult(res['data']['data'].sort(function (a, b) {
                    return b.created_date - a.created_date
                }))
            } else {
                setNotData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    // useEffect(() => {
    //     findMeasureResult()
    // }, [])

    // const split = (id) => {
    //     let splt = id.split(" ")
    //     return splt[0]
    // }

    // const findMeasureResult = async () => {
    //     setLoading(true)
    //     let res = await api.findMeasureResult(PID)
    //     setLoading(false)
    //     if (res) {
    //         let raw = res['data']
    //         let date_list = raw.bloodpressure.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] }));
    //         date_list = date_list.concat(raw.glucose.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
    //         date_list = date_list.concat(raw.weight.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
    //         date_list = date_list.concat(raw.oxygen.map(item => ({ collected: split(item.collected), bloodpressure: [], glucose: [], weight: [], oxygen: [] })));
    //         date_list = _.orderBy(_.uniqBy(date_list, 'collected'), ['collected'], ['desc'])

    //         date_list.forEach((item_) => {
    //             item_.bloodpressure = raw.bloodpressure.filter(item => split(item.collected) === item_.collected)
    //             item_.glucose = raw.glucose.filter(item => split(item.collected) === item_.collected)
    //             item_.weight = raw.weight.filter(item => split(item.collected) === item_.collected)
    //             item_.oxygen = raw.oxygen.filter(item => split(item.collected) === item_.collected)
    //         })
    //         console.log(date_list)
    //         setMeasureResult(date_list)
    //     } else {
    //         notifyError('ไม่ตอบสนอง')
    //     }
    // }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {notData === '' ?
                <Box sx={{
                    display: 'flex',
                    gridGap: 20,
                    marginTop: 3,
                    overflow: "hidden",
                    overflowX: "scroll",
                    marginLeft: 5,
                    marginRight: 5,
                }}>
                    {dataResult.map((i, idx) => (
                        <Box key={idx} sx={{
                            backgroundColor: '#E1F4EB',
                            borderRadius: 2,
                            padding: 2,
                            minWidth: '350px'
                        }}>
                            <Typography sx={{ fontSize: 22, fontWeight: 600 }}>{convertDate(i['vstdate'])}</Typography>
                            <Typography sx={{ fontSize: 16 }}>{i['vsttime']}</Typography>
                            <Box sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: 2,
                                padding: 2,
                                display: 'grid',
                                gridGap: 10
                            }}>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Height :</div>
                                    <div className={styles.value2}>{i['height'] ? i['height'] + ' cm.' : '-'} </div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Weight :</div>
                                    <div className={styles.value2}>{i['weight'] ? i['weight'] + ' kg.' : '-'} </div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Dia/Sys :</div>
                                    <div className={styles.value2}>{i['sys'] ? i['sys'] + '/' + i['dia'] + ' mmHg.' : '-'}</div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Pulse :</div>
                                    <div className={styles.value2}>{i['pulse'] ? i['pulse'] + ' ครั้ง/นาที' : '-'} </div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Bmi :</div>
                                    <div className={styles.value2}>{i['bmi'] ? i['bmi'] : '-'} </div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>Temp :</div>
                                    <div className={styles.value2}>{i['temp'] ? i['temp'] + ' องศาเซลเซียส' : '-'}</div>
                                </Box>
                                <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
                                    <div className={styles.value}>SpO2 :</div>
                                    <div className={styles.value2}>{i['sp 2'] ? i['spo2'] + ' %' : '-'}</div>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                :
                <div style={{ textAlign: 'center', margin: '20%', fontSize: 28 }}>ไม่พบข้อมูล</div>
            }

            {/* <Box sx={{
                color: '#263238',
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                marginTop: 3,
                marginRight: 5,
                marginLeft: 5,
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                {measureResult.length > 0 ?
                    <Box sx={{
                        display: 'flex',
                        gridGap: 15,
                        overflow: "hidden",
                        overflowX: "scroll",
                        marginBottom: '1rem',
                    }}>
                        {measureResult.map((i, idx) => (
                            <Box sx={{
                                minWidth: '450px',
                                backgroundColor: '#E1F4EB',
                                borderRadius: 3,
                                padding: 3,
                                maxWidth: '300px',
                                overflowY: 'auto',
                                // minHeight: 'auto',
                                maxHeight: '700px',
                                marginBottom: '1rem',
                                '@media (max-width: 1024px)': {
                                    minWidth: '350px',
                                    padding: 1,
                                },
                                '@media (max-width: 800px)': {
                                    minWidth: '300px',
                                    padding: 1,
                                }
                            }} key={idx}>
                                <Box sx={{
                                    fontWeight: 700,
                                    color: '#006838',
                                    fontSize: 24,
                                    '@media (max-width: 1024px)': {
                                        fontSize: 20
                                    }
                                }}>{convertDate(i['collected'])}</Box>
                                <Box sx={{
                                    display: 'grid',
                                    gridGap: 15,
                                    '@media (max-width: 1023px)': {
                                        padding: 1,
                                        gridGap: 15,
                                    },
                                    '@media (max-width: 800px)': {
                                        gridGap: 8,
                                    }
                                }}>
                                    {i['glucose'] ?
                                        <>
                                            {i['glucose'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }}>
                                                    <Box>
                                                        <img src={machSugar} />
                                                    </Box>
                                                    <Box>
                                                        <Box key={idx} sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>ระดับน้ำตาลในเลือด (Blood sugar) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['glucose'] ? i['glucose'] + ' mg/dL' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['weight'] ?
                                        <>
                                            {i['weight'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }}>
                                                    <Box>
                                                        <img src={machWeight} />
                                                    </Box>
                                                    <Box>
                                                        <Box key={idx} sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>น้ำหนัก (Weight) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['weight'] ? i['weight'] + ' kg' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['bloodpressure'] ?
                                        <>
                                            {i['bloodpressure'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }} key={idx}>
                                                    <Box>
                                                        <img src={machPress} />
                                                    </Box>
                                                    <Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>ค่าความดันโลหิตตัวบน (SYS)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['sys'] ? i['sys'] + ' mmHg' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>ค่าความดันโลหิตตัวล่าง (DIA)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['dia'] ? i['dia'] + ' mmHg' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>อัตราการเต้นของหัวใจ (BPM)</Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['pulse'] ? i['pulse'] + ' /min' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                    {i['oxygen'] ?
                                        <>
                                            {i['oxygen'].map((i, idx) => (
                                                <Box sx={{
                                                    display: 'flex',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: 2,
                                                    padding: 2
                                                }} key={idx}>
                                                    <Box>
                                                        <img src={machOxygen} />
                                                    </Box>
                                                    <Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>ค่าออกซิเจนในเลือด (SpO2) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['spo2'] ? i['spo2'] + ' %' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>อัตราการเต้นของหัวใจ (BPM) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['bpm'] ? i['bpm'] + ' /min' : '-'}</Box>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 30px' }}>
                                                            <Box sx={{ fontWeight: 700, color: '#006838' }}>การไหลเวียนของเลือด (PI) </Box>
                                                            <Box sx={{ padding: '0 10px' }}>{i['pi'] ? i['pi'] + ' %' : '-'}</Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </>
                                        : null
                                    }

                                </Box>
                            </Box>
                        ))}

                    </Box>
                    :
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20%',
                        fontSize: '24px',
                        '@media (max-width: 800px)': {
                            marginTop: '10%',
                            fontSize: '18px',
                        }
                    }}>ไม่พบข้อมูล</Box>
                }
            </Box > */}
        </>
    )
}