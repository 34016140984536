// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SystemPatient_btGreen__EWPo1 {
    background-color: #006838;
    border: 1px solid #006838;
    border-radius: 10px;

    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
}

.SystemPatient_btGay__bZfAQ {
    background-color: #E1F4EB;
    border: none;
    border-radius: 10px;

    font-weight: 700;
    color: #006838;
    text-align: center;
    padding: 10px;
}

.SystemPatient_btGay__bZfAQ , .SystemPatient_btGreen__EWPo1:hover {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/consult_doctor/SystemPatient.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;;IAEnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;;IAEnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".btGreen {\n    background-color: #006838;\n    border: 1px solid #006838;\n    border-radius: 10px;\n\n    font-weight: 700;\n    color: #FFFFFF;\n    text-align: center;\n    padding: 10px;\n}\n\n.btGay {\n    background-color: #E1F4EB;\n    border: none;\n    border-radius: 10px;\n\n    font-weight: 700;\n    color: #006838;\n    text-align: center;\n    padding: 10px;\n}\n\n.btGay , .btGreen:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btGreen": `SystemPatient_btGreen__EWPo1`,
	"btGay": `SystemPatient_btGay__bZfAQ`
};
export default ___CSS_LOADER_EXPORT___;
