import axios from "axios";
import { environment } from "../../../config.env";

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain
const domain = ENDPOINT_DOMAIN

export function findReferOut(date, hospital) { //ข้อมูลตารางทั้งหมด
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findReferOut?sort=desc&date=${date}&hcode=${hospital}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findReferOutGroup(date, ReferGroup, hospital) { // ข้อมูลตารางแบบแยกกลุ่ม
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findReferOut?sort=desc&date=${date}&ReferGroupCode=${ReferGroup}&hcode=${hospital}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findDocReferBack(ReferBackNumber) { //เอกสารส่งตัว
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findDocReferBack?ReferBackNumber=${ReferBackNumber}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findDocReferOut(ReferNumber) { //เอกสารส่งตัว
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findDocReferOut?ReferOutNumber=${ReferNumber}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function changeStateReferOut(ReferNumber, ReferGroupCode, Remark) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/changeStateReferOut?ReferNumber=${ReferNumber}`
        let data = JSON.stringify({
            "ReferGroupCode": ReferGroupCode,
            "Remark": Remark
        });
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
                'Platform': 'r12network-dashboard',
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            });
    })
}

export function findReferoutSummary(refer_no) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/report/findReferoutSummary?refer_no=${refer_no}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function newReferOut(body) {
    return new Promise(resolve => {
        let url = `${domain}//api/v1/microservice/refer/newReferOut/r12network-dashboard`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function editReferOut(body) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/editReferOut/r12network-dashboard`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.put(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findReferbackSummary(refer_no) { //ข้อมูลตารางทั้งหมด
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/report/findReferbackSummary?refer_no=${refer_no}`
        let header = {
            'Platform': 'other',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}