import React, { useState } from "react";
import PageHeader from '../../components/PageHeader'
import { Box } from "@mui/material";
import styles from './ConsultDoctor.module.css'

export default function HomeMonitoring() {
    const [value, setValue] = useState(0);

    return (
        <>
            <PageHeader title_name={'กลับไปที่ผู้ป่วยนัด'} back_path={'/auth/consult_doctor'} hide_date />
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                fontSize: 18,
                marginTop: 3,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >Smart watch</div>
                <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >Camera</div>
            </Box>

            {value === 0 ?
                <>
                </>
                : null
            }

            {value === 1 ?
                <>
                </>
                : null
            }
        </>
    )
}