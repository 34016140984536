import axios from "axios";
import { environment } from '../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN
// console.log("domain ====> ",domain)

export const login = (username, password) => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/login`
        let body = {
            Username: username,
            Password: password
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const logout = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/logout`
        let body = {
            RefreshToken: localStorage.getItem('RefreshToken')
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.delete(url, { headers: header, data: body }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const refreshToken = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/refreshToken`
        let body = {
            RefreshToken: localStorage.getItem('RefreshToken')
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const register = (email, password, cid, title_name, fname, lname, confirm_register, group_role, hospital_code, phone, department_code, position) => {
    return new Promise((resolve) => {
        // let url =  `${domain}/api/v1/authen/register`
        // let body = {
        //     Email: email,
        //     Password: password,
        //     CID: cid,
        //     TitleName: title_name,
        //     FirstName: fname,
        //     LastName: lname,
        //     Position: position,
        //     PhoneNumber: phone,
        //     Confirm: confirm_register,
        //     Permission:{
        //         GroupRole: group_role,
        //         HospitalCode: hospital_code,
        //         DepartmentCode: department_code
        //     }
        // }
        // let header = {
        //     'Content-Type': 'application/json'
        // }

        // axios.post(url,body,{headers: header}).then((res) => {
        //     resolve(res)
        // }).catch((err) => {
        //     resolve(false)
        // })

        let data = JSON.stringify({
            "Email": email,
            "Password": password,
            "CID": cid,
            "TitleName": title_name,
            "FirstName": fname,
            "LastName": lname,
            "Position": position,
            "PhoneNumber": phone,
            "Confirm": confirm_register,
            "Permission": {
                "GroupRole": group_role,
                "HospitalCode": hospital_code,
            }
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${domain}/api/v1/authen/register`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response)
            })
            .catch((error) => {
                // console.log(error);
                resolve(false)
            });
    })
}

export const findTitleName = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/public/findTitleName?`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const findHospital = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/public/findHospital?DocStatus=W&&sub=true`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const findRoleGroup = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/public/findRoleGroup?`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const findDepartment = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/public/findDepartmentType?`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const profile = () => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/profile`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Content-Type': 'application/json'
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export const editProfile = (title_name, fname, lname, phone, department_code) => {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/profile`
        let body = {
            TitleName: title_name,
            FirstName: fname,
            LastName: lname,
            PhoneNumber: phone,
            Permission: {
                DepartmentCode: department_code
            }
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Content-Type': 'application/json'
        }

        axios.put(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

// export const activeUser = (uid) => {
//     return new Promise((resolve) => {
//         let url = `${domain}/api/v1/authen/activeUser`
//         let body = {
//             UID: uid,
//         }
//         let header = {
//             'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
//             'Content-Type': 'application/json'
//         }

//         axios.put(url,body,{headers: header}).then((res) => {
//             resolve(res)
//         }).catch((err) => {
//             resolve(false)
//         })
//     })      
// }

// export const unActiveUser = (uid) => {
//     return new Promise((resolve) => {
//         let url = `${domain}/api/v1/authen/unActiveUser`
//         let body = {
//             UID: uid,
//         }
//         let header = {
//             'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
//             'Content-Type': 'application/json'
//         }

//         axios.put(url,body,{headers: header}).then((res) => {
//             resolve(res)
//         }).catch((err) => {
//             resolve(false)
//         })
//     })      
// }

// export const cancelUser = (uid) => {
//     return new Promise((resolve) => {
//         let url = `${domain}/api/v1/authen/user`
//         let body = {
//             UID: uid,
//         }
//         let header = {
//             'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
//             'Content-Type': 'application/json'
//         }

//         axios.delete(url,{headers: header,data: body}).then((res) => {
//             resolve(res)
//         }).catch((err) => {
//             resolve(false)
//         })
//     })   
// }

export const resetPassword = (email) => { //**
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/resetPassword`
        let body = {
            Email: email
        }
        let header = {
            'Content-Type': 'application/json'
        }

        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function updatePassword(new_password, token) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/authen/updatePassword`
        let body = {
            NewPassword: new_password
        }
        let header = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }

        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}


export function getQrCode() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/authen/digitalID/getQrCode`
        let header = {}

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function getUserData(token) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/authen/digitalID/getUserData`
        let header = {
            'Authorization': `Bearer ${token}`,
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function registerThaiId(data) {
    console.log(data)
    return new Promise(resolve => {
        let url = `${domain}/api/v1/authen/register/digitalID`
        let body = {
            "Email": data['Email'],
            "Password": data['Password'],
            "CID": data['CID'],
            "TitleName": data['TitleName'],
            "FirstName": data['FirstName'],
            "LastName": data['LastName'],
            "Position": data['Position'],
            "Confirm": data['Confirm'],
            "Permission": {
                "GroupRole": data['Permission']['GroupRole'],
                "HospitalCode":data['Permission']['HospitalCode']
            }
        }
        let header = {
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findProvince() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/public/findProvince`
        let header = {
            'Content-Type': 'application/json'
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findHospitalProvince(ProvinceCode) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/public/findHospital?DocStatus=W&ProvinceZone=12&order=ProvinceCode&sort=asc&ProvinceCode=${ProvinceCode}`
        let header = {
            'Content-Type': 'application/json'
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}