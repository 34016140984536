import React from 'react'
import { Typography,Box,Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import {backgroundModal,boxModal,buttomPrimary} from './ModalAccessCss'
import passStatus from '../../assets/icons/correct_status.svg'
import { useNavigate } from 'react-router-dom';

function ModalSuccess(props) {
    const  { closeModal,title,detail,goPage,state } = props
    const navigate = useNavigate()

    const handlePage = async() => {
        if(goPage === '' || null){
            // await closeModal()
            return window.location.reload();
        }
        if(goPage === 'closeModal'){
            return closeModal()
        }
        navigate(goPage,{ state })

    }

    return (
    <Box sx={backgroundModal}>
        <Box sx={{textAlign:'center',...boxModal}}>
        <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
            <Box sx={{marginBottom:'1rem'}}>
                <img src={passStatus} alt=''/>
            </Box>
            <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'calc(20px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>{title}</Typography>  
            <Typography component="p" variant="p" color='secondary' sx={{fontSize:'calc(15px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>{detail}</Typography>  
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
                <Button onClick={() => handlePage()}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    {'ตกลง'}
                </Button>            
            </Box>        
        </Box>
    </Box>
  )
}

export default ModalSuccess