// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalPayment_overlay__3R\\+g5 {
    z-index: 1000;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(28, 28, 28, 0.40);
}

.ModalPayment_modalController__pxdQX {
    width: 550px;
    height: auto;
    background: #ffffff;
    border-radius: 9px;
    margin: 0 1rem;
    padding: 1rem;
}

.ModalPayment_closeButton__G2d0H {
    display: flex;
    justify-content: flex-end;
}

.ModalPayment_contentModal__arRMb {
    margin-top: -10px;
    padding: 1rem;
    display: grid;
    justify-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalController/ModalPayment.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,WAAW;IACX,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kCAAkC;AACtC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".overlay {\n    z-index: 1000;\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(28, 28, 28, 0.40);\n}\n\n.modalController {\n    width: 550px;\n    height: auto;\n    background: #ffffff;\n    border-radius: 9px;\n    margin: 0 1rem;\n    padding: 1rem;\n}\n\n.closeButton {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.contentModal {\n    margin-top: -10px;\n    padding: 1rem;\n    display: grid;\n    justify-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ModalPayment_overlay__3R+g5`,
	"modalController": `ModalPayment_modalController__pxdQX`,
	"closeButton": `ModalPayment_closeButton__G2d0H`,
	"contentModal": `ModalPayment_contentModal__arRMb`
};
export default ___CSS_LOADER_EXPORT___;
