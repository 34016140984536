import React, { useEffect, useRef, useState } from 'react'
import {
    Box, Button, Grid, Typography, Checkbox, FormControlLabel, FormGroup, TextField,
    FormControl, Select, MenuItem, InputAdornment, IconButton, Autocomplete
} from '@mui/material'

import { content } from '../Css'
import PageHeader from '../../../components/PageHeader'
import { ButtonContained, ButtonOutlined } from '../Css'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import ModalAddReferOPD, { ModalAddReferIPD, ModalOpenData, ModalOpenDataIPD } from '../../../components/ModalController/ModalAddRefer'
import styles from './resive.module.css'
import LoaderAnimation from '../../../components/LoaderAnimation'
import * as apiResive from '../../../services/refer/resive/ApiResive'
import * as api from '../../../services/refer/ApiPublicRefer'
import { notifyError, notifyInfo, notifySuccess } from '../../../components/NotifyToast';

import ModalCalendar from '../../../components/ModalController/ModalCalendar'
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

export default function SentResiveBackEdit() {
    const location = useLocation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [openModalOPD, setOpenModalOPD] = useState(false)
    const [openModalIPD, setOpenModalIPD] = useState(false)
    const [openModalData, setOpenModalData] = useState(false)
    const [openModalDataIpd, setOpenModalDataIpd] = useState(false)

    const [openCalendar, setOpenCalendar] = useState(false)
    const [disabledDay, setDisabledDay] = useState([])
    const [typeDate, setTypeDate] = useState('')

    const type_sent = [
        { key: '2', value: 'ER' },
        { key: '1', value: 'OPD' },
        { key: '7', value: 'IPD' },
        { key: '0', value: 'OR' },
        { key: '4', value: 'LR' }
    ]
    const type_sent2 = ['ในจังหวัด', 'นอกจังหวัด']
    const type_file = ['OPD', 'IPD']
    const type_treat = ['รับไว้รักษาต่อ', 'ตรวจชันสูตร', 'คุมไว้สังเกต', 'ขอทราบ']
    const type_care = ['Airway = ระบบทางเดินหายใจ', 'Breathing = การหายใจ', 'IV fluid = ให้น้ำเกลือ', 'stop bluding = ห้ามเลือด', 'splint = เข้าเฝือก', 'อื่นๆ']
    const type_patient = ['ผู้ป่วยทั่วไป', 'ผู้ป่วยอุบัติเหตุ', 'ผู้ป่วยฉุกเฉิน(ยกเว้นอุบัติเหตุ)']
    const type_level_urgency = ['life threatening', 'emergency', 'urgent', 'acute', 'non acute']
    const type_specific = ['STEM', 'Stroke', 'trauma', 'cancer', 'sepsis', 'pregnancy, labor, postpartum', 'new born', 'อื่นๆ']
    const type_cause = ['เพื่อการวินิจฉัยและรักษา', 'เพื่อการวินิจฉัย', 'เพื่อการรักษาต่อเนื่อง', 'เพื่อการดูแลต่อใกล้บ้าน', 'ตามความต้องการผู้ป่วย', 'เพื่อส่งผู้ป่วยกลับไปยัง หน่วยบริการที่ส่งผู้ป่วยมา', 'เป็นการตอบกลับการส่งต่อ(ไม่ได้ส่งผู้ป่วย)']

    const inputFileRef = useRef(null)
    const [attachFile, setAttachFile] = useState([])
    const [medicalInfomationType, setMedicalInfomationType] = useState(null)

    const [FromHospitalCode, setFromHospitalCode] = useState(localStorage.getItem('hospital_code_')) //จากโรงพยาบาลต้นทาง 
    const [FromHospitalName, setFromHospitalName] = useState(localStorage.getItem('hospital_name')) //จากโรงพยาบาลต้นทาง 

    const [optionHospital, setOptionHospital] = useState([])

    const [itemData, setItemData] = useState()


    const [RemarkNote1, setRemarkNote1] = useState('')//ผลการชันสูตรที่สำคัญ
    const [RemarkNote2, setRemarkNote2] = useState('')//การวินิจฉัยโรคขั้นสุดท้าย
    const [RemarkNote3, setRemarkNote3] = useState('')//การรักษาที่ให้
    const [RemarkNote4, setRemarkNote4] = useState('')//ขอให้ดำเนินการต่อ ดังนี้ 

    const [data, setData] = useState({
        hn: '', //hn
        Name: '', //ชื่อ-นามสกุล
        cid: '', //เลขบัตรประจำตัวประชาชน
        sex: '', //เพศ
        BirthDay: '',//วันเกิด
        ageY: '', //อายุ
        MedicalMainTerm: '', //สิทธิการรักษาหลัก
        MedicalSubTerm: '', //สิทธิการรักษารอง
        MedicalBloodThinner: '', //การได้รับยาละลายลิ่มเลือด
        Address: '', //ที่อยู่
        DrugAllergy: '', //การแพ้ยา
        ReferCodeType: '', //ประเภทการส่งต่อ
        ReferNameType: '', //ประเภทการส่งต่อ
        ReferTypeProvince: '',//ประเภทการส่งต่อ ในจังหวัด นอกจังหวัด
        ToHospitalCode: '', //ถึงโรงพยาบาล
        ToHospitalName: '', //ถึงโรงพยาบาล
        FromHospitalPhone: '', //เบอร์โทรศัพท์โรงพยาบาลต้นทาง
        ToHospitalPhone: '', //เบอร์โทรศัพท์โรงพยาบาลปลายทาง

        ScreenNote1: '', //การให้การดูแลผู้ป่วย ขณะส่งต่อ
        ScreenNoteOther1: '', //การให้การดูแลผู้ป่วย ขณะส่งต่อ อื่นๆ
        ScreenNote2: '',//ประเภทผู้ป่วย
        ScreenNote3: '',//ระดับความเร่งด่วน
        ScreenNote4: '', //ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ
        ScreenNoteOther4: '',//ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ อื่นๆ
        ScreenNote5: '', //สาเหตุการส่งต่อผู้ป่วย
        NearbyPlace: '', //สถานที่ใกล้เคียงคือ
        ReferBy: '', //ขอทราบผล

        ReferDate: '', //วันที่ส่งต่อ
        iReferTime: '', //เวลาที่ส่ง
        ReferOutNumber: '', //เลขที่ส่งต่อจากรพ.ต้นทาง
        ReferNumber: '', //เลขที่ส่งต่อ
        ExpireDate: '', //วันที่หมดอายุ
        Department: '', //แผนก
        DoctorName: '', //แพทย์ผู้สั่ง
        Station: '', //จุดส่งต่อ
        Room: '', //ห้อง
        StaffName: '', //ผู้บันทึกใบส่งตัว
        MedicalInfomation: '' //แนบข้อมูลทางการแพทย์ของผู้ป่วย
    })

    useEffect(() => {
        console.log(location.state)
        
        findHospital()
        findReferBackSummary()
    },[])

    if (!location.state) {
        location.state = { button: '1' }
    }

    const handleButton = (number) => {
        navigate('/sentResiveBackEdit', { state: { button: number } })
    }

    const inputFileChange = async (event) => {
        console.log(event.target.files[0])
        let fileObj = event.target.files && event.target.files[0]

        let formData = new FormData()
        formData.append('file', fileObj)

        setLoading(true)
        let res = await api.uploads(formData)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                // console.log(res['data']['data']['url'])
                urlAttachFile(res['data']['data']['url'])
            } else {
                notifyInfo(res['data']['message_th'] || res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const urlAttachFile = (url) => {
        let set = new Set(attachFile);
        let arr = Array.from(set);
        arr.push({ FileURL: url, Remark: '' });
        setAttachFile(arr)
    }

    const handleChangeRemark = (event, item, index) => {
        // console.log(event)
        const e = event
        const newAttachFile = attachFile.map((i, idx) => {
            if (idx === index) {
                return { ...i, Remark: e.target.value }
            }
            return i
        })
        setAttachFile(newAttachFile)
    }

    const clickAttachData = (i) => {
        setData({ ...data, ['MedicalInfomation']: {} })
        setMedicalInfomationType(i)
    }

    const clickOpenAttachData = () => {
        if (medicalInfomationType === 'OPD') {
            setOpenModalOPD(true)
        } else {
            setOpenModalIPD(true)
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const findReferBackSummary = async () => {
        console.log('refer data')

        setLoading(true)
        let res = await apiResive.findReferbackSummary(location.state.refer_no)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                console.log(res['data']['data'])

                setData(res['data']['data'])
                setMedicalInfomationType(res['data']['data']['MedicalInfomationType'])
                setAttachFile(res['data']['data']['AttachmentFile'])

                setRemarkNote1(res['data']['data']['RemarkNote1'])
                setRemarkNote2(res['data']['data']['RemarkNote2'])
                setRemarkNote3(res['data']['data']['RemarkNote3'])
                setRemarkNote4(res['data']['data']['RemarkNote4'])

            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const findHospital = async () => {
        setLoading(true)
        let res = await api.findHospital()
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        option.push({
                            key: res['data']['data'][i]['hoscode'],
                            label: res['data']['data'][i]['hosname']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                // console.log(unique)
                setOptionHospital(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOpenCalendar = (t) => {
        setTypeDate(t)
        setOpenCalendar(true)
    }

    const resiveTypeName = (id) => {
        for (let i = 0; i < type_sent.length; i++) {
            if (type_sent[i]['key'] === id) {
                return type_sent[i]['value']
            }
        }
    }

    const clickSubmit = () => {
        // //tab 1
        // if (!data['hn']) {
        //     notifyError('กรุณากรอกHN/VN - AN')
        //     return
        // }
        // if (!data['Name']) {
        //     notifyError('กรุณากรอกชื่อ-นามสกุล')
        //     return
        // }
        // if (!data['cid']) {
        //     notifyError('กรุณากรอกเลขบัตรประจำตัวประชาชน')
        //     return
        // }
        // if (!data['sex']) {
        //     notifyError('กรุณาเลือกเพศ')
        //     return
        // }
        // if (!data['ageY']) {
        //     notifyError('กรุณากรอกอายุ')
        //     return
        // }
        // if (!data['MedicalMainTerm']) {
        //     notifyError('กรุณากรอกสิทธิการรักษาหลัก')
        //     return
        // }
        // if (!data['MedicalSubTerm']) {
        //     notifyError('กรุณากรอกสิทธิการรักษารอง')
        //     return
        // }
        // if (!data['Address']) {
        //     notifyError('กรุณากรอกที่อยู่')
        //     return
        // }
        // if (!data['DrugAllergy']) {
        //     notifyError('กรุณากรอกการแพ้ยา')
        //     return
        // }
        // if (!data['MedicalBloodThinner']) {
        //     notifyError('กรุณากรอกการได้รับยาละลายลิ่มเลือด')
        //     return
        // }
        // if (!data['ReferCodeType']) {
        //     notifyError('กรุณาเลือกประเภทการส่งต่อ')
        //     return
        // }
        // if (!data['ReferTypeProvince']) {
        //     notifyError('กรุณาเลือกประเภทการส่งต่อ')
        //     return
        // }
        // if (!data['FromHospitalPhone']) {
        //     notifyError('กรุณากรอกเบอร์โทรศัพท์โรงพยาบาลต้นทาง')
        //     return
        // }
        // if (!data['ToHospitalPhone']) {
        //     notifyError('กรุณากรอกเบอร์โทรศัพท์โรงพยาบาลปลายทาง')
        //     return
        // }

        // //tab 2
        // if (!data['ScreenNote1']) {
        //     notifyInfo('กรุณาเลือกการให้การดูแลผู้ป่วย ขณะส่งต่อ')
        //     return
        // }
        // if (!data['ScreenNote2']) {
        //     notifyInfo('กรุณาเลือกประเภทผู้ป่วย')
        //     return
        // }
        // if (!data['ScreenNote3']) {
        //     notifyInfo('กรุณาเลือกระดับความเร่งด่วน')
        //     return
        // }
        // if (!data['ScreenNote4']) {
        //     notifyInfo('กรุณาเลือกประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ')
        //     return
        // }
        // if (!data['ScreenNote5']) {
        //     notifyInfo('กรุณาเลือกสาเหตุการส่งต่อผู้ป่วย')
        //     return
        // }
        // if (!data['NearbyPlace']) {
        //     notifyInfo('กรุณากรอกสถานที่ใกล้เคียงคือ')
        //     return
        // }
        // if (!data['ReferBy']) {
        //     notifyInfo('กรุณาเลือกเหตุผล')
        //     return
        // }

        //tab 3
        if (!data['iReferTime']) {
            setData({ ...data, ['iReferTime']: dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm')) })
        }
        if (!data['Department']) {
            notifyInfo('กรุณากรอกแผนก')
            return
        }
        if (!data['DoctorName']) {
            notifyInfo('กรุณากรอกแพทย์ผู้สั่ง')
            return
        }
        if (!data['Station']) {
            notifyInfo('กรุณากรอกจุดส่งต่อ')
            return
        }
        if (!data['Station']) {
            notifyInfo('กรุณากรอกจุดส่งต่อ')
            return
        }
        if (!data['Room']) {
            notifyInfo('กรุณากรอกห้อง')
            return
        }
        if (!data['StaffName']) {
            notifyInfo('กรุณากรอกผู้บันทึกใบส่งตัว')
            return
        }
        if (!RemarkNote1) {
            notifyInfo('กรุณากรอกผลการชันสูตรที่สำคัญ')
            return
        }
        if (!RemarkNote2) {
            notifyInfo('กรุณากรอกการวินิจฉัยโรคขั้นสุดท้าย')
            return
        }
        if (!RemarkNote3) {
            notifyInfo('กรุณากรอกการรักษาที่ให้')
            return
        }
        if (!RemarkNote4) {
            notifyInfo('กรุณากรอกการวินิจฉัยโรคขั้นต้น')
            return
        }
        if (!data['ReferNumber']) {
            notifyInfo('กรุณากรอกขอให้ดำเนินการต่อ')
            return
        }

        if (!data['ReferDate']) {
            data['ReferDate'] = moment(new Date()).format('YYYY-MM-DD')
        } else {
            if (typeof data['ReferDate'] === 'string') {
                data['ReferDate'] = data['ReferDate']
            } else {
                data['ReferDate'] = moment(data['ReferDate']['$d']).format('YYYY-MM-DD')
            }
        }

        if (!data['ExpireDate']) {
            data['ExpireDate'] = moment(new Date()).format('YYYY-MM-DD')
        } else {
            if (typeof data['ExpireDate'] === 'string') {
                data['ExpireDate'] = data['ExpireDate']
            } else {
                data['ExpireDate'] = moment(data['ExpireDate']['$d']).format('YYYY-MM-DD')
            }
        }

        if (!data['BirthDay']) {
            data['BirthDay'] = ''
        } else {
            if (typeof data['BirthDay'] === 'string') {
                data['BirthDay'] = data['BirthDay']
            } else {
                data['BirthDay'] = moment(data['BirthDay']['$d']).format('YYYY-MM-DD')
            }
        }

        const information = {
            "ReferTypeCode": data['ReferCodeType'],
            "ReferTypeName": data['ReferNameType'] ? data['ReferNameType'] : resiveTypeName(data['ReferCodeType']),
            "ReferTypeProvince": data['ReferTypeProvince'],
            "ToHospitalCode": data['ToHospitalCode'],
            "ToHospitalName": data['ToHospitalName'],
            "ToHospitalPhone": data['ToHospitalPhone'],
            "FromHospitalCode": FromHospitalCode,
            "FromHospitalName": FromHospitalName,
            "FromHospitalPhone": data['FromHospitalPhone'],
            "HN": data['hn'],
            "Name": data['Name'],
            "CID": data['cid'],
            "Gender": data['sex'],
            "BirthDay": data['BirthDay'],
            "Age": data['ageY'],
            "MedicalMainTerm": data['MedicalMainTerm'],
            "MedicalSubTerm": data['MedicalSubTerm'],
            "MedicalBloodThinner": data['MedicalBloodThinner'],
            "Address": data['Address'],
            "DrugAllergy": data['DrugAllergy'],
            "ScreenNote1": data['ScreenNote1'],
            "ScreenNoteOther1": data['ScreenNoteOther1'],
            "ScreenNote2": data['ScreenNote2'],
            "ScreenNote3": data['ScreenNote3'],
            "ScreenNote4": data['ScreenNote4'],
            "ScreenNoteOther4": data['ScreenNoteOther4'],
            "ScreenNote5": data['ScreenNote5'],
            "NearbyPlace": data['NearbyPlace'],
            "ReferBy": data['ReferBy'],
            "ReferOutNumber": data['ReferOutNumber'],
            "ReferNumber": data['ReferNumber'],
            "ReferDate": data['ReferDate'],
            "ReferTime": moment(data['iReferTime']).format('HH:mm'),
            "ReferDateExpire": data['ExpireDate'],
            "Department": data['Department'],
            "DoctorName": data['DoctorName'],
            "Station": data['Station'],
            "Room": data['Room'],
            "StaffName": data['StaffName'],
            "RemarkNote1": RemarkNote1,
            "RemarkNote2": RemarkNote2,
            "RemarkNote3": RemarkNote3,
            "RemarkNote4": RemarkNote4,
            "MedicalInfomationType": medicalInfomationType,
            "MedicalInfomation": data['MedicalInfomation'],
            "AttachmentFile": attachFile
        }
        console.log(information)
        editReferBack(information)
    }

    const editReferBack = async (information) => {
        console.log(information)

        let formData = new FormData()
        formData.append('payload', JSON.stringify(information))

        setLoading(true)
        let res = await apiResive.editReferBack(formData)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess(res['data']['message_th'] || res['data']['message'])
                navigate('/resive')
            }
            else {
                notifyInfo(res['data']['message_th'] || res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const deleteItemAttach = (item) => {
        const newAttachFile = attachFile.filter(i => i !== item)
        setAttachFile(newAttachFile)
    }

    const clickOpenData = (item) => {
        if (medicalInfomationType === 'OPD') {
            setItemData(item)
            setOpenModalData(true)
        } else {
            setItemData(item)
            setOpenModalDataIpd(true)
        }

    }

    const calculateAge = (birth) => {
        var today = new Date()
        var birthDate = new Date(birth)
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log(age_now)
        setData({ ...data, ['ageY']: age_now })
    }

    const handleNextPageTwo = () => {
        if (!data['hn']) {
            notifyInfo('กรุณากรอกHN/VN - AN')
            return
        }
        if (!data['Name']) {
            notifyInfo('กรุณากรอกชื่อ-นามสกุล')
            return
        }
        if (!data['cid']) {
            notifyInfo('กรุณากรอกเลขบัตรประจำตัวประชาชน')
            return
        }
        if (!data['sex']) {
            notifyInfo('กรุณาเลือกเพศ')
            return
        }
        if (!data['BirthDay']) {
            notifyInfo('กรุณาเลือกวันเกิด')
            return
        }
        if (!data['ageY']) {
            notifyInfo('กรุณากรอกอายุ')
            return
        }
        if (!data['MedicalMainTerm']) {
            notifyInfo('กรุณากรอกสิทธิการรักษาหลัก')
            return
        }
        if (!data['MedicalSubTerm']) {
            notifyInfo('กรุณากรอกสิทธิการรักษารอง')
            return
        }
        if (!data['Address']) {
            notifyInfo('กรุณากรอกที่อยู่')
            return
        }
        if (!data['DrugAllergy']) {
            notifyInfo('กรุณากรอกการแพ้ยา')
            return
        }
        if (!data['MedicalBloodThinner']) {
            notifyInfo('กรุณากรอกการได้รับยาละลายลิ่มเลือด')
            return
        }
        if (!data['ReferCodeType']) {
            notifyInfo('กรุณาเลือกประเภทการส่งต่อ')
            return
        }
        if (!data['ReferTypeProvince']) {
            notifyInfo('กรุณาเลือกประเภทการส่งต่อ')
            return
        }
        if (!data['FromHospitalPhone']) {
            notifyInfo('กรุณากรอกเบอร์โทรศัพท์โรงพยาบาลต้นทาง')
            return
        }
        if (!data['ToHospitalPhone']) {
            notifyInfo('กรุณากรอกเบอร์โทรศัพท์โรงพยาบาลปลายทาง')
            return
        }
        // if (!data['ReferNumber']) {
        //     setData({ ...data, ['ReferNumber']: 'RE' + FromHospitalCode + '-' + (Math.floor(100000 + Math.random() * 900000)) })
        // }
        handleButton('2')
    }

    const handleNextPageThree = () => {
        if (!data['ScreenNote1']) {
            notifyInfo('กรุณาเลือกการให้การดูแลผู้ป่วย ขณะส่งต่อ')
            return
        }
        if (!data['ScreenNote2']) {
            notifyInfo('กรุณาเลือกประเภทผู้ป่วย')
            return
        }
        if (!data['ScreenNote3']) {
            notifyInfo('กรุณาเลือกระดับความเร่งด่วน')
            return
        }
        if (!data['ScreenNote4']) {
            notifyInfo('กรุณาเลือกประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ')
            return
        }
        if (!data['ScreenNote5']) {
            notifyInfo('กรุณาเลือกสาเหตุการส่งต่อผู้ป่วย')
            return
        }
        if (!data['NearbyPlace']) {
            notifyInfo('กรุณากรอกสถานที่ใกล้เคียงคือ')
            return
        }
        if (!data['ReferBy']) {
            notifyInfo('กรุณาเลือกเหตุผล')
            return
        }

        handleButton('3')
    }


    return (
        <Box {...data} inputProps={{ readonly: true }}>
            <LoaderAnimation loading={loading} />
            {openCalendar &&
                <ModalCalendar
                    onFinished={(params) => {
                        if (typeDate === 'birth') {
                            calculateAge(params)
                        }
                    }}
                    onClose={() => { setOpenCalendar(false) }}
                    value={
                        typeDate === 'expire' ?
                            data['ExpireDate'] ? dayjs(moment(data['ExpireDate']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                            :
                            typeDate === 'resive' ?
                                data['ReferDate'] ? dayjs(moment(data['ReferDate']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                                :
                                data['BirthDay'] ? dayjs(moment(data['BirthDay']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                    }
                    onChange={(e) => {
                        typeDate === 'expire' ?
                            setData({ ...data, ['ExpireDate']: e })
                            :
                            typeDate === 'resive' ?
                                setData({ ...data, ['ReferDate']: e })
                                :
                                setData({ ...data, ['BirthDay']: e })
                    }}
                    disableDay={disabledDay}
                />
            }

            {openModalOPD ?
                <ModalAddReferOPD
                    pid={data['cid']}
                    onSubmit={(detail) => {
                        let inform = []
                        for (let i = 0; i < detail.length; i++) {
                            inform.push(detail[i])
                        }
                        setData({ ...data, ['MedicalInfomation']: inform })
                    }}
                    closeModal={() => setOpenModalOPD(false)}
                /> : null
            }

            {openModalIPD ?
                <ModalAddReferIPD
                    pid={data['cid']}
                    onSubmit={(detail) => {
                        let inform = []
                        for (let i = 0; i < detail.length; i++) {
                            inform.push(detail[i])
                        }
                        setData({ ...data, ['MedicalInfomation']: inform })
                    }}
                    closeModal={() => setOpenModalIPD(false)}
                />
                : null
            }

            {openModalData ?
                <ModalOpenData
                    data={itemData}
                    closeModal={() => setOpenModalData(false)}
                />
                : null
            }

            {openModalDataIpd ?
                <ModalOpenDataIPD
                    data={itemData}
                    closeModal={() => setOpenModalDataIpd(false)}
                />
                : null
            }

            <PageHeader title_name="กลับไปหน้ารายชื่อทั้งหมด" back_path='/resive' hide_date />
            <Typography sx={{
                textAlign: 'center',
                fontSize: '22px',
                '@media (max-width: 800px)': {
                    fontSize: '18px',
                }
            }}>เพิ่มข้อมูลการส่งต่อ</Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gridGap: '5px',
                margin: '20px',
                '@media (max-width: 1024px)': {
                }
            }}>
                <Box sx={location.state.button === '1' ? ButtonContained : ButtonOutlined}>1</Box>
                <Box sx={location.state.button === '2' ? ButtonContained : ButtonOutlined}>2</Box>
                <Box sx={location.state.button === '3' ? ButtonContained : ButtonOutlined}>3</Box>
            </Box>

            {location.state.button === '1' ?
                <Box>
                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Typography>ข้อมูลส่วนตัว</Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    HN/VN - AN
                                </Typography>
                                <TextField
                                    placeholder="HN/VN - AN"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['hn']}
                                    name={'hn'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    ชื่อ-นามสกุล
                                </Typography>
                                <TextField
                                    placeholder="ชื่อ-นามสกุล"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['Name']}
                                    name={'Name'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เลขบัตรประจำตัวประชาชน
                                </Typography>
                                <TextField
                                    placeholder="เลขบัตรประจำตัวประชาชน"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['cid']}
                                    name={'cid'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เพศ
                                </Typography>
                                <Select
                                    size='small'
                                    fullWidth
                                    value={data['sex'] || 'null'}
                                    name={'sex'}
                                    onChange={handleChange}
                                >
                                    <MenuItem value='null' disabled>เลือกเพศ</MenuItem>
                                    <MenuItem value='ชาย'>ชาย</MenuItem>
                                    <MenuItem value='หญิง'>หญิง</MenuItem>
                                </Select>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    วันเกิด
                                </Typography>
                                <TextField
                                    placeholder="วันเกิด"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    name={'BirthDay'}
                                    value={
                                        data['BirthDay'] === null || data['BirthDay'] === '' ?
                                            '' : convertDate(data['BirthDay'])
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton
                                                    onClick={() => {
                                                        setTypeDate('birthday')
                                                        handleOpenCalendar('birth')
                                                    }}>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    อายุ
                                </Typography>
                                <TextField
                                    type='number'
                                    placeholder="อายุ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['ageY']}
                                    name={'ageY'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    สิทธิการรักษาหลัก
                                </Typography>
                                <TextField
                                    placeholder="สิทธิการรักษาหลัก"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['MedicalMainTerm']}
                                    name={'MedicalMainTerm'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    สิทธิการรักษารอง
                                </Typography>
                                <TextField
                                    placeholder="สิทธิการรักษารอง"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['MedicalSubTerm']}
                                    name={'MedicalSubTerm'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    การได้รับยาละลายลิ่มเลือด
                                </Typography>
                                <TextField
                                    placeholder="การได้รับยาละลายลิ่มเลือด"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['MedicalBloodThinner']}
                                    name={'MedicalBloodThinner'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} sx={6}>
                                <Typography component='p' variant='p' color='primary'>
                                    ที่อยู่
                                </Typography>
                                <TextField
                                    placeholder="ที่อยู่"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['Address']}
                                    name={'Address'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} sx={6}>
                                <Typography component='p' variant='p' color='primary'>
                                    การแพ้ยา
                                </Typography>
                                <TextField
                                    placeholder="การแพ้ยา"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['DrugAllergy']}
                                    name={'DrugAllergy'}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6}>
                                <Typography component='p' variant='p' color='primary'>
                                    ประเภทการส่งต่อ
                                </Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                                    '@media (max-width: 800px)': {
                                        gridTemplateColumns: '1fr 1fr 1fr'
                                    }
                                }}>
                                    {
                                        type_sent.map((item, index) => (
                                            <label className={styles.container} key={index}>
                                                <input
                                                    type="radio"
                                                    name={'ReferCodeType'}
                                                    value={data['ReferCodeType']}
                                                    checked={item['key'] === data['ReferCodeType']}
                                                    onChange={() => {
                                                        setData({ ...data, ['ReferCodeType']: item['key'], ['ReferNameType']: item['value'] })
                                                    }}
                                                /><span className={styles.checkmark}></span>{item['value']}
                                            </label>
                                        ))
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6}>
                                <Typography component='p' variant='p' color='primary'>
                                    ประเภทการส่งต่อ
                                </Typography>
                                <Box sx={{ display: 'flex' }}>
                                    {
                                        type_sent2.map((item, index) => (
                                            <label className={styles.container} key={index}>
                                                <input
                                                    type="radio"
                                                    name={'ReferTypeProvince'}
                                                    value={data['ReferTypeProvince']}
                                                    checked={item === data['ReferTypeProvince']}
                                                    onChange={() => {
                                                        // console.log(item)
                                                        setData({ ...data, ['ReferTypeProvince']: item })
                                                    }}
                                                /><span className={styles.checkmark}></span>{item}
                                            </label>
                                        ))
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                <Typography component='p' variant='p' color='primary'>
                                    จากโรงพยาบาลต้นทาง
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={FromHospitalName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                <Typography component='p' variant='p' color='primary'>
                                    ถึงโรงพยาบาล
                                </Typography>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        size='small'
                                        options={optionHospital}
                                        value={data['ToHospitalName']}
                                        name={'ToHospitalCode'}
                                        onChange={(e, value) => {
                                            setData({ ...data, ['ToHospitalCode']: value?.['key'], ['ToHospitalName']: value?.['label'] })
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                InputProps={{ ...params.InputProps }}
                                                placeholder="เลือกโรงพยาบาลที่ต้องการส่งต่อผู้ป่วย"
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                <Typography component='p' variant='p' color='primary'>เบอร์โทรศัพท์โรงพยาบาลต้นทาง</Typography>
                                <TextField
                                    placeholder="ระบุ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['FromHospitalPhone']}
                                    name={'FromHospitalPhone'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                <Typography component='p' variant='p' color='primary'>เบอร์โทรศัพท์โรงพยาบาลปลายทาง</Typography>
                                <TextField
                                    placeholder="ระบุ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['ToHospitalPhone']}
                                    name={'ToHospitalPhone'}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gridGap: '10px',
                        margin: '20px 0',
                        '@media (max-width: 800px)': {
                            justifyContent: 'center',
                        }
                    }}>
                        <Button variant='outlined' onClick={() => { navigate('/resive') }}>ยกเลิก</Button>
                        <Button variant='contained' onClick={() => { handleNextPageTwo() }}>ถัดไป</Button>
                    </Box>
                </Box>
                : null
            }

            {location.state.button === '2' ?
                <Box>
                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>การให้การดูแลผู้ป่วย ขณะส่งต่อ</Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 900px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}>
                                    {type_care.map((item, index) => {
                                        return item === 'อื่นๆ' ?
                                            (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className={styles.container}>
                                                        <input

                                                            type="radio"
                                                            name={'ScreenNote1'}
                                                            value={data['ScreenNote1']}
                                                            checked={item === data['ScreenNote1']}
                                                            onChange={() => {
                                                                setData({ ...data, ['ScreenNote1']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                    <TextField
                                                        disabled={data['ScreenNote1'] !== item}
                                                        placeholder={'ระบุ'}
                                                        variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        value={data['ScreenNoteOther']}
                                                        onChange={(e) => {
                                                            if (data['ScreenNote1'] === 'อื่นๆ') {
                                                                setData({ ...data, ['ScreenNoteOther1']: e.target.value })
                                                            } else {
                                                                setData({ ...data, ['ScreenNoteOther1']: '' })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} >
                                                    <label className={styles.container}>
                                                        <input

                                                            type="radio"
                                                            name={'ScreenNote1'}
                                                            value={data['ScreenNote1']}
                                                            checked={item === data['ScreenNote1']}
                                                            onChange={() => {
                                                                console.log(item)
                                                                setData({ ...data, ['ScreenNote1']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                </div>
                                            )
                                    })}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>ประเภทผู้ป่วย</Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 900px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}>
                                    {type_patient.map((item, index) => (
                                        <label className={styles.container} key={index}>
                                            <input

                                                type="radio"
                                                name={'ScreenNote2'}
                                                value={data['ScreenNote2']}
                                                checked={item === data['ScreenNote2']}
                                                onChange={() => {
                                                    console.log(item)
                                                    setData({ ...data, ['ScreenNote2']: item })
                                                }}
                                            /><span className={styles.checkmark}></span>{item}
                                        </label>
                                    ))}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>ระดับความเร่งด่วน</Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 900px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}>
                                    {type_level_urgency.map((item, index) => (
                                        <label className={styles.container} key={index}>
                                            <input

                                                type="radio"
                                                name={'ScreenNote3'}
                                                value={data['ScreenNote3']}
                                                checked={item === data['ScreenNote3']}
                                                onChange={() => {
                                                    console.log(item)
                                                    setData({ ...data, ['ScreenNote3']: item })
                                                }}
                                            /><span className={styles.checkmark}></span>{item}
                                        </label>
                                    ))}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ</Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 900px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}>
                                    {type_specific.map((item, index) => {
                                        return item === 'อื่นๆ' ?
                                            (
                                                <div key={index} style={{ display: 'flex' }}>
                                                    <label className={styles.container}>
                                                        <input

                                                            type="radio"
                                                            name={'ScreenNote4'}
                                                            value={data['ScreenNote4']}
                                                            checked={item === data['ScreenNote4']}
                                                            onChange={() => {
                                                                console.log(item)
                                                                setData({ ...data, ['ScreenNote4']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                    <TextField
                                                        disabled={data['ScreenNote4'] !== item}
                                                        placeholder={'ระบุ'}
                                                        variant="outlined"
                                                        fullWidth
                                                        size='small'
                                                        value={data['ScreenNoteOther4']}
                                                        onChange={(e) => {
                                                            if (data['ScreenNote4'] === 'อื่นๆ') {
                                                                setData({ ...data, ['ScreenNoteOther4']: e.target.value })
                                                            } else {
                                                                setData({ ...data, ['ScreenNoteOther4']: '' })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                <div key={index} >
                                                    <label className={styles.container} key={index}>
                                                        <input

                                                            type="radio"
                                                            name={'ScreenNote4'}
                                                            value={data['ScreenNote4']}
                                                            checked={item === data['ScreenNote4']}
                                                            onChange={() => {
                                                                console.log(item)
                                                                setData({ ...data, ['ScreenNote4']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                </div>
                                            )
                                    })}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>สาเหตุการส่งต่อผู้ป่วย</Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 900px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}>
                                    {type_cause.map((item, index) => (
                                        <label className={styles.container} key={index}>
                                            <input

                                                type="radio"
                                                name={'ScreenNote5'}
                                                value={data['ScreenNote5']}
                                                checked={item === data['ScreenNote5']}
                                                onChange={() => {
                                                    console.log(item)
                                                    setData({ ...data, ['ScreenNote5']: item })
                                                }}
                                            /><span className={styles.checkmark}></span>{item}
                                        </label>
                                    ))}
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>
                                    สถานที่ใกล้เคียงคือ
                                </Typography>
                                <TextField
                                    placeholder="กรุณาระบุสถานที่้ใกล้เคียง"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['NearbyPlace']}
                                    name={'NearbyPlace'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} sx={8}>
                                <Typography component='p' variant='p' color='primary'>
                                    มาเพื่อโปรด
                                </Typography>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    '@media (max-width: 800px)': {
                                        gridTemplateColumns: '1fr 1fr'
                                    }
                                }}
                                >
                                    {
                                        type_treat.map((item, index) => (
                                            <label className={styles.container} key={index}>
                                                <input

                                                    type="radio"
                                                    name={'ReferBy'}
                                                    value={data['ReferBy']}
                                                    checked={item === data['ReferBy']}
                                                    onChange={() => {
                                                        console.log(item)
                                                        setData({ ...data, ['ReferBy']: item })
                                                    }}
                                                /><span className={styles.checkmark}></span>{item}
                                            </label>
                                        ))
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gridGap: '10px',
                        margin: '20px 0',
                        '@media (max-width: 800px)': {
                            justifyContent: 'center',
                        }
                    }}>
                        <Button variant='outlined' onClick={() => handleButton('1')}>ย้อนกลับ</Button>
                        <Button variant='contained' onClick={() => { handleNextPageThree() }}>ถัดไป</Button>
                    </Box>

                </Box>
                : null
            }

            {location.state.button === '3' ?
                <Box>
                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Typography>ข้อมูลการส่งต่อ</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    วันที่ส่งต่อ
                                </Typography>
                                <TextField
                                    placeholder="Default วันที่ปัจจุบัน"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    name={'ReferDate'}
                                    value={
                                        data['ReferDate'] === null || data['ReferDate'] === '' ?
                                            convertDate(dayjs(moment(new Date()).format('YYYY-MM-DD')))
                                            :
                                            convertDate(data['ReferDate'])
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton onClick={() => {
                                                    setTypeDate('resive')
                                                    handleOpenCalendar('resive')
                                                }}>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เวลาที่ส่งต่อ
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileTimePicker
                                        ampm={false}
                                        sx={{
                                            width: '100%',
                                            "& .MuiInputBase-input": {
                                                padding: '8.5px 14px'
                                            }
                                        }}
                                        value={
                                            data['iReferTime'] ?
                                                dayjs(moment(data['iReferTime'], 'H:mm').format('YYYY-MM-DDTHH:mm'))
                                                :
                                                dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm'))
                                        }
                                        onChange={(e) => {
                                            setData({ ...data, ['iReferTime']: e })
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                sx={{ padding: '20px' }}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เลขที่ส่งต่อจากโรงพยาบาลต้นทาง
                                </Typography>
                                <TextField
                                    placeholder="เลขที่ส่งต่อจากโรงพยาบาลต้นทาง"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['ReferOutNumber']}
                                    name={'ReferOutNumber'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เลขที่ส่งต่อ
                                </Typography>
                                <TextField
                                    placeholder="เลขที่ส่งต่อ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['ReferNumber']}
                                    name={'ReferNumber'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    วันที่หมดอายุ
                                </Typography>
                                <TextField
                                    placeholder="วันที่หมดอายุ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    name={'ExpireDate'}
                                    value={data['ExpireDate'] ?
                                        data['ExpireDate'] === null || data['ExpireDate'] === '' ?
                                            convertDate(dayjs(moment(new Date()).format('YYYY-MM-DD')))
                                            :
                                            convertDate(data['ExpireDate'])
                                        :
                                        ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton onClick={() => {
                                                    setTypeDate('expire')
                                                    handleOpenCalendar('expire')
                                                }}>
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    แผนก
                                </Typography>
                                <TextField
                                    placeholder="แผนก"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['Department']}
                                    name={'Department'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    แพทย์ผู้สั่ง
                                </Typography>
                                <TextField
                                    placeholder="ชื่อ - นามสกุล แพทย์"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['DoctorName']}
                                    name={'DoctorName'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    จุดส่งต่อ
                                </Typography>
                                <TextField
                                    placeholder="จุดส่งต่อ"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['Station']}
                                    name={'Station'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    ห้อง
                                </Typography>
                                <TextField
                                    placeholder="ห้อง"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['Room']}
                                    name={'Room'}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    ผู้บันทึกใบส่งตัว
                                </Typography>
                                <TextField
                                    placeholder="ชื่อ - นามสกุล"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['StaffName']}
                                    name={'StaffName'}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>
                                    ผลการชันสูตรที่สำคัญ
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={RemarkNote1}
                                    onChange={(e) => {
                                        setRemarkNote1(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>
                                    การวินิจฉัยโรคขั้นสุดท้าย
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={RemarkNote2}
                                    onChange={(e) => {
                                        setRemarkNote2(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>
                                    การรักษาที่ให้
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={RemarkNote3}
                                    onChange={(e) => {
                                        setRemarkNote3(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component='p' variant='p' color='primary'>
                                    ขอให้ดำเนินการต่อ ดังนี้
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={RemarkNote4}
                                    onChange={(e) => {
                                        setRemarkNote4(e.target.value)
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>

                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={12} >
                                <Typography component='p' variant='p' color='primary'>
                                    แนบข้อมูลทางการแพทย์ของผู้ป่วย
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'start', gridGap: '1rem' }}>
                                    {type_file.map((item, index) => (
                                        <label className={styles.container} key={index}>
                                            <input
                                                type="radio"
                                                checked={item === medicalInfomationType}
                                                onChange={() => clickAttachData(item)}
                                            /><span className={styles.checkmark}></span>{item}
                                        </label>
                                    ))}
                                </Box>
                                <Button
                                    disabled={medicalInfomationType === null ? true : null}
                                    variant="contained"
                                    onClick={() => { clickOpenAttachData() }}
                                >เลือกข้อมูล</Button>
                                <Box sx={{ margin: '10px 20px 0 20px', width: '100%' }}>
                                    {data['MedicalInfomation'].length > 0 ?
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gridGap: 10 }}>
                                            {data['MedicalInfomation'].map((i, idx) => (
                                                <Box
                                                    sx={{
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            textDecoration: 'underline'
                                                        },
                                                    }}
                                                    key={idx}
                                                    onClick={() => { clickOpenData(i) }}
                                                >
                                                    {convertDate(i['date'])}
                                                </Box>
                                            ))}
                                        </Box>
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ ...content, marginTop: '1rem' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gridGap: '10px' }}>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        inputFileRef.current.click()
                                    }}
                                >แนบไฟล์</Button>
                                <Box sx={{ color: '#000000' }}>.PDF/.PNG/.JPG/.MP4</Box>
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    type={'file'}
                                    accept='image/png,image/jpeg,application/pdf,video/mp4'
                                    ref={inputFileRef}
                                    onChange={(e) => {
                                        inputFileChange(e)
                                    }}
                                    style={{ display: 'none' }}>
                                </input>
                                {attachFile ?
                                    <>
                                        {attachFile.map((i, idx) => (
                                            <Box key={idx}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    margin: '10px 0'
                                                }}>
                                                    <Box sx={{
                                                        border: 1,
                                                        borderColor: '#E1E3E9',
                                                        borderRadius: '10px',
                                                        padding: '5px 10px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => {
                                                            window.open(i.FileURL, '_system')
                                                        }}
                                                    >{i['FileURL']}</Box>
                                                    <CloseIcon onClick={() => { deleteItemAttach(i, idx) }} />
                                                </Box>
                                                <TextField
                                                    placeholder="รายละเอียดเอกสาร"
                                                    variant="outlined"
                                                    size='small'
                                                    fullWidth
                                                    value={i['Remark']}
                                                    onChange={(e) => { handleChangeRemark(e, i, idx) }}
                                                />
                                            </Box>
                                        ))}
                                    </>
                                    : null
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gridGap: '10px',
                        margin: '20px 0',
                        '@media (max-width: 800px)': {
                            justifyContent: 'center',
                        }
                    }}>
                        <Button variant='outlined' onClick={() => handleButton('2')}>ย้อนกลับ</Button>
                        <Button variant='contained' onClick={() => { clickSubmit() }}>บันทึก</Button>
                    </Box>

                </Box>
                : null
            }
        </Box >
    )
}