import { Button, Box, TextField,Typography,Grid,FormControl,Select,MenuItem,Radio } from "@mui/material"
import React from 'react'
import {contentProfile,boxProfile, buttonActive, buttomPrimary,buttomSecondary} from './ProfileCss'
import profileUser from '../../assets/icons/profile-user.svg'
import { useNavigate } from "react-router-dom"

function RegisterMastodon() {
    const navigate = useNavigate()

    const formMastodon = [
        {
            label:'ชื่อ',
            name:'username'
        },
        {
            label:'อีเมล',
            name:'email'
        },
        {
            label:'รหัสผ่าน',
            name:'password'
        },
        {
            label:'ยืนยันรหัสผ่าน',
            name:'confirm_password',
        }
    ]

  return (
    <Box sx={contentProfile}>
        <Box sx={boxProfile}>
            <Box sx={{textAlign:'center',marginBottom:'2rem'}}>
                <img src={profileUser} alt=''/>
            </Box>
            <Box>
                <Typography variant="p" color='primary' sx={{fontSize:'18px',fontWeight:'bold',margin:'1.5rem 0'}}>
                    กรอกข้อมูลเพิ่มเติม
                </Typography>
                <hr style={{margin:'1.5rem 0',width:'100%',border: '0.5px solid #E1E3E9'}}/>
                <Grid container spacing={2}>
                    {
                        formMastodon.map((item,index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={4} md={4} xl={4}>
                                    <Typography 
                                        variant="p" 
                                        color='primary'
                                    >
                                        {item['label']}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} xl={8}>
                                    <TextField 
                                        placeholder={item['label']} 
                                        variant="outlined"
                                        size="small"
                                        name={item['name']}
                                        value={item['name'] === 'email' ? localStorage.getItem('email') : null}
                                        fullWidth 
                                        disabled={item['name'] === 'email' ? true : false}
                                    />
                                    {
                                        item['label'] === 'ยืนยันรหัสผ่าน' ?
                                        <Typography 
                                            component="p"
                                            variant="p" 
                                            color='secondary'
                                            sx={{marginTop:'0.5rem',fontSize: 'calc(11px + 0.25vw)'}}
                                        >
                                            หมายเหตุ: ใช้รหัสผ่านเดียวกันกับเว็บไซต์ RAMA SRI
                                        </Typography> : ''
                                    }
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                    <Grid item  xs={12}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'2rem'}}>
                            <Radio
                                // value={register['confirm_register']}
                                // onClick={() => setRegister({...register,['confirm_register']:true})}    
                            />
                            <Typography variant="p" color="secondary" sx={{fontSize: 'calc(11px + 0.25vw)'}}>ยอมรับข้อตกลง 
                                <Typography variant="span" color='primary' sx={{fontWeight:'600',borderBottom:'0.5px solid #006838',cursor:'pointer'}}>เงื่อนไขการใช้งาน</Typography> และ 
                                <Typography variant="span" color='primary' sx={{fontWeight:'600',borderBottom:'0.5px solid #006838',cursor:'pointer'}}>นโยบายความเป็นส่วนตัว</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{margin:'1.5rem 0',width:'100%',border: '0.5px solid #E1E3E9'}}/>
                <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem'}}>
                    <Button variant="outlined" onClick={() => navigate('/profile')}>ยกเลิก</Button>
                    <Button variant="contained">ลงทะเบียน mastodon</Button>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default RegisterMastodon