import React, { useEffect } from 'react'
import { Box, Grid, Pagination, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, Autocomplete } from '@mui/material'
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { InputAdornment, Button, FormControl, MenuItem, Select, Stack } from '@mui/material';
import ModalCalendar from '../../components/ModalController/ModalCalendar';
import VideocamIcon from '@mui/icons-material/Videocam';
import ModalSharedConference from '../../components/ModalController/ModalSharedConference';
import ModalConfinueAssignment from '../../components/ModalController/ModalConfinueAssignment';
import moment from 'moment';
import dayjs from 'dayjs';
import LoaderAnimation from '../../components/LoaderAnimation';
import DeleteIcon from '@mui/icons-material/Delete';
import { notifyError, notifyInfo, notifySuccess } from '../../components/NotifyToast';
import * as apiOutPatient from '../../services/OutPatient/ApiFilterPoint'
import * as apiRefer from '../../services/refer/ApiPublicRefer'
import * as ApiPublic from '../../services/public_api'

import { useLocation } from 'react-router'
import ModalContactConference from '../../components/ModalController/ModalContactConference';

function TakeHistory(props) {
  const { appoiontmentState } = props
  
  const [loading, setLoading] = useState(true)
  const routeState = useLocation()
  const [value, setValue] = useState(0)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [confirmModalConference, setComfirmModalConference] = useState(false)
  const [openModalAssignment, setOpenModalAssignment] = useState(false)
  const [dataPeople, setDataPeople] = useState('')
  const [totalData, setTotalData] = useState('')
  const [date, setDate] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))

  const [numPage, setNumPage] = useState(5)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [showList, setShowList] = useState([])
  const [channel, setChannel] = useState('R12Network-dashboard')

  const [disabledDay, setDisabledDay] = useState([])
  const [modalVideoCall, setModalVideoCall] = useState(false)
  const [pid, setPid] = useState('')

  const [forwardAppointment, setForwardAppointment] = useState({
    id: '',
    appoint_state: routeState.state['appointment_state']
  })

  const [hospital,setHospital] = useState([])
  const [idHospital,setIdHospital] = useState('')
  const [nameHospital,setNameHospital] = useState('')
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
        if (newValue === 1) {
          setChannel('R12Network-app')
        } else {
          setChannel('R12Network-dashboard')
        }
    };
  
    const openTreatmentHistory = async (pid) => {
      window.open(`/systemPatientInformation?PID=${pid}`)
    }
  
    useEffect(() => {
      if(localStorage.getItem('hospital_code') === 'N/A'){
        getHospital()
      }
      // console.log("====> ",routeState.state['appointment_state'])
    }, [])
  
    useEffect(() => {
      findAppointment()
    }, [value,idHospital,numPage])
  
    useEffect(() => {
      setPage(1)
    }, [numPage])
  
    useEffect(() => {
      let start = page * numPage - numPage
      setShowList(dataPeople.slice(start, start + numPage))
    }, [page, dataPeople])
  
    const handlePageChange = (event, page) => {
      setPage(page)
    }
  
    const findAppointment = async () => {
      let now = moment(date['$d']).format('YYYY-MM-DD')
  
      setLoading(true)
      let res = await apiOutPatient.findAppointment(now, channel, routeState.state['appointment_state'],idHospital)
      setLoading(false)
      if (res) {
        if (res['data']['code'] === 200) {
          setDataPeople(res['data']['data'])
          setTotalData(res['data']['total'])
          setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
        } else {
          setDataPeople([])
          setTotalData('')
        }
      } else {
        return notifyError('ไม่ตอบสนอง')
      }
    }
  
    const cancelAppointment = async (DocNumber) => {
      setLoading(true)
      let res = await apiOutPatient.cancelAppointment(DocNumber)
      setLoading(false)
      if (res) {
        if (res['data']['code'] === 200) {
          notifySuccess('ลบนัดหมายสำเร็จ')
          findAppointment()
        } else {
          console.log(res['data']['message'])
        }
      } else {
        return notifyError('ไม่ตอบสนอง')
      }
    }
  
    const convertDate = (dataDate) => {
      const date = new Date(dataDate)
      const optionDate = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
      const thaiLocale = 'th-TH';
      const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)
  
      const newDate = `${formattedDate}`
      return newDate
    }
  
    const deleteAppointment = (DocNumber) => {
      cancelAppointment(DocNumber)
      findAppointment()
    }
  
    const ForwardAppointmentState = async () => {
      setLoading(true)
      let res = await apiOutPatient.changeState(forwardAppointment['appoint_state'], forwardAppointment['id'])
      setLoading(false)
      if (res) {
        if (res['data']['code'] === 200) {
          notifySuccess(res['data']['message'])
          setOpenModalAssignment(false)
          findAppointment()
        } else {
          return notifyInfo(res['data']['message'])
        }
      } else {
        return notifyError('ไม่ตอบสนอง')
      }
    }
  
    const createVideoCall = (pid) => {
      console.log("pid create vedio ====> ", pid)
      setPid(pid)
      setModalVideoCall(true)
      // setComfirmModalConference(true)
    }

    const getHospital = async() => {
      setLoading(true)
      let res = await ApiPublic.findHospital()
      setLoading(false)
      if (res) {
        if (res['data']['code'] === 200) {
          // console.log(res)
          // setHospital(res['data']['data'])
          let option = []
          for (let i = 0; i < res['data']['data'].length; i++) {
              if (res['data']['data'][i]) {
                  option.push({
                  key: res['data']['data'][i]['HospitalCode'],
                  label: res['data']['data'][i]['HospitalName']
                  })
              }
              const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
              // console.log(unique)
              setHospital(unique)
          }
        } else {
          notifyInfo((res['data']['message']))
        }
      } else {
        notifyError('ไม่ตอบสนอง')
      }
    }

  return (
    <div>
    <LoaderAnimation loading={loading} />
    {openCalendar &&
      <ModalCalendar
        onClose={() => { setOpenCalendar(false) }}
        value={date ? dayjs(moment(date['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
        onChange={(e) => setDate(e)}
        disableDay={disabledDay}
        onFinished={(paramsState => {
          if (paramsState === paramsState) {
            findAppointment()
          }
        })}
      />}
    {openModalAssignment && <ModalConfinueAssignment
      dataState={appoiontmentState}
      form={forwardAppointment}
      submitForm={() => ForwardAppointmentState()}
      onClose={() => {
        setOpenModalAssignment(!openModalAssignment)
      }}
    />}

    {
      modalVideoCall ?
        <ModalContactConference
          onClose={() => { setModalVideoCall(false) }}
          pid={pid}
        /> : ''
    }
    <Grid container sx={{
      display: 'flex',
      justifyContent: localStorage.getItem('hospital_code') === 'N/A' ? 'space-between' : 'end',
      margin: '1rem 0'
    }}>
      {
        localStorage.getItem('hospital_code') === 'N/A' ?
        <Autocomplete
            size='small'
            disablePortal
            options={hospital}
            value={nameHospital}
            onChange={(e, value) => {
                // console.log(value)
                setIdHospital(value?.['key'])
                setNameHospital(value?.['label'])
            }}
            sx={{width:'250px'}}
            renderInput={(params) => <TextField {...params} placeholder="เลือกโรงพยาบาล" />}
        />: null
      }
      
      <TextField size='small'
        value={convertDate(date['$d'])}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => {
                setOpenCalendar(true)
              }}>
                <CalendarTodayIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

    </Grid>

    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
          <Tab sx={{ width: '50vw', fontWeight: 'bold' }} label="นัดหมายจากโรงพยาบาล" />
          <Tab sx={{ width: '50vw', fontWeight: 'bold' }} label="นัดหมายจากประชาชน" />
      </Tabs>


      <Box sx={{
        mx: 2,
        pb: 2,
        '@media (max-width: 1023px)': {

        },
        '@media (max-width: 800px)': {
          fontSize: 14
        }
      }}>

        <div style={{ color: '#747676', textAlign: 'end', margin: '2rem 0' }}>รายชื่อทั้งหมด {totalData}</div>
        <TableContainer>
          <Table sx={{ marginTop: 3, minWidth: '1200px' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}>#</TableCell>
                <TableCell sx={{ color: '#747676', fontWeight: 'bold' }}>รายชื่อ</TableCell>
                <TableCell sx={{ color: '#747676', fontWeight: 'bold' }}>โรงพยาบาล</TableCell>
                <TableCell sx={{ color: '#747676', fontWeight: 'bold' }}>แผนก</TableCell>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}>เวลา</TableCell>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}>ประวัติการแพทย์</TableCell>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}>วิดีโอคอล</TableCell>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                <TableCell sx={{ color: '#747676', textAlign: 'center', fontWeight: 'bold' }}>ลบนัดหมาย</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {
                  showList.length === 0 ?
                    (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          align='center'
                        >
                          <Typography
                            component={"p"}
                            sx={{ margin: '1rem 0' }}
                          >
                            ไม่พบข้อมูล {value === 0 ? "นัดหมายจากโรงพยาบาล" : "นัดหมายจากประชาชน"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) :
                    <>
                      {
                        showList && showList.map((i, idx) => (
                          <TableRow key={idx}>
                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{numPage * (page - 1) + 1 + idx}</TableCell>
                            <TableCell sx={{ color: '#747676' }}>{i['Name']}</TableCell>
                            <TableCell sx={{ color: '#747676' }}>{i['HospitalName']}</TableCell>
                            <TableCell sx={{ color: '#747676' }}>{i['DepartmentName']}</TableCell>
                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>{`${i['AppointTime']} น.`}</TableCell>
                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                              <Button
                                sx={{ borderRadius: 2, width: '100%', fontWeight: 'bold' }}
                                variant='outlined'
                                onClick={() => openTreatmentHistory(i['PID'])}
                              >ดูประวัติการแพทย์</Button>
                            </TableCell>
                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                              <div style={{
                                backgroundColor: '#E1F4EB',
                                padding: '0px 5px 0px 5px',
                                borderRadius: '9px'
                              }}>
                                <IconButton onClick={() => createVideoCall(i['PID'])}>
                                  <VideocamIcon color='primary' />
                                </IconButton>
                              </div>
                            </TableCell>
                            <TableCell sx={{ color: '#747676', textAlign: 'center' }}>
                              <Button
                                variant='contained'
                                sx={{ borderRadius: 2, color: '#FFFFFF', width: '100%' }}
                                onClick={() => {
                                  setOpenModalAssignment(true)
                                  setForwardAppointment({ ...forwardAppointment, ['id']: i['ID'] })
                                }}
                              >ส่งต่อ</Button>
                            </TableCell>
                            <TableCell align='center'>
                              <IconButton
                                onClick={() => { deleteAppointment(i['DocNumber']) }}
                              >
                                <DeleteIcon sx={{ color: '#006838', cursor: 'pointer' }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </>
                }
              </>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px 0px 0px 10px'
        }}>

          <Box sx={{ width: '100px' }}>
            <FormControl fullWidth>
              <Select
                value={numPage}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setNumPage(value)
                }}
                size='small'
                sx={{ width: '100px', fontSize: '14px' }} // Set width and font size here
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack spacing={2}>
            <Pagination
              count={maxPage}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              color="primary"
            />
          </Stack>
        </div>

      </Box>
    </Box>
  </div>
  )
}

export default TakeHistory