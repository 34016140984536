import React from 'react'
import { Typography,Box,Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import {backgroundModal,boxModal,buttomPrimary} from '../components/ModalController/ModalAccessCss'
import imgModal from '../assets/icons/icon_modal_user_active.svg'
import { useNavigate } from 'react-router-dom';

function ModalActiveUser({closeModal}) {
    const navigate = useNavigate()
  return (
    // <div>ModalActiveUser</div>
    <Box sx={backgroundModal}>
        <Box sx={{textAlign:'center',...boxModal}}>
            <Box sx={{marginBottom:'1rem'}}>
                <img src={imgModal} alt=''/>
            </Box>
            <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'calc(20px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>กรุณายืนยันตัวตน</Typography>  
            <Typography component="p" variant="p" color='secondary' sx={{fontSize:'calc(15px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>
                ยืนยันตัวตนกับแอดมินของทางโรงพยาบาลก่อนเข้าใช้งาน
            </Typography>  
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
                <Button 
                    onClick={closeModal}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    ตกลง
                </Button>            
            </Box>        
        </Box>
    </Box>
  )
}

export default ModalActiveUser