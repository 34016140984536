import React, { useState, useEffect } from 'react'
import { tabContained, tabOutlined } from '../Css'
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box, Button, Grid, TextField, Typography, InputAdornment, IconButton,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination,
    FormControl, InputLabel, Select, MenuItem, Stack
} from '@mui/material'
import ModalCalendar from '../../../components/ModalController/ModalCalendar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment-timezone';
import 'moment/locale/th';
import { content, contentTable, btnSecondary } from '../Css'
import dayjs from 'dayjs';

import AddIcon from '@mui/icons-material/Add';
import ModeIcon from '@mui/icons-material/Mode';
import ModalSentResive from '../../../components/ModalController/ModalSentResive';
import { useDispatch } from 'react-redux';
import { menuActive } from '../../../store/menuSlice';

import * as api from '../../../services/refer/ApiPublicRefer'
import * as apiRefer from '../../../services/refer/refer/ApiRefer'
import { notifyError, notifyInfo, notifySuccess } from '../../../components/NotifyToast';
import LoaderAnimation from '../../../components/LoaderAnimation'
import ReferOther from './ReferOther';

export default function Refer() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [buttonData, setButtonData] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [openCalendar, setOpenCalendar] = useState(false)
    const [date, setDate] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))

    const [dataAll, setDataAll] = useState([])
    const [disabledDay, setDisabledDay] = useState([])

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [namePatient, setNamePatient] = useState('')
    const [pid, setPid] = useState('')

    const [referNumber, setReferNumber] = useState('')

    useEffect(() => {
        findReferGroup()
    }, [])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(menuActive('ส่งต่อผู้ป่วย'))
    }, [])

    if (!location.state) {
        location.state = { button: '00' }
    }

    const handleButton = (type_tpe) => {
        navigate('/refer', { state: { button: type_tpe } })
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const findReferGroup = () => {
        const data = [
            {
                "TypeReferCode": "01",
                "TypeReferName": "รอจัดการ",
                "DocStatus": "W",
                "TypeReferRemark": "-"
            },
            {
                "TypeReferCode": "02",
                "TypeReferName": "รับเข้ารักษาแล้ว",
                "DocStatus": "W",
                "TypeReferRemark": "-"
            },
            // {
            //     "TypeReferCode": "03",
            //     "TypeReferName": "ปฎิเสธการรับรักษา",
            //     "DocStatus": "W",
            //     "TypeReferRemark": "-"
            // },
            // {
            //     "TypeReferCode": "04",
            //     "TypeReferName": "เสียชีวิต",
            //     "DocStatus": "W",
            //     "TypeReferRemark": "เสียชีวิตระหว่างการส่งต่อ"
            // },
            // {
            //     "TypeReferCode": "05",
            //     "TypeReferName": "ไม่พบผู้ป่วย",
            //     "DocStatus": "W",
            //     "TypeReferRemark": "ไม่พบผู้ป่วย"
            // },
            // {
            //     "TypeReferCode": "06",
            //     "TypeReferName": "ยกเลิกการส่งต่อ",
            //     "DocStatus": "W",
            //     "TypeReferRemark": "ยกเลิกการส่งต่อ โปรดระบุ"
            // },
            {
                "TypeReferCode": "99",
                "TypeReferName": "สำเร็จ",
                "DocStatus": "W",
                "TypeReferRemark": "-"
            },
            // {
            //     "TypeReferCode": "07",
            //     "TypeReferName": "อื่น ๆ",
            //     "DocStatus": "W",
            //     "TypeReferRemark": "อื่น ๆ โปรดระบุ"
            // }
        ]
        setButtonData(data)
    }

    useEffect(() => {
        setNamePatient('')
        setPid('')

        if (location.state.button !== '000') {
            if (location.state.button === '00') {
                findReferOut()
            } else {
                findReferOutGroup()
            }
        }

    }, [numPage, location.state.button])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(searchData().slice(start, start + numPage))
    }, [page, dataAll, namePatient, pid])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const searchData = () => {
        return dataAll.filter((item) =>
            (namePatient === '' || (item['Name']).includes(namePatient)) &&
            (pid === '' || item['cid'].includes(pid))
        )
    }

    const findReferOut = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')
        setLoading(true)
        let res = await apiRefer.findReferOut(dateNow, localStorage.getItem('hospital_code_'))
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataAll(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            } else {
                setDataAll([])
                setMaxPage(1)
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findReferOutGroup = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')
        setLoading(true)
        let res = await apiRefer.findReferOutGroup(dateNow, location.state.button, localStorage.getItem('hospital_code_'))
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                setDataAll(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            } else {
                setDataAll([])
                setMaxPage(1)
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const manageRefer = (id) => {
        setReferNumber(id)
        setOpenModal(true)
    }

    const clickOpenDoc = (no) => {
        findDocReferOut(no)
    }

    const findDocReferOut = async (referNum) => {
        setLoading(true)
        let res = await apiRefer.findDocReferOut(referNum)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                window.open(res['data']['data']['DocsURL'], '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickOpenDocBack = (no) => {
        findDocReferBack(no)
    }

    const findDocReferBack = async (referBackNum) => {
        setLoading(true)
        let res = await apiRefer.findDocReferBack(referBackNum)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                window.open(res['data']['data']['DocsURL'], '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const changeStateReferOut = async (Refer_number, Refer_Group, Remark) => {
        setLoading(true)
        let res = await apiRefer.changeStateReferOut(Refer_number, Refer_Group, Remark)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess(res['data']['message'])
                if (location.state.button === '00') {
                    findReferOut()
                } else {
                    findReferOutGroup()
                }
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickOpenAdd = async (pid, refer_no, name) => {
        navigate('/addRefer', { state: { refer_no: refer_no, type: 'edit', button: '1' } })
    }

    const clickViewMedicalHistory = async (refer_no) => {
        setLoading(true)
        let res = await apiRefer.findReferoutSummary(refer_no)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                navigate('/viewMedicalHistory', { state: { MedicalInfomation: res['data']['data']['MedicalInfomation'], MedicalInfomationType: res['data']['data']['MedicalInfomationType'] } })
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const openDatafindReferBack = (refer_no) => {
        navigate('/dataReferBack', { state: { refer_no: refer_no, type: 'show', button: '1' } })
    }

    return (
        <Box>
            <LoaderAnimation loading={loading} />
            <Box sx={{ textAlign: 'center', margin: '10px', fontSize: '20px', fontWeight: 700 }}>ส่งต่อผู้ป่วย</Box>
            <Box sx={{
                display: 'grid',
                // gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                gridGap: '5px',
                '@media (max-width: 1024px)': {
                    // gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gridTemplateColumns: '1fr 1fr 1fr',
                }
            }}>
                <Box
                    sx={'00' === location.state.button ? tabContained : tabOutlined}
                    onClick={() => handleButton('00')}
                >ทั้งหมด</Box>
                {buttonData.map((item, index) => (
                    <Box key={index}
                        sx={item['TypeReferCode'] === location.state.button ? tabContained : tabOutlined}
                        onClick={() => handleButton(item['TypeReferCode'])}
                    >
                        {item['TypeReferName']}
                    </Box>
                ))}
                <Box
                    sx={'000' === location.state.button ? tabContained : tabOutlined}
                    onClick={() => handleButton('000')}
                >...</Box>
            </Box>

            <Box>
                {openModal ?
                    <ModalSentResive
                        type={'refer'}
                        closeModal={() => setOpenModal(false)}
                        onSubmit={((referGroup, remark) => {
                            if (referGroup) {
                                changeStateReferOut(referNumber, referGroup, remark)
                            }
                        })}
                    />
                    : null
                }

                {openCalendar &&
                    <ModalCalendar
                        onFinished={(paramsState => {
                            if (paramsState === paramsState) {
                                if (location.state.button === '00') {
                                    findReferOut()
                                } else {
                                    findReferOutGroup()
                                }
                            }
                        })}
                        onClose={() => { setOpenCalendar(false) }}
                        value={date ? dayjs(moment(date['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
                        onChange={(e) => setDate(e)}
                        disableDay={disabledDay}
                    />
                }
                {location.state.button !== '000' &&
                    <>
                        <Box sx={{ ...content, margin: '1rem 0' }}>
                            <Box sx={{ display: 'flex', gridGap: '1rem', marginBottom: '1rem' }}>
                                <Button variant='contained'
                                    onClick={() => {
                                        navigate('/addNewcase', { state: { type: 'add', button: '1' } })
                                    }}>
                                    <AddIcon />
                                    <div>เพิ่มเคสใหม่</div>
                                </Button>
                            </Box>

                            <Typography component='p' variant='p' color='primary'>Filters</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                    <Typography variant='p'>
                                        ค้นหาโดยชื่อ-นามสกุล
                                    </Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        placeholder={'กรุณาระบุชื่อ-นามสกุลของผู้ป่วย'}
                                        value={namePatient}
                                        onChange={(event) => {
                                            const {
                                                target: { value },
                                            } = event;
                                            setNamePatient(value)
                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                    <Typography variant='p'>
                                        ค้นหาโดยเลขประจำตัวประชาชน
                                    </Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        placeholder={'กรุณาระบุเลขประจำตัวประชาชนของผู้ป่วย'}
                                        value={pid}
                                        onChange={(event) => {
                                            const {
                                                target: { value },
                                            } = event;
                                            const maxLength = 13;
                                            const numericRegex = /^[0-9]*$/;
                                            if (value.length <= maxLength && numericRegex.test(value)) {
                                                setPid(value)
                                            }
                                        }}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                    <Typography variant='p'>
                                        ค้นหาโดยวันที่
                                    </Typography>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        value={convertDate(date['$d'])}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton onClick={() => {
                                                        setOpenCalendar(true)
                                                    }}>
                                                        <CalendarTodayIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'end' }}>
                                        <Typography component='p' variant='p'>
                                            จำนวนทั้งหมด {dataAll?.length}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={contentTable}>
                            {dataAll?.length > 0 ?
                                <TableContainer>
                                    <Table sx={{ minWidth: 1700 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center' variant='p' color='primary'>#</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>รหัสการส่งต่อ</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>เลขบัตรประจำตัวประชาชน</TableCell>
                                                <TableCell variant='p' color='primary'>รายชื่อ</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>วันที่ส่งต่อ</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>โรงพยาบาลที่ส่งไป</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>ประเภทการส่งต่อ</TableCell>
                                                <TableCell align='center' variant='p' color='primary'>สถานะ</TableCell>
                                                {location.state.button !== '00' &&
                                                    <>
                                                        <TableCell align='center' variant='p' color='primary'>ประวัติการแพทย์</TableCell>
                                                        <TableCell align='center' variant='p' color='primary'>เอกสารส่งตัว</TableCell>
                                                        {location.state.button !== '02' && location.state.button !== '99' &&
                                                            <TableCell align='center' variant='p' color='primary'>จัดการ</TableCell>
                                                        }
                                                        {location.state.button === '99' &&
                                                            <TableCell align='center' variant='p' color='primary'>เอกสารการส่งกลับ</TableCell>
                                                        }
                                                    </>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {showList.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center">{item['ReferNumber']}</TableCell>
                                                    <TableCell align='center'>{item['cid']}</TableCell>
                                                    {location.state.button === '02' ?
                                                        <TableCell align='center'>{item['Name']}</TableCell>
                                                        :
                                                        <TableCell sx={{
                                                            color: '#006838',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                            onClick={() => { clickOpenAdd(item['cid'], item['ReferNumber'], item['Name']) }}
                                                        >{item['Name']}</TableCell>
                                                    }
                                                    <TableCell align='center'>{convertDate(item['ReferDate'])}</TableCell>
                                                    <TableCell align='center'>{item['ToHospitalName']}</TableCell>
                                                    <TableCell align='center'>{item['ReferTypeName']}</TableCell>
                                                    <TableCell align='center'>{item['ReferGroupTypeName']}</TableCell>
                                                    {location.state.button !== '00' &&
                                                        <>
                                                            <TableCell align='center'>
                                                                <Button
                                                                    sx={btnSecondary}
                                                                    variant="contained"
                                                                    onClick={() => { clickViewMedicalHistory(item['ReferNumber']) }}
                                                                >ดูประวัติการแพทย์</Button>
                                                            </TableCell>
                                                            {location.state.button === '99' ?
                                                                <TableCell align='center'>
                                                                    <Button onClick={() => { clickOpenDoc(item['ReferNumber']) }} variant="contained">การส่งตัว</Button>
                                                                    <Button onClick={() => { clickOpenDocBack(item['ReferbackNumber']) }} sx={{ marginLeft: '10px' }} variant="contained">การส่งกลับ</Button>
                                                                </TableCell>
                                                                :
                                                                <TableCell align='center' onClick={() => { clickOpenDoc(item['ReferNumber']) }}>
                                                                    <Button variant="contained">การส่งตัว</Button>
                                                                </TableCell>
                                                            }
                                                            {location.state.button !== '02' && location.state.button !== '99' &&
                                                                <TableCell align='center'>
                                                                    <ModeIcon color='primary' sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => { manageRefer(item['ReferNumber']) }} />
                                                                </TableCell>
                                                            }
                                                            {location.state.button === '99' &&
                                                                <TableCell align='center' onClick={() => { openDatafindReferBack(item['ReferbackNumber']) }}>
                                                                    <Button variant="contained">ดูข้อมูลส่งกลับ</Button>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Box style={{ fontSize: 20, textAlign: 'center', margin: '10%' }}>ไม่พบข้อมูล</Box>
                            }

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: 2
                            }}>
                                <Box sx={{ width: '100px' }}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={numPage}
                                            onChange={(e) => {
                                                const {
                                                    target: { value },
                                                } = e;
                                                setNumPage(value)
                                            }}
                                            size='small'
                                            sx={{ width: '100px', fontSize: '14px' }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={maxPage}
                                        page={page}
                                        onChange={handlePageChange}
                                        shape="rounded"
                                        color="primary"
                                    />
                                </Stack>
                            </Box>
                        </Box>
                    </>
                }

                {location.state.button === '000' &&
                    <ReferOther />
                }
            </Box>

        </Box>
    )
}

