import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function findAppointment(date, channel, appointment_state,idHospital) {
    return new Promise((resolve) => {
        let url = ''
        if(!idHospital){
            url = `${domain}/api/v1/microservice/appointment/findAppointment?AppointDate=${date}&order=DocNumber&sort=desc&DocStatus=W&AppointState=${appointment_state}&Channel=${channel}&HospitalCode=${localStorage.getItem('hospital_code')}`
        }else{
            url = `${domain}/api/v1/microservice/appointment/findAppointment?AppointDate=${date}&order=DocNumber&sort=desc&DocStatus=W&AppointState=${appointment_state}&Channel=${channel}&HospitalCode=${idHospital}`
        }
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findAppointmentNewAppoint() {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/findAppointment?order=DocNumber&sort=desc&DocStatus=W&AppointState=NEW_APPOINT&HospitalCode=${localStorage.getItem('hospital_code')}`
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
                'Platform': 'R12Network-dashboard',
                'Content-Type': 'application/json'
            }
        };

        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            });
    })
}

export function cancelAppointment(DocNumber) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/cancelAppointment?DocNumber=${DocNumber}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findAppointmentState() {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/findState?DocStatus=W&order=ID&sort=asc`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'R12Network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function changeState(appointment_state, id) {
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/changeState?ID=${id}`
        let data = JSON.stringify({
            "AppointState": appointment_state
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
                'Platform': 'R12Network-dashboard',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response)
            })
            .catch((error) => {
                // console.log(error);
                resolve(false)
            });
    })
}