import axios from "axios"
import { environment } from "../../config.env"

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain
const domain = ENDPOINT_DOMAIN

export function findReferGroup() { //เมนู
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findReferGroup`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findHospital() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/refer/findHospital?provcode=90%2C91%2C92%2C93%2C94%2C95%2C96&hostype=05%2C06%2C07&order=hosname&sort=asc`
        let header = {
            'Platform': 'r12network-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findAddress(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/data/findAddress?PID=${pid}`
        let header = {
            'Platform': 'r12network-dashboard',
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function uploads(formData) {
    return new Promise(resolve => {
        let url = 'https://api-r12network-uat.numeddatacenter.com/uploads-api/uploads/v1/R12Refer?FileName=R12'
        let header = {

        }
        axios.post(url, formData, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function consent(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/concent/checkConcent?PID=${pid}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'other'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function confirmConcent(pid) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/concent/confirmConcent?PID=${pid}`
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
                'Platform': 'R12Network-dashboard',
                'Content-Type': 'application/json'
            }
        }
        axios.request(config)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                resolve(false)
            })
    })
}

export function getConsent() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/authen/digitalID/getQrCode/consent`
        let header = {}
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findInformationOPD(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/R12Network-dashboard`
        let body = {
            "collection": [
                "personal_information",
                "visit_information",
                "order_information",
                "labs_information",
                "diagnosis_information"
            ],
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard',
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}
export function findInformationIPD(pid, hos_id) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/microservice/medical/findInformation/R12Network-dashboard`
        let body = {
            "collection": [
                "personal_information",
                "visit_information_ipd",
                "labs_information_ipd",
                "order_information_ipd",
                "diagnosis_information_ipd",
                "proced_information_ipd"
            ],
            "pid": pid,
            "hos_id": hos_id
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard',
            'Content-Type': 'application/json'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

