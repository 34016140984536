import React, { useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import AddAppointment from './AddAppointment';
import FilterPoint from './FilterPoint';
import MedicalRecord from './MedicalRecord';
import TakeHistory from './TakeHistory';
import SeeDoctor from './SeeDoctor';
import Appointment from './Appointment';
import DispenseMedication from './DispenseMedication';
import { useLocation, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';
import { useEffect } from 'react';
import LoaderAnimation from '../../components/LoaderAnimation';
import * as api from '../../services/OutPatient/ApiFilterPoint';
import { notifyError, notifyInfo, notifySuccess } from '../../components/NotifyToast';
import NewAppoint from './NewAppoint';


function OutPatient() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const routeState = useLocation()
  const dispatch = useDispatch()
  const [appoiontmentState, setAppointmentState] = useState([])

  if(!routeState.state){
    routeState.state = { appointment_state: 'ADD_APPOINT' }
  }

  let init_button_feature = [
    {
      name_btn: 'เพิ่มการนัดหมาย',
      state: 'ADD_APPOINT',
      status: false
    },
    {
      name_btn: 'รายการนัดหมาย',
      state: 'NEW_APPOINT',
      status: false
    },
    {
      name_btn: 'จุดคัดกรอง',
      state: 'SCREEN_APPOINT',
      status: false
    },
    {
      name_btn: 'เวชระเบียน',
      state: 'MEDICAL_RECORD',
      status: false
    },
    {
      name_btn: 'ซักประวัติ',
      state: 'TAKE_HISTORY',
      status: false
    },
    {
      name_btn: 'พบแพทย์',
      state: 'DOCTOR',
      status: false
    },
    {
      name_btn: 'จุดออกใบนัด',
      state: 'NEXT_APPOINT',
      status: false
    },
    {
      name_btn: 'จ่ายยา',
      state: 'DRUG_ORDER',
      status: false
    },
  ]

  useEffect(() => {
    dispatch(menuActive('ผู้ป่วยนอก (ทางไกล)'))
    findAppointmentState()
  },[])

  const handleManage = (state) => {
    navigate("/auth/out_patient", { state: { appointment_state: state }})
  }
  //  console.log("routeState ====> ",routeState.state['appointment_state'])

  const findAppointmentState = async () => {
    let res = await api.findAppointmentState()
    if (res) {
      if (res['data']['code'] === 200) {
        setAppointmentState(res['data']['data'])
        // console.log(res['data']['data'])
      }
    } else {
      return notifyError('ไม่ตอบสนอง')
    }
  }

  return (
    <>
    <LoaderAnimation loading={loading} />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
          justifyContent: 'center',
          marginTop: '20px', 
          gap: '15px' }}
      >
        {
          init_button_feature.map((item, index) => (
            <Button sx={{
              fontWeight: 'semibold'
            }} 
            disabled={item['status']} 
            key={index} 
            onClick={() => handleManage(item['state'],index)} 
            variant={item['state'] === routeState.state['appointment_state'] ? "contained" : "outlined"} 
            >
              {item['name_btn']}
            </Button>
          ))

        }
      </Box>


      {
        routeState.state['appointment_state'] === 'ADD_APPOINT' ? <AddAppointment /> : null
      }

      {
        routeState.state['appointment_state'] === 'NEW_APPOINT' ? 
        <NewAppoint
          appoiontmentState={appoiontmentState}  
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'SCREEN_APPOINT' ? 
        <FilterPoint 
          appoiontmentState={appoiontmentState}  
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'MEDICAL_RECORD' ? 
        <MedicalRecord 
          appoiontmentState={appoiontmentState} 
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'TAKE_HISTORY' ? 
        <TakeHistory
          appoiontmentState={appoiontmentState} 
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'DOCTOR' ? 
        <SeeDoctor 
          appoiontmentState={appoiontmentState} 
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'NEXT_APPOINT' ? 
        <Appointment 
          appoiontmentState={appoiontmentState} 
        /> : null
      }

      {
        routeState.state['appointment_state'] === 'DRUG_ORDER' ? 
        <DispenseMedication 
          appoiontmentState={appoiontmentState} 
        /> : null
      }
    </>
  )
}

export default OutPatient