import { Box, Button } from "@mui/material";
import React from "react";

import { IoIosAdd } from "react-icons/io";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export function ManageIcd10() {
    return (
        <Box sx={{
            border: '1px solid #E1E3E9',
            borderRadius: '10px',
            padding: '20px',
            marginTop: '32px'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: '10px',
                    background: '#006838',
                    borderRadius: '5px',
                    color: '#FFFFFF',
                    padding: 1,
                    width: '120px'
                }}><IoIosAdd />เพิ่ม ICD10</Box>
            </Box>
            <Box>
                <Box>เพิ่ม ICD10</Box>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '8fr 2fr 2fr',
                    gridGap: '10px'
                }}>
                    <Box sx={{border: '1px solid #E1E3E9', borderRadius: '5px',display: 'flex',alignItems: 'center', padding: '0 20px'}}>ssss</Box>
                    <Button variant="contained"><EditIcon />แก้ไข</Button>
                    <Button variant="outlined"><DeleteIcon />ลบ</Button>
                </Box>
            </Box>

        </Box>
    )
}