// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/bg_login$register.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Register_contentRegister__67d0y{
    display: flex;
    justify-content: center;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}
.Register_boxRegister__OfCh4{
    margin: 5rem 0;
    max-width: 600px;
    padding: calc(1rem + 1vw);
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
    height: 100%;
}
.Register_contentCondition__ybLMT{
    display: flex;
    align-items: center;
}
.Register_titleCondition__2E7qi{
    font-size: calc(11px + 0.25vw);
    margin-left: calc(0.5rem + 00.5vw);
    font-weight: bold;
}
.Register_condition__fKtCP{
    font-weight: bold;
    color: #006838;
    border-bottom: 1px solid #006838;
}
.Register_goBack__xGgfX{
    font-size: 15px;
    text-decoration: none;
    color: #006838;
    font-weight: bold;
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Register.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yDAA4D;IAC5D,4BAA4B;IAC5B,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,iDAAiD;IACjD,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,8BAA8B;IAC9B,kCAAkC;IAClC,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,gCAAgC;AACpC;AACA;IACI,eAAe;IACf,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".contentRegister{\n    display: flex;\n    justify-content: center;\n    background-image: url('../assets/img/bg_login$register.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    min-height: 100vh;\n    height: 100%;\n}\n.boxRegister{\n    margin: 5rem 0;\n    max-width: 600px;\n    padding: calc(1rem + 1vw);\n    border-radius: 1rem;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    background-color: #fff;\n    height: 100%;\n}\n.contentCondition{\n    display: flex;\n    align-items: center;\n}\n.titleCondition{\n    font-size: calc(11px + 0.25vw);\n    margin-left: calc(0.5rem + 00.5vw);\n    font-weight: bold;\n}\n.condition{\n    font-weight: bold;\n    color: #006838;\n    border-bottom: 1px solid #006838;\n}\n.goBack{\n    font-size: 15px;\n    text-decoration: none;\n    color: #006838;\n    font-weight: bold;\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentRegister": `Register_contentRegister__67d0y`,
	"boxRegister": `Register_boxRegister__OfCh4`,
	"contentCondition": `Register_contentCondition__ybLMT`,
	"titleCondition": `Register_titleCondition__2E7qi`,
	"condition": `Register_condition__fKtCP`,
	"goBack": `Register_goBack__xGgfX`
};
export default ___CSS_LOADER_EXPORT___;
