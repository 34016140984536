import React, { useEffect, useState } from "react";
import { backgroundModal, boxModal } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Table, TableBody, TableContainer, TableHead, TableCell, TextField, TableRow, Button, Stack, Pagination, FormControl, Select, MenuItem, Typography } from "@mui/material";
import * as api from '../../services/ApiConsultDoctor'
import LoaderAnimation from '../../components/LoaderAnimation'
import { InputTextSearch } from "../InputText";
import { notifyError, notifyInfo, notifySuccess } from '../../components/NotifyToast.jsx'

import { useDispatch } from "react-redux";
import { logout } from './../../store/authSlice';

export default function ModalSetupRoom({ onClose, data, title }) {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [page, setMaxPage] = useState(1)
    const [roomList, setRoomList] = useState([])

    useEffect(() => {
        console.log(data, title)
        getRoomForAssign()
    }, [])

    const getRoomForAssign = async () => {
        setLoading(true)
        let res = await api.getRoomForAssign(data)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setRoomList(res['data']['room_list'])
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const saveAssignRoom = async (roomName) => {
        setLoading(true)
        let res = await api.saveAssignRoom(data, roomName)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('จัดห้องสำเร็จ')
                onClose(true)
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const saveAssignRoomEmergency = async (roomName) => {
        setLoading(true)
        let res = await api.saveAssignRoomEmergency(data, roomName, true)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('จัดห้องฉุกเฉินสำเร็จ')
                onClose(true)
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const changeAssignRoom = async (roomName) => {
        setLoading(true)
        let res = await api.changeAssignRoom(data, roomName)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess(res['data']['message'])
                onClose(true)
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickChooseRoom = (roomName) => {
        if (title === 'จัดห้อง') {
            console.log('จัดห้อง')
            saveAssignRoom(roomName)
        } else if (title === 'เปลี่ยนห้อง') {
            console.log('เปลี่ยนห้อง')
            changeAssignRoom(roomName)
        }
    }

    const clickEmergencyRoom = (roomName) => {
        console.log(roomName)
        saveAssignRoomEmergency(roomName)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={backgroundModal}>
                <Box sx={boxModal}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={() => { onClose(false) }}><ClearIcon /></Box>
                    <Box>
                        <Typography sx={{
                            fontWeight: 'bold',
                        }}>
                            {title}
                        </Typography>
                        <div style={{ borderBottom: '1px solid #E1E3E9' }}></div>
                    </Box>
                    <Box sx={{
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: 2
                        }}>
                            <Typography sx={{
                                display: 'flex',
                                gap: 2
                            }}>
                                จำนวนทั้งหมด
                                <Typography sx={{
                                    fontWeight: 'bold'
                                }}>
                                    1
                                </Typography>
                                ห้อง
                            </Typography>
                            <InputTextSearch
                                placeholder={"ค้นหา"}
                                value={''}
                                disabled={true}
                                onChange={(e) => {
                                    console.log(e)
                                }}
                                submitForm={() => {
                                    console.log('search clik ++')
                                }}
                            />
                        </Box>
                        <div style={{ borderBottom: '1px solid #E1E3E9' }}></div>
                        <TableContainer>
                            <Table sx={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'center' }}>ห้องตรวจ</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>จำนวน</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>จัดห้อง</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>ฉุกเฉิน</TableCell>
                                    </TableRow>
                                </TableHead>
                                {roomList.map(i => (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>{i['room_name']}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{i['count']}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}><Button variant="contained" onClick={() => { clickChooseRoom(i['room_name']) }}>เลือกห้อง</Button></TableCell>
                                            <TableCell style={{ textAlign: 'center' }}><Button style={{ background: '#E92424', color: '#ffffff' }} onClick={() => { clickEmergencyRoom(i['room_name']) }}>ส่งฉุกเฉิน</Button></TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: 2
                        }}>
                            <Box sx={{ width: '100px' }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={5}
                                        size='small'
                                        sx={{ width: '100px', fontSize: '14px' }}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Stack spacing={2}>
                                <Pagination count={1} shape="rounded" color="primary" />
                            </Stack>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}