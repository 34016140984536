import { Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Switch, MenuList } from '@mui/material'
import React, { useContext, useState } from 'react'
import { buttonPrimary, buttonSecondary, contentBox, menuTable } from './mainCss'
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { styled } from '@mui/material/styles'
import ModalAccess from '../../components/ModalController/ModalAccess';
import { useEffect } from 'react';
import * as ApiMenu from '../../services/Manage/ApiManageMenu'
import LoaderAnimation from '../../components/LoaderAnimation';
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { MainContext } from '../../App';
import { notifyError, notifyInfo, notifySuccess , notifyWarning } from '../../components/NotifyToast';
import { async } from 'q';
import { Group } from '@mui/icons-material';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#006838',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))
function AccessPermission() {

    const { setMenuState } = useContext(MainContext)

    const [loading, setLoading] = useState(false)

    const [menuList, setMenuList] = useState([])
    const [groupRoleList, setGroupRoleList] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [typeModal, setTypeModal] = useState('')

    const [openModalSuccess, setOpenModalSuccess] = useState(false)
    const [menu, setMenu] = useState('')

    const [messageModal, setMessageModal] = useState({
        title: '',
        detail: ''
    })

    useEffect(() => {
        getManagePermissionMenu()
        getMenu()
        getGroupRole()
    }, [])

    // async function CreateAddManageMenu(MenuName) {
    //     console.log(MenuName)
    //     setLoading(true)
    //     let res = await ApiMenu.CreateAddManageMenu(MenuName)
    //     setLoading(false)
    //     if (res) {
    //         if (res['data']['code'] === 200) {
    //             setOpenModal(false)
    //             setOpenModalSuccess(true)
    //             setMessageModal({
    //                 title: 'เพิ่มระดับผู้ใช้งานสำเร็จ',
    //                 detail: 'ท่านได้เพิ่มระดับผู้ใช้งานเรียบร้อยแล้ว'
    //             })
    //         } else {
    //             notifyInfo(res['data']['message'])
    //         }
    //     } else {
    //         notifyError('ไม่ตอบสนอง')
    //     }

    // }

    async function getManagePermissionMenu() {
        setLoading(true)
        let res = await ApiMenu.getManagePermissionMenu()
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                let data = res['data']['data']
                if(localStorage.getItem('hospital_code') === 'N/A'){
                    setMenuList(data)
                }else{
                    let newData = data.filter(item => {
                        // console.log(item)
                        return item['GroupCode'] !== 'SUPERADMIN'
                    })
                    // console.log("newData ===> ",newData)
                    setMenuList(newData)
                }
                // if(localStorage.getItem('group_role') === 'ADMIN'){
                //     let newData = data.filter(item => {
                //         // console.log(item)
                //         return item['GroupCode'] !== 'SUPERADMIN'
                //     })
                //     console.log("newData ===> ",newData)
                //     setMenuList(newData)
                // }
                
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }

    }

    async function togglePermissionMenu(GroupRole, MenuCode) {
        setLoading(true)
        let res = await ApiMenu.togglePermissionMenu(GroupRole, MenuCode)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setMenuState(res['data'])
                notifySuccess(res['data']['message'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const getMenu = async () => {
        setLoading(true)
        let res = await ApiMenu.getMenu()
        setLoading(false)
        if (res) {
            // console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                setMenu(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const getGroupRole = async() => {
        setLoading(true)
        let res = await ApiMenu.getGroupRole()
        setLoading(false)
        if (res) {
            // console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                setGroupRoleList(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }

    const handleAccess = (type_access) => {
        setOpenModal(true)
        switch (type_access) {
            case 'add': return setTypeModal('add')
            case 'delete': return setTypeModal('delete')
            default: break
        }
    }

    // const deleteMenu = async (menu_id) => {
    //     // console.log('delete menu ====> ',menu_id)
    //     setLoading(true)
    //     let res = await ApiMenu.deleteManageMenu(menu_id)
    //     setLoading(false)
    //     if (res) {
    //         // console.log("res ====> ", res)
    //         if (res['data']['code'] === 200) {
    //             setOpenModal(false)
    //             setOpenModalSuccess(true)
    //             setMessageModal({
    //                 title: 'ลบระดับผู้ใช้งานสำเร็จ',
    //                 detail: 'ท่านได้ลบระดับผู้ใช้งานเรียบร้อยแล้ว'
    //             })
    //             getMenu()
    //             getGroupRole()
    //         } else {
    //             notifyInfo(res['data']['message'])
    //         }
    //     } else {
    //         notifyError('ไม่ตอบสนอง')
    //     }
    // }

    const addGroupRole = async(GroupName,GroupCode) => {
        // console.log(GroupName,"===",GroupCode)
        setLoading(true)
        let res = await ApiMenu.addGroupRole(GroupName,GroupCode)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOpenModal(false)
                setOpenModalSuccess(true)
                setMessageModal({
                    title: 'เพิ่มระดับผู้ใช้งานสำเร็จ',
                    detail: 'ท่านได้เพิ่มระดับผู้ใช้งานเรียบร้อยแล้ว'
                })
                getManagePermissionMenu()
                getGroupRole()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }       
    }

    const deleteGroupRole = async(GroupID) => {
        // console.log(GroupID)
        setLoading(true)
        let res = await ApiMenu.deleteGroupRole(GroupID)
        setLoading(false)
        if (res) {
            // console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                setOpenModal(false)
                setOpenModalSuccess(true)
                setMessageModal({
                    title: 'ลบระดับผู้ใช้งานสำเร็จ',
                    detail: 'ท่านได้ลบระดับผู้ใช้งานเรียบร้อยแล้ว'
                })
                getGroupRole()
                getManagePermissionMenu()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {
                openModalSuccess ? (
                    <ModalSuccess
                        title={messageModal['title']}
                        detail={messageModal['detail']}
                        closeModal={() => setOpenModalSuccess(!openModalSuccess)}
                        goPage={'closeModal'}
                    />
                ) : ''
            }
            <Box sx={contentBox}>
                {
                    openModal ? (
                        <ModalAccess
                            closeModal={() => handleOpenModal()}
                            typeAccess={typeModal}
                            titleModal={typeModal === 'add' ? 'เพิ่มระดับผู้ใช้งาน' : 'ลบระดับผู้ใช้งาน'}
                            data={typeModal === 'delete' ? groupRoleList : ''}
                            onFinishApi={(GroupName,GroupCode) => {
                                // console.log(GroupName,"===",GroupCode)
                                // CreateAddManageMenu(GroupName,GroupCode)
                                addGroupRole(GroupName,GroupCode)
                            }}
                            deleteMenu={(GroupID) => {
                                // deleteMenu(menuID)
                                deleteGroupRole(GroupID)
                            }}
                            goPage={''}
                        />
                    ) : ''
                }
                <Box sx={menuTable}>
                    <Button sx={buttonPrimary} onClick={() => handleAccess('add')}>
                        <><AddOutlinedIcon sx={{ marginRight: '1rem' }} />{'เพิ่มระดับผู้ใช้งาน'}</>
                    </Button>
                    <Button sx={buttonSecondary} onClick={() => handleAccess('delete')}>
                        <><DeleteIcon sx={{ marginRight: '1rem' }} />{'ลบระดับผู้ใช้งาน'}</>
                    </Button>
                </Box>
                <TableContainer sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Table sx={{ minWidth: '1300px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ width: '10%', fontWeight: '600' }}>#</TableCell>
                                <TableCell sx={{
                                    fontWeight: '600',
                                    width: '15%',
                                    borderRight: '1px solid #E1E3E9'
                                }}>เมนูการใช้งาน</TableCell>
                                {menu && menu.map((headerTb, idex) => (
                                    <TableCell key={idex} sx={{
                                        fontWeight: '600'
                                    }} align="center">{headerTb['MenuName']}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {menuList && menuList.map((data, index) => (
                                <TableRow key={data['GroupID']}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align='left' sx={{borderRight: '1px solid #E1E3E9'}}>{data['GroupName']}</TableCell>

                                    {data['Menu'] && data['Menu'].map((menuItem, idex) => (
                                        <TableCell key={idex} align='center'>
                                            <FormControlLabel sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                                key={menuItem['MenuCode']}
                                                control={<IOSSwitch onChange={() => {
                                                    togglePermissionMenu(menuItem['GroupRole'], menuItem['MenuCode'])
                                                }} defaultChecked={menuItem['Enable']} />}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default AccessPermission