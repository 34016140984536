import axios from "axios"
import { environment } from "../../config.env"

// const ENDPOINT_DOMAIN = environment.production ? environment.production_payment : environment.development_payment
const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain
const domain = ENDPOINT_DOMAIN

export function checkPackage() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/transaction/checkPackage`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function findPackage() {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/data/findPackage?order=line_number&sort=asc&doc_status=W`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard'
        }
        axios.get(url, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function requestPackage(package_code) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/transaction/requestPackage`
        let body = {
            "package_code": package_code
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function cancelRequestPackage(doc_number) {
    return new Promise(resolve => {
        let url = `${domain}/api/v1/transaction/cancelRequestPackage`
        let body = {
            "doc_number": doc_number
        }
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard'
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}