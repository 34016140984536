import { Box,Button,Grid, Typography } from '@mui/material'
import React from 'react'
import registerMastodon from '../../assets/img/register_mastodon.svg' 

const contentMastodon = {
    width:'90%',
    margin:'0 auto',
    // background:'red'
}
const cardContent = {
    display:'flex',
    flexDirection:'column',
    textAlign:'center',
    alignItems:'center',
    backgroundColor:'#fff',
    padding:'1rem',
    border:'0.5px solid #E1E3E9'
}
function NoneProfileMastodaon() {

    const content = [
        {
            title:'คลังความรู้ <br/> จากบุคลากรทางการแพทย์',
            img: ''
        },
        {
            title:'เข้าร่วมแบ่งปันสิ่งใหม่ๆ',
            img: ''
        },
        {
            title: 'เพิ่มภาพ วิดีโอ <br/> บทความที่น่าสนใจ',
            img:''
        }
    ]
  return (
    <Box sx={contentMastodon}>
        <Grid container>
            <Grid item xs={12}>
                <Box sx={cardContent}>
                    <Typography
                        variant='h6'
                        color='primary'
                        sx={{fontWeight:600}}
                    >
                        ลงทะเบียน Mastodon เพื่อเข้าร่วม <br/>
                        Community บุคลากรทางการแพทย์
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{width:'250px',margin:'1rem 0'}}
                    >
                        ลงทะเบียน
                    </Button>
                    <img src={registerMastodon} style={{height:'250px', width:'100%',display:'block'}} alt=''/>
                </Box>
            </Grid>
            {
                    content.map((item) => (
                        <Grid xs={12} sm={4} md={4} lg={4} xl={4}>
                            <Box sx={{...cardContent,height:'350px'}}>
                                <Typography
                                    variant='h6'
                                    color='primary'
                                    sx={{fontWeight:600}}
                                >
                                    {item['title']}
                                </Typography>
                                <img src={registerMastodon} style={{height:'250px', width:'100%',display:'block'}} alt=''/>
                            </Box>
                        </Grid>
                    ))
                }
            
        </Grid>
    </Box>
  )
}

export default NoneProfileMastodaon