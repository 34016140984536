import { Box, Button, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from '../../services/ApiConsultDoctor'
import * as apiPublic from '../../services/public_api'

import ModalSuccess from '../../components/ModalController/ModalSuccess'
import LoaderAnimation from '../../components/LoaderAnimation'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ModalCalendarAddAppoinment from "../../components/ModalController/ModalCalendarAddAppoinment";

import moment from "moment";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { notifyError, notifyInfo, notifyWarning } from "../../components/NotifyToast";

import { BsPersonSquare } from "react-icons/bs";

export default function AddAppointment() {

    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const [search, setSearch] = useState('')
    const [box, setBox] = useState(false)

    const [consultationHospital, setConsultationHospital] = useState(localStorage.getItem('hospital_name')) // โรงพยาบาลที่ขอคำปรึกษา

    const [clinicID, setClinicID] = useState('')
    const [clinicCode, setClinicCode] = useState('')

    const [hospital, setHospital] = useState('') // โรงพยาบาลที่รับคำปรึกษา
    const [optionHospital, setOptionHospital] = useState([])

    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])

    const [locationValue, setLocationValue] = useState('')
    const [optionLocation, setOptionLocation] = useState([])

    const [dateAppointment, setDateAppointment] = useState('')
    const [timeAppointment, setTimeAppointment] = useState('')

    const [findData, setFindData] = useState([])
    const [nofindData, setNoFindData] = useState('')

    const [pid, setPid] = useState('')
    const [pname, setPname] = useState('')
    const [optionPname, setOptionPname] = useState([])
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')

    const locationList = []
    const option = []

    const [openModalDate, setOpenModalDate] = useState(false)

    const [dataAll, setDataAll] = useState([])

    const [disabledDay, setDisabledDay] = useState([0, 1, 2, 3, 4, 5, 6,])
    const [dataDay, setDataDay] = useState([
        {
            day: 0,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 1,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 2,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 3,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 4,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 5,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        },
        {
            day: 6,
            status_open: false,
            time_open: "00:00",
            time_close: "00:00"
        }
    ])
    const [open, setOpen] = useState({
        open_hours: '',
        open_minutes: '',
    })
    const [close, setClose] = useState({
        close_hours: '',
        close_minutes: '',
    })

    const [dataHos, setDataHos] = useState([])

    useEffect(() => {
        findTitleName()
        findDataDepartment()
    }, [])

    useEffect(() => {
        findDepartment()
        findClinicCode()
    }, [hospital])

    useEffect(() => {
        findLocation()
    }, [department])

    useEffect(() => {
        setTime()
    }, [dateAppointment])

    const clickSearch = () => {
        setHospital('')
        setDepartment('')
        setLocationValue('')
        setDateAppointment('')
        setPid(search)
        setPname('')
        setFname('')
        setLname('')
        setTimeAppointment('')

        setBox(false)
        setFindData([])

        setNoFindData('')
        findInformationSearch()
    }

    const findTitleName = async () => {
        setLoading(true)
        let res = await apiPublic.findTitleName()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOptionPname(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findInformationSearch = async () => {
        setLoading(true)
        let res = await api.findInformationSearch(search)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                const unique = [...new Map(res['data']['data'].map((m) => [m.pid, m])).values()] //กรองชื่อซ้ำ
                setFindData(unique)
            } else {
                setNoFindData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDataDepartment = async () => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataAll(res['data']['data'])
                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]['HospitalCode'] === localStorage.getItem('hospital_code')) {
                        List.push(res['data']['data'][i]['Clinic'])
                    }
                }
                let data_new = List.reduce((prev, cur) => prev.concat(cur), [])
                setDataHos(data_new)
                const unique = [...new Map(data_new.map((m) => [m.ClinicCode, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
                console.log(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async () => {
        console.log(hospital)
        let code = []
        for (let i = 0; i < dataHos.length; i++) {
            if (dataHos[i]['ClinicCode'] === hospital) {
                code.push(dataHos[i]['DepartmentCode'])
            }
        }
        const List = dataAll.filter(item => {
            return code?.find((menuItem) => menuItem === item['DepartmentCode'])
        })
        setOptionDepartment(List)
    }

    const findClinicCode = async () => {
        for (let i = 0; i < optionHospital.length; i++) {
            if (optionHospital[i]['ClinicCode'] === hospital) {
                setClinicID(optionHospital[i]['ClinicID'])
                setClinicCode(optionHospital[i]['ClinicCode'])
            }
        }
    }

    const findLocation = () => {
        for (let i = 0; i < optionDepartment.length; i++) {
            if (optionDepartment[i]['DepartmentCode'] === department) {
                locationList.push(optionDepartment[i])
            }
        }
        findLoc()
    }

    const findLoc = () => {
        locationList.map((i, idx) => (
            i['RoomConference'].map((item, index) => (
                option.push(item)
            ))
        ))
        const unique = [...new Map(option.map((m) => [m.Location, m])).values()] //กรองชื่อซ้ำ
        setOptionLocation(unique)
        findDate()
    }

    const findDate = () => {
        if (optionDepartment.length !== 0) {
            // console.log(optionDepartment)
            // let data = optionDepartment[0]['DepartmentDetail']
            let data = optionDepartment.filter((item) => item['DepartmentCode'] == department)

            // console.log(data)
            // console.log(department)

            data = data[0]['DepartmentDetail']

            const updateData = [
                {
                    day: 0,
                    status_open: data['Sunday']['Open'],
                    time_open: data['Sunday']['TimeOpen'],
                    time_close: data['Sunday']['TimeClose']
                },
                {
                    day: 1,
                    status_open: data['Monday']['Open'],
                    time_open: data['Monday']['TimeOpen'],
                    time_close: data['Monday']['TimeClose']
                },
                {
                    day: 2,
                    status_open: data['Tuesday']['Open'],
                    time_open: data['Tuesday']['TimeOpen'],
                    time_close: data['Tuesday']['TimeClose']
                },
                {
                    day: 3,
                    status_open: data['Wednesday']['Open'],
                    time_open: data['Wednesday']['TimeOpen'],
                    time_close: data['Wednesday']['TimeClose']
                },
                {
                    day: 4,
                    status_open: data['Thursday']['Open'],
                    time_open: data['Thursday']['TimeOpen'],
                    time_close: data['Thursday']['TimeClose']
                },
                {
                    day: 5,
                    status_open: data['Friday']['Open'],
                    time_open: data['Friday']['TimeOpen'],
                    time_close: data['Friday']['TimeClose']
                },
                {
                    day: 6,
                    status_open: data['Saturday']['Open'],
                    time_open: data['Saturday']['TimeOpen'],
                    time_close: data['Saturday']['TimeClose']
                }
            ]
            setDataDay(updateData)
            const result = updateData.filter((item) => (item['status_open'] !== true)).map(item => item.day)
            // console.log('result day',result)
            setDisabledDay(result)
        } else {
            setDisabledDay([0, 1, 2, 3, 4, 5, 6])
        }
    }

    const handleChange = (e) => {
        const maxLength = 13;
        const numericRegex = /^[0-9]*$/;
        if (e.target.value.length <= maxLength && numericRegex.test(e.target.value)) {
            setSearch(e.target.value)
        }
    }

    const handleChangePID = (e) => {
        const maxLength = 13;
        const numericRegex = /^[0-9]*$/;
        if (e.target.value.length <= maxLength && numericRegex.test(e.target.value)) {
            setPid(e.target.value)
        }
    }

    const addTelemedicine = async (body) => {
        setLoading(true)
        let res = await api.addTelemedicine(body)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOpenModal(true)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickBox = (pid, p, f, l) => {
        setBox(true)
        setPid(pid)
        setPname(p)
        setFname(f)
        setLname(l)
    }

    const clickSubmit = () => {
        if (!hospital || hospital === ' ') {
            notifyWarning('กรุณาเลือกโรงพยาบาลที่รับคำปรึกษา')
            return
        }
        if (!department || department === ' ') {
            notifyWarning('กรุณาเลือกแผนกที่เข้ารับบริการ')
            return
        }
        if (!locationValue || locationValue === ' ') {
            notifyWarning('กรุณาเลือกสถานที่')
            return
        }
        if (!dateAppointment) {
            notifyWarning('กรุณาเลือกวันที่ต้องการนัดหมาย')
            return
        }
        if (!timeAppointment) {
            notifyWarning('กรุณาเลือกเวลาที่ต้องการนัดหมาย')
            return
        }

        let body = {
            "PID": pid,
            "Name": pname + ' ' + fname + ' ' + lname,
            "HospitalCodeFrom": localStorage.getItem('hospital_code'),
            "HospitalCodeTo": hospital,
            "DepartmentCode": department,
            "Location": locationValue,
            "AppointDate": moment(dateAppointment['$d']).format('YYYY-MM-DD'),
            "AppointTime": moment(timeAppointment['$d']).format('HH:mm'),
            "ClinicCode": clinicCode,
            "ClinicID": clinicID,
        }
        addTelemedicine(body)
    }

    const clickSubmitNot = () => {
        if (!pid) {
            notifyWarning('กรุณากรอกระบุเลขบัตรประจำตัวประชาชน')
            return
        }
        if (!pname || pname === ' ') {
            notifyWarning('กรุณาเลือกคำนำหน้า')
            return
        }
        if (!fname) {
            notifyWarning('กรุณากรอกชื่อ')
            return
        }
        if (!lname) {
            notifyWarning('กรุณากรอกนามสกุล')
            return
        }
        if (!hospital || hospital === ' ') {
            notifyWarning('กรุณาเลือกโรงพยาบาลที่รับคำปรึกษา')
            return
        }
        if (!department || department === ' ') {
            notifyWarning('กรุณาเลือกแผนกที่เข้ารับบริการ')
            return
        }
        if (!locationValue || locationValue === ' ') {
            notifyWarning('กรุณาเลือกสถานที่')
            return
        }
        if (!dateAppointment) {
            notifyWarning('กรุณาเลือกวันที่ต้องการนัดหมาย')
            return
        }
        if (!timeAppointment) {
            notifyWarning('กรุณาเลือกเวลาที่ต้องการนัดหมาย')
            return
        }

        let body = {
            "PID": pid,
            "Name": pname + ' ' + fname + ' ' + lname,
            "HospitalCodeFrom": localStorage.getItem('hospital_code'),
            "HospitalCodeTo": hospital,
            "DepartmentCode": department,
            "Location": locationValue,
            "AppointDate": moment(dateAppointment['$d']).format('YYYY-MM-DD'),
            "AppointTime": moment(timeAppointment['$d']).format('HH:mm'),
            "ClinicCode": clinicCode,
            "ClinicID": clinicID,
        }
        addTelemedicine(body)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const setTime = () => {
        const findDay = dataDay.filter((item) => item['day'] === dateAppointment['$W'])

        console.log('select day',findDay)

        if (findDay.length > 0) {
            const [hoursStr_open, minutesStr_open] = findDay[0]['time_open'].split(':')
            const hours_open = parseInt(hoursStr_open, 10)
            const minutes_open = parseInt(minutesStr_open, 10)

            open['open_hours'] = hours_open
            open['open_minutes'] = minutes_open

            const [hoursStr_close, minutesStr_close] = findDay[0]['time_close'].split(':')
            const hours_close = parseInt(hoursStr_close, 10)
            const minutes_close = parseInt(minutesStr_close, 10)

            close['close_hours'] = hours_close
            close['close_minutes'] = minutes_close

            setClose({ ...close, ['close_hours']: hours_close, ['close_minutes']: minutes_close })
        }
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <div>เพิ่มการนัดหมาย</div>
                <Box sx={{
                    margin: 'auto',
                    width: '60%',
                    '@media (max-width: 800px)': {
                        width: '100%',
                    }
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 3
                    }}>
                        <TextField
                            sx={{ width: '100%' }}
                            variant="standard"
                            placeholder={'ระบุเลขประจำตัวประชาชน'}
                            value={search}
                            onChange={(e) => { handleChange(e) }}
                        >
                        </TextField>
                        <SearchTwoToneIcon onClick={() => { clickSearch() }} />
                    </Box>

                    {findData ?
                        <>
                            {findData.map((i, idx) => (
                                <Box sx={{
                                    width: '100%',
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #E1E3E9',
                                    borderRadius: 3,
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 3fr',
                                    alignItems: 'center',
                                    padding: '20px 40px',
                                    fontSize: 18,
                                    '@media (max-width: 500px)': {
                                        padding: '10px 20px',
                                        fontSize: 10
                                    }
                                }}
                                    key={idx}
                                    onClick={() => { clickBox(i['pid'], i['pname'], i['fname'], i['lname']) }}
                                >
                                    <Box>
                                       <BsPersonSquare size={60} />
                                    </Box>
                                    <Box sx={{ display: 'grid', gridGap: 10 }}>
                                        <Box sx={{ display: 'flex', gridGap: 10 }}>
                                            <div>ชื่อ-นามสกุล : </div>
                                            <div>{i['pname'] + i['fname'] + ' ' + i['lname']}</div>
                                        </Box>
                                        <Box sx={{ display: 'flex', gridGap: 10 }}>
                                            <div>เลขประจำตัวประชาชน : </div>
                                            <div>{i['pid']}</div>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </>
                        : null
                    }

                    {nofindData ?
                        <Box sx={{
                            width: '100%',
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #E1E3E9',
                            borderRadius: 3,
                            display: 'grid',
                            justifyItems: 'center',
                            alignItems: 'center',
                            padding: 2,
                            fontSize: 18,
                            '@media (max-width: 500px)': {
                                padding: 1,
                                fontSize: 10
                            }
                        }}
                        >
                            <div>ไม่พบข้อมูล</div>
                        </Box>
                        : null
                    }
                </Box>
            </Box>

            {box ?
                <>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 2,
                        padding: 2,
                        fontSize: 18,
                        '@media (max-width: 1023px)': {
                            marginTop: 2,
                            padding: 1
                        },
                        '@media (max-width: 800px)': {
                            marginTop: 1,
                            padding: 1,
                            fontSize: 14
                        }
                    }}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gridGap: '20px 40px',
                            '@media (max-width: 1023px)': {
                                gridTemplateColumns: '1fr 1fr',
                                gridGap: 20,
                            },
                            '@media (max-width: 800px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 15,
                            }
                        }}>
                            <FormControl>
                                <div >โรงพยาบาลที่ขอคำปรึกษา</div>
                                <TextField
                                    value={consultationHospital}
                                    InputProps={{
                                        inputProps: {
                                            readOnly: true,
                                        }
                                    }}
                                ></TextField>
                            </FormControl>

                            <FormControl>
                                <div >โรงพยาบาลที่รับคำปรึกษา</div>
                                <Select
                                    value={hospital || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setHospital(value)
                                        setDepartment('')
                                        setOptionDepartment([])
                                        setLocationValue('')
                                        setOptionLocation([])
                                    }}
                                >
                                    <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                                    {optionHospital.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['ClinicCode']}
                                        >
                                            {item['ClinicName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div >แผนกที่เข้ารับบริการ</div>
                                <Select
                                    disabled={hospital === '' ? true : null}
                                    value={department || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setDepartment(value)
                                        setLocationValue('')
                                        setOptionLocation([])
                                    }}
                                >
                                    <MenuItem value=" ">เลือกแผนก</MenuItem>
                                    {optionDepartment.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['DepartmentCode']}
                                        >
                                            {item['DepartmentName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div>สถานที่</div>
                                <Select
                                    disabled={department === '' ? true : null}
                                    value={locationValue || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setLocationValue(value)
                                    }}
                                >
                                    <MenuItem value=" ">เลือกสถานที่</MenuItem>
                                    {optionLocation.map((i, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={i['Location']}
                                        >
                                            {i['Location']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div>วันที่</div>
                                <TextField
                                    disabled={locationValue === '' ? true : null}
                                    value={dateAppointment === '' ? 'เลือกวันที่' : convertDate(dateAppointment)}
                                    // error={dateAppointment ? false : true}
                                    InputProps={{
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton
                                                    disabled={locationValue === '' ? true : null}
                                                    onClick={() => { setOpenModalDate(true) }}
                                                >
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                ></TextField>
                            </FormControl>

                            <FormControl>
                                <div>เวลา</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer sx={{
                                        "&.MuiStack-root": {
                                            paddingTop: '0px'
                                        }
                                    }} components={['MobileTimePicker']}>
                                        <MobileTimePicker
                                            disabled={locationValue === '' ? true : null}
                                            ampm={false}
                                            sx={{ width: '100%' }}
                                            value={timeAppointment ? dayjs(moment(timeAppointment['$d']).format('YYYY-MM-DDTHH:mm')) : dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm')).set('hour', 0).set('minute', 0)}
                                            onChange={(e) => setTimeAppointment(e)}
                                            minTime={open ? dayjs().set('hour', open['open_hours']).set('minute', open['open_minutes']) : null}
                                            maxTime={close ? dayjs().set('hour', close['close_hours']).set('minute', close['close_minutes']) : null}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gridGap: 15,
                            margin: 3
                        }}>
                            <Button variant="outlined">ยกเลิก</Button>
                            <Button onClick={() => { clickSubmit() }} variant="contained" >บันทึก</Button>
                        </Box>
                    </Box>

                    {openModalDate ?
                        <ModalCalendarAddAppoinment
                            onClose={() => { setOpenModalDate(false) }}
                            onSubmit={() => { setOpenModalDate(false) }}
                            value={
                                dateAppointment ? dayjs(moment(dateAppointment['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                            }
                            setValue={(e) => {
                                setDateAppointment(e)
                            }}
                            disableDay={disabledDay}
                        />
                        :
                        null
                    }

                    {openModal ?
                        <ModalSuccess
                            title={'นัดหมายสำเร็จ'}
                            detail={'ท่านได้เพิ่มการนัดหมายเรียบร้อยแล้ว'}
                            closeModal={() => setOpenModal(false)}
                            goPage={'/auth/consult_doctor'}
                            state={{ value: 0 }}
                        />
                        : null
                    }

                </>
                : null
            }

            {nofindData ?
                <>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 2,
                        padding: 2,
                        fontSize: 18,
                        '@media (max-width: 1023px)': {
                            marginTop: 2,
                            padding: 1
                        },
                        '@media (max-width: 800px)': {
                            marginTop: 1,
                            padding: 1,
                            fontSize: 14
                        }
                    }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr',
                                gridGap: '20px 40px',
                                marginBottom:'1rem',
                                '@media (max-width: 1023px)': {
                                    gridTemplateColumns: '1fr 1fr',
                                    gridGap: 20,
                                },
                                '@media (max-width: 800px)': {
                                    gridTemplateColumns: '1fr',
                                    gridGap: 15,
                                }
                            }}
                        >
                            <FormControl>
                                <div>เลขบัตรประจำตัวประชาชน</div>
                                <TextField
                                    placeholder="ระบุเลขบัตรประจำตัวประชาชน"
                                    name="pid"
                                    value={pid}
                                    onChange={(e) => { handleChangePID(e) }}
                                ></TextField>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gridGap: '20px 40px',
                            '@media (max-width: 1023px)': {
                                gridTemplateColumns: '1fr 1fr',
                                gridGap: 20,
                            },
                            '@media (max-width: 800px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 15,
                            }
                        }}>

                            <FormControl>
                                <div>คำนำหน้า</div>
                                <Select
                                    value={pname || ' '}
                                    onChange={(e) => { setPname(e.target.value) }}
                                >
                                    <MenuItem value=" ">เลือกคำนำหน้า</MenuItem>
                                    {optionPname.map((i, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={i['TitleName']}
                                        >
                                            {i['TitleName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div>ชื่อ</div>
                                <TextField
                                    placeholder="ชื่อ"
                                    value={fname}
                                    onChange={(e) => { setFname(e.target.value) }}
                                >
                                </TextField>
                            </FormControl>

                            <FormControl>
                                <div>นามสกุล</div>
                                <TextField
                                    placeholder="นามสกุล"
                                    value={lname}
                                    onChange={(e) => { setLname(e.target.value) }}
                                ></TextField>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 2,
                        padding: 2,
                        fontSize: 18,
                        '@media (max-width: 1023px)': {
                            marginTop: 2,
                            padding: 1
                        },
                        '@media (max-width: 800px)': {
                            marginTop: 1,
                            padding: 1,
                            fontSize: 14
                        }
                    }}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gridGap: '20px 40px',
                            '@media (max-width: 1023px)': {
                                gridTemplateColumns: '1fr 1fr',
                                gridGap: 20,
                            },
                            '@media (max-width: 800px)': {
                                gridTemplateColumns: '1fr',
                                gridGap: 15,
                            }
                        }}>
                            <FormControl>
                                <div >โรงพยาบาลที่ขอคำปรึกษา</div>
                                <TextField
                                    value={consultationHospital}
                                    InputProps={{
                                        inputProps: {
                                            readOnly: true,
                                        }
                                    }}
                                ></TextField>
                            </FormControl>

                            <FormControl>
                                <div >โรงพยาบาลที่รับคำปรึกษา</div>
                                <Select
                                    value={hospital || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setHospital(value)
                                        setDepartment('')
                                        setOptionDepartment([])
                                        setLocationValue('')
                                        setOptionLocation([])
                                    }}
                                >
                                    <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem>
                                    {optionHospital.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['ClinicCode']}
                                        >
                                            {item['ClinicName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div >แผนกที่เข้ารับบริการ</div>
                                <Select
                                    disabled={hospital === '' ? true : null}
                                    value={department || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setDepartment(value)
                                        setLocationValue('')
                                        setOptionLocation([])
                                    }}
                                >
                                    <MenuItem value=" ">เลือกแผนก</MenuItem>
                                    {optionDepartment.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item['DepartmentCode']}
                                        >
                                            {item['DepartmentName']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div >สถานที่</div>
                                <Select
                                    disabled={department === '' ? true : null}
                                    value={locationValue || ' '}
                                    onChange={(event) => {
                                        const {
                                            target: { value },
                                        } = event;
                                        setLocationValue(value)
                                    }}
                                >
                                    <MenuItem value=" ">เลือกสถานที่</MenuItem>
                                    {optionLocation.map((i, idx) => (
                                        <MenuItem
                                            key={idx}
                                            value={i['Location']}
                                        >
                                            {i['Location']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <div>วันที่</div>
                                <TextField
                                    disabled={locationValue === '' ? true : null}
                                    value={dateAppointment === '' ? 'เลือกวันที่' : convertDate(dateAppointment)}
                                    // error={dateAppointment ? false : true}
                                    InputProps={{
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton
                                                    disabled={locationValue === '' ? true : null}
                                                    onClick={() => { setOpenModalDate(true) }}
                                                >
                                                    <CalendarTodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                ></TextField>
                            </FormControl>

                            <FormControl>
                                <div>เวลา</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer sx={{
                                        "&.MuiStack-root": {
                                            paddingTop: '0px'
                                        }
                                    }} components={['MobileTimePicker']}>
                                        <MobileTimePicker
                                            disabled={locationValue === '' ? true : null}
                                            ampm={false}
                                            sx={{ width: '100%' }}
                                            value={timeAppointment ? dayjs(moment(timeAppointment['$d']).format('YYYY-MM-DDTHH:mm')) : dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm')).set('hour', 0).set('minute', 0)}
                                            onChange={(e) => setTimeAppointment(e)}
                                            minTime={open ? dayjs().set('hour', open['open_hours']).set('minute', open['open_minutes']) : null}
                                            maxTime={close ? dayjs().set('hour', close['close_hours']).set('minute', close['close_minutes']) : null}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gridGap: 15,
                            margin: 3
                        }}>
                            <Button variant="outlined" onClick={() => { }}>ยกเลิก</Button>
                            <Button onClick={() => { clickSubmitNot() }} variant="contained" >บันทึก</Button>
                        </Box>
                    </Box >

                    {openModalDate ?
                        <ModalCalendarAddAppoinment
                            onClose={() => { setOpenModalDate(false) }}
                            onSubmit={() => { setOpenModalDate(false) }}
                            value={
                                dateAppointment ? dayjs(moment(dateAppointment['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                            }
                            setValue={(e) => {
                                setDateAppointment(e)
                            }}
                            disableDay={disabledDay}
                        />
                        :
                        null
                    }

                    {openModal ?
                        <ModalSuccess
                            title={'นัดหมายสำเร็จ'}
                            detail={'ท่านได้เพิ่มการนัดหมายเรียบร้อยแล้ว'}
                            closeModal={() => setOpenModal(false)}
                            goPage={'/auth/consult_doctor'}
                            state={{ value: 0 }}
                        />
                        : null
                    }

                </>
                : null
            }
        </>
    )
}