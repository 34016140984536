import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import styles from './QueueSystem.module.css'
import DailyAppointments from "./DailyAppointments";
import DoctorCallsQueue from "./DoctorCallsQueue";
import AskOrganizingRoom from "./AskOrganizingRoom";
import { useNavigate } from "react-router-dom";

export const buttonPrimary = {
    backgroundColor: '#E1F4EB',
    border: '1px solid #006838',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: '8px',
    color: '#006838',
    boxShadow: 'none',
    width: '100%',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#E1F4EB',
        color: '#006838',
        boxShadow: 'none'
    },
}

export const buttonInPrimary = {
    backgroundColor: '#FBFBFB',
    border: 'none',
    padding: '0.5rem 1rem',
    width: 'auto',
    color: '#747676',
    width: '100%',
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#FBFBFB',
        color: '#747676',
        boxShadow: 'none'
    },
}

export default function TabQueueSystem() {
    const navigate = useNavigate()
    const [value, setValue] = useState(0);

    const clickOpenQueue = () => {
        window.open('/examinationRoomQueue')
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 2
            }}>
                <Button variant="contained" onClick={()=> {clickOpenQueue()}}>เปิดจอแสดงคิว</Button>
            </Box>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    fontSize: 16
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 16
                }
            }}>
                <Button onClick={() => { setValue(0) }} sx={value === 0 ? buttonPrimary : buttonInPrimary} >รายชื่อนัดประจำวัน</Button>
                <Button onClick={() => { setValue(1) }} sx={value === 1 ? buttonPrimary : buttonInPrimary} >ซักประวัติและจัดห้อง</Button>
                <Button onClick={() => { setValue(2) }} sx={value === 2 ? buttonPrimary : buttonInPrimary} >แพทย์เรียกคิว</Button>
            </Box>
            {value === 0 ?
                <>
                    <DailyAppointments />
                </> : null
            }
            {value === 1 ?
                <>
                    <AskOrganizingRoom/>
                </> : null
            }
            {value === 2 ?
                <>
                    <DoctorCallsQueue />
                </> : null
            }
        </>
    )
}