// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contailner__pageheader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex__content__top {
    display: flex;
    gap: 10px;
    align-items: center;
}

.title__component__pageheader {
    font-size: 16px;
    font-weight: 600;
    color: #222222;
    background-color: transparent;
    border-radius: 8px;
}

.label__date__name {
    color: #747676;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 3rem;
}

@media screen and (max-width: 800px) {
    .flex__content__top {
        gap: 5px;
    }

    .title__component__pageheader {
        font-size: 14px;
    }

    .label__date__name {
        font-size: 14px;
        margin-top: 0px;
        margin-left: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QACf,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".contailner__pageheader {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.flex__content__top {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.title__component__pageheader {\n    font-size: 16px;\n    font-weight: 600;\n    color: #222222;\n    background-color: transparent;\n    border-radius: 8px;\n}\n\n.label__date__name {\n    color: #747676;\n    font-size: 16px;\n    margin-top: 10px;\n    margin-left: 3rem;\n}\n\n@media screen and (max-width: 800px) {\n    .flex__content__top {\n        gap: 5px;\n    }\n\n    .title__component__pageheader {\n        font-size: 14px;\n    }\n\n    .label__date__name {\n        font-size: 14px;\n        margin-top: 0px;\n        margin-left: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
