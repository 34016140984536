import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from '../../components/PageHeader'
import styles from './ViewMedicalHistory.module.css'
import CircleIcon from '@mui/icons-material/Circle';

export default function ViewMedicalHistory() {
    const location = useLocation()
    const [value, setValue] = useState(0)

    const [dataInformationType, setDataInformationType] = useState([])
    const [dataInformation, setDataInformation] = useState([])
    const [menuSelected, setMenuSelected] = useState();

    useEffect(() => {
        console.log(location.state)
        setMenuSelected(Array(location.state.MedicalInfomation.length).fill(0))
        setDataInformationType(location.state.MedicalInfomationType)
        setDataInformation(location.state.MedicalInfomation)
    }, [])

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const dataSetMenu = (index, value) => {
        let tmp = menuSelected;
        if (menuSelected.length != dataInformation.length) {
            tmp = Array(dataInformation.length).fill(0);
        }

        tmp[index] = value;
        setMenuSelected([...tmp]);
    }

    return (
        <Box sx={{color: '#272727'}}>
            <PageHeader title_name="กลับไปหน้ารายชื่อทั้งหมด" back_path='/refer' hide_date />
            <Box sx={{
                color: '#272727',
                fontWeight: 700,
                textAlign: 'center',
                fontSize: '24px',
                marginBottom: 2,
                '@media (max-width: 800px)': {
                    fontSize: 18
                }
            }}
            >ข้อมูลทางการแพทย์</Box>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: 15,
                fontSize: 18,
                marginBottom: 3,
                '@media (max-width: 1023px)': {
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 14,
                }
            }}>
                <div onClick={() => { setValue(0) }} className={value === 0 ? styles.btGreen : styles.btGay} >ประวัติผู้ป่วยนอก</div>
                <div onClick={() => { setValue(1) }} className={value === 1 ? styles.btGreen : styles.btGay} >ประวัติการนอนโรงพยาบาล</div>
            </Box>

            {value === 0 ?
                <>
                    {dataInformationType === 'OPD' ?
                        <Box sx={{
                            display: 'grid',
                            gridGap: '30px'
                        }}>
                            {dataInformation.length > 0 ?
                                <>
                                    {dataInformation.map((i, idx) => (
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 3fr',
                                            border: 1,
                                            borderColor: '#E1E3E9',
                                            borderRadius: '10px',
                                            height: '400px',
                                            '@media (max-width: 800px)': {
                                                gridTemplateColumns: '1fr 2fr',
                                            }
                                        }} key={idx}>
                                            <Box sx={{
                                                backgroundColor: '#E1F4EB',
                                                borderRadius: '10px',
                                                padding: '20px',
                                                display: 'grid',
                                                gridGap: '20px'
                                            }}>
                                                <Box sx={{
                                                    color: '#006838',
                                                    fontWeight: 700,
                                                    fontSize: '24px',
                                                    textAlign: 'center',
                                                    '@media (max-width: 1024px)': {
                                                        fontSize: 18,
                                                    },
                                                    '@media (max-width: 800px)': {
                                                        fontSize: 14,
                                                    }
                                                }}>{convertDate(i['date'])}</Box>
                                                <Button onClick={() => { dataSetMenu(idx, 0) }} variant={menuSelected[idx] === 0 ? 'contained' : 'outlined'}>ประวัติการแพทย์</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 1) }} variant={menuSelected[idx] === 1 ? 'contained' : 'outlined'}>ผลแลป</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 2) }} variant={menuSelected[idx] === 2 ? 'contained' : 'outlined'}>การวินิจฉัย</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 3) }} variant={menuSelected[idx] === 3 ? 'contained' : 'outlined'}>การจ่ายยา</Button>
                                            </Box>

                                            <Box>
                                                {menuSelected[idx] === 0 ?
                                                    <Box sx={{ padding: '20px', height: '350px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการแพทย์</Box>
                                                        {i['visit_information_list'] ?
                                                            <>
                                                                {i['visit_information_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '310px', overflowY: "auto" }}>
                                                                        {i['visit_information_list'].map((item, index) => (
                                                                            <Box key={index} >
                                                                                <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                                <Box sx={{
                                                                                    display: 'grid',
                                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                                    margin: '20px',
                                                                                    '@media (max-width: 800px)': {
                                                                                        gridTemplateColumns: '1fr'
                                                                                    }
                                                                                }}>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>CC</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['cc'] === '' ? '-' : item['cc']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Ht</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['ht'] === '' ? '-' : item['ht']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Pi</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['pi'] === '' ? '-' : item['pi']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Bt</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['bt'] === '' ? '-' : item['bt']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Pe</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['pe'] === '' ? '-' : item['pe']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Rr</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['rr'] === '' ? '-' : item['rr']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Bp</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['bp'] === '' ? '-' : item['bp']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Bmi</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['bmi'] === '' ? '-' : item['bmi']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Pr</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['pr'] === '' ? '-' : item['pr']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>sat O2</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['sat_o2'] === '' ? '-' : item['sat_o2']}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ fontWeight: 700, color: '#006838' }}>Bw</div>
                                                                                        <div style={{ margin: '0 10%' }}>{item['bw'] === '' ? '-' : item['bw']}</div>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 1 ?
                                                    <Box sx={{ padding: '20px', height: '350px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ผลแลป</Box>
                                                        {i['labs_information_list'] ?
                                                            <>
                                                                {i['labs_information_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '310px', overflowY: "auto" }}>
                                                                        {i['labs_information_list'].map((item, index) => (
                                                                            <Box key={index}>
                                                                                <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                                {item['lab_list'].map((i, idx) => (
                                                                                    <Box key={idx}>
                                                                                        <Box sx={{ fontWeight: 700, color: '#006838', fontSize: 16 }}>{i['ordername']}</Box>
                                                                                        {i['detail'].map((item, index) => (
                                                                                            <Box key={index} sx={{ display: 'flex', gridGap: 5, margin: '0 10px' }}>
                                                                                                <CircleIcon style={{ width: '5px' }} /> {item}
                                                                                            </Box>
                                                                                        ))}
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 2 ?
                                                    <Box sx={{ padding: '20px', height: '350px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>การวินิจฉัย</Box>
                                                        {i['diagnosis_information_list'] ?
                                                            <>
                                                                {i['diagnosis_information_list'].length > 0 ?
                                                                    <Box sx={{ height: '310px', overflowY: "auto" }}>
                                                                        {i['diagnosis_information_list'].map((item, index) => (
                                                                            <Box key={index} >
                                                                                <Box sx={{ color: '#006838', fontWeight: 700, marginBottom: '20px' }}>{item['hos_name']}</Box>
                                                                                {item['diagnosis'].map((i, idx) => (
                                                                                    <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 20px' }}>
                                                                                        <CircleIcon style={{ width: '5px' }} /> {i['diag']}
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 3 ?
                                                    <Box sx={{ padding: '20px', height: '350px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>การจ่ายยา</Box>
                                                        {i['order_information_list'] ?
                                                            <>
                                                                {i['order_information_list'].length > 0 ?
                                                                    <Box sx={{ height: '310px', overflowY: "auto" }}>
                                                                        {i['order_information_list'].map((item, index) => (
                                                                            <Box key={index}>
                                                                                <Box sx={{ color: '#006838', fontWeight: 700, marginBottom: '20px' }}>{item['hos_name']}</Box>
                                                                                {item['order_list'].length > 0 ?
                                                                                    <Box>
                                                                                        {item['order_list'].map((i, idx) => (
                                                                                            <Box key={idx} sx={{ display: 'flex', gridGap: 5, margin: '0 20px' }}>
                                                                                                <CircleIcon style={{ width: '5px' }} /> {i['med']}
                                                                                            </Box>
                                                                                        ))}
                                                                                    </Box>
                                                                                    :
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                                }
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }
                                            </Box>
                                        </Box>
                                    ))}
                                </>
                                :
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                            }
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                    }
                </>
                : null
            }

            {value === 1 ?
                <>
                    {dataInformationType === 'IPD' ?
                        <Box sx={{
                            display: 'grid',
                            gridGap: '30px'
                        }}>

                            {dataInformation.length > 0 ?
                                <>
                                    {dataInformation.map((i, idx) => (
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 3fr',
                                            border: 1,
                                            borderColor: '#E1E3E9',
                                            borderRadius: '10px',
                                            '@media (max-width: 800px)': {
                                                gridTemplateColumns: '1fr 2fr',
                                            }
                                        }} key={idx}>
                                            <Box sx={{
                                                backgroundColor: '#E1F4EB',
                                                borderRadius: '10px',
                                                padding: '20px',
                                                display: 'grid',
                                                gridGap: '20px'
                                            }}>
                                                <Box sx={{
                                                    color: '#006838',
                                                    fontWeight: 700,
                                                    fontSize: '24px',
                                                    textAlign: 'center',
                                                    '@media (max-width: 1024px)': {
                                                        fontSize: 18,
                                                    },
                                                    '@media (max-width: 800px)': {
                                                        fontSize: 14,
                                                    }
                                                }}>{convertDate(i['date'])}</Box>
                                                <Button onClick={() => { dataSetMenu(idx, 0) }} variant={menuSelected[idx] === 0 ? 'contained' : 'outlined'}>ประวัติการนอน รพ.</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 1) }} variant={menuSelected[idx] === 1 ? 'contained' : 'outlined'}>การเข้ารับการรักษา</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 2) }} variant={menuSelected[idx] === 2 ? 'contained' : 'outlined'}>การวินิจฉัย</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 3) }} variant={menuSelected[idx] === 3 ? 'contained' : 'outlined'}>การตรวจแลป</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 4) }} variant={menuSelected[idx] === 4 ? 'contained' : 'outlined'}>รายการยา</Button>
                                                <Button onClick={() => { dataSetMenu(idx, 5) }} variant={menuSelected[idx] === 5 ? 'contained' : 'outlined'}>การทำหัตถการ</Button>
                                            </Box>

                                            <Box>
                                                {menuSelected[idx] === 0 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการนอน รพ.</Box>
                                                        {i['visit_information_ipd_list'] ?
                                                            <>
                                                                {i['visit_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        {i['visit_information_ipd_list'].map((item, index) => (
                                                                            <Box>
                                                                                <Box sx={{ color: '#006838', fontWeight: 700 }}>{item['hos_name']}</Box>
                                                                                <Box sx={{ margin: '20px' }}>
                                                                                    <Box sx={{
                                                                                        display: 'grid',
                                                                                        gridTemplateColumns: '1fr 1fr 1fr',
                                                                                        gridGap: '50px 10px',
                                                                                        '@media (max-width: 800px)': {
                                                                                            gridGap: '20px',
                                                                                            gridTemplateColumns: '1fr',
                                                                                        }
                                                                                    }} key={index}>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>วันที่แอดมิด</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{convertDate(item['admit_date'])}</div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่แอดมิด</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{item['admit_time']}</div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>แผนกที่แอดมิด</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{item['admit_clinic']}</div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ดที่แอดมิด</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{item['admit_ward']}</div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ออกจาก รพ.</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{convertDate(item['dch_date'])}</div>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <div style={{ color: '#006838', fontWeight: 700 }}>เวลาที่ออกจาก รพ.</div>
                                                                                            <div style={{ marginLeft: '15px' }}>{item['dch_time']}</div>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }

                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 1 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการเข้ารับการรักษา</Box>
                                                        {i['visit_information_ipd_list'] ?
                                                            <>
                                                                {i['visit_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        <Box sx={{ margin: '20px' }}>
                                                                            {i['visit_information_ipd_list'].map((item, index) => (
                                                                                <Box sx={{
                                                                                    display: 'grid',
                                                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                                                    gridGap: '20px',
                                                                                    '@media (max-width: 800px)': {
                                                                                        gridGap: '10px',
                                                                                        gridTemplateColumns: '1fr',
                                                                                    }
                                                                                }}>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bmi</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['bmi'] ? item['bmi'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bp</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['bp'] ? item['bp'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bt</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['bt'] ? item['bt'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>bw</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['bw'] ? item['bw'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>cc</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['cc'] ? item['cc'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>code</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['code'] ? item['code'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>ht</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['ht'] ? item['ht'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pe</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['pe'] ? item['pe'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pi</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['pi'] ? item['pi'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>pr</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['pr'] ? item['pr'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>rr</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['rr'] ? item['rr'] : '-'}</div>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <div style={{ color: '#006838', fontWeight: 700 }}>sat_o2</div>
                                                                                        <div style={{ marginLeft: '15px' }}>{item['sat_o2'] ? item['sat_o2'] : '-'}</div>
                                                                                    </Box>
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 2 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการวินิจฉัย</Box>
                                                        {i['diagnosis_information_ipd_list'] ?
                                                            <>
                                                                {i['diagnosis_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        {i['diagnosis_information_ipd_list'].map((item, index) => (
                                                                            <Box sx={{ margin: '20px' }} key={index}>
                                                                                {item['diagnosis'].map((i, idx) => (
                                                                                    <Box key={idx}>
                                                                                        <Box sx={{
                                                                                            display: 'grid',
                                                                                            gridTemplateColumns: '1fr 1fr',
                                                                                            '@media (max-width: 800px)': {
                                                                                                gridTemplateColumns: '1fr',
                                                                                                gridGap: '10px'
                                                                                            }
                                                                                        }}>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>วันที่วินิจฉัย</div>
                                                                                                <div style={{ marginLeft: '20px' }}>{convertDate(i['diagnosis_date'])}</div>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <div style={{ color: '#006838', fontWeight: 700 }}>ผลการวินิจฉัย</div>
                                                                                                <div style={{ marginLeft: '20px' }}>{i['diag']}</div>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 3 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการตรวจแลป</Box>
                                                        {i['labs_information_ipd_list'] ?
                                                            <>
                                                                {i['labs_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        {i['labs_information_ipd_list'].map((item, index) => (
                                                                            <Box key={index}>
                                                                                {item['lab_list'].map((i, idx) => (
                                                                                    <Box sx={{ margin: '20px' }}>
                                                                                        <Box key={idx}>
                                                                                            {i['detail'].map((item, index) => (
                                                                                                <Box key={index} sx={{ display: 'flex', gridGap: 5 }}>
                                                                                                    <CircleIcon style={{ width: '5px' }} /> {item}
                                                                                                </Box>
                                                                                            ))}
                                                                                        </Box>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 4 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการจ่ายยา</Box>
                                                        {i['order_information_ipd_list'] ?
                                                            <>
                                                                {i['order_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        {i['order_information_ipd_list'].map((item, index) => (
                                                                            <Box key={index}>
                                                                                {item['order_list'].map((i, idx) => (
                                                                                    <Box key={idx}>
                                                                                        <Box sx={{ margin: '20px' }}>
                                                                                            <Box sx={{
                                                                                                display: 'grid',
                                                                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                                                                gridGap: '20px',
                                                                                                '@media (max-width: 800px)': {
                                                                                                    gridTemplateColumns: '1fr',
                                                                                                    gridGap: '10px'
                                                                                                }
                                                                                            }} key={index}>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ได้รับยา</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{convertDate(i['verify_date'])}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>รายการยา</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['drug']}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วิธีการใช้ยา</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['drugUse']}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>ปริมาณยาที่ได้รับ</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['drugAmount']}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>med</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['med']}</div>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                                {menuSelected[idx] === 5 ?
                                                    <Box sx={{ padding: '20px', height: '500px' }}>
                                                        <Box sx={{ color: '#006838', fontWeight: 700, fontSize: 20 }}>ประวัติการทำหัตถการ</Box>
                                                        {i['proced_information_ipd_list'] ?
                                                            <>
                                                                {i['proced_information_ipd_list'].length > 0 ?
                                                                    <Box sx={{ maxHeight: '440px', overflowY: "auto" }}>
                                                                        {i['proced_information_ipd_list'].map((item, index) => (
                                                                            <Box key={index}>
                                                                                {item['proced'].map((i, idx) => (
                                                                                    <Box key={idx}>
                                                                                        <Box sx={{ margin: '20px' }}>
                                                                                            <Box sx={{
                                                                                                display: 'grid',
                                                                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                                                                gridGap: '20px',
                                                                                                '@media (max-width: 800px)': {
                                                                                                    gridTemplateColumns: '1fr',
                                                                                                    gridGap: '10px'
                                                                                                }
                                                                                            }} key={index}>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วันที่ทำหัตถการ</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{convertDate(i['proced_date'])}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>วอร์ด</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['proced_ward']}</div>
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <div style={{ color: '#006838', fontWeight: 700 }}>รายการ</div>
                                                                                                    <div style={{ marginLeft: '15px' }}>{i['proced']}</div>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box sx={{ borderTop: 1, borderColor: '#006838', margin: '30px 0' }}></Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                ))}
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                                }
                                                            </>
                                                            :
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                                                        }
                                                    </Box>
                                                    : null
                                                }

                                            </Box>
                                        </Box>
                                    ))}
                                </>
                                :
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                            }
                        </Box>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>ไม่พบข้อมูล</Box>
                    }
                </>
                : null
            }

        </Box>
    )
}