import React, { useState } from 'react'
import { Box, Button, Grid, Typography, TextField, Autocomplete } from '@mui/material'
import { buttonPrimary, buttonThird, buttonFourth, contentBox, menuTable, boxContent, boxDepaertment, boxSettigDepartment, boxSearch } from './mainCss'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect } from 'react';
import AddDepartment from './AddDepartment';
import * as apiManage from '../../services/Manage/ApiManageDepartment'
import * as apiOutPatient from '../../services/OutPatient/ApiAddAppointment'
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { useLocation, useNavigate } from 'react-router-dom';
import EditDepartment from './EditDepartment';
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifySuccess, notifyInfo } from '../../components/NotifyToast';
import AddClinic from './AddClinic';
import ManageMedication from './ManageMedication';
import { ManageIcd10 } from './ManageIcd10';


function HospitalPermission() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [dataDepartment, setDataDepartment] = useState([])
    const [modalSuccess, setModalSuccess] = useState(false)
    const routeState = useLocation()
    const [hospital,setHospital] = useState([])
    const [idHospital,setIdHospital] = useState('')
    const [nameHospital,setNameHospital] = useState('')

    useEffect(() => {
        if(localStorage.getItem('hospital_code') === 'N/A'){
            getHospital()
        }
    },[])

    useEffect(() => {
        getDepartment()
    }, [routeState,idHospital])

    const getDepartment = async () => {
        setLoading(true)
        let res = await apiManage.getDepartment(idHospital)
        setLoading(false)
        if (res) {
            // console.log('res ====> ',res)
            if (res['data']['code'] === 200) {
                setDataDepartment(res['data']['data'])
            } else {
                setDataDepartment([])
                // notifyInfo(res['data']['message'])
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ระบบไม่ตอบสนอง')
        }
    }

    const deleteClinicAllDepartment = async (department_code) => {
        setLoading(true)
        let res = await apiManage.deleteClinicAllDepartment(department_code)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                // console.log('ลบสถานพยบาลเสร็จ')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ระบบไม่ตอบสนอง')
        }
    }

    // console.log(routeState)

    const handleTap = (type_tap, rowDepart) => {

        if (type_tap === 'edit_department') {
            return navigate("/auth/manage_user", {
                state: {
                    typeManage: 'hospital',
                    Tap: type_tap,
                    department_code: rowDepart['DepartmentCode'],
                    hospital_code: rowDepart['HospitalCode'] //ทำไว้เผื่อได้ใช้งาน แต่ตอนนี้ไม่ได้ใช้
                }
            })
        }
        if (type_tap === 'addClinic_department') {
            // console.log(rowDepart)
            return navigate("/auth/manage_user", {
                state: {
                    typeManage: 'hospital',
                    Tap: type_tap,
                    department_code: rowDepart['DepartmentCode'],
                }
            })
        }

        if (type_tap === 'drug') {
            // console.log(rowDepart)
            return navigate("/auth/manage_user", {
                state: {
                    typeManage: 'hospital',
                    Tap: type_tap
                }
            })
        }

        // if (type_tap === 'icd') {
        //     // console.log(rowDepart)
        //     return navigate("/auth/manage_user", {
        //         state: {
        //             typeManage: 'hospital',
        //             Tap: type_tap
        //         }
        //     })
        // }
        
        navigate("/auth/manage_user", { state: { typeManage: 'hospital', Tap: type_tap } })

    }

    const submitFormDelete = async (department_code) => {
        setLoading(true)
        let res = await apiManage.deleteDepartment(department_code)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                await deleteClinicAllDepartment(department_code)
                setModalSuccess(true)
                getDepartment()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ระบบไม่ตอบสนอง')
        }
    }

    const getHospital = async() => {
        setLoading(true)
        let res = await apiOutPatient.findHospital()
        setLoading(false)
        if (res) {
          if (res['data']['code'] === 200) {
            // console.log(res)
            // setHospital(res['data']['data'])
            let option = []
            for (let i = 0; i < res['data']['data'].length; i++) {
                if (res['data']['data'][i]) {
                    option.push({
                    key: res['data']['data'][i]['HospitalCode'],
                    label: res['data']['data'][i]['HospitalName']
                    })
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                // console.log(unique)
                setHospital(unique)
            }
          } else {
            notifyInfo((res['data']['message']))
          }
        } else {
          notifyError('ไม่ตอบสนอง')
        }
      }

    return (
        <div>
            <LoaderAnimation loading={loading} />
            {
                modalSuccess ? (
                    <ModalSuccess
                        title={'ลบแผนกงานสำเร็จ'}
                        detail={'ท่านได้ลบแผนกเรียบร้อยแล้ว'}
                        closeModal={() => setModalSuccess(false)}
                        goPage={'closeModal'}
                    />
                ) : ''
            }

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button onClick={() => handleTap('department')}
                            sx={routeState.state['Tap'] === 'department' || routeState.state['Tap'] === 'add_department' || routeState.state['Tap'] === 'edit_department' || routeState.state['Tap'] === 'addClinic_department' ? buttonThird : buttonFourth}
                        >
                            {'จัดการแผนก'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleTap('drug')}
                            sx={routeState.state['Tap'] === 'drug' ? buttonThird : buttonFourth}
                        >
                            {'จัดการรายการยา'}
                        </Button>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Button onClick={() => handleTap('icd')}
                            sx={routeState.state['Tap'] === 'icd' ? buttonThird : buttonFourth}
                        >
                            {'จัดการ ICD10'}
                        </Button>
                    </Grid> */}
                </Grid>
            </Box>

            {
                routeState.state['typeManage'] === 'hospital' && routeState.state['Tap'] === 'department' ?
                    (
                        <Box sx={contentBox}>
                            {
                                localStorage.getItem('hospital_code') === 'N/A' ? 
                                null : 
                                <Box sx={menuTable}>
                                    <Button sx={buttonPrimary} onClick={() => handleTap('add_department')}>
                                        {<><AddOutlinedIcon sx={{ marginRight: '1rem' }} />{'เพิ่มแผนก'}</>}
                                    </Button>
                                </Box>
                            }
                            
                            <Box sx={boxContent}>
                                <Box sx={{ display:'flex', justifyContent:'space-between',alignItems:'center',marginBottom: '2rem' }}>
                                    <Typography component="p" variant="p" sx={{ fontWeight: 'bold'}}>เพิ่มแผนก</Typography>
                                    {
                                        localStorage.getItem('hospital_code') === 'N/A' ?
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            options={hospital}
                                            value={nameHospital}
                                            onChange={(e, value) => {
                                                // console.log(value)
                                                setIdHospital(value?.['key'])
                                                setNameHospital(value?.['label'])
                                            }}
                                            sx={{width:'250px'}}
                                            renderInput={(params) => <TextField {...params} placeholder="เลือกโรงพยาบาล" />}
                                        /> : null
                                    }
                                </Box>
                                
                                <Grid container spacing={1}>
                                    {
                                        dataDepartment.length === 0 ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Box sx={boxDepaertment}>
                                                        <Typography component="p" variant="p" color='primary' sx={{ padding: '1rem' }}>ไม่พบข้อมูล {nameHospital}</Typography>
                                                    </Box>
                                                </Grid>
                                            </> :
                                            <>
                                                {
                                                    dataDepartment.map((data) => (
                                                        <React.Fragment key={data['ID']}>
                                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ marginBottom: '1rem' }}>
                                                                <Box sx={boxDepaertment}>
                                                                    <Typography component="p" variant="p" color='primary'>{data['DepartmentName']}</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4} sm={3} md={3} lg={2} xl={2} sx={{ marginBottom: '1rem' }}>
                                                                <Button
                                                                     onClick={() => handleTap('edit_department',data)}
                                                                     fullWidth
                                                                     variant="contained"
                                                                >
                                                                     {<><ModeIcon sx={{marginRight:'0.5rem'}}/>{'แก้ไข'}</>}
                                                                 </Button>
                                                            </Grid>
                                                            {
                                                                data['DepartmentType'] === 'APPOINTMENT' ? '' :
                                                                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                                                        <Button
                                                                            fullWidth
                                                                            onClick={() => handleTap('addClinic_department', data)}
                                                                            variant="contained"
                                                                            sx={{ fontSize: 'calc(13px + 0.05vw)' }}
                                                                        >
                                                                            {<><ModeIcon sx={{ marginRight: '0.5rem',fontSize: 'calc(15px + 0.05vw)' }} />{'การจัดการ'}</>}
                                                                        </Button>
                                                                    </Grid>
                                                            }
                                                            <Grid item xs={4} sm={3} md={3} lg={2} xl={2} sx={{ marginBottom: '1rem' }}>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => submitFormDelete(data['DepartmentCode'])}
                                                                    variant="contained"
                                                                // sx={{width:'110px'}}
                                                                >
                                                                    {<><DeleteIcon sx={{ marginRight: '0.5rem' }} />{'ลบ'}</>}
                                                                </Button>
                                                                
                                                            </Grid>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    ) : ''
            }

            {
                routeState.state['typeManage'] === 'hospital' && routeState.state['Tap'] === 'add_department' ? (<AddDepartment />) : ''
            }
            {
                routeState.state['typeManage'] === 'hospital' && routeState.state['Tap'] === 'edit_department' ? (<EditDepartment />) : ''
            }
            {
                routeState.state['typeManage'] === 'hospital' && routeState.state['Tap'] === 'addClinic_department' ? (<AddClinic />) : ''
            }


            {
                routeState.state['Tap'] === 'drug' ? <ManageMedication /> : ''
            }
            {/* {
                routeState.state['Tap'] === 'icd' ? <ManageIcd10/> : ''
            } */}
        </div>
    )
}

export default HospitalPermission

// const TapNotification = () => {
//     return (
//         <Box sx={contentBox}>
//             <Box sx={boxContent}>
//                 <Typography component="p" variant="p" sx={{fontWeight:'bold',marginTop:'2rem'}}>รับแจ้งเตือนผ่าน Line Notify</Typography>
//                 <Box sx={boxSearch}>
//                     <TextField fullWidth id="standard-basic" placeholder="กรุณาระบุ Token Line Notify" variant="standard" />
//                 </Box>
//                 <Box sx={{display:'flex',justifyContent:'center'}}>
//                     <Button sx={buttonPrimary}>
//                         {'บันทึก'}
//                     </Button>
//                 </Box>
//             </Box>    
//         </Box>
//     )
// }