import React,{ useState } from 'react'
import { Box, Button,Grid,Typography,TextField,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,FormControl,Select,MenuItem,Stack,Pagination, IconButton,Autocomplete } from '@mui/material'
import { buttonPrimary,buttonThird,buttonFourth,contentBox,menuTable,boxContent, boxDepaertment, boxSettigDepartment,contentMenuMed,
         boxPagination} from './mainCss'
import { useEffect } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useNavigate } from 'react-router-dom';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalAddMedicineGroup from '../../components/ModalController/ModalAddMedicineGroup';
import * as apiManage from '../../services/Manage/ApiManageDrugGroup'
import * as apiOutPatient from '../../services/OutPatient/ApiAddAppointment'
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import ModalEditMedicineGroup from '../../components/ModalController/ModalEditMedicineGroup';
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifyInfo, notifyWarning } from '../../components/NotifyToast';

function ManageMedication() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [openModal,setOpenModal] = useState(false)
    const [typeModal,setTypeModal] = useState('')
    const [medicineGroup,setMedicineGroup] = useState([])

    const [nameDrugGroup,setNameDrugGroup] = useState('')
    const [modalSuccess,setModalSuccess] = useState(false)
    const [messageSuccess,setMessageSuccess] = useState({
        title:'',
        detail:''
    })
    const [idDrugGroup,setIdDrugGroup] = useState('')

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [hospital,setHospital] = useState([])
    const [idHospital,setIdHospital] = useState('')
    const [nameHospital,setNameHospital] = useState('')

    useEffect(() => {
        if(localStorage.getItem('hospital_code') === 'N/A'){
            getHospital()
        }
    },[])

    useEffect(() => {
        getDrugGroup()
    },[idHospital,numPage])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(medicineGroup.slice(start, start + numPage))
    }, [page, medicineGroup])
    
    
      const handlePageChange = (event, page) => {
        setPage(page)
      }

    const th_medication = [
        {
            id: 'th_title01',
            th_name: '#',
        },
        {
            id: 'th_title02',
            th_name: 'กลุ่มยา',
        },
        // {
        //     id: 'th_title03',
        //     th_name: 'จำนวนยา (รายการ)',
        // },
        {
            id: 'th_title04',
            th_name: 'เพิ่มยา',
        },
        {
            id: 'th_title05',
            th_name: 'จัดการ',    
        }
    ]

    const getDrugGroup = async() =>{
        setLoading(true)
        let res = await apiManage.getDrugGroup(idHospital)
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setMedicineGroup(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            }else{
                setMedicineGroup([])
                // notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ระบบไม่ตอบสนอง')
        }
    }

    const goAddMedicinePage = (id_drugGroup,drug_name) => {
        navigate('/addMedicine',{ state: { id_drugGroup: id_drugGroup  , drug_name : drug_name}})
    }

    const handleModal = async(typeModal,id_drugGroup) => {
        if(typeModal === 'add'){
            setNameDrugGroup('')
        }
        if(typeModal === 'edit'){
            setIdDrugGroup(id_drugGroup)
            setLoading(true)
            let res = await apiManage.findDrungGroup(id_drugGroup)
            setLoading(false)
            if(res){
                // console.log("res ====> ", res)
                if(res['data']['code'] === 200){
                        setNameDrugGroup(res['data']['data'][0]['DrugGroupName'])
                }else{
                   notifyInfo(res['data']['message'])
                }
            }else{
                notifyError('ระบบไม่ตอบสนอง')
            }
        }
        setTypeModal(typeModal)
        setOpenModal(true)
    } 


    const submitForm = async(type_form,id_drugGroup) => {
        let res = ''
        switch(type_form) {
            case 'add':
                if(!nameDrugGroup){
                    notifyWarning('กรุณากรอก หมวดยา')
                    return
                }
                setLoading(true)
                res = await apiManage.addDrugGroup(nameDrugGroup)
                setLoading(false)
                if(res){
                    // console.log("res ====> ",res)
                    if(res['data']['code'] === 200){
                        setModalSuccess(true)
                        setMessageSuccess({
                            title:'เพิ่มหมวดยาสำเร็จ',
                            detail:'ท่านได้เพิ่มหมวดยาเรียบร้อยแล้ว'
                        })
                        getDrugGroup()
                        setOpenModal(false)
                    }else{
                        notifyInfo(res['data']['message'])
                    }
                }else{
                    notifyError('ระบบไม่ตอบสนอง')
                }
                break
            case 'edit':
                if(!nameDrugGroup){
                    notifyWarning('กรุณากรอก หมวดยา')
                    return
                }
                setLoading(true)
                res = await apiManage.editDrugGroup(idDrugGroup,nameDrugGroup)
                setLoading(false)
                if(res){
                    // console.log("res ====> ",res)
                    if(res['data']['code'] === 200){
                        setModalSuccess(true)
                        setMessageSuccess({
                            title:'แก้ไขหมวดยาสำเร็จ',
                            detail:'ท่านได้แก้ไขหมวดยาเรียบร้อยแล้ว'
                        })
                        getDrugGroup()
                        setOpenModal(false)
                    }else{
                        notifyInfo(res['data']['message'])
                    }
                }else{
                    notifyError('ระบบไม่ตอบสนอง')
                }
                break
            case 'delete':
                setLoading(true)
                res = await apiManage.deleteDrugGroup(id_drugGroup)
                setLoading(false)
                    if(res){
                        // console.log("res ====> ",res)
                        if(res['data']['code'] === 200){
                            setModalSuccess(true)
                            setMessageSuccess({
                                title:'ลบหมวดยาสำเร็จ',
                                detail:'ท่านได้ลบหมวดยาเรียบร้อยแล้ว'
                            })
                            getDrugGroup()
                            setOpenModal(false)
                        }else{
                            notifyInfo(res['data']['message'])
                        }
                    }else{
                        notifyError('ระบบไม่ตอบสนอง')
                    }
                break
            default: break
        }
    }

    const getHospital = async() => {
        setLoading(true)
        let res = await apiOutPatient.findHospital()
        setLoading(false)
        if (res) {
          if (res['data']['code'] === 200) {
            // console.log(res)
            // setHospital(res['data']['data'])
            let option = []
            for (let i = 0; i < res['data']['data'].length; i++) {
                if (res['data']['data'][i]) {
                    option.push({
                    key: res['data']['data'][i]['HospitalCode'],
                    label: res['data']['data'][i]['HospitalName']
                    })
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                // console.log(unique)
                setHospital(unique)
            }
          } else {
            notifyInfo((res['data']['message']))
          }
        } else {
          notifyError('ไม่ตอบสนอง')
        }
      }

    if (openModal) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }

  return (
    <div>
        <LoaderAnimation loading={loading} />
        {
            openModal && typeModal === 'add' ? (
                <ModalAddMedicineGroup 
                    value={nameDrugGroup}
                    hospital={hospital}
                    nameHospital={nameHospital}
                    onChangeHospital={         
                         (e, value) => {
                        // console.log(value)
                        setIdHospital(value?.['key'])
                        setNameHospital(value?.['label'])
                    }}
                    onChange={(e) => setNameDrugGroup(e.target.value)}
                    submitForm={() => submitForm('add')}
                    closeModal={() => setOpenModal(false)}
                />
            ) : ''
        }
        {
             openModal && typeModal === 'edit' ? (
                <ModalEditMedicineGroup
                    value={nameDrugGroup}
                    onChange={(e) => setNameDrugGroup(e.target.value)}
                    submitForm={() => submitForm('edit')}
                    closeModal={() => setOpenModal(false)}
                />
             ) : ''
        }
        {
            modalSuccess? (
                <ModalSuccess
                    title={messageSuccess['title']}
                    detail={messageSuccess['detail']}
                    closeModal={() => setModalSuccess(false)}
                    goPage={'closeModal'}
                />
            ) : ''
        }
        <Box sx={contentMenuMed}>
            {
                localStorage.getItem('hospital_code') === 'N/A' ? null :
                <Button sx={buttonPrimary} onClick={() => handleModal('add')}>
                    <><AddOutlinedIcon sx={{marginRight:'1rem'}}/>เพิ่มหมวดยา</>
                </Button> 
            }
            
            {
                localStorage.getItem('hospital_code') === 'N/A' ?
                <Autocomplete
                    size='small'
                    disablePortal
                    options={hospital}
                    value={nameHospital}
                    onChange={(e, value) => {
                        // console.log(value)
                        setIdHospital(value?.['key'])
                        setNameHospital(value?.['label'])
                    }}
                    sx={{width:'250px'}}
                    renderInput={(params) => <TextField {...params} placeholder="เลือกโรงพยาบาล" />}
                /> : null
            }
        </Box>
        <Box sx={contentBox}>
            {
                // medicineGroup.length > 0 ? (
                    <>
                        <TableContainer >
                            <Table sx={{ minWidth: 800 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        { th_medication.map((th,index) => (
                                            <TableCell key={th['id']} align="center" sx={index === 0 ? {width:'15%',fontWeight: '600'} :{fontWeight: '600'}}>{th['th_name']}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        showList.length === 0 ? 
                                        <>
                                        <TableRow>
                                            <TableCell 
                                                colSpan={12}
                                                align='center'
                                            >
                                                <Typography 
                                                component={"p"}
                                                sx={{margin:'1rem 0'}}
                                                >
                                                    ไม่พบข้อมูล {nameHospital}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        </> : 
                                        <>
                                            {showList.map((data,index) => (
                                                <TableRow key={data['ID']}>
                                                    <TableCell align="center">{numPage * (page - 1) + 1 + index}</TableCell>
                                                    <TableCell align='left'>{data['DrugGroupName']}</TableCell>
                                                    {/* <TableCell align='center'>{'-'}</TableCell> */}
                                                    <TableCell align='center'>
                                                    <Button sx={buttonPrimary} onClick={() => goAddMedicinePage(data['ID'],data['DrugGroupName'])}>
                                                        {'เพิ่มยา'}
                                                    </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem'}}>
                                                            <IconButton onClick={() => handleModal('edit',data['ID'])}>
                                                                <ModeIcon sx={{color:'#006838'}} />
                                                            </IconButton>
                                                            <IconButton onClick={() => submitForm('delete',data['ID'])}>
                                                                <DeleteIcon sx={{color:'#006838'}}/>
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={boxPagination}>
                            <Box sx={{width:'80px'}}>
                                <FormControl fullWidth>
                                    <Select 
                                        value={numPage}
                                        onChange={(e) => {
                                            const {
                                                target: { value },
                                            } = e;
                                            setNumPage(value)
                                        }}
                                        size='small'
                                    
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select> 
                                </FormControl>
                            </Box>
                            <Stack spacing={2}>
                            <Pagination 
                                count={maxPage}
                                page={page}
                                onChange={handlePageChange}
                                shape="rounded" color="primary"
                            />
                            </Stack>
                        </Box>
                    </>
                    // ) : (
                    //     <Typography component="p" variant="p" sx={{fontWeight:'bold',textAlign:{xs:'center'},margin:'2rem 0'}}>ไม่มีรายการหมวดยา</Typography>
                    // )
                        }
                    </Box>
    </div>
  )
}

export default ManageMedication