import React from 'react'
import { Box, Button,Grid,Typography,TextField,Switch,FormControlLabel,Checkbox } from '@mui/material'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import { buttonPrimary,contentBox,boxContent,boxAddRoom,contentAddRoom,boxService, contentService, buttonSecondary,boxSearch } from './mainCss'
import { styled } from '@mui/material/styles'
import { useState,useEffect } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import * as apiPublic from '../../services/public_api'
import * as apiManage from '../../services/Manage/ApiManageDepartment' 
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { useLocation } from 'react-router-dom';
import LoaderAnimation from '../../components/LoaderAnimation';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalAddHospital from '../../components/ModalController/ModalAddHospital';

import { notifyError , notifyInfo , notifySuccess , notifyWarning } from '../../components/NotifyToast';

dayjs.extend(utc);
dayjs.extend(timezone);

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#006838',
            opacity: 1,
            border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
        },
        '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        },
        '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        },
    }))

function EditDepartment() {

  const routerState = useLocation()
  const [loading, setLoading] = useState(true)
  const [dataDepartment,setDataDepartment] = useState([])
  const [divLink, setDivLink] = useState([])
  const [modalSuccess,setModalSuccess] = useState(false)

  const date = dayjs().tz('Asia/Bangkok').set('hour', 0).set('minute', 0)
  const timeDefult = date['$d'].toLocaleString('th-TH', { hour: '2-digit', minute: '2-digit' })

  const [department,setDepartment] = useState({
    department_code: '',
    clinic_code:'',
    department_name: '',
    department_type: '',
    location:'',
    room_conference:'',
    line_notify_token:''
  })

    // const [div, setDiv] = useState([]);
    // const [dataInput, setDataInput] = useState([]);

  const [day,setDay] = useState([
    {
      day: 'วันอาทิตย์',
      status_open: false,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันจันทร์',
      status_open: true,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันอังคาร',
      status_open: true,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันพุธ',
      status_open: true,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันพฤหัสบดี',
      status_open: false,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันศุกร์',
      status_open: false,
      time_open: timeDefult,
      time_close: timeDefult
    },
    {
      day: 'วันเสาร์',
      status_open: false,
      time_open: timeDefult,
      time_close: timeDefult
    }
  ])

//   const [addHospital,setAddHospital] = useState(false)
//   const [formAdd,setFormAdd] = useState({
//     hospital_code : '',
//     hospital_name: ''
//   })
  const [divHospital,setDivHospital] = useState([])
  const [modalSuccessAddHospital,setModalAddHospital] = useState(false)

  useEffect(()=>{
    findDepartment()
    getDepartment()
    // console.log(divLink)
  },[])

  useEffect(() => {
    handleCreateDiv()
  },[department['room_conference']])

  const getDepartment = async() => {
      setLoading(true)
      let res = await apiPublic.findDepartment()
      setLoading(false)
      if(res){
        console.log(res['data']['data'])
          if(res['data']['code'] === 200){
              setDataDepartment(res['data']['data'])
          }else{
              return notifyInfo(res['data']['message'])
          }
      }else{
          return notifyError('ระบบไม่ตอบสนอง')
      }
  }

  const findDepartment = async() => {
    setLoading(true)
    let res = await apiManage.findDepartment(routerState.state['department_code'])
    setLoading(false)
    if(res){
    //   console.log("res ====> ", res['data']['data'])
      if(res['data']['code'] === 200){
        let link = res['data']['data'][0]['RoomConference']
        // console.log(link.length)
        console.log(link)

        let arrayLink = []
        link.map((item) => {
            arrayLink.push(item['ConferenceURL'])
        })
        // console.log(arrayLink)

        setDivLink(arrayLink)

        const updateData = {
          department_code: res['data']['data'][0]['DepartmentCode'],
          clinic_code: res['data']['data'][0]['ClinicCode'],
          department_name: res['data']['data'][0]['DepartmentName'],
          department_type: res['data']['data'][0]['DepartmentType'],
          location:res['data']['data'][0]['RoomConference'][0]['Location'],
          room_conference: link.length,
          line_notify_token: res['data']['data'][0]['LineNotifyToken']
        }

        setDepartment(updateData)
        // setLinkConference(arrayLink)
       

        const updateDay = [
          {
            day: 'วันอาทิตย์',
            status_open: res['data']['data'][0]['DepartmentDetail']['Sunday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Sunday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Sunday']['TimeClose']
          },
          {
            day: 'วันจันทร์',
            status_open: res['data']['data'][0]['DepartmentDetail']['Monday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Monday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Monday']['TimeClose']
          },
          {
            day: 'วันอังคาร',
            status_open: res['data']['data'][0]['DepartmentDetail']['Tuesday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Tuesday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Tuesday']['TimeClose']
          },
          {
            day: 'วันพุธ',
            status_open: res['data']['data'][0]['DepartmentDetail']['Wednesday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Wednesday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Wednesday']['TimeClose']
          },
          {
            day: 'วันพฤหัสบดี',
            status_open: res['data']['data'][0]['DepartmentDetail']['Thursday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Thursday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Thursday']['TimeClose']
          },
          {
            day: 'วันศุกร์',
            status_open: res['data']['data'][0]['DepartmentDetail']['Friday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Friday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Friday']['TimeClose']
          },
          {
            day: 'วันเสาร์',
            status_open: res['data']['data'][0]['DepartmentDetail']['Saturday']['Open'],
            time_open: res['data']['data'][0]['DepartmentDetail']['Saturday']['TimeOpen'],
            time_close: res['data']['data'][0]['DepartmentDetail']['Saturday']['TimeClose']
          }
        ]

        setDay(updateDay)

        // setDivHospital(res['data']['data'][0]['Clinic'])
      }else{
        return notifyInfo(res['data']['message'])
      }
    }else{
      return notifyError('ระบบไม่ตอบสนอง')
    }
  }

  const handleSwitch = (e,index,name) => {
    const updateDay = [...day]
    updateDay[index] = { ...updateDay[index], [name]: !updateDay[index][name] }
    setDay(updateDay)
  }

  const handelOnChange = (e) => {
    const {name,value} = e.target
    if(name === 'room_conference'){
        const maxLength = 2;
        const numericRegex = /^[0-9]*$/;
        if (value.length <= maxLength && numericRegex.test(value)) {
            setDepartment((prevDepartment) => ({ ...prevDepartment, [name]: value }));
        }
    }else{
        setDepartment((prevDepartment) => ({ ...prevDepartment, [name]: value }));
    }
  }

  const handleOnChangeDay = (time,index,name) => {
    const setTime = time['$d'].toLocaleString('th-TH', { hour: '2-digit', minute: '2-digit' })
    const updateDay = [...day];
    updateDay[index] = { ...updateDay[index], [name]: setTime };
    setDay(updateDay);    
  }

  const handleDivInputChange = (index, event) => {
    const newDivLink = [...divLink];
    newDivLink[index] = event.target.value;
    setDivLink(newDivLink);
    console.log(index)
  }

  const handleCreateDiv = () => {
    const numDivs = parseInt(department['room_conference'], 10);
    if (!isNaN(numDivs)) {
        let numArray = numDivs
        const fill = divLink
        const newDivLink = Array(numDivs).fill('').map((value, index) => index < fill.length ? fill[index] : value)
    //   console.log(newDivLink)
      setDivLink(newDivLink);
    }
  }

  const getClinic = async() => {
    setLoading(true)
    let res = await apiManage.getClinic(department['department_code'])
    setLoading(false)
    if(res){
        // console.log("res getClinic ====> ",res)
        if(res['data']['code'] === 200){
            setDivHospital(res['data']['data'])
        }else{
            setDivHospital([])
        }
    }else{
        return notifyError('ระบบไม่ตอบสนอง')
    }
  }

//     const addMoreHospital = async() => {
//         if(!formAdd['hospital_name'] && !formAdd['hospital_code']){
//             return notifyWarning('กรุณาเลือก สถานพยาบาล')
//         }
//         if(!department['department_code']){
//             return notifyWarning('กรุณากรอก รหัสแผนก')
//         }

//         setLoading(true)
//         let res = await apiManage.addClinic(department['department_code'],formAdd['hospital_code'],formAdd['hospital_name'])
//         setLoading(false)
//         if(res){
//             // console.log("res ====> ",res)
//             if(res['data']['code'] === 200){
//                 await getClinic()
//                 setAddHospital(false)
//             }else{
//                 return notifyInfo(res['data']['message'])
//             }
//         }else{
//             return notifyError('ระบบไม่ตอบสนอง')
//         }
//   }

    // const deleteHospital = async(id_delete) => {
    //     // console.log("id_delete",id_delete)
    //     setLoading(true)
    //     let res = await apiManage.deleteClinic(id_delete)
    //     setLoading(false)
    //     if(res){
    //         if(res['data']['code'] === 200){
    //             await getClinic()
    //             setModalAddHospital(true)
    //         }
    //     }else{
    //         return notifyError('ระบบไม่ตอบสนอง')
    //     }
    // }

  const submitForm = async() => {
    // console.log(linkConference)
    const check_department_code = !department['clinic_code'] ? /[A-Z]/.test(department['department_code']) : /[A-Z]/.test(department['clinic_code'])

    if(!department['department_name']){
        return notifyWarning('กรุณากรอก ชื่อแผนก')
    }
    if(department['department_type'] === 'APPOINTMENT'){
        if(!department['department_code']){
            return notifyWarning('กรุณากรอก รหัสแผนก')
        }
    }else{
        if(!department['clinic_code']){
            return notifyWarning('กรุณากรอก รหัสแผนก1')
        }
    }
  
    if(!check_department_code){
        return notifyWarning('รหัสแผนก ต้องระบุตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว')
    }
    if(!department['location']){
        return notifyWarning('กรุณากรอก สถานที่')
    }
    if(!department['department_type']){
        return notifyWarning('กรุณากรอก แผนก')
    }
    // if(!department['line_notify_token']){
    //     return notifyWarning('กรุณากรอก token line notify')
    // }
    // console.log("div Link ====>",divLink)

    setLoading(true)
    let res = await apiManage.editDepartment(
        department['department_code'],
        department['department_name'],
        department['department_type'],
        department['location'],
        divLink,
        day,
        department['line_notify_token'],
        divHospital
        )
    setLoading(false)
    if(res){
        if(res['data']['code'] === 200){
                setModalSuccess(true)
        }else{
            return notifyInfo(res['data']['message'])
        }
    }else{
        return notifyError('ระบบไม่ตอบสนอง')
    }
  }
  // console.log(divLink)
  return (
    <div>
        <LoaderAnimation loading={loading} />
       {
            modalSuccess? (
                <ModalSuccess
                    title={'แก้ไขแผนกสำเร็จ'}
                    detail={'ท่านได้แก้ไขแผนกเรียบร้อยแล้ว'}
                    closeModal={() => setModalSuccess(false)}
                    goPage={'/auth/manage_user'}
                    state={ {typeManage: 'hospital', Tap: 'department'}}
                />
            ) : ''
        }
                {/* {
            addHospital? (
                <ModalAddHospital
                    closeModal={() => setAddHospital(false)}
                    selectedValue={setFormAdd}
                    submitForm={() => addMoreHospital()}
                />
            ) : ''
        } */}
        {
            modalSuccessAddHospital ? (
                <ModalSuccess
                    title={'ลบสถานพยาบาลที่ต้องการ ขอคำปรึกษา'}
                    detail={'ท่านได้ลบสถานพยาบาลที่ต้องการ ขอคำปรึกษาเรียบร้อยแล้ว'}
                    closeModal={() => setModalAddHospital(false)}
                    goPage={'closeModal'}
                />
            ) : ''
        }
          <Box sx={contentBox}>
            <Box sx={boxContent}>
                <Typography component="p" variant="p" sx={{fontWeight:'bold',marginBottom:'2rem'}}>แก้ไขแผนก</Typography>
                <Grid container spacing={2}>
                    <Grid item  xs={12} sm={12} md={6} xl={6}>
                        <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ชื่อแผนก</Typography>
                        <TextField fullWidth  placeholder="กรุณากรอกชื่อแผนก" variant="outlined" 
                            name='department_name'
                            value={department['department_name']}
                            onChange={handelOnChange}
                        />
                    </Grid>
                    <Grid item  xs={12} sm={12} md={6} xl={6}>
                        <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>รหัสแผนก</Typography>
                        <TextField disabled fullWidth  placeholder="กรุณากรอกรหัสแผนก โดยระบุ A-Z พิมพ์ใหญ่จำนวน 1 ตัว" variant="outlined" 
                            name='department_code'
                            value={!department['clinic_code'] ? department['department_code'] : department['clinic_code']}
                            onChange={handelOnChange}
                        />
                    </Grid>
                    {
                        day.map((data,index) => (
                        <React.Fragment key={index}>
                            <Grid item  xs={12} sm={12} md={3} xl={1.5}>
                                <Box sx={{display:'flex',justifyContent:'space-between',gridGap:'1rem',alignItems:'center',height:'100%'}}>
                                <Typography component="p" variant="p" sx={{fontWeight:'bold'}}>{data['day']}</Typography>
                                    <FormControlLabel control={<IOSSwitch name={data['id']} checked={data['status_open']}
                                     onChange={(e) => handleSwitch(e,index,'status_open')}
                                      />}/>
                                </Box>
                            </Grid>
                            <Grid item  xs={12} sm={12} md={4.5} xl={5.25}>
                                <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>เวลาเปิดทำการ</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <TimePicker sx={{width:'100%'}}
                                        ampm={false}
                                        value={day[index]['time_open'] ? dayjs(day[index]['time_open'], 'HH:mm') : dayjs().set('hour', 0).set('minute', 0)}
                                        onChange={(time) => handleOnChangeDay(time,index,'time_open')}
                                        disabled={data['status_open'] ? false : true} />
                                </LocalizationProvider>
                                    
                            </Grid>
                            <Grid item  xs={12} sm={12} md={4.5} xl={5.25}>
                                <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>เวลาปิดทำการ</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <TimePicker  sx={{width:'100%'}}
                                        ampm={false}
                                        value={day[index]['time_close'] ? dayjs(day[index]['time_close'], 'HH:mm') : dayjs().set('hour', 0).set('minute', 0)}
                                        onChange={(time) =>handleOnChangeDay(time,index,'time_close')} 
                                        disabled={data['status_open'] ? false : true} />
                                </LocalizationProvider>
                            </Grid>                            
                        </React.Fragment>
                        ))
                    }
                </Grid>
            </Box>
        </Box>

        {/* <Box sx={contentBox}>
             <Box sx={boxContent}>
                <Typography component="p" variant="p" sx={{fontWeight:'bold',marginBottom:'2rem'}}>
                    เพิ่มสถานพยาบาลที่ต้องการ ขอคำปรึกษา
                </Typography>
                <Grid container spacing={3}>
                    {
                        divHospital.map((item,idex) => (
                            <Grid key={idex} item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Box sx={{border:'1px solid #E1E3E9',padding:'0.5rem',borderRadius:'0.5rem',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography component="p" variant="p" 
                                        sx={{fontWeight:'bold'}}>
                                        {item['ClinicName']}
                                    </Typography>
                                    <Button
                                        onClick={() => deleteHospital(item['ID'])}
                                    >
                                        <DeleteIcon/>
                                        <Typography component="span" variant="span" 
                                            sx={{fontWeight:'bold',marginLeft:'0.25rem'}}>
                                            ลบ
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
                <Box 
                    sx={{display:'flex',justifyContent:'center',marginTop:'2rem'}}
                >
                    <Button
                        sx={buttonPrimary}
                        onClick={() => setAddHospital(true)}
                    >
                        เพิ่มสถานพยาบาล
                    </Button>
                </Box>
            </Box>
        </Box> */}

        <Box sx={contentBox}>
            <Box sx={boxContent}>
                <Typography component="p" variant="p" sx={{fontWeight:'bold',marginBottom:'2rem'}}>เพิ่มห้อง</Typography>
                <Box sx={contentAddRoom}>
                    <Grid container spacing={5}>
                        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                            <Box sx={boxAddRoom}>
                                <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>สถานที่</Typography>
                                <TextField fullWidth  placeholder="อาคาร telemed" variant="outlined"
                                    name='location'
                                    value={department['location']}
                                    onChange={handelOnChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                            <Box sx={boxAddRoom}>
                                <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>จำนวนห้อง</Typography>
                                <TextField fullWidth  placeholder="จำนวนห้อง" variant="outlined"
                                    type='text'
                                    value={department['room_conference']}
                                    name='room_conference'
                                    onChange={handelOnChange}
                                />
                            </Box>
                        </Grid>
                        {
                            divLink.map((value,index) => (
                                <Grid item key={`link${index+1}`} xs={6} sm={6} md={6} lg={4} xl={4}>
                                    <Box  sx={boxAddRoom}>
                                        <Box>
                                        <Typography component="p" variant="p" sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>Link Conference</Typography>
                                        <TextField fullWidth  placeholder="http://linkconference.com" variant="outlined"
                                        value={divLink[index]}
                                        onChange={(event) => handleDivInputChange(index, event)}/>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>                
                </Box>
            </Box>
        </Box>

        <Box sx={contentBox}>
            <Box sx={boxContent}>
                <Typography component="p" variant="p"  sx={{fontWeight:'bold',marginBottom:'2rem'}}>การเปิดให้บริการ</Typography>
                <Box sx={contentService}>
                    {
                        dataDepartment.map((item,idex) => (
                            <Box key={idex} sx={boxService}>
                                <Checkbox  checked={department['department_type'] === item['DepartmentTypeCode'] } name='department_type' value={item['DepartmentTypeCode']} onChange={handelOnChange}/>
                                <Typography component="p" variant="p"  color={'#747676'} sx={{fontWeight:'bold'}}>{item['DepartmentTypeName']}</Typography>
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </Box>

        <Box sx={contentBox}>
            <Box sx={boxContent}>
                <Typography component="p" variant="p" sx={{fontWeight:'bold',marginTop:'2rem'}}>รับแจ้งเตือนผ่าน Line Notify</Typography>
                <Box sx={boxSearch}>
                    <TextField 
                        fullWidth 
                        placeholder="กรุณาระบุ Token Line Notify" 
                        variant="standard" 
                        value={department['line_notify_token']}
                        name='line_notify_token'
                        onChange={handelOnChange}
                        />
                </Box>
                <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1rem'}}>
                    <Button sx={buttonPrimary}
                        onClick={() => submitForm()}
                        >
                        {'แก้ไขแผนก'}
                    </Button>
                </Box>
            </Box>    
        </Box>
    </div>
  )
}

export default EditDepartment