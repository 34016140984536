import React from 'react'
import { Typography,Box,Button } from '@mui/material'
import {buttomPrimary} from './ModalAccessCss'
import passStatus from '../../assets/icons/correct_status.svg'
import { useNavigate } from 'react-router-dom';
import bgImg from '../../assets/img/bg_login$register.png'

function ModalRegister() {
    const navigate = useNavigate()

    const backgroundModal = {
        backgroundImage: `url(${bgImg})`,
        position: 'fixed',
        width: '100%',
        minHeight: '100vh',
        height:'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '0',
        left: '0',
        zIndex: 999,
    }
    
    const boxModal2 = {
        backgroundColor: '#fff',
        width: 'calc(350px + 20vw)',
        // minHeight:'100vh',
        height: 'auto',
        padding: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem' },
        borderRadius: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        margin:'1rem 0',
    }
  return (
    <Box sx={backgroundModal}>
        <Box sx={{textAlign:'center',...boxModal2}}>
            <Box sx={{marginBottom:'1rem'}}>
                <img src={passStatus} alt=''/>
            </Box>
            <Typography component="h1" variant="h1" color='#92D050' sx={{fontSize:'calc(20px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>ลงทะเบียนสำเร็จ</Typography>  
            <Typography component="p" variant="p" color='secondary' sx={{fontSize:'calc(14px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>
                กรุณาติดต่อผู้ดูแลระบบของสถานพยาบาลของท่าน <br/>
                เพื่อเปิดใช้งานระบบ
            </Typography>  
            <Typography component="p" variant="p" color='secondary' sx={{fontSize:'calc(14px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>
                จากนั้นตรวจสอบข้อความ และยืนยันตัวตนในอีเมลของคุณก่อนเข้าใช้งาน
            </Typography>
            <hr style={{border: '0.5px solid #E1E3E9'}}/>
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
                <Button 
                    onClick={() => navigate('/')}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    กลับไปหน้าเข้าสู่ระบบ
                </Button>            
            </Box>        
        </Box>
    </Box>
  )
}

export default ModalRegister