import React from 'react'
import { Box, Button, TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Stack,Pagination,
    TextField,Typography,FormControl,InputLabel,Select,MenuItem, IconButton } from '@mui/material'
import PageHeader from '../../components/PageHeader';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { boxContent,contentBox,boxAddMed,buttonPrimary,boxPagination } from './mainCss';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';

import { useState } from 'react';
import ModalAddMedicine from '../../components/ModalController/ModalAddMedicine';
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import * as apiManage from '../../services/Manage/ApiManageDrug'
import ModalEditMedicine from '../../components/ModalController/ModalEditMedicine';
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifyInfo, notifySuccess , notifyWarning } from '../../components/NotifyToast';


function AddMedicine() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    // const [page, setPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(5)
    const routeState = useLocation()
    const [openModal,setOpenModal] = useState(false)
    const [typeModal,setTypeModal] = useState('')
    const [dataDrug,setDataDrug] = useState([])
    const [drugIdGroup,setDrungIdGroup] = useState('')
    const [drugId,setDrungId] = useState('')
    const [drugNameGroup,setDrugNameGroup] = useState('')
    const [drug,setDrug] = useState({
        drug_name: '',
        drug_detail: ''
    })
    const [modalSuccess,setModalSuccess] = useState(false)
    const [messageSuccess,setMessageSuccess] = useState({
        title:'',
        detail:''
    })

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    useEffect(() => {
        if(routeState.state === null){
            return navigate('/auth/manage_user',{state: {typeManage: 'medication'}})
            
        }else{
            findDrugIdDrugGroup()
            setDrungIdGroup(routeState.state.id_drugGroup)
            setDrugNameGroup(routeState.state.drug_name)
        }
    },[])

    useEffect(() => {
        findDrugIdDrugGroup()
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(dataDrug.slice(start, start + numPage))
    }, [page, dataDrug])
    
    
      const handlePageChange = (event, page) => {
        setPage(page)
      }

    const findDrugIdDrugGroup = async() => {
        setLoading(true)
        let res = await apiManage.findDrugIdDrugGroup(routeState.state.id_drugGroup)
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setDataDrug(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            }else{
                // console.log("res ====> ",res)
                setDataDrug([])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const th_medication = [
        {
            id: 'th_title01',
            th_name: '#',
        },
        {
            id: 'th_title02',
            th_name: 'ชื่อยา',
        },
        {
            id: 'th_title03',
            th_name: 'รายละเอียด',
        },
        {
            id: 'th_title04',
            th_name: 'แก้ไข',
        },
        {
            id: 'th_title05',
            th_name: 'ลบ',
        }
    ]

    // console.log('id_drugGroup ====> ', idDrugCode) 
    // console.log("routeState ====> ", routeState)

    const handleModal = async(typeModal,id_drug) => {
        if(typeModal === 'add'){
            setDrug({
                drug_name:'',
                drug_detail:''
            })
        }
        if(typeModal === 'edit'){
            setDrungId(id_drug)
            setLoading(true)
            let res = await apiManage.findDrug(drugIdGroup,id_drug)
            setLoading(false)
            if(res){
                // console.log("res ====> ", res)
                if(res['data']['code'] === 200){
                    setDrug({
                        drug_name:res['data']['data'][0]['DrugName'],
                        drug_detail:res['data']['data'][0]['DrugDescription']
                    })
                }else{
                    notifyInfo(res['data']['message'])
                }
            }else{
                notifyError('ไม่ตอบสนอง')
            }
        }
        setTypeModal(typeModal)
        setOpenModal(true)
    } 

    const handleOnChange = (e) => {
        const {name,value} = e.target
        setDrug({...drug,[name]: value})
    }

    const submitForm = async(type_form,id_drug) => {
        let res = ''
        switch(type_form){
            case 'add' : 
                    if(!drug['drug_name']){
                        return notifyWarning('กรุณากรอก ชื่อยา')
                    }
                    if(!drug['drug_detail']){
                        return notifyWarning('กรุณากรอก คำอธิบาย')
                    }
                    setLoading(true)
                    res = await apiManage.addDrug(drugIdGroup,drug['drug_name'],drug['drug_detail'])
                    setLoading(false)
                    if(res){
                        // console.log("res ====> ",res)
                        if(res['data']['code'] === 200){
                            setModalSuccess(true)
                            setMessageSuccess({
                                title: 'เพิ่มชื่อยาสำเร็จ',
                                detail: 'ท่านได้เพิ่มชื่อยาเรียบร้อยแล้ว'
                            })
                            findDrugIdDrugGroup()
                            setOpenModal(false)
                        }else{
                            notifyInfo(res['data']['message'])
                        }
                    }else{
                        notifyError('ไม่ตอบสนอง')
                    }
                break
            case 'edit' :
                    if(!drug['drug_name']){
                        return notifyWarning('กรุณากรอก ชื่อยา')
                    }
                    if(!drug['drug_detail']){
                        return notifyWarning('กรุณากรอก คำอธิบาย')
                    }
                    setLoading(true)
                    res = await apiManage.editDrug(drugId,drug['drug_name'],drug['drug_detail'])
                    setLoading(false)
                    if(res){
                        // console.log("res ====> ",res)
                        if(res['data']['code'] === 200){
                            setModalSuccess(true)
                            setMessageSuccess({
                                title: 'จัดการแก้ไขสำเร็จ',
                                detail: 'ท่านได้แก้ไขรายการยาเรียบร้อยแล้ว'
                            })
                            findDrugIdDrugGroup()
                            setOpenModal(false)
                        }else{
                            notifyInfo(res['data']['message'])
                        }
                    }else{
                        notifyError('ไม่ตอบสนอง')
                    }
                break
            case 'delete' :
                    setLoading(true)
                    res = await apiManage.deleteDrug(id_drug)
                    setLoading(false)
                    if(res){
                        // console.log("res ====> ",res)
                        if(res['data']['code'] === 200){
                            setModalSuccess(true)
                            setMessageSuccess({
                                title: 'ลบรายการยาสำเร็จ',
                                detail: 'ท่านได้ลบรายการยาเรียบร้อยแล้ว'
                            })
                            findDrugIdDrugGroup()
                            setOpenModal(false)
                        }else{
                            notifyInfo(res['data']['message'])
                        }
                    }else{
                        notifyError('ไม่ตอบสนอง')
                    }
                break
            default: break
        }
    }

  return (
    <div>
        <LoaderAnimation loading={loading} />
        {
            openModal && typeModal === 'add' ? (
                <ModalAddMedicine
                    data= {drug}
                    onChange={handleOnChange}
                    submitForm={() => submitForm('add')}
                    closeModal={() => setOpenModal(false)}
                />
            ) : ''
        }
        {
            openModal && typeModal === 'edit' ? (
                <ModalEditMedicine
                    data={drug}
                    onChange={handleOnChange}
                    submitForm={() => submitForm('edit')}
                    closeModal={() => setOpenModal(false)}
                />
            ) : ''
        }
        {
            modalSuccess ? (
                <ModalSuccess
                    title={messageSuccess['title']}
                    detail={messageSuccess['detail']}
                    closeModal={() => setModalSuccess(false)}
                    goPage={'closeModal'}
                />
            ) : ''
        }
        
        <PageHeader title_name={'กลับไปที่จัดการรายการยา'} back_path={'/auth/manage_user'} state={{typeManage: 'hospital', Tap: 'drug'}} hide_date={true}/>
        <Box sx={contentBox}>
           <Box sx={boxContent}>
                <Box sx={boxAddMed}>
                    <Typography component="p" variant="p" sx={{fontWeight:'bold',textAlign:{xs:'center'},margin:'1rem 0'}}>กลุ่มยา {drugNameGroup}</Typography>
                    <Button sx={buttonPrimary} onClick={() => handleModal('add')}>
                        <><AddOutlinedIcon sx={{marginRight:'1rem'}}/>{'เพิ่มชื่อยา'}</>
                    </Button>
                </Box>
           </Box>
                <>
                    <TableContainer sx={{borderTop:'1px solid rgba(224, 224, 224, 1)'}}>
                    <Table sx={{ minWidth: 800 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                { th_medication.map((th,index) => (
                                    <TableCell key={th['id']} align="center" 
                                        sx={
                                            {width: index === 0 ? '15%' : index === 3 || index === 4 ? '5%' :  'auto' , 
                                            fontWeight:'600'}}
                                        >
                                            {th['th_name']}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showList.length === 0 ? 
                                <>
                                 <TableRow>
                                    <TableCell 
                                        colSpan={12}
                                        align='center'
                                    >
                                        <Typography 
                                        component={"p"}
                                        sx={{margin:'1rem 0'}}
                                        >
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                </> : 
                                <>
                                    {showList.map((data,index) => (
                                        <TableRow key={data['ID']}>
                                            <TableCell align="center">{index+1}</TableCell>
                                            <TableCell align='left'>{data['DrugName']}</TableCell>
                                            <TableCell align='left'>{data['DrugDescription']}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton onClick={() => handleModal('edit',data['ID'])}>
                                                    <ModeIcon sx={{color:'#006838',cursor:'pointer'}} />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align='center'>
                                                <IconButton onClick={() => submitForm('delete',data['ID'])}>
                                                    <DeleteIcon sx={{color:'#006838',cursor:'pointer'}} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={boxPagination}>
                    <Box sx={{width:'80px'}}>
                        <FormControl fullWidth>
                            <Select 
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select> 
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination 
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded" color="primary"
                        />
                    </Stack>
                </Box>
                </>
        </Box>
    </div>
  )
}

export default AddMedicine