
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import ModalController from '../../components/ModalController/ModalSearchHistoryPatient'
import Box from '@mui/material/Box';
import ContactConference from './ContactConference'
import { SystemPatientInformationData } from '../consult_doctor/SystemPatientInformationData'
import WebViewPhrDoctor from './WebViewPhrDoctor';
import { useContext } from 'react';
import { MainContext } from '../../App';
import { useDispatch } from 'react-redux';
import { menuActive } from '../../store/menuSlice';
import PointOfCare from '../consult_doctor/PointOfCare';


function PatientHistory() {
  const {
    initStatePopup
  } = useContext(MainContext)
  const [initModalController, setInitModalController] = useState(true)
  const [success, setSuccess] = useState(false)
  const [pid, setPid] = useState('')

  const [value, setValue] = useState(0);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(menuActive('ประวัติผู้ป่วย'))
  }, [])

  useEffect(() => {
    if (initStatePopup?.['actvie']) {
      setInitModalController(initStatePopup['actvie'])
    }
  }, [initStatePopup])

  useEffect(() => {
    console.log(pid)
  }, [pid])

  return (
    <>
      {initModalController ?
        <ModalController onClose={setInitModalController} success={setSuccess} pid={setPid} />
        : null
      }

      {success ?
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            gap: '15px',
            marginBottom: '2rem',
            '@media (max-width: 600px)': {
              display: 'grid'
            }
          }}>
            <Button sx={{
              '@media (max-width: 600px)': {
                width: 350
              }
            }} onClick={() => { setValue(0) }} variant={value === 0 ? "contained" : "outlined"} >
              ข้อมูลทางการแพทย์
            </Button>
            {/* <Button onClick={() => { setValue(1) }} variant={value === 1 ? "contained" : "outlined"} >
              สรุปผลสุขภาพ
            </Button> */}
            <Button onClick={() => { setValue(1) }} variant={value === 1 ? "contained" : "outlined"}>
              Point of Care
            </Button>
            <Button onClick={() => { setValue(2) }} variant={value === 2 ? "contained" : "outlined"}>
              ติดต่อ
            </Button>
          </Box>


          {value === 0 ?
            <SystemPatientInformationData
              pid={pid}
            />
            : null
          }

          {/* {value === 1 ?
            <WebViewPhrDoctor pid_patient={pid}/>
            : null
          } */}

          {value === 1 ?
            <PointOfCare
              pid={pid}
            />
            : null
          }

          {value === 2 ?
            <Box sx={
              {
                background: '#FFFFFF',
                width: { xl: '600px', lg: '400px', md: '400px', sm: '350px', xs: '320px' },
                borderRadius: '1rem',
                margin: '0 auto',
                padding: '3rem 0 2rem 0',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
              }
            }>
              <ContactConference
                pid={pid}
              />
            </Box>

            : null
          }
        </>
        : null
      }
    </>
  )
}

export default PatientHistory