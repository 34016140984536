import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LogoHospital from '../../../assets/icons/logo-hospital.svg'
import Emergency from '../../../assets/icons/refer-emergencyGreen.svg'
import FastTrack from '../../../assets/icons/refer-fast-track.svg'
import OutPatiend from '../../../assets/icons/refer-out-patiend.svg'
import PatientBack from '../../../assets/icons/refer-patient-back.svg'

import * as api from '../../../services/refer/dashboard/ApiDashboard'
import { notifyError, notifyInfo } from '../../../components/NotifyToast'
import moment from 'moment'
import LoaderAnimation from '../../../components/LoaderAnimation'

function DashboardHospital() {

    const [loading, setLoading] = useState(false)

    const [statisticsAll, setStatisticsAll] = useState(null)
    const [statistics, setStatistics] = useState(null)


    useEffect(() => {
        findReferStatisticsAll()
        findReferStatistics()
    }, [])

    const findReferStatisticsAll = async () => {
        setLoading(true)
        let res = await api.findReferStatisticsAll(localStorage.getItem('hospital_code_'))
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setStatisticsAll(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findReferStatistics = async () => {
        let date = moment(new Date()).format("YYYY-MM-DD")

        setLoading(true)
        let res = await api.findReferStatistics(localStorage.getItem('hospital_code_'), date, date)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setStatistics(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <Box>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                display: 'grid',
                gridGap: '20px'
            }}>
                <Box sx={{
                    border: '1px solid #E1E3E9',
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: '20px',
                    borderRadius: '10px',
                    padding: '30px',
                    '@media (max-width: 800px)': {
                        padding: '15px',
                    }
                }}>
                    <img src={LogoHospital} alt='' style={{ width: 'calc(60px + 1vw)', height: 'calc(60px + 1vw)' }} />
                    <Typography
                        component='h5'
                        variant='h5'
                        color='#000'
                        sx={{ fontWeight: 600, fontSize: 'calc(18px + 0.25vw)' }}
                    >{localStorage.getItem('hospital_name')}</Typography>
                </Box>

                {statisticsAll &&
                    <>
                        <Box>สรุปจำนวนการส่งต่อผู้ป่วย</Box>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gridGap: '20px',
                            '@media (max-width: 1024px)': {
                                gridTemplateColumns: '1fr 1fr',
                            },
                            '@media (max-width: 600px)': {
                                gridTemplateColumns: '1fr',
                            }
                        }}>
                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <img src={Emergency} style={{ width: '50px', height: '50px' }} />
                                <Box>
                                    <Box sx={{ fontSize: '25px' }}>{statisticsAll['referStation']['er']['total']}</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยฉุกเฉิน</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <img src={FastTrack} style={{ width: '50px', height: '50px' }} />
                                <Box>
                                    <Box sx={{ fontSize: '25px' }}>{statisticsAll['referStation']['opd']['total']}</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยนอก</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <img src={OutPatiend} style={{ width: '50px', height: '50px' }} />
                                <Box>
                                    <Box sx={{ fontSize: '25px' }}>{statisticsAll['referStation']['ipd']['total']}</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยใน</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <img src={PatientBack} style={{ width: '50px', height: '50px' }} />
                                <Box>
                                    <Box sx={{ fontSize: '25px' }}>{statisticsAll['summary']['referback']}</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยกลับ</Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }

                {statistics &&
                    <>
                        <Box>รับผู้ป่วยเข้ารักษา {convertDate(moment(new Date()).format("YYYY-MM-DD"))} </Box>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gridGap: '20px',
                            '@media (max-width: 1024px)': {
                                gridTemplateColumns: '1fr 1fr',
                            },
                            '@media (max-width: 600px)': {
                                gridTemplateColumns: '1fr',
                            }
                        }}>
                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                alignItems: 'center',
                                padding: '20px'
                            }}>
                                <Box sx={{ fontSize: '35px' }}>{statistics['referStation']['er']['out']}</Box>
                                <Box>
                                    <Box>รอรับ</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยฉุกเฉิน</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <Box sx={{ fontSize: '35px' }}>{statistics['referStation']['opd']['out']}</Box>
                                <Box>
                                    <Box>รอรับ</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยนอก</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <Box sx={{ fontSize: '35px' }}>{statistics['referStation']['ipd']['out']}</Box>
                                <Box>
                                    <Box>รอรับ</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยใน</Box>
                                </Box>
                            </Box>

                            <Box sx={{
                                border: '1px solid #E1E3E9',
                                borderRadius: '10px',
                                background: '#FFFFFF',
                                color: '#006838',
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                padding: '20px'
                            }}>
                                <Box sx={{ fontSize: '35px' }}>{statistics['summary']['referout']}</Box>
                                <Box>
                                    <Box>รอรับ</Box>
                                    <Box sx={{ fontSize: '15px', fontWeight: 600 }}>ผู้ป่วยกลับ</Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
}

export default DashboardHospital