import React, { useEffect, useRef, useState } from 'react'
import {
    Box, Button, Grid, Typography, TextField,
    FormControl, Select, MenuItem, InputAdornment, IconButton, Autocomplete
} from '@mui/material'

import { content } from '../Css'
import PageHeader from '../../../components/PageHeader'
import { ButtonContained, ButtonOutlined } from '../Css'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import { ModalOpenData } from '../../../components/ModalController/ModalAddRefer'
import styles from './resive.module.css'
import LoaderAnimation from '../../../components/LoaderAnimation'
import * as api from '../../../services/refer/ApiPublicRefer'
import * as apiRefer from '../../../services/refer/refer/ApiRefer'
import { notifyError, notifyInfo } from '../../../components/NotifyToast';

import ModalCalendar from '../../../components/ModalController/ModalCalendar'
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

export default function DataRefer() {
    const location = useLocation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [openModalOPD, setOpenModalOPD] = useState(false)
    const [openModalIPD, setOpenModalIPD] = useState(false)
    const [openModalData, setOpenModalData] = useState(false)

    const [openCalendar, setOpenCalendar] = useState(false)
    const [disabledDay, setDisabledDay] = useState([])
    const [typeDate, setTypeDate] = useState('')

    const type_sent = [
        { key: '2', value: 'ER' },
        { key: '1', value: 'OPD' },
        { key: '7', value: 'IPD' },
        { key: '0', value: 'OR' },
        { key: '4', value: 'LR' }
    ]
    const type_sent2 = ['ในจังหวัด', 'นอกจังหวัด']
    const type_file = ['OPD', 'IPD']
    const type_treat = ['รับไว้รักษาต่อ', 'ตรวจชันสูตร', 'คุมไว้สังเกต', 'ขอทราบ']
    const type_care = ['Airway = ระบบทางเดินหายใจ', 'Breathing = การหายใจ', 'IV fluid = ให้น้ำเกลือ', 'stop bluding = ห้ามเลือด', 'splint = เข้าเฝือก', 'อื่นๆ']
    const type_patient = ['ผู้ป่วยทั่วไป', 'ผู้ป่วยอุบัติเหตุ', 'ผู้ป่วยฉุกเฉิน(ยกเว้นอุบัติเหตุ)']
    const type_level_urgency = ['life threatening', 'emergency', 'urgent', 'acute', 'non acute']
    const type_specific = ['STEM', 'Stroke', 'trauma', 'cancer', 'sepsis', 'pregnancy, labor, postpartum', 'new born', 'อื่นๆ']
    const type_cause = ['เพื่อการวินิจฉัยและรักษา', 'เพื่อการวินิจฉัย', 'เพื่อการรักษาต่อเนื่อง', 'เพื่อการดูแลต่อใกล้บ้าน', 'ตามความต้องการผู้ป่วย', 'เพื่อส่งผู้ป่วยกลับไปยัง หน่วยบริการที่ส่งผู้ป่วยมา', 'เป็นการตอบกลับการส่งต่อ(ไม่ได้ส่งผู้ป่วย)']

    const inputFileRef = useRef(null)
    const [attachFile, setAttachFile] = useState([])
    const [medicalInfomationType, setMedicalInfomationType] = useState(null)

    const [FromHospitalCode, setFromHospitalCode] = useState(localStorage.getItem('hospital_code_')) //จากโรงพยาบาลต้นทาง 
    const [FromHospitalName, setFromHospitalName] = useState(localStorage.getItem('hospital_name')) //จากโรงพยาบาลต้นทาง 

    const [optionHospital, setOptionHospital] = useState([])

    const [itemData, setItemData] = useState()

    const [detail, setDetail] = useState('')
    const [data, setData] = useState({
        hn: '', //hn
        Name: '', //ชื่อ-นามสกุล
        cid: '', //เลขบัตรประจำตัวประชาชน
        sex: '', //เพศ
        BirthDay: '',//วันเกิด
        ageY: '', //อายุ
        MedicalMainTerm: '', //สิทธิการรักษาหลัก
        MedicalSubTerm: '', //สิทธิการรักษารอง
        MedicalBloodThinner: '', //การได้รับยาละลายลิ่มเลือด
        Address: '', //ที่อยู่
        DrugAllergy: '', //การแพ้ยา
        ReferCodeType: '', //ประเภทการส่งต่อ
        ReferNameType: '', //ประเภทการส่งต่อ
        ReferTypeProvince: '',//ประเภทการส่งต่อ ในจังหวัด นอกจังหวัด
        ToHospitalCode: '', //ถึงโรงพยาบาล
        ToHospitalName: '', //ถึงโรงพยาบาล
        FromHospitalPhone: '', //เบอร์โทรศัพท์โรงพยาบาลต้นทาง
        ToHospitalPhone: '', //เบอร์โทรศัพท์โรงพยาบาลปลายทาง

        ScreenNote1: '', //การให้การดูแลผู้ป่วย ขณะส่งต่อ
        ScreenNoteOther1: '', //การให้การดูแลผู้ป่วย ขณะส่งต่อ อื่นๆ
        ScreenNote2: '',//ประเภทผู้ป่วย
        ScreenNote3: '',//ระดับความเร่งด่วน
        ScreenNote4: '', //ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ
        ScreenNoteOther4: '',//ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ อื่นๆ
        ScreenNote5: '', //สาเหตุการส่งต่อผู้ป่วย
        NearbyPlace: '', //สถานที่ใกล้เคียงคือ
        ReferBy: '', //ขอทราบผล

        ReferDate: '', //วันที่ส่งต่อ
        ReferTime: '', //เวลาที่ส่ง
        ReferNumber: '', //เลขที่ส่งต่อ
        ReferOutNumber: '', //เลขที่ส่งต่อจากรพ.ต้นทาง
        ExpireDate: '', //วันที่หมดอายุ
        Department: '', //แผนก
        DoctorName: '', //แพทย์ผู้สั่ง
        Station: '', //จุดส่งต่อ
        Room: '', //ห้อง
        StaffName: '', //ผู้บันทึกใบส่งตัว
        RemarkNote1: '',
        RemarkNote2: '',
        RemarkNote3: '',
        RemarkNote4: '',
        MedicalInfomation: '' //แนบข้อมูลทางการแพทย์ของผู้ป่วย
    })

    const [typeMenu, setTypeMenu] = useState('')
    const [open, setOpen] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        if (location.state.type === 'show') {
            setReadOnly(true)
        }
        findHospital()
        setTypeMenu(location.state.type)
        if (location.state.refer_no) {
            findReferbackSummary(location.state.data)
        }
    }, [])

    if (!location.state) {
        location.state = { button: '1' }
    }

    const handleButton = (number) => {
        navigate('/dataRefer', { state: { button: number } })
    }

    const inputFileChange = async (event) => {
        console.log(event.target.files[0])
        let fileObj = event.target.files && event.target.files[0]

        let formData = new FormData()
        formData.append('file', fileObj)

        setLoading(true)
        let res = await api.uploads(formData)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                // console.log(res['data']['data']['url'])
                urlAttachFile(res['data']['data']['url'])
            } else {
                notifyInfo(res['data']['message_th'] || res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const urlAttachFile = (url) => {
        let set = new Set(attachFile);
        let arr = Array.from(set);
        arr.push({ FileURL: url, Remark: '' });
        setAttachFile(arr)
    }

    const handleChangeRemark = (event, item, index) => {
        // console.log(event)
        const e = event
        const newAttachFile = attachFile.map((i, idx) => {
            if (idx === index) {
                return { ...i, Remark: e.target.value }
            }
            return i
        })
        setAttachFile(newAttachFile)
    }

    // const clickAttachData = (i) => {
    //     setData({ ...data, ['MedicalInfomation']: {} })
    //     setMedicalInfomationType(i)
    // }

    // const clickOpenAttachData = () => {
    //     if (medicalInfomationType === 'OPD') {
    //         setOpenModalOPD(true)
    //     } else {
    //         setOpenModalIPD(true)
    //     }
    // }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const findReferbackSummary = async (data) => {
        console.log(data)
        if (data) {
            if (data['code'] === 200) {
                setDetail(data['message'])

                setData(data['data'])
                setMedicalInfomationType(data['data']['MedicalInfomationType'])
                setAttachFile(data['data']['AttachmentFile'])
            } else {
                setDetail(data['message'])
                notifyInfo(data['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const findHospital = async () => {
        setLoading(true)
        let res = await api.findHospital()
        setLoading(false)
        if (res) {
            if (res['data']['status'] === true) {
                let option = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        option.push({
                            key: res['data']['data'][i]['hoscode'],
                            label: res['data']['data'][i]['hosname']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                // console.log(unique)
                setOptionHospital(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleOpenCalendar = (t) => {
        setTypeDate(t)
        setOpenCalendar(true)
    }

    // const resiveTypeName = (id) => {
    //     for (let i = 0; i < type_sent.length; i++) {
    //         if (type_sent[i]['key'] === id) {
    //             return type_sent[i]['value']
    //         }
    //     }
    // }

    const deleteItemAttach = (item) => {
        const newAttachFile = attachFile.filter(i => i !== item)
        setAttachFile(newAttachFile)
    }

    const clickOpenData = (item) => {
        setItemData(item)
        setOpenModalData(true)
    }

    const calculateAge = (birth) => {
        var today = new Date()
        var birthDate = new Date(birth)
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log(age_now)
        setData({ ...data, ['ageY']: age_now })
    }

    return (
        <Box {...data} inputProps={{ readonly: true }}>
            <LoaderAnimation loading={loading} />
            {openCalendar &&
                <ModalCalendar
                    onFinished={(params) => {
                        if (typeDate === 'birth') {
                            calculateAge(params)
                        }
                    }}
                    onClose={() => { setOpenCalendar(false) }}
                    value={
                        typeDate === 'expire' ?
                            data['ExpireDate'] ? dayjs(moment(data['ExpireDate']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                            :
                            typeDate === 'resive' ?
                                data['ReferDate'] ? dayjs(moment(data['ReferDate']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                                :
                                data['BirthDay'] ? dayjs(moment(data['BirthDay']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                    }
                    onChange={(e) => {
                        typeDate === 'expire' ?
                            setData({ ...data, ['ExpireDate']: e })
                            :
                            typeDate === 'resive' ?
                                setData({ ...data, ['ReferDate']: e })
                                :
                                setData({ ...data, ['BirthDay']: e })
                    }}
                    disableDay={disabledDay}
                />
            }

            {/* {openModalOPD ?
                <ModalAddReferOPD
                    pid={data['cid']}
                    onSubmit={(detail) => {
                        let inform = []
                        for (let i = 0; i < detail.length; i++) {
                            inform.push(detail[i])
                        }
                        setData({ ...data, ['MedicalInfomation']: inform })
                    }}
                    closeModal={() => setOpenModalOPD(false)}
                /> : null
            } */}

            {/* {openModalIPD ?
                <ModalAddReferIPD
                    pid={data['cid']}
                    onSubmit={(detail) => {
                        let inform = []
                        for (let i = 0; i < detail.length; i++) {
                            inform.push(detail[i])
                        }
                        setData({ ...data, ['MedicalInfomation']: inform })
                    }}
                    closeModal={() => setOpenModalIPD(false)}
                />
                : null
            } */}

            {openModalData ?
                <ModalOpenData
                    data={itemData}
                    closeModal={() => setOpenModalData(false)}
                />
                : null
            }

            <PageHeader title_name="กลับไปหน้ารายชื่อทั้งหมด" back_path='/resive' hide_date />
            <Typography sx={{
                textAlign: 'center',
                fontSize: '22px',
                '@media (max-width: 800px)': {
                    fontSize: '18px',
                }
            }}>ข้อมูลการส่งต่อ</Typography>

            {detail === 'พบข้อมูล' ?
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gridGap: '5px',
                        margin: '20px',
                        '@media (max-width: 1024px)': {
                        }
                    }}>
                        <Box sx={location.state.button === '1' ? ButtonContained : ButtonOutlined}>1</Box>
                        <Box sx={location.state.button === '2' ? ButtonContained : ButtonOutlined}>2</Box>
                        <Box sx={location.state.button === '3' ? ButtonContained : ButtonOutlined}>3</Box>
                    </Box>

                    {location.state.button === '1' ?
                        <Box>
                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Typography>ข้อมูลส่วนตัว</Typography>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            HN/VN - AN
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="HN/VN - AN"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['hn']}
                                            name={'hn'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ชื่อ-นามสกุล
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ชื่อ-นามสกุล"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['Name']}
                                            name={'Name'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            เลขบัตรประจำตัวประชาชน
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="เลขบัตรประจำตัวประชาชน"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['cid']}
                                            name={'cid'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            เพศ
                                        </Typography>
                                        <Select
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            size='small'
                                            fullWidth
                                            value={data['sex'] || 'null'}
                                            name={'sex'}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='null' disabled>เลือกเพศ</MenuItem>
                                            <MenuItem value='ชาย'>ชาย</MenuItem>
                                            <MenuItem value='หญิง'>หญิง</MenuItem>
                                        </Select>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            วันเกิด
                                        </Typography>
                                        <TextField
                                            placeholder="วันเกิด"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            name={'BirthDay'}
                                            value={
                                                data['BirthDay'] === null || data['BirthDay'] === '' ?
                                                    '' : convertDate(data['BirthDay'])
                                            }
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment>
                                                        {typeMenu === 'show' ?
                                                            null
                                                            :
                                                            <IconButton
                                                                onClick={() => {
                                                                    setTypeDate('birthday')
                                                                    handleOpenCalendar('birth')
                                                                }}>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            อายุ
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            type='number'
                                            placeholder="อายุ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['ageY']}
                                            name={'ageY'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            สิทธิการรักษาหลัก
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="สิทธิการรักษาหลัก"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['MedicalMainTerm']}
                                            name={'MedicalMainTerm'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            สิทธิการรักษารอง
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="สิทธิการรักษารอง"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['MedicalSubTerm']}
                                            name={'MedicalSubTerm'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            การได้รับยาละลายลิ่มเลือด
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="การได้รับยาละลายลิ่มเลือด"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['MedicalBloodThinner']}
                                            name={'MedicalBloodThinner'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} sx={6}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ที่อยู่
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ที่อยู่"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['Address']}
                                            name={'Address'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} sx={6}>
                                        <Typography component='p' variant='p' color='primary'>
                                            การแพ้ยา
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="การแพ้ยา"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['DrugAllergy']}
                                            name={'DrugAllergy'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ประเภทการส่งต่อ
                                        </Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                                            '@media (max-width: 800px)': {
                                                gridTemplateColumns: '1fr 1fr 1fr'
                                            }
                                        }}>
                                            {
                                                type_sent.map((item, index) => (
                                                    <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                        <input
                                                            style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                            type="radio"
                                                            name={'ReferCodeType'}
                                                            value={data['ReferCodeType']}
                                                            checked={item['key'] === data['ReferCodeType']}
                                                            onChange={() => {
                                                                setData({ ...data, ['ReferCodeType']: item['key'], ['ReferNameType']: item['value'] })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item['value']}
                                                    </label>
                                                ))
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ประเภทการส่งต่อ
                                        </Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            {
                                                type_sent2.map((item, index) => (
                                                    <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                        <input
                                                            style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                            type="radio"
                                                            name={'ReferTypeProvince'}
                                                            value={data['ReferTypeProvince']}
                                                            checked={item === data['ReferTypeProvince']}
                                                            onChange={() => {
                                                                // console.log(item)
                                                                setData({ ...data, ['ReferTypeProvince']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                ))
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                        <Typography component='p' variant='p' color='primary'>
                                            จากโรงพยาบาลต้นทาง
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={FromHospitalName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                        <Typography component='p' variant='p' color='primary'>
                                            ถึงโรงพยาบาล
                                        </Typography>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                fullWidth
                                                size='small'
                                                options={optionHospital}
                                                value={data['ToHospitalName']}
                                                name={'ToHospitalCode'}
                                                onChange={(e, value) => {
                                                    setData({ ...data, ['ToHospitalCode']: value?.['key'], ['ToHospitalName']: value?.['label'] })
                                                }}
                                                open={open}
                                                onOpen={() => typeMenu !== "show" && setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                disableClearable={readOnly}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        InputProps={{ ...params.InputProps, readOnly }}
                                                        placeholder="เลือกโรงพยาบาลที่ต้องการส่งต่อผู้ป่วย"
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                        <Typography component='p' variant='p' color='primary'>เบอร์โทรศัพท์โรงพยาบาลต้นทาง</Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ระบุ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['FromHospitalPhone']}
                                            name={'FromHospitalPhone'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} sx={6} >
                                        <Typography component='p' variant='p' color='primary'>เบอร์โทรศัพท์โรงพยาบาลปลายทาง</Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ระบุ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['ToHospitalPhone']}
                                            name={'ToHospitalPhone'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gridGap: '10px',
                                margin: '20px 0',
                                '@media (max-width: 800px)': {
                                    justifyContent: 'center',
                                }
                            }}>
                                <Button variant='outlined' onClick={() => { navigate('/resive') }}>ยกเลิก</Button>
                                <Button variant='contained' onClick={() => {
                                    handleButton('2')
                                }}>ถัดไป</Button>
                            </Box>
                        </Box>
                        : null
                    }

                    {location.state.button === '2' ?
                        <Box>
                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>การให้การดูแลผู้ป่วย ขณะส่งต่อ</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 900px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}>
                                            {type_care.map((item, index) => {
                                                return item === 'อื่นๆ' ?
                                                    (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}>
                                                                <input
                                                                    style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                                    type="radio"
                                                                    name={'ScreenNote1'}
                                                                    value={data['ScreenNote1']}
                                                                    checked={item === data['ScreenNote1']}
                                                                    onChange={() => {
                                                                        setData({ ...data, ['ScreenNote1']: item })
                                                                    }}
                                                                /><span className={styles.checkmark}></span>{item}
                                                            </label>
                                                            <TextField
                                                                disabled={data['ScreenNote1'] !== item}
                                                                placeholder={'ระบุ'}
                                                                variant="outlined"
                                                                fullWidth
                                                                size='small'
                                                                value={data['ScreenNoteOther']}
                                                                onChange={(e) => {
                                                                    if (data['ScreenNote1'] === 'อื่นๆ') {
                                                                        setData({ ...data, ['ScreenNoteOther1']: e.target.value })
                                                                    } else {
                                                                        setData({ ...data, ['ScreenNoteOther1']: '' })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div key={index} >
                                                            <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}>
                                                                <input
                                                                    style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                                    type="radio"
                                                                    name={'ScreenNote1'}
                                                                    value={data['ScreenNote1']}
                                                                    checked={item === data['ScreenNote1']}
                                                                    onChange={() => {
                                                                        console.log(item)
                                                                        setData({ ...data, ['ScreenNote1']: item })
                                                                    }}
                                                                /><span className={styles.checkmark}></span>{item}
                                                            </label>
                                                        </div>
                                                    )
                                            })}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>ประเภทผู้ป่วย</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 900px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}>
                                            {type_patient.map((item, index) => (
                                                <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                    <input
                                                        style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                        type="radio"
                                                        name={'ScreenNote2'}
                                                        value={data['ScreenNote2']}
                                                        checked={item === data['ScreenNote2']}
                                                        onChange={() => {
                                                            console.log(item)
                                                            setData({ ...data, ['ScreenNote2']: item })
                                                        }}
                                                    /><span className={styles.checkmark}></span>{item}
                                                </label>
                                            ))}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>ระดับความเร่งด่วน</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 900px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}>
                                            {type_level_urgency.map((item, index) => (
                                                <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                    <input
                                                        style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                        type="radio"
                                                        name={'ScreenNote3'}
                                                        value={data['ScreenNote3']}
                                                        checked={item === data['ScreenNote3']}
                                                        onChange={() => {
                                                            console.log(item)
                                                            setData({ ...data, ['ScreenNote3']: item })
                                                        }}
                                                    /><span className={styles.checkmark}></span>{item}
                                                </label>
                                            ))}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>ประเภทกลุ่มโรคเฉพาะเพื่อการส่งต่อ</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 900px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}>
                                            {type_specific.map((item, index) => {
                                                return item === 'อื่นๆ' ?
                                                    (
                                                        <div key={index} style={{ display: 'flex' }}>
                                                            <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}>
                                                                <input
                                                                    style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                                    type="radio"
                                                                    name={'ScreenNote4'}
                                                                    value={data['ScreenNote4']}
                                                                    checked={item === data['ScreenNote4']}
                                                                    onChange={() => {
                                                                        console.log(item)
                                                                        setData({ ...data, ['ScreenNote4']: item })
                                                                    }}
                                                                /><span className={styles.checkmark}></span>{item}
                                                            </label>
                                                            <TextField
                                                                disabled={data['ScreenNote4'] !== item}
                                                                placeholder={'ระบุ'}
                                                                variant="outlined"
                                                                fullWidth
                                                                size='small'
                                                                value={data['ScreenNoteOther4']}
                                                                onChange={(e) => {
                                                                    if (data['ScreenNote4'] === 'อื่นๆ') {
                                                                        setData({ ...data, ['ScreenNoteOther4']: e.target.value })
                                                                    } else {
                                                                        setData({ ...data, ['ScreenNoteOther4']: '' })
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div key={index} >
                                                            <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                                <input
                                                                    style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                                    type="radio"
                                                                    name={'ScreenNote4'}
                                                                    value={data['ScreenNote4']}
                                                                    checked={item === data['ScreenNote4']}
                                                                    onChange={() => {
                                                                        console.log(item)
                                                                        setData({ ...data, ['ScreenNote4']: item })
                                                                    }}
                                                                /><span className={styles.checkmark}></span>{item}
                                                            </label>
                                                        </div>
                                                    )
                                            })}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>สาเหตุการส่งต่อผู้ป่วย</Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 900px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}>
                                            {type_cause.map((item, index) => (
                                                <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                    <input
                                                        style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                        type="radio"
                                                        name={'ScreenNote5'}
                                                        value={data['ScreenNote5']}
                                                        checked={item === data['ScreenNote5']}
                                                        onChange={() => {
                                                            console.log(item)
                                                            setData({ ...data, ['ScreenNote5']: item })
                                                        }}
                                                    /><span className={styles.checkmark}></span>{item}
                                                </label>
                                            ))}
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Box>

                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>
                                            สถานที่ใกล้เคียงคือ
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="กรุณาระบุสถานที่้ใกล้เคียง"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['NearbyPlace']}
                                            name={'NearbyPlace'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={8} sx={8}>
                                        <Typography component='p' variant='p' color='primary'>
                                            มาเพื่อโปรด
                                        </Typography>
                                        <Box sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            '@media (max-width: 800px)': {
                                                gridTemplateColumns: '1fr 1fr'
                                            }
                                        }}
                                        >
                                            {
                                                type_treat.map((item, index) => (
                                                    <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                        <input
                                                            style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                            type="radio"
                                                            name={'ReferBy'}
                                                            value={data['ReferBy']}
                                                            checked={item === data['ReferBy']}
                                                            onChange={() => {
                                                                console.log(item)
                                                                setData({ ...data, ['ReferBy']: item })
                                                            }}
                                                        /><span className={styles.checkmark}></span>{item}
                                                    </label>
                                                ))
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gridGap: '10px',
                                margin: '20px 0',
                                '@media (max-width: 800px)': {
                                    justifyContent: 'center',
                                }
                            }}>
                                <Button variant='outlined' onClick={() => handleButton('1')}>ย้อนกลับ</Button>
                                <Button variant='contained' onClick={() => {
                                    handleButton('3')
                                }}>ถัดไป</Button>
                            </Box>

                        </Box>
                        : null
                    }

                    {location.state.button === '3' ?
                        <Box>
                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Typography>ข้อมูลการส่งต่อ</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            วันที่ส่งต่อ
                                        </Typography>
                                        <TextField
                                            placeholder="Default วันที่ปัจจุบัน"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            name={'ReferDate'}
                                            value={
                                                data['ReferDate'] === null || data['ReferDate'] === '' ?
                                                    convertDate(dayjs(moment(new Date()).format('YYYY-MM-DD')))
                                                    :
                                                    convertDate(data['ReferDate'])
                                            }
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment>
                                                        {typeMenu === 'show' ?
                                                            null
                                                            :
                                                            <IconButton onClick={() => {
                                                                setTypeDate('resive')
                                                                handleOpenCalendar('resive')
                                                            }}>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        }

                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            เวลาที่ส่งต่อ
                                        </Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileTimePicker
                                                readOnly={typeMenu === 'show' ? true : null}
                                                ampm={false}
                                                sx={{
                                                    width: '100%',
                                                    "& .MuiInputBase-input": {
                                                        padding: '8.5px 14px'
                                                    }
                                                }}
                                                value={
                                                    data['ReferTime'] ?
                                                        dayjs(moment(data['ReferTime'], 'H:mm').format('YYYY-MM-DDTHH:mm'))
                                                        :
                                                        dayjs(moment(new Date()).format('YYYY-MM-DDTHH:mm'))
                                                }
                                                onChange={(e) => {
                                                    setData({ ...data, ['ReferTime']: e })
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params}
                                                        sx={{ padding: '20px' }}

                                                    />
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                <Typography component='p' variant='p' color='primary'>
                                    เลขที่ส่งต่อจากโรงพยาบาลต้นทาง
                                </Typography>
                                <TextField
                                    inputProps={typeMenu === 'show' &&
                                        { readOnly: true }
                                    }
                                    placeholder="เลขที่ส่งต่อจากโรงพยาบาลต้นทาง"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={data['ReferOutNumber']}
                                    name={'ReferOutNumber'}
                                    onChange={handleChange}
                                />
                            </Grid> */}
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            เลขที่ส่งต่อ
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="เลขที่ส่งต่อ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['ReferNumber']}
                                            name={'ReferNumber'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            วันที่หมดอายุ
                                        </Typography>
                                        <TextField
                                            placeholder="วันที่หมดอายุ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            name={'ExpireDate'}
                                            value={data['ExpireDate'] ?
                                                data['ExpireDate'] === null || data['ExpireDate'] === '' ?
                                                    convertDate(dayjs(moment(new Date()).format('YYYY-MM-DD')))
                                                    :
                                                    convertDate(data['ExpireDate'])
                                                :
                                                ''
                                            }
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment>
                                                        {typeMenu === 'show' ?
                                                            null
                                                            :
                                                            <IconButton onClick={() => {
                                                                setTypeDate('expire')
                                                                handleOpenCalendar('expire')
                                                            }}>
                                                                <CalendarTodayIcon />
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            แผนก
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="แผนก"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['Department']}
                                            name={'Department'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            แพทย์ผู้สั่ง
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ชื่อ - นามสกุล แพทย์"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['DoctorName']}
                                            name={'DoctorName'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            จุดส่งต่อ
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="จุดส่งต่อ"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['Station']}
                                            name={'Station'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ห้อง
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ห้อง"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['Room']}
                                            name={'Room'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} sx={4}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ผู้บันทึกใบส่งตัว
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            placeholder="ชื่อ - นามสกุล"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={data['StaffName']}
                                            name={'StaffName'}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ผลการชันสูตรที่สำคัญ
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={data['RemarkNote1']}
                                            name={'RemarkNote1'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>
                                            การวินิจฉัยโรคขั้นสุดท้าย
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={data['RemarkNote2']}
                                            name={'RemarkNote2'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>
                                            การรักษาที่ให้
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={data['RemarkNote3']}
                                            name={'RemarkNote3'}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component='p' variant='p' color='primary'>
                                            ขอให้ดำเนินการต่อ ดังนี้
                                        </Typography>
                                        <TextField
                                            inputProps={typeMenu === 'show' &&
                                                { readOnly: true }
                                            }
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={data['RemarkNote4']}
                                            name={'RemarkNote4'}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>

                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={12} >
                                        <Typography component='p' variant='p' color='primary'>
                                            แนบข้อมูลทางการแพทย์ของผู้ป่วย
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'start', gridGap: '1rem' }}>
                                            {type_file.map((item, index) => (
                                                <label className={styles.container} style={typeMenu === 'show' ? { pointerEvents: 'none' } : null} key={index}>
                                                    <input
                                                        style={typeMenu === 'show' ? { pointerEvents: 'none' } : null}
                                                        type="radio"
                                                        checked={item === medicalInfomationType}
                                                    // onChange={() => clickAttachData(item)}
                                                    /><span className={styles.checkmark}></span>{item}
                                                </label>
                                            ))}
                                        </Box>
                                        {/* <Button
                                            disabled={medicalInfomationType === null ? true : null || typeMenu === 'show' ? true : null}
                                            variant="contained"
                                            onClick={() => { clickOpenAttachData() }}
                                        >เลือกข้อมูล</Button> */}
                                        <Box sx={{ margin: '10px 20px 0 20px', width: '100%' }}>
                                            {data['MedicalInfomation'].length > 0 ?
                                                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gridGap: 10 }}>
                                                    {data['MedicalInfomation'].map((i, idx) => (
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    textDecoration: 'underline'
                                                                },
                                                            }}
                                                            key={idx}
                                                            onClick={() => { clickOpenData(i) }}
                                                        >
                                                            {convertDate(i['date'])}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                :
                                                null
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ ...content, marginTop: '1rem' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gridGap: '10px' }}>
                                        <Button
                                            variant='contained'
                                            disabled={typeMenu === 'show'}
                                            onClick={() => {
                                                inputFileRef.current.click()
                                            }}
                                        >แนบไฟล์</Button>
                                        <Box sx={{ color: '#000000' }}>.PDF/.PNG/.JPG/.MP4</Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <input
                                            type={'file'}
                                            accept='image/png,image/jpeg,application/pdf,video/mp4'
                                            ref={inputFileRef}
                                            onChange={(e) => {
                                                inputFileChange(e)
                                            }}
                                            style={{ display: 'none' }}>
                                        </input>
                                        {attachFile ?
                                            <>
                                                {attachFile.map((i, idx) => (
                                                    <Box key={idx}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            margin: '10px 0'
                                                        }}>
                                                            <Box sx={{
                                                                border: 1,
                                                                borderColor: '#E1E3E9',
                                                                borderRadius: '10px',
                                                                padding: '5px 10px',
                                                                cursor: 'pointer'
                                                            }}
                                                                onClick={() => {
                                                                    window.open(i.FileURL, '_system')
                                                                }}
                                                            >{i['FileURL']}</Box>
                                                            {typeMenu === 'show' ?
                                                                null : <CloseIcon onClick={() => { deleteItemAttach(i, idx) }} />
                                                            }
                                                        </Box>
                                                        <TextField
                                                            inputProps={typeMenu === 'show' &&
                                                                { readOnly: true }
                                                            }
                                                            placeholder="รายละเอียดเอกสาร"
                                                            variant="outlined"
                                                            size='small'
                                                            fullWidth
                                                            value={i['Remark']}
                                                            onChange={(e) => { handleChangeRemark(e, i, idx) }}
                                                        />
                                                    </Box>
                                                ))}
                                            </>
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gridGap: '10px',
                                margin: '20px 0',
                                '@media (max-width: 800px)': {
                                    justifyContent: 'center',
                                }
                            }}>
                                <Button variant='outlined' onClick={() => handleButton('2')}>ย้อนกลับ</Button>
                                {/* <Button variant='contained' disabled={typeMenu === 'show'} onClick={() => { clickSubmit() }}>บันทึก</Button> */}
                            </Box>

                        </Box>
                        : null
                    }
                </>
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>{detail}</Box>
            }
        </Box >
    )
}