import React, { useState } from 'react'
import {
    Box, TextField, Typography, InputAdornment
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { content, boxSearch } from '../Css'
import icon_avatar_search_history from '../../../assets/icons/avatar-search-history.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader'
import * as api from '../../../services/refer/ApiPublicRefer'
import { notifyError } from '../../../components/NotifyToast';
import LoaderAnimation from '../../../components/LoaderAnimation';

export default function AddNewCaseResive() {
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pid, setPid] = useState()
    const [search, setSearch] = useState(false)
    const [findData, setFindData] = useState([])


    const handleSearch = (e) => {
        const { value } = e.target
        const numericRegex = /^[0-9]*$/;
        if (numericRegex.test(value)) {
            setPid(value)
        }
        setSearch(false)
    }

    const searchData = () => {
        searchFindData()
    }

    const searchFindData = async () => {
        setLoading(true)
        let res = await api.findAddress(pid)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                const unique = [...new Map(res['data']['data'].map((m) => [m.PID, m])).values()] //กรองชื่อซ้ำ
                setFindData(unique)
                setSearch(true)
            } else {
                setSearch(true)
                setFindData([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleAddResive = (PID, name) => {
        if (findData.length !== 0) {
            navigate('/addResive', { state: { type: 'add', button: '1', pid: PID, name: name } })
        }
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <PageHeader title_name="กลับไปหน้ารายชื่อทั้งหมด" back_path='/resive' hide_date />
            <Typography sx={{
                textAlign: 'center',
                fontSize: '22px',
                '@media (max-width: 800px)': {
                    fontSize: '18px',
                }
            }}>เพิ่มข้อมูลการส่งต่อ</Typography>
            <Box sx={{ ...content, marginTop: '20px' }}  >
                <Typography component='p' variant='p' color='primary' sx={{ marginBottom: '2rem', fontWeight: 600 }}>
                    ค้นหาเลขบัตรประจำตัวประชาชนผู้ป่วย
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        placeholder='เลขบัตรประจำตัวประชาชน'
                        variant="standard"
                        size='small'
                        value={pid}
                        onChange={handleSearch}
                        sx={{ width: { xl: '60%', lg: '60%', md: '70%', sm: '80%', xs: '80%' }, marginBottom: '1rem' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => searchData()}
                                    />
                                </InputAdornment>
                            ),
                            inputProps: {
                                maxLength: 13,
                            }
                        }}
                    />
                    {search === true ?
                        <Box sx={boxSearch}>
                            {findData.length === 0 ?
                                <Typography component='p' variant='p' color='secondary'>
                                    ไม่พบข้อมูล
                                </Typography>
                                :
                                <>
                                    {findData.map((i, idx) => (
                                        <Box onClick={() => handleAddResive(i['PID'], i['Name'])} key={idx} sx={{ display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr 5fr' }}>
                                            <img src={icon_avatar_search_history} style={{ width: '60px', height: '60px' }} alt='' />
                                            <Box sx={{ textAlign: 'start' }}>
                                                <Typography component='p' variant='p' color='secondary'>
                                                    ชื่อ-นามสกุล : {i['Name']}
                                                </Typography>
                                                <Typography component='p' variant='p' color='secondary'>
                                                    เลขประจำตัวประชาชน : {i['PID']}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))
                                    }
                                </>
                            }
                        </Box>
                        : ''
                    }
                </Box>
            </Box>
        </>

    )
}