import React from 'react'
import { Typography,Box } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import {backgroundModal,boxModal,boxItem} from './ModalAccessCss'

function ModalConditionRegister(props) {
  const  { closeModal,title,conditionDetail } = props
  
  return (
    <Box sx={backgroundModal}>
        <Box sx={boxModal}>
            <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
            <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginLeft:'1rem'}}>{title}</Typography> 
            <Box sx={boxItem}>
              {
                title === 'เงื่อนไขการใช้งาน'  ? 
                <>
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',textAlign:'center'}}>ข้อตกลงการใช้งาน (Terms of Use)</Typography>
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1.5rem'}}>การใช้คุกกี้ (Cookies)</Typography> 
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      “คุกกี้” คือ ข้อมูลที่ สำนักงานเขตสุขภาพที่ 12 ส่งไปยังโปรแกรมค้นผ่านเว็บไซต์ (Web browser) ของผู้ใช้บริการ และเมื่อมีการติดตั้งข้อมูลดังกล่าวไว้ในระบบของท่านแล้ว หากมีการใช้ “คุกกี้” ก็จะทำให้เว็บไซต์ http://www.rh12.moph.go.th/ สามารถบันทึกหรือจดจำข้อมูลของผู้ใช้บริการไว้ จนกว่าผู้ใช้บริการจะออกจากโปรแกรมค้นผ่านเว็บไซต์ หรือจนกว่าผู้ใช้บริการจะทำการลบ “คุกกี้” นั้นเสีย หรือไม่อนุญาตให้ “คุกกี้” นั้น ทำงานอีกต่อไป
                  </Typography> 
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      หากท่านเลือกใช้ “คุกกี้” แล้ว ท่านจะได้รับความสะดวกสบายในการท่องเว็บไซต์มากขึ้น เพราะ “คุกกี้” จะช่วยจดจำเว็บไซต์ที่ท่านแวะหรือเยี่ยมชม ทั้งนี้ สำนักงานเขตสุขภาพที่ 12 จะนำข้อมูลที่ “คุกกี้” ได้บันทึกหรือเก็บรวบรวมไว้ ไปใช้ในการวิเคราะห์เชิงสถิติ หรือในกิจกรรมอื่นของ สำนักงานเขตสุขภาพที่ 12 เพื่อปรับปรุงคุณภาพการให้บริการของ สำนักงานเขตสุขภาพที่ 12 ต่อไป
                  </Typography> 
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>การปรับปรุงนโยบายการคุ้มครองข้อมูลส่วนบุคคล</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      สำนักงานเขตสุขภาพที่ 12 อาจทำการปรับปรุงหรือแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคลโดยมิได้แจ้งให้ท่านทราบล่วงหน้า ทั้งนี้ เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ ดังนั้น สำนักงานเขตสุขภาพที่ 12  จึงขอแนะนำให้ผู้ใช้บริการอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคลทุกครั้งที่เยี่ยมชม หรือมีการใช้บริการจากเว็บไซต์ของสำนักงานเขตสุขภาพที่ 12
                  </Typography>  
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>การปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและการติดต่อกับ สำนักงานเขตสุขภาพที่ 12</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      ในกรณีที่ท่านมีข้อสงสัย ข้อเสนอแนะ หรือข้อติชมใดๆ เกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคล หรือการปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ สำนักงานเขตสุขภาพที่ 12 ยินดีที่จะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชมทั้งหลาย อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของสำนักงานเขตสุขภาพที่ 12 ต่อไป โดยท่านสามารถติดต่อกับ สำนักงานเขตสุขภาพที่ 12 ตามที่อยู่ ดังนี้สำนักงานเขตสุขภาพที่ 12 อาคารมหิดลอดุลยเดช เลขที่ 161/1 ถนนรามวิถี ตำบลบ่อยาง อำเภอเมือง จังหวัดสงขลา รหัสไปรษณีย์ 90000 โทร 0-7432-3431 โทรสาร 0-7432-3285 Email: saraban-region12@moph.go.th
                  </Typography>        
                </> : ''
              }
              {
                title === 'นโยบายความเป็นส่วนตัว'  ? 
                <>
                <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',textAlign:'center'}}>นโยบายการคุ้มครองข้อมูลส่วนบุคคลของสำนักงานเขตสุขภาพที่ 12</Typography> 
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      สำนักงานเขตสุขภาพที่ 12 ได้จัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น เพื่อคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการทุกท่าน (Personal Information) ที่ติดต่อเข้ามายังเว็บไซต์ของสำนักงานเขตสุขภาพที่ 12 ดังนี้
                  </Typography> 
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>การเก็บรวบรวมข้อมูลส่วนบุคคล</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      1. เพื่อความสะดวกในการให้บริการแก่ผู้ใช้บริการทุกท่านที่เข้ามาใช้บริการเว็บไซต์ของสำนักงานเขตสุขภาพที่ 12 ทางเว็บไซต์จึงได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้ เช่น อีเมล์แอดเดรส ชื่อ-นามสกุล หรือหมายเลขโทรศัพท์ เป็นต้น
                  </Typography>  
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      2. ในกรณีที่ท่านสมัคร (Sign Up) เพื่อสมัครสมาชิกหรือเพื่อใช้บริการอย่างใดอย่างหนึ่ง สำนักงานเขตสุขภาพที่ 12 จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพิ่มเติม ได้แก่ หมายเลขบัตรประจำตัวประชาชน ประเภทผู้ใช้งาน ตำแหน่ง เป็นต้น
                  </Typography>  
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      3. นอกจากนั้น เพื่อสำรวจความนิยมในการใช้บริการ อันจะเป็นประโยชน์ในการนำสถิติไปใช้ในการปรับปรุงคุณภาพในการให้บริการของสำนักงานเขตสุขภาพที่ 12 จึงจำเป็นต้องจัดเก็บรวบรวมข้อมูลของท่านบางอย่างเพิ่มเติม ได้แก่ หมายเลขไอพี ชนิดของโปรแกรม ค้นผ่าน โดเมนเนม บันทึกหน้าเว็บ ของเว็บไซต์ที่ผู้ใช้เยี่ยมชม เวลาที่เยี่ยมชมเว็บไซต์ และเว็บไซต์ที่ผู้ใช้บริการเข้าถึงก่อนหน้านั้น 
                  </Typography> 
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      4. สำนักงานเขตสุขภาพที่ 12 ขอแนะนำให้ท่านตรวจสอบนโยบายการคุ้มครองข้อมูลส่วนบุคคล ของเว็บไซต์อื่นที่เชื่อมโยงจากเว็บไซต์นี้ เพื่อจะได้ทราบและเข้าใจว่าเว็บไซต์ดังกล่าวเก็บรวบรวม ใช้ หรือดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านอย่างไร ทั้งนี้ สำนักงานเขตสุขภาพที่ 12 ไม่สามารถรับรอง ข้อความ หรือรับรองการดำเนินการใดๆ ตามที่ได้มีการประกาศไว้ในเว็บไซต์ดังกล่าวได้ และไม่ขอรับผิดชอบใดๆ หากเว็บไซต์เหล่านั้นไม่ได้ปฏิบัติการหรือดำเนินการใดๆ ตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่ เว็บไซต์ดังกล่าวได้ประกาศไว้
                  </Typography> 
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>การใช้ข้อมูลส่วนบุคคล</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      1. สำนักงานเขตสุขภาพที่ 12 จะใช้ข้อมูลส่วนบุคคลของท่านเพียงเท่าที่จำเป็น เช่น ชื่อ และ ที่อยู่ เพื่อใช้ในการติดต่อ ให้บริการประชาสัมพันธ์ หรือให้ข้อมูลข่าวสารต่างๆ รวมทั้งสำรวจความคิดเห็นของท่านในกิจการหรือกิจกรรมของ สำนักงานเขตสุขภาพที่ 12 เท่านั้น
                  </Typography>  
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      2. สำนักงานเขตสุขภาพที่ 12 ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของท่านที่ สำนักงานเขตสุขภาพที่ 12 ได้เก็บรวบรวมไว้ ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด เว้นแต่จะได้รับอนุญาตจากท่านเท่านั้น
                  </Typography>  
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      3. ในกรณีที่ สำนักงานเขตสุขภาพที่ 12 ได้ว่าจ้างหน่วยงานอื่นเพื่อให้ดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่าน เช่น การจัดส่งพัสดุไปรษณีย์ การวิเคราะห์เชิงสถิติในกิจการหรือกิจกรรมของ สำนักงานเขตสุขภาพที่ 12 เป็นต้น สำนักงานเขตสุขภาพที่ 12 จะกำหนดให้หน่วยงานที่ได้ว่าจ้างให้ดำเนินการดังกล่าว เก็บรักษาความลับ และความปลอดภัยของข้อมูลส่วนบุคคลของท่าน และกำหนดข้อห้ามมิให้มีการนำข้อมูลส่วนบุคคลดังกล่าว ไปใช้นอกเหนือจากกิจกรรมหรือกิจการของ สำนักงานเขตสุขภาพที่ 12 
                  </Typography>  
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>สิทธิในการควบคุมข้อมูลส่วนบุคคลของท่าน</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      เพื่อประโยชน์ในการรักษาความเป็นส่วนตัวของท่านๆ มีสิทธิเลือกที่จะให้มีการใช้หรือแชร์ข้อมูลส่วนบุคคลของท่าน หรืออาจเลือกที่จะไม่รับข้อมูลหรือสื่อทางการตลาดใดๆ จากสำนักงานเขตสุขภาพที่ 12 ก็ได้ โดยเพียงแต่ท่านกรอกความจำนงดังกล่าวเพื่อแจ้งให้ สำนักงานเขตสุขภาพที่ 12 ทราบในหน้าเว็บ http://www.rh12.moph.go.th/
                  </Typography> 
                  <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'18px',fontWeight:'bold',marginTop:'1rem'}}>การรักษาความปลอดภัยสำหรับข้อมูลส่วนบุคคล</Typography>
                  <Typography 
                    component="p" 
                    variant="p" 
                    color='primary' 
                    sx={{textIndent: '3rem',marginTop:'1rem'}}
                    >
                      เพื่อประโยชน์ในการรักษาความลับและความปลอดภัยสำหรับข้อมูลส่วนบุคคลของท่าน สำนักงานเขตสุขภาพที่ 12  จึงได้กำหนดระเบียบภายในหน่วยงานเพื่อกำหนดสิทธิในการเข้าถึงหรือใช้ข้อมูลส่วนบุคคลของท่าน และเพื่อรักษาความลับและความปลอดภัยของข้อมูลบางอย่างที่มีความสำคัญอย่างยิ่ง สำนักงานเขตสุขภาพที่ 12  จึงได้จัดให้มีช่องทางการสื่อสารแบบปลอดภัยสำหรับข้อมูลดังกล่าวด้วยการเข้ารหัสลับข้อมูลดังกล่าว เช่น จัดให้มีการใช้ Secure Socket Layer (SSL) protocol เป็นต้น
                  </Typography>
                </> : ''
              }
            </Box>
        </Box>
    </Box>
  )
}

export default ModalConditionRegister