import React, { useEffect, useState } from 'react'
import styles from './ModalPayment.module.css'
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import correct from '../../assets/icons/correct.svg'
import { useNavigate } from 'react-router-dom';

export default function ModalPaymentSuccess({ onClose }) {

    const navigate = useNavigate()
    const [counter, setCounter] = useState(5)

    useEffect(() => {
        CountNumber()
    }, [counter])

    const CountNumber = () => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
        if (counter === 0) {
            onClose()
            // navigate('/upgradeSuccess')
            navigate('/profile')
        }
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.modalController} style={{height: '300px'}}>
                <div className={styles.closeButton}>
                    <CloseIcon style={{cursor: 'pointer'}} onClick={() => { onClose(false) }} />
                </div>
                <Box sx={{
                    display: 'grid',
                    justifyItems: 'center',
                    gridGap: '30px'
                }}>
                    <img style={{width: '100px'}} src={correct} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 32, fontWeight: 700 }}>ชำระค่าบริการสำเร็จ</Typography>
                        {/* <Typography sx={{ color: '#747676' }} >ระบบจะพาไปหน้าถัดไปภายใน {counter} วินาที</Typography> */}
                        <Typography sx={{ color: '#747676' }} >ระบบจะพาไปหน้าโปรไฟล์ภายใน {counter} วินาที</Typography>
                    </Box>
                </Box>
            </div>
        </div>
    )
}
