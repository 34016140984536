import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { backgroundModal, boxModal } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';

export default function ModalChangeStateReferStatus({ onClose, onSubmit }) {
    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}>
                    <IconButton onClick={() => { onClose() }}>
                        <ClearIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 600
                    }}>
                        <div>ยืนยันการส่งข้อมูลผู้ป่วยกลับไปยัง</div>
                        <div>สถานพยาบาลต้นทางใช่หรือไม่</div>
                    </Typography>
                    <Box sx={{ display: 'flex', gridGap: '20px', justifyContent: 'center', margin: '30px' }}>
                        <Button variant="outlined" sx={{fontSize: '18px'}} onClick={() => { onClose() }}>ยกเลิก</Button>
                        <Button variant="contained" sx={{fontSize: '18px'}} onClick={() => { onSubmit() }}>ยืนยัน</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}