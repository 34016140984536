import React from 'react'
import styles from './ModalPayment.module.css'
import { Box, Button, Typography } from '@mui/material'
import warning from '../../assets/icons/warningRed.svg'

export default function ModalPaymentFailed({ onClose, onSubmit }) {
    return (
        <div className={styles.overlay}>
            <div className={styles.modalController}>
                <Box sx={{
                    display: 'grid',
                    justifyItems: 'center',
                    gridGap: '30px'
                }}>
                    <img style={{ width: '20%' }} src={warning} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 32, fontWeight: 700 }}>ชำระค่าบริการไม่สำเร็จ</Typography>
                        <Typography sx={{ color: '#747676' }} >เนื่องจาก QR Code หมดอายุ</Typography>
                    </Box>
                    <Button
                        variant='contained'
                        sx={{ width: '50%' }}
                        onClick={() => {
                            onSubmit()
                            onClose()
                        }}
                    >สร้าง QR Code ใหม่</Button>
                </Box>
            </div>
        </div>
    )
}
