import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import loginIcon from '../../assets/icons/login-icon.svg'
import thaiIdIcon from '../../assets/icons/icon-thaiid.svg'
import { contentModal, boxScan } from './Css'
import { useLocation, useNavigate } from 'react-router-dom'
import * as api from '../../services/refer/ApiPublicRefer'
import { notifyError, notifyInfo } from '../../components/NotifyToast'
import LoaderAnimation from '../../components/LoaderAnimation'

function ScanQrCode() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    console.log(location.state)

    const clickScanQrCode = async () => {

        // navigate('/consent', { state: { type: location.state['type_refer'], type_add: 'add' } })
        setLoading(true)
        let res = await api.getConsent()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                window.open(res['data']['url'], '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <Box sx={contentModal}>
            <LoaderAnimation loading={loading} />
            <Box sx={boxScan}>
                <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
                    <img src={loginIcon} style={{ width: '150px', height: '150px' }} alt='' />
                </Box>
                <Typography variant='h6' color='primary' sx={{ fontWeight: 600, textAlign: 'center' }}>
                    สแกน QR Code ด้วย ThaID <br />
                    เพื่อเข้ายินยอมเข้ารับบริการ R12Refer
                </Typography>
                <Box sx={{ margin: '3rem 0', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{
                            display: 'flex',
                            gridGap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '0.5rem 1rem'
                        }}
                        onClick={() => { clickScanQrCode() }}
                    >
                        <img src={thaiIdIcon} style={{ width: '35px', height: '35px' }} alt='' />
                        สแกน QR Code ด้วย Thai ID
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ScanQrCode