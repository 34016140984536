import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function addAppointment(data,idHospital){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/addAppointment/R12Network-dashboard`
        let body = {
            PID: data['pid'],
            Name: data['name'],
            HospitalCode: idHospital ? idHospital : localStorage.getItem('hospital_code'),
            DepartmentCode: data['department_code'],
            AppointDate: data['appoint_date'],
            AppointTime: data['appoint_time'],
            Channel:"R12Network-dashboard"
        }
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard', 
            'Content-Type': 'application/json'
        }

        // console.log(body,idHospital)

        axios.post(url, body, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findDepartment(idHospital){
    console.log(idHospital)
    // console.log("=====> findDepartment ",localStorage.getItem('AccessToken'))
    return new Promise((resolve) => {
        let url =''
        if(!idHospital){
            url = `${domain}/api/v1/microservice/appointment/findDepartment?HospitalCode=${localStorage.getItem('hospital_code')}`
        }else{
            url = `${domain}/api/v1/microservice/appointment/findDepartment?HospitalCode=${idHospital}`
        }
        
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard', 
            'Content-Type': 'application/json'
        }
    
        axios.get(url,{headers: header}).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findOneDepartment(department_code,idHospital) {
    return new Promise((resolve) => {
        // let url = `${domain}/api/v1/microservice/appointment/findDepartment?DepartmentCode=${department_code}`
        let url =''
        if(!idHospital){
            url = `${domain}/api/v1/manage/department?HospitalCode=${localStorage.getItem('hospital_code')}&DepartmentCode=${department_code}`
        }else{
            url = `${domain}/api/v1/manage/department?HospitalCode=${idHospital}&DepartmentCode=${department_code}`
        }
        
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard', 
            'Content-Type': 'application/json'
        }

        axios.get(url,{headers: header}).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })    
}

export function findHospital(){
    return new Promise((resolve) => {
        let url = `${domain}/api/v1/microservice/appointment/findHospital`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`,
            'Platform': 'R12Network-dashboard', 
            'Content-Type': 'application/json'
        }
    
        axios.get(url,{headers: header}).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

