import { Box, Button, FormControl, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoaderAnimation from "../../components/LoaderAnimation";
import { notifyError, notifyInfo, notifySuccess } from "../../components/NotifyToast";
import * as ApiSoS from '../../services/ApiSOS'

export default function ListOfHelpers() {

    const [loading, setLoading] = useState(false)

    const [option, setOption] = useState('รอดำเนินการ')
    const [optionAll, setOptionAll] = useState([
        {
            key: 'รอดำเนินการ',
            value: 'รอดำเนินการ'
        },
        {
            key: 'ดำเนินการแล้ว',
            value: 'ดำเนินการแล้ว'
        }
    ])

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [dataHelpers, setDataHelpers] = useState([])

    useEffect(() => {
        findHelper()
    }, [numPage, option])

    useEffect(() => {
        setPage(1)
    }, [numPage])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(dataHelpers.slice(start, start + numPage))
    }, [page, dataHelpers])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const findHelper = async () => {
        setLoading(true)
        let res = await ApiSoS.findSOS([`State=${option == 'รอดำเนินการ' ? 1 : 2}`, `order=${'CreatedDate'}`, `sort=${'desc'}`])
        setLoading(false)
        if (res) {
            if (res['data']['status']) {
                setDataHelpers(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['data'].length / numPage)))
            } else {
                notifyInfo(res['data']['data'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
        // let data = [
        //     {
        //         PID: "1234567890123",
        //         Name: "นาย ทดลอง ระบบ",
        //         Number: "0912345678",
        //         latitude: "16.8358",
        //         longitude: "100.2518",
        //         url: 'https://www.youtube.com/'
        //     },
        //     {
        //         PID: "1234567890123",
        //         Name: "นาย ทดลอง ระบบ",
        //         Number: "0912345678",
        //         latitude: "16.8358",
        //         longitude: "100.2518",
        //         url: 'https://www.youtube.com/'
        //     }

        // ]
        // setDataHelpers(data)
        // setMaxPage((Math.ceil(data.length / numPage)))
    }

    const updateState = async (SOSCode) => {
        setLoading(true)
        let res = await ApiSoS.updateState(SOSCode)
        setLoading(false)
        if (res) {
            if (res['data']['status']) {
                notifySuccess(res['data']['message'])
                findHelper()
            } else {
                notifyInfo(res['data']['data'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }

    }

    const clickNavigateMap = (locaton) => {
        let parse_lat_lng = locaton.split(',')
        window.open(`https://maps.google.com/?q=${parse_lat_lng[0]},${parse_lat_lng[1]}`, '_system')
    }

    const clickMedicalHistory = (pid) => {
        window.open(`/systemPatientInformation?PID=${pid}`)
    }

    const clickContact = (url) => {
        window.open(url, '_system')
    }

    const clickDone = (SOSCode) => {
        console.log('ดำเนินการแล้ว')
        updateState(SOSCode)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1
                }
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    alignItems: 'flex-end',
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr 1fr',
                    },
                    '@media (max-width: 800px)': {
                        gridTemplateColumns: '1fr',
                    }
                }}>
                    <FormControl>
                        <div>การดำเนินการ</div>
                        <Select
                            size="small"
                            value={option}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setOption(value)
                            }}
                        >
                            {optionAll.map((i, idx) => (
                                <MenuItem key={idx} value={i['key']}>{i['value']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <TableContainer>
                    <Table sx={{ marginTop: 3, minWidth: '1500px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>#</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ชื่อ</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>เลขบัตรประจำตัวประชาชน</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>เบอร์โทรศัพท์</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ตำแหน่งจุดหมาย</TableCell>
                                <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ประวัติการแพทย์</TableCell>
                                {option === 'รอดำเนินการ' ?
                                    <>
                                        <TableCell sx={{ color: '#006838', textAlign: 'center' }}>นำทาง</TableCell>
                                        <TableCell sx={{ color: '#006838', textAlign: 'center' }}>ติดต่อ</TableCell>
                                        <TableCell sx={{ color: '#006838', textAlign: 'center' }}>การดำเนินการ</TableCell>
                                    </>
                                    : null
                                }

                            </TableRow>
                        </TableHead>
                        {showList.map((i, idx) => (
                            <TableBody key={idx}>
                                <TableRow>
                                    <TableCell sx={{ textAlign: 'center' }}>{i['ID']}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{i['Name']}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{i['CID']}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{i['PhoneNumber']}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>{i['Coordinates']}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => { clickMedicalHistory(i['CID']) }}
                                        >ประวัติการแพทย์</Button>
                                    </TableCell>
                                    {option === 'รอดำเนินการ' ?
                                        <>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => { clickNavigateMap(i['Coordinates']) }}
                                                >นำทาง</Button>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Button
                                                    disabled={i?.['ConferenceURL'] ? false : true}
                                                    variant="contained"
                                                    onClick={() => {
                                                        clickContact(i['ConferenceURL'])
                                                    }}
                                                >ติดต่อ</Button>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => { clickDone(i['SOSCode']) }}
                                                >ดำเนินการแล้ว</Button>
                                            </TableCell>
                                        </>
                                        : null
                                    }
                                </TableRow>
                            </TableBody>
                        ))}
                    </Table>
                </TableContainer>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 2
                }}>
                    <Box sx={{ width: '100px' }}>
                        <FormControl fullWidth>
                            <Select
                                value={numPage}
                                onChange={(e) => {
                                    const {
                                        target: { value },
                                    } = e;
                                    setNumPage(value)
                                }}
                                size='small'
                                sx={{ width: '100px', fontSize: '14px' }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2}>
                        <Pagination
                            count={maxPage}
                            page={page}
                            onChange={handlePageChange}
                            shape="rounded"
                            color="primary"
                        />
                    </Stack>
                </Box>
            </Box>
        </>
    )
}