import { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import AuthLayout from './layouts/AuthLayout';
import MenuLayout from "./layouts/MenuLayout";

//routed reduce
// import ProtectedRoute from "./router/ProtectedRoute";
import PublicRoute from './router/PublicRoute'
import PrivateRoute from './router/PrivateRoute'

import Login from './pages/Login';
import Register from './pages/Register';
import PatientHistory from './pages/record_patient/PatientHistory';
import { RECORD_PATIENT_PATH, OUT_PITENT_PATH, CONSULT_PATH, MANAGE_USER_PATH, DRUG_DELIVERY_PATH, SOS_PATH, CONSULT_HOSPITAL_PATH } from './config/constants'
import OutPatient from './pages/out_patient/OutPatient';
import ManageUser from './pages/manage_user/ManageUser';

import Home from './pages/Home';
import TabConsultDoctor from './pages/consult_doctor/TabConsultDoctor';
import SystemPatientInformation from './pages/consult_doctor/SystemPatientInformation';
import Profile from './pages/profile/Profile';
import TreatmentHistory from './pages/consult_doctor/TreatmentHistory';
import AddAppointment from './pages/consult_doctor/AddAppointment';
import DrugDelivery from './pages/drug_delivery/DrugDelivery';
import LoginThaiID from './pages/LoginThaiID';
import ForgetPassword from './pages/forgetpassword/ForgetPassword';
import NewPassword from './pages/forgetpassword/NewPassword';
import AddMedicine from './pages/manage_user/AddMedicine';
import ExaminationRoomQueue from './pages/consult_doctor/queue_system/ExaminationRoomQueue';
import HomeMonitoring from './pages/consult_doctor/HomeMonitoring';
import HelperInformation from './pages/sos/HelperInformation'

import RamaAyutthaya from './pages/consult_hospital/RamaAyutthaya';
import RamaMahidol from './pages/consult_hospital/RamaMahidol'
import RamaPhayathai from './pages/consult_hospital/RamaPhayathai'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment/moment';

//redux
import { useDispatch, useSelector } from 'react-redux';

import { logout } from './store/authSlice';
import LoginOnesId from './pages/login_onesid/LoginOnesId';
import { SystemPatientInformationData } from './pages/consult_doctor/SystemPatientInformationData';
import ConsultHospital from './pages/consult_hospital/ConsultHospital';
import ModalSOS from './components/ModalController/ModalSOS';

import { io } from 'socket.io-client';

import { environment } from './config.env'
import RegisterMastodon from './pages/profile/RegisterMastodon';
import NoneProfileMastodaon from './pages/profile/NoneProfileMastodaon';
import Refer from './pages/refer/refer_patient/Refer'
import ScanQrCode from './pages/refer/ScanQrCode';
import Consent from './pages/refer/Consent';
import AddRefer from './pages/refer/refer_patient/AddRefer';
import Resive from './pages/refer/resive_patient/Resive';
import AddNewCase from './pages/refer/refer_patient/AddNewCase'
import AddNewCaseResive from './pages/refer/resive_patient/AddNewCaseResive'
import DashboardHospital from './pages/refer/dashboard/DashboardHospital';
import DashboardAll from './pages/refer/dashboard/DashboardAll';
import DashboardRefer from './pages/refer/dashboard/DashboardRefer';
import AddResive from './pages/refer/resive_patient/AddResive';
import ViewMedicalHistory from './pages/refer/ViewMedicalHistory';
import SentResiveBack from './pages/refer/resive_patient/SentResiveBack';
import DataReferBack from './pages/refer/refer_patient/DataReferBack';
import DataRefer from './pages/refer/resive_patient/DataRefer';
import SentResiveBackEdit from './pages/refer/resive_patient/SentResiveBackEdit';
import UpgradeEquipment from './pages/payment/UpgradeEquipment';
import UpgradeSuccess from './pages/payment/UpgradeSuccess';
import DataUpgradeHistory from './pages/payment/DataUpgradeHistory';
import ReceiptRequest from './pages/payment/ReceiptRequest';

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

export const MainContext = createContext()


function App() {

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth.user)
  const userData = useSelector((state) => state.auth.user_data)
  const CheckLogin = useSelector((state) => state.auth)

  // console.log(auth)
  // console.log(userData)

  // const [userData, setUserData] = useState(null)
  const [menuState, setMenuState] = useState(null)
  const [initStatePopup, setInitStatePopup] = useState(null)

  const [socket, setSocket] = useState(null)

  const [openModalSOS, setOpenModalSOS] = useState(false)
  const [payloadAlertSOS, setPayLoadAlertSOS] = useState(null)

  // useEffect(() => {
  //   console.log(userData, 'log จาก app js')
  // }, [userData])

  // const expireToken = useSelector((state) => state.auth.user?.auth)

  // console.log(expireToken)

  // useEffect(() => {
  //   // console.log(auth)
  //   if (auth) {
  //     const { ExpireInTimestamp } = auth;
  //     console.log(ExpireInTimestamp)
  //     console.log(moment().unix())
  //     console.log(checkExpireToken(ExpireInTimestamp))
  //     if (checkExpireToken(ExpireInTimestamp)) {
  //       //auto reduce
  //       return
  //     } {
  //       dispatch(logout())
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const socket = io(ENDPOINT_DOMAIN + '/', {
  //     path: "/realtime", //รอ confirm จาก backend
  //     autoConnect: true,
  //     reconnection: true,
  //     transports: ["websocket"]
  //   })

  //   // console.log("====> ",socket)

  //   setSocket(socket)

  //   socket.on("connect_error", () => {
  //     console.log('on : connect_error');
  //     // socket.connect();
  //   });

  //   socket.io.on("reconnect", () => {
  //     console.log('on : reconnect');
  //   });

  //   socket.io.on("connect", () => {
  //     console.log('on : connect');
  //   });

  //   return () => {
  //     console.log('disconnet');
  //     socket.disconnect();
  //   };
  // }, [])


  // useEffect(() => {
  //   if (userData) {
  //     // console.log('userData', userData)

  //     let {
  //       UID
  //     } = userData

  //     //CONDITION CASE SOCKET NULL
  //     if (socket) {
  //       // socket.emit("join-sos-rama", { payload: { uid: UID, platform: 'R12Network-dashboard' } });

  //       // socket.on("join-sos-rama-response", ({ payload }) => {
  //       //   console.log('on : join-sos-rama-response');
  //       //   console.log('join-sos-rama-response payload : ', payload)
  //       // });

  //       // socket use push alert SOS
  //       // socket.emit("push-sos-message", {
  //       //   payload: {
  //       //     uid: "5735512002",
  //       //     phone: "0652410155",
  //       //     email:'jatupat.pn@gmail.com',
  //       //     name: "Jatupat Pannoi",
  //       //     gps:'18.3170581,99.3986862'

  //       //   },
  //       // });

  //       // socket.on('sos-notification', ({ payload }) => {
  //       //   console.log('on : sos-notification : ', payload);
  //       //   //content modal

  //       //   onPlaySound()
  //       //   setOpenModalSOS(true)
  //       //   setPayLoadAlertSOS(payload)
  //       // });

  //     }

  //   }
  // }, [userData])


  // const onPlaySound = function () {
  //   const soundPath = require(`./assets/audioSOS/electronic-school-bell-81033.mp3`);
  //   PlayAudioSound(soundPath);
  // }

  // const PlayAudioSound = function (path) {
  //   const audioElement = new Audio(path);
  //   try {
  //     audioElement.play();
  //   } catch (error) {
  //     console.log(error)
  //   }

  // };


  const checkExpireToken = (expire) => {
    const currentTimestamp = moment().unix();
    return currentTimestamp < expire;
  };

  const handleOpenPopup = (params) => {
    // console.log(params)
    setInitStatePopup(params)
  }

  return (
    <div className='bg__rama__dashboard'>
      <ToastContainer />
      {/* {openModalSOS && <ModalSOS onClose={() => { setOpenModalSOS(false) }} payload={payloadAlertSOS} />} */}
      <Router>
        <MainContext.Provider value={{
          // userData,
          // setUserData,
          menuState,
          setMenuState,
          handleOpenPopup,
          initStatePopup
        }}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="/" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>} />
              <Route path="/register" element={<Register />} />
              <Route path='/loginThaiId' element={<LoginThaiID />} />
              <Route path='/forgetPassword' element={<ForgetPassword />} />
              <Route path='/confirmPassword' element={<NewPassword />} />

              {/*หน้านี้คือแยกออกมาแบบไม่เอาเมนูคือหน้าคิวเข้าห้องตรวจ*/}
              <Route path='/examinationRoomQueue' element={<ExaminationRoomQueue />} />
              <Route path='/systemPatientInformation' element={<SystemPatientInformation />} />
              <Route path='/systemPatientInformationData' element={<SystemPatientInformationData />} />

              <Route path='/loginOnesid' element={<LoginOnesId />} />
              <Route path='/scanQrcode' element={<ScanQrCode />} />
              <Route path='/consent' element={<Consent />} />

              <Route path='/upgradeEquipment' element={<UpgradeEquipment/>} />
              <Route path='/upgradeSuccess' element={<UpgradeSuccess/>} />

              <Route path='/dataUpgradehistory' element={<DataUpgradeHistory/>} />
              <Route path='/receiptRequest' element={<ReceiptRequest/>} />

            </Route>

            <Route
              element={
                <PrivateRoute>
                  <MenuLayout />
                </PrivateRoute>
              }
            >
              {/* /auth/record_patient */}
              <Route path='/auth/home' element={<Home />} />
              <Route path={RECORD_PATIENT_PATH} element={<PatientHistory />} />
              <Route path={CONSULT_PATH} element={<TabConsultDoctor />} />
              <Route path={OUT_PITENT_PATH} element={<OutPatient />} />
              <Route path={MANAGE_USER_PATH} element={<ManageUser />} />
              <Route path={DRUG_DELIVERY_PATH} element={<DrugDelivery />} />
              <Route path={SOS_PATH} element={<HelperInformation />} />

              {/* ปรึกษาแพทย์/พยาบาล */}

              <Route path='/treatmentHistory' element={<TreatmentHistory />} />
              <Route path='/addAppointment' element={<AddAppointment />} />
              <Route path='/homeMonitoring' element={<HomeMonitoring />} />

              {/* profile user */}
              <Route path='/profile' element={<Profile />} />

              {/* การเพิ่มยา */}
              <Route path='/addMedicine' element={<AddMedicine />} />

              <Route path={CONSULT_HOSPITAL_PATH} element={<ConsultHospital />} />


              <Route path='/ramaAyutthaya' element={<RamaAyutthaya />} />
              <Route path='/ramaMahidol' element={<RamaMahidol />} />
              <Route path='/ramaPhayathai' element={<RamaPhayathai />} />
              <Route path='/registerMastodon' element={<RegisterMastodon />} />
              <Route path='/noneProfileMastodon' element={<NoneProfileMastodaon />} />

              {/* ส่งต่อผู้ป่วย */}
              <Route path='/refer' element={<Refer />} />
              <Route path='/addRefer' element={<AddRefer />} />
              <Route path='/addNewcase' element={<AddNewCase />} />
              <Route path='/dataReferBack' element={<DataReferBack/>} />

              {/* รับผู้ป่วย */}
              <Route path='/resive' element={<Resive />} />
              <Route path='/addResive' element={<AddResive />} />
              <Route path='/addNewcaseResive' element={<AddNewCaseResive />} />
              <Route path='/sentResiveBack' element={<SentResiveBack/>} />
              <Route path='/sentResiveBackEdit' element={<SentResiveBackEdit/>} />
              <Route path='/dataRefer' element={<DataRefer/>} />

              <Route path='/viewMedicalHistory' element={<ViewMedicalHistory />} />
              <Route path='/dashboardRefer' element={<DashboardRefer />} />

            </Route>
          </Routes>
        </MainContext.Provider>
      </Router>
    </div>
  );
}

export default App;
