import React, { useEffect, useState } from 'react'
import { Button, Box, TextField, Typography, Grid, FormControl, Select, MenuItem } from "@mui/material"
import { contentProfile, buttomPrimary, buttomSecondary } from './ProfileCss'
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import * as api from '../../services/public_api'
import { useNavigate } from 'react-router-dom'
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '../../components/NotifyToast';
import { IoPersonCircleSharp } from "react-icons/io5";
import ModalUpgradeHealthATM from '../../components/ModalController/ModalUpgradeHealthATM'
import * as api_payment from '../../services/payment/payment_api'

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor: '#FFF1A9',
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function Profile() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)

    const [isEditProfile, setIsEditProfile] = useState(false)
    const [isModalSuccess, setIsModalSuccess] = useState(false)
    const [titleName, setTitleName] = useState([])
    const [department, setDepartment] = useState([])
    const [hospital, setHospital] = useState([])
    const [profile, setProfile] = useState({
        email: '',
        fullname: '',
        title_name: '',
        fname: '',
        lname: '',
        cid: '',
        phone: '',
        department_code: '',
        hospital_code: '',
        hospital_name: '',
        group_role: '',
        group_name: '',
        position: ''
    })

    const formEditUser = [
        {
            label: "อีเมล",
            name: 'email'
        },
        {
            label: "ชื่อ-นามสกุล",
            name: 'fullname'
        },
        {
            label: 'เลขบัตรประจำตัวประชาชน',
            name: 'cid'
        },
        {
            label: 'เบอร์โทรศัพท์',
            name: 'phone'
        },
        // {
        //     label : 'แผนก',
        //     name: 'department_code'
        // },
        {
            label: 'โรงพยาบาล/สถานพยาบาล',
            name: 'hospital_name'
        },
        {
            label: 'สิทธิการใช้งาน',
            name: 'group_role'
        },
        {
            label: 'ตำแหน่ง',
            name: 'position'
        }
    ]

    const [dataPackage, setDataPackage] = useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        checkPackage()
    }, [])

    useEffect(() => {
        findTitleName()
        findDepartment()
        findHospital()

        setProfile({
            email: localStorage.getItem('email'),
            fullname: ` ${localStorage.getItem('title_name')} ${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`,
            title_name: localStorage.getItem('title_name'),
            fname: localStorage.getItem('first_name'),
            lname: localStorage.getItem('last_name'),
            cid: localStorage.getItem('cid'),
            phone: localStorage.getItem('phone'),
            department_code: localStorage.getItem('department_code'),
            hospital_code: localStorage.getItem('hospital_code'),
            hospital_name: localStorage.getItem('hospital_name'),
            group_role: localStorage.getItem('group_role'),
            group_name: localStorage.getItem('group_name'),
            position: localStorage.getItem('position'),
        })
    }, [])

    const findTitleName = async () => {
        setLoading(true)
        let res = await api.findTitleName()
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setTitleName(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async () => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setDepartment(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findHospital = async () => {
        setLoading(true)
        let res = await api.findHospital()
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setHospital(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleModleSuccess = () => {
        setIsModalSuccess(!isModalSuccess)
    }

    const handleProfileEdit = () => {
        setIsEditProfile(!isEditProfile)
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target

        if (name === 'phone') {
            const maxLength = 10;
            const numericRegex = /^[0-9]*$/;
            if (value.length <= maxLength && numericRegex.test(value)) {
                setProfile({ ...profile, [name]: value });
            }
            return
        }
        setProfile({ ...profile, [name]: value })

    }

    const handleSubmitForm = async () => {
        // console.log(profile)
        if (!profile['title_name'] || profile['title_name'] === ' ') {
            return notifyWarning('กรุณากรอก คำนำหน้าชื่อ')
        }
        if (!profile['fname']) {
            return notifyWarning('กรุณากรอก ชื่อ')
        }
        if (!profile['lname']) {
            return notifyWarning('กรุณากรอก นามสกุล')
        }
        if (!profile['phone']) {
            return notifyWarning('กรุณากรอก เบอร์โทรศัพท์')
        }
        if (!profile['department_code']) {
            return notifyWarning('กรุณากรอก แผนก')
        }
        if (!profile['position']) {
            return notifyWarning('กรุณากรอก ตำแหน่ง')
        }
        setLoading(true)
        let res = await api.editProfile(profile['title_name'], profile['fname'], profile['lname'], profile['phone'], profile['department_code'],)
        setLoading(false)
        if (res) {
            console.log("res ====> ", res)
            if (res['data']['code'] === 200) {
                await setIsModalSuccess(!isModalSuccess)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleClickUpgrade = () => {
        setAnchorEl(null)
        setOpenModal(true)
    }

    const checkPackage = async () => {
        setLoading(true)
        let res = await api_payment.checkPackage()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataPackage(res['data']['data'])
            } else {
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <Box sx={contentProfile}>
            <LoaderAnimation loading={loading} />
            {isModalSuccess ? (
                <ModalSuccess
                    title={'แก้ไขข้อมูลผู้ใช้สำเร็จ'}
                    detail={'ท่านได้แก้ไขข้อมูลผู้ใช้เรียบร้อยแล้ว'}
                    closeModal={() => handleModleSuccess()}
                    goPage={'/auth/home'}
                />
            ) : ''}

            {openModal ?
                <ModalUpgradeHealthATM
                    onClose={() => { setOpenModal(false) }}
                    detail={dataPackage}
                />
                : null
            }

            <Box sx={{
                width: { xl: '70%', lg: '70%', md: '70%', sm: '100%', xs: '100%' },
                display: 'grid',
                gridGap: '20px'
            }}>
                <Box sx={{
                    border: '1px solid #E1E3E9',
                    borderRadius: '20px',
                    backgroundColor: '#FFFFFF',
                    padding: '1rem'
                }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gridGap: '10px' }}>
                        <Box sx={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 4fr' }}>
                            <Box sx={{ display: 'grid', justifyItems: 'center' }}>
                                <IoPersonCircleSharp size={80} />
                            </Box>
                            <Box sx={{ display: 'grid', gridGap: '10px' }}>
                                <Typography color={'#006838'} sx={{ fontSize: '18px', fontWeight: 'bold' }}>{profile['fullname']}</Typography>
                                <Typography sx={{
                                    backgroundColor: '#E1F4EB',
                                    borderRadius: '6px',
                                    color: '#006838',
                                    padding: '2px 10px',
                                    width: '70px'
                                }}>Active</Typography>
                                {dataPackage ?
                                    <Typography sx={{
                                        backgroundColor: '#FFF1A9',
                                        borderRadius: '6px',
                                        color: '#006838',
                                        padding: '2px 10px',
                                        maxWidth: '350px'
                                    }}>Health ATM : {dataPackage.day_remain}</Typography>
                                    :
                                    <Typography sx={{
                                        backgroundColor: '#E1F4EB',
                                        borderRadius: '6px',
                                        color: '#006838',
                                        padding: '2px 10px',
                                        width: '150px'
                                    }}>Health ATM Free</Typography>
                                }
                            </Box>
                        </Box>

                        <Box sx={{ display: 'grid', alignItems: 'center' }}>
                            {
                                isEditProfile ? '' : (
                                    <Button
                                        sx={{ width: '180px', height: '40px' }}
                                        onClick={() => handleProfileEdit()}
                                        variant="contained"
                                    >แก้ไขโปรไฟล์</Button>
                                )
                            }
                            {localStorage.getItem('position') === 'Doctor' || localStorage.getItem('position') === 'Nurse' ?
                                // <Button
                                //     sx={{ width: '180px', boxShadow: 'none', height: '40px' }}
                                //     variant='contained'
                                //     color='coloryellow'
                                //     onClick={() => { handleClickUpgrade() }}
                                // >อัปเกรด Health ATM</Button>
                                <>
                                    <Button
                                        sx={{ width: '180px', boxShadow: 'none', height: '40px' }}
                                        variant='contained'
                                        color='coloryellow'
                                        id="demo-customized-button"
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        disableElevation
                                        onClick={handleClick}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >Health ATM</Button>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => { handleClickUpgrade() }} >อัปเกรด Health ATM</MenuItem>
                                        <Divider sx={{ my: 0.5 }} />
                                        <MenuItem onClick={() => { navigate('/dataUpgradehistory') }}>ประวัติการอัปเกรด</MenuItem>
                                    </StyledMenu>
                                </>
                                : null
                            }

                        </Box>
                    </Box>
                </Box>

                {/* <hr style={{ margin: '1.5rem 0', width: '100%', border: '0.5px solid #E1E3E9' }} /> */}
                <Box sx={{
                    border: '1px solid #E1E3E9',
                    borderRadius: '20px',
                    backgroundColor: '#FFFFFF',
                    padding: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '1rem' }
                }}>
                    {
                        isEditProfile ?
                            (
                                <EditProfile
                                    titleName={titleName}
                                    hospital={hospital}
                                    department={department}
                                    EditProfile={profile}
                                    onChange={handleOnChange}
                                    closeForm={() => handleProfileEdit()}
                                    submitForm={() => handleSubmitForm()}
                                />
                            ) :
                            (
                                <Grid container spacing={3}>
                                    {
                                        formEditUser.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Grid item xs={12} sm={6} md={6} xl={5}>
                                                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>{item['label']}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} xl={7}>

                                                    <Typography component="p" variant="p" color={'#747676'} >
                                                        {
                                                            profile[item['name']]
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        ))
                                    }
                                    {/* <hr style={{margin:'1.5rem 0',width:'100%',border: '0.5px solid #E1E3E9'}}/>
                        <Button
                             variant="contained"
                             sx={{width:'60%',margin: '0 auto',padding:'0.5rem 0'}}
                             onClick={() => navigate('/registerMastodon')}
                        >
                            <div style={{display:'flex',justifyContent:'center',gridGap:'1rem',alignItems:'center'}}>
                                <img src={iconMastodon} style={{height:'35px',width:'35px'}} alt=''/>
                                เชื่อมต่อ Mastodon
                            </div>
                        </Button> */}
                                </Grid>
                            )

                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Profile

const EditProfile = (props) => {
    const { titleName, department, hospital, onChange, EditProfile, closeForm, submitForm } = props
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={12} lg={4} xl={3}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>คำนำหน้าชื่อ</Typography>
                    <FormControl fullWidth>
                        <Select
                            name='title_name'
                            value={EditProfile['title_name'] || ' '}
                            onChange={onChange}
                            fullWidth
                            size='small'
                        >
                            <MenuItem value=" ">เลือก</MenuItem>
                            {
                                titleName.map((item, index) => (
                                    <MenuItem key={`T${item['TitleNameID']}`} value={item['TitleName']}>{item['TitleName']}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>ชื่อ</Typography>
                    <TextField fullWidth placeholder="ชื่อ" variant="outlined"
                        type='text'
                        name='fname'
                        value={EditProfile['fname']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>นามสกุล</Typography>
                    <TextField fullWidth placeholder="นามสกุล" variant="outlined"
                        type='text'
                        name='lname'
                        size='small'
                        value={EditProfile['lname']}
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>อีเมล</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="อีเมล" variant="outlined"
                        disabled
                        type='text'
                        name='email'
                        value={EditProfile['email']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>เลขบัตรประจำตัวประชาชน</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="เลขบัตรประจำตัวประชาชน" variant="outlined"
                        disabled
                        type='text'
                        name='cid'
                        value={EditProfile['cid']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>เบอร์โทรศัพท์</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="เบอร์โทรศัพท์" variant="outlined"
                        type='text'
                        name='phone'
                        value={EditProfile['phone']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>แผนก</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <FormControl fullWidth>
                            <Select
                            name='department_code'
                            value={EditProfile['department_code']}         
                            onChange={onChange}                   
                            fullWidth
                        >
                            <MenuItem  value=" ">เลือกแผนก</MenuItem>
                        {
                            department.map((item) => (
                                <MenuItem  key={item['DepartmentTypeCode']} value={item['DepartmentTypeCode']}>{item['DepartmentTypeName']}</MenuItem>
                            ))
                        }   
                        </Select> 
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>โรงพยาบาล/สถานพยาบาล</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="โรงพยาบาล/สถานพยาบาล" variant="outlined"
                        disabled
                        type='text'
                        name='hospital_name'
                        value={EditProfile['hospital_name']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>สิทธิการใช้งาน</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="สิทธิการใช้งาน" variant="outlined"
                        disabled
                        type='text'
                        name='group_name'
                        value={EditProfile['group_name']}
                        size='small'
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{ fontWeight: 'bold' }}>ตำแหน่ง</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth placeholder="ตำแหน่ง" variant="outlined"
                        disabled
                        type='text'
                        name='position'
                        value={EditProfile['position']}
                        size='small'
                        onChange={onChange} />
                </Grid>
            </Grid>
            <hr style={{ margin: '1.5rem 0', width: '100%' }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '1.5rem' }}>
                <Button
                    onClick={closeForm}
                    fullWidth
                    variant="contained"
                    sx={buttomSecondary}
                >
                    {'ยกเลิก'}
                </Button>
                <Button
                    onClick={submitForm}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                >
                    {'บันทึก'}
                </Button>
            </Box>
        </>
    )
}