import React, { useEffect, useRef, useState } from 'react'
import {backgroundModal,boxModal,buttomPrimary} from './ModalAccessCss'
import { Typography,Box,Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import imgOnesId from '../../assets/img/img_modalOnesId.svg'
import { ClipLoader } from 'react-spinners';
import CountDownTime from '../CountDownTime';

function ModalCheckAuthOnesID(props) {
    const  { closeModal } = props
    const navigate = useNavigate()
    const [countDown,setCountDown] = useState(300)
    const timeId = useRef()

    useEffect(() => {
      timeId.current = setInterval(() => {
          setCountDown((prev) => prev - 1)
      },1000)
      return () => clearInterval(timeId.current)
  },[])

  useEffect(() => {
      if(countDown <= 0){
          console.log('time out loading...')
          clearInterval(timeId.current)
          closeModal()
      }
  },[countDown])

  return (
    <Box sx={backgroundModal}>
        <Box sx={{textAlign:'center',...boxModal}}>
        <Box sx={{textAlign:'end','&:hover': {cursor:'pointer'}}} onClick={closeModal}><ClearIcon/></Box>
            <img src={imgOnesId} alt='' style={{margin:'1rem 0'}}/>
            <Typography component="h1" variant="h1" color='primary' sx={{fontSize:'calc(20px + 0.10vw)',fontWeight:'bold',marginBottom:'1rem'}}>
              กรุณากดยืนยันบนแอปพลิเคชัน OnesID
            </Typography>
            <Typography component="h1" variant="h1" color='secondary' sx={{fontSize:'calc(20px + 0.10vw)',marginBottom:'1rem'}}>
              {/* 05:00 นาที */}
              <CountDownTime seconds={countDown}/>
            </Typography>
            <ClipLoader color="#006838" />
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
                <Button onClick={closeModal}
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    ยกเลิก
                </Button>            
            </Box>       
        </Box>
    </Box>
  )
}

export default ModalCheckAuthOnesID