import React from 'react'
import { Grid, TextField, Button, Box } from "@mui/material"

function CardThreeContainer({
    name_btn_top,
    name_btn_bottom,
    icon_path,
}) {
    return (
        <Box
            sx={{
                boxShadow: 3,
                borderRadius: '9px',
                px: 4,
                py: 6,
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
            bgcolor="in_secondary.main"
        >
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                    <Box textAlign="center">
                        <img src={icon_path}/>
                    </Box>
                </Grid>
                <Grid item xs={6} >
                    <Box textAlign="center" >
                        <Button type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: '5px', mb: '5px', borderRadius: '9px' }}>
                            {name_btn_top}
                        </Button>
                    </Box>
                    <Box textAlign="center">
                        <Button type="submit"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: '5px', mb: '5px', borderRadius: '9px' }}>
                            {name_btn_bottom}
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}

export default CardThreeContainer